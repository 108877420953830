import React, { Component } from 'react';
import { connect } from "react-redux";
//import { getLabel } from '../../Lang';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser } from '../../redux/actions';

import { Row, Col,  Tabs } from 'antd';
import HeaderPage from '.././HeaderPage';
import { bind, dataPutAndGo } from '../../Utils';

import Report from './Report';

const { TabPane } = Tabs;

const mapStateToProps = (state) => {
    return {
        currentLang: state.currentLang,
        loginUser: state.loginUser,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLang: (info) => {
            dispatch(setCurrentLang(info));
        },
        setLoginUser: (info) => {
            dispatch(setLoginUser(info));
        },
    };
}

class ReferenceDataPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        bind(this, ['gotoTestPage']);
    }

    componentWillMount() {
        setTimeout(() => {
            this.setState({ data: {} });
        }, 200);
    }

    gotoTestPage = () => {
        dataPutAndGo(this, '/test', { name: 'Van Liao' }, { age: 33 });
    }

    render() {
        //var path = this.props.location.pathname;
       // var tabKey = path.substring(path.lastIndexOf('/') + 1);

        return (
            <div className="pageMain">
                <HeaderPage parentObj={this} telescopic={false} />
                <Row type="flex">
                    <Col className="pageLift" style={this.state.pageLeftScreenWidthStyle} >{this.genMenu2 && this.genMenu2()}</Col>
                    <Col className="pageRight" style={this.state.pageRightScreenWidthStyle}>
                        {this.genPathDiv && this.genPathDiv()}<Report parentObj={this}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

const ConnectedReferenceDataPage = connect(mapStateToProps, mapDispatchToProps)(ReferenceDataPage);

export default withRouter(ConnectedReferenceDataPage);
