import { Select } from 'antd';
import React, { Component } from 'react';
//import lodash from 'lodash';
import './MyComponent.css';

const { Option } = Select;

global.filterSelect = {
    Idx:0,
    filterName:"",
};

const SHOW_TYPE = {
    ALL:'ALL',
    CLEAR:'CLEAR',
    SELECTED:'SELECTED'
};

class MySelect extends Component {
    
        constructor(props) {
          super(props);
          this.state = {
            value: this.props.value,
            options: this.props.options,
            /* 
            { topCount:5, initAll: false }
            */
            topMode: this.formatShowTopMode(this.props.topMode),
            //topMode: this.formatShowTopMode({topCount:5, initAll:false}),
          };
          this.genOptions = this.genOptions.bind(this);
          this.selectChange = this.selectChange.bind(this);
          this.handlerFilterOption = this.handlerFilterOption.bind(this);
          this.matchedCount = 0; //just for topMode
        }
    
        // componentWillMount() {//
        //     //console.log("=====MySelect========componentWillMount============", this.props.name);
        //     this.setState({
        //         value: this.props.value
        //     });
        // }
    
        componentWillReceiveProps(nextProps) {
            this.setState({
                value: nextProps.value,
                options: nextProps.options,
            });
        }

        formatShowTopMode = (mode) => {
            if(mode){
                if(!mode.topCount || mode.topCount < 1){
                    mode.topCount = 20;
                }
                if(mode.initAll == undefined || mode.initAll == null){
                    mode.initAll = true;
                }
                // mode.type = SHOW_TYPE.ALL; //?
                if(mode.initAll){
                    mode.type = SHOW_TYPE.ALL;
                }else{
                    mode.type = SHOW_TYPE.CLEAR;
                }
                //console.log("@@@ mode.type = ", this.props.name,  mode.type);
                return mode;
            }
            return undefined;
        }

        selectChange = (val) => {
            var hander = this.props.onChange;
            var values = val;
            //console.log("################### selectChange: ", val);
            if(this.props.mode == "multiple"){
                var maxSelectCount = this.props.maxSelectCount;
                if(maxSelectCount && maxSelectCount > 0){
                    if(val.length > maxSelectCount){
                        values = val.slice(0, maxSelectCount);
                    }
                }
            }            
            if(hander){
                hander(this.props.name, values, this.props.pageObj);
            }

            var topMode = this.state.topMode;
            if(topMode){
                this.matchedCount = 0;
                if(!topMode.initAll){
                    topMode.type = SHOW_TYPE.SELECTED;
                    this.setState({topMode}, ()=>{
                        topMode.type = SHOW_TYPE.CLEAR;
                        this.setState({topMode});
                    });
                    
                }
            }
        }

        /*****
        ALL
        CLEAR
        SELECTED
        ******/
        genOptions = (showType) => {
            //console.log("# showType is: ", this.props.name, showType);
            if(SHOW_TYPE.CLEAR == showType){
                return;
            }
            var options = this.state.options;
            if(!options){
                options = [];
            }
            var selectedValues = this.state.value;
            if(!selectedValues){
                selectedValues = [];
            }
            var optionList = [];
            for(var index = 0; index < options.length; index++){
                var item = options[index];
                if(SHOW_TYPE.SELECTED == showType){
                    for(var i = 0; i < selectedValues.length; i++){
                        if(selectedValues[i] == item.value){
                            optionList.push(
                                <Option similar={item.similar} key={'_o'+index} value={item.value} >{item.label}</Option>
                            );
                        }
                    }
                }else{
                    optionList.push(
                        <Option similar={item.similar} key={'_o'+index} value={item.value} >{item.label}</Option>
                    );
                }
            }
            return optionList;
        }

        handlerFilterOption = (inputValue, option) => {
            
            var matched = false;
            var topMode = this.state.topMode;
            if(topMode){//top mode
                if(this.matchedCount < topMode.topCount){
                    if(this.props.filterOption){
                        matched = this.props.filterOption(inputValue, option);
                    }else{
                        var lable = option.props.children;
                        if(lable && inputValue && (lable.toLowerCase()).indexOf(inputValue.toLowerCase()) ==0){
                            matched = true;
                        }
                        var similar = option.props.similar;
                        if(!matched && similar && inputValue && (similar.toLowerCase()).indexOf(inputValue.toLowerCase()) ==0){
                            matched = true;
                        }
                    }
                }
                if(matched){
                    this.matchedCount++;
                }
                
                
                
                
                
                
                
            }else{//non top mode
                if(this.props.filterOption){
                    matched = this.props.filterOption(inputValue, option);
                }else{
                    var lable2 = option.props.children;
                    if(lable2 && inputValue && (lable2.toLowerCase()).indexOf(inputValue.toLowerCase()) ==0){
                        matched = true;
                    }
                    var similar2 = option.props.similar;
                    if(!matched && similar2 && inputValue && (similar2.toLowerCase()).indexOf(inputValue.toLowerCase()) ==0){
                        matched = true;
                    }
                }
            }
            return matched;
        }

        searchHandler = (inputValue) => {
            //console.log("# searchHandler, inputValue is:", inputValue, this.state.topMode);
            var topMode = this.state.topMode;
            if(topMode){
                this.matchedCount = 0;
                var type = topMode.type;
                if(!topMode.initAll){
                    if(inputValue.length > 0 && type == SHOW_TYPE.CLEAR){
                        topMode.type = SHOW_TYPE.ALL;
                        this.setState({topMode});
                    }else if(inputValue.length < 1 && type == SHOW_TYPE.ALL){
                        topMode.type = SHOW_TYPE.CLEAR;
                        this.setState({topMode});
                    }
                }

                // if(!topMode.initAll && inputValue.length > 0 && type == SHOW_TYPE.CLEAR){
                //     topMode.type = SHOW_TYPE.ALL;
                //     this.setState({topMode});
                // }else if(!topMode.initAll && inputValue.length < 1 && type == SHOW_TYPE.ALL){
                //     topMode.type = SHOW_TYPE.CLEAR;
                //     this.setState({topMode});
                // }
            }else{
                if(this.props.searchHandlerPromise){
                    var promise = this.props.searchHandlerPromise(inputValue);
                    if(promise && promise.then){
                        promise.then((data) => {
                            //console.log("### data promise: ", data);
                            this.setState({options: data});
                        });
                    }
                }
            }
        }

        focusHandler = () => {
            var topMode = this.state.topMode;
            //console.log("# topMode is: ", this.props.name, topMode);
            if(topMode){
                this.matchedCount = 0;
                if(!topMode.initAll){
                    topMode.type = SHOW_TYPE.CLEAR;
                    this.setState({topMode});
                }
            }else{
                if(this.props.searchHandlerPromise){
                    this.setState({options: []});
                }
            }
        }

        blurHandler = () => {
            var topMode = this.state.topMode;
            //console.log("# topMode is: ", this.props.name, topMode);
            if(topMode){
                this.matchedCount = 0;
                if(!topMode.initAll){
                    topMode.type = SHOW_TYPE.CLEAR;
                    this.setState({topMode});
                }
            }else{
                if(this.props.searchHandlerPromise){
                    this.setState({options: []});
                }
            }
        }

        render() {
            var topMode = this.state.topMode;
            var showType = SHOW_TYPE.ALL;
            if(topMode && !topMode.initAll){
                showType = topMode.type;
            }
            
            return (
                <Select
                    {...this.props}
                    showSearch={true}
                    value={ this.state.value }
                    onChange={this.selectChange}                    
                    filterOption={this.handlerFilterOption}
                    className={"_NMT_myselect " + this.props.className}
                    onSearch={this.searchHandler}
                    onBlur={this.blurHandler}
                    onFocus={this.focusHandler}
                    notFoundContent={null }
                    showArrow = { (this.state.options!=undefined &&  this.state.options.length>0)}
                    menuItemSelectedIcon={' '}
                    allowClear={true}
                    //optionLabelProp="label"
                    //dropdownMatchSelectWidth={false}
                >
                    {this.genOptions(showType) }
                </Select>
            );
        }
    }
 
export default MySelect;
