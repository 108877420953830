import React, { Component } from 'react';
import { connect } from "react-redux";
//import { getLabel } from '../../Lang';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser ,setUserPermission,setRoleCode} from '../../redux/actions';
import '../../index.css';
import MySelect from '../component/MySelect';
import {LEVEL_UI_CONFIG} from '../../DataConfig'
import {GUI_DATE_FORMAT} from '../../Config'
import { Input, Row, Col, Button, Switch, Icon,message ,ConfigProvider, Modal} from 'antd';
//import HeaderPage from '.././HeaderPage';
import { bind, dataPutAndGo, dataGet, getDateStr ,sortDateStr, DATA_SAVE_MSG, CompareSpecials , getPermission } from '../../Utils';
import { checkDataDiff } from '../../UtilDataDiff';
import { get , post} from "../../services/AuthService";
import ResponseTable from '../component/ResponseTable';
import { Tabs } from 'antd';
import lodash from 'lodash';
// import "../../mock/EventApi";
import MyButton from '../component/MyButton';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
moment.locale('en');

const { TabPane } = Tabs;


const mapStateToProps = (state) => {
    return {
        userRoleCode: state.userRoleCode,
        currentLang: state.currentLang,
        loginUser: state.loginUser,
        userPermission : state.userPermission
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLang: (info) => {
            dispatch(setCurrentLang(info));
        },
        setLoginUser: (info) => {
            dispatch(setLoginUser(info));
        },
        setUserPermission:(info) =>{
            dispatch(setUserPermission(info));
        },
        setRoleCode:(info) =>{
            dispatch(setRoleCode(info));
        }
    };
}

class event extends Component {

    constructor(props) {
        super(props);
        this.state = {
            industry : "",
            hideTable : true ,
			eventType : 'All',
        }
        bind(this, ['handleClean', 'handleSearch', 'dataDiffHandler', 'saveHandler']);
        this.props.parentObj.Event = this;
        
        
    }
    messageerror (msg) {
		Modal.error({
		    title: msg,
		    content: (
		      <div>
		        
		      </div>
		    ),
		    onOk() {},
		  });
	}
    messagesuccess (msg) {
		Modal.success({
		    title: msg,
		    content: (
		      <div>
		        
		      </div>
		    ),
		    onOk() {},
		  });
	}
    componentWillMount() {
        dataGet(this);

        var buttonList = null;

        console.log(" getPermission(this.props, >>> " , getPermission(this.props,"eventMaintenanceWriteInd") ) ;
		
        if( getPermission(this.props,"eventMaintenanceWriteInd") ){
            buttonList = [  { title: 'Create New', handler: this.handleNew , style: {cursor: 'pointer'}, icon: 'file-add'}     ];
        }
		
		var eventTypeForm = {};
		eventTypeForm.textInputInd = "Y";
		post(LEVEL_UI_CONFIG.url + '/searchTagging/SearchEventType?entity=', eventTypeForm, this.props.parentObj).then(data => {
					if(data && data.returnType == "SUCESS" ){
						var HKTDCList = [];
						HKTDCList.push({ id: 0, value:"All" , label : 'All'});
						var HKTDCCoulumList = [];
						data.types.map((item,index)=>{
							HKTDCList.push({ id:index+1, value:item.eventTypeEn , label:item.eventTypeEn,
							labelTC:item.eventTypeTc,labelSC:item.eventTypeSc,labelEN:item.eventTypeEn});
							return item;
						});
						
						HKTDCCoulumList = data.types.map((item,index)=>{
							item.id=index;
							item.label=item.eventTypeEn;
							item.value=item.eventTypeEn;
							item.labelTC=item.eventTypeTc;
							item.labelSC=item.eventTypeSc;
							item.labelEN=item.eventTypeEn;
							return item;
						});
						this.setState({ HKTDCList: HKTDCList });
						this.setState({ HKTDCCoulumList: HKTDCCoulumList });
					}
		
		}).catch((e) => {
			message.config({ top: '10%',});
			this.messageerror('Event type is search fail.');
		});
		
		// var HKTDCList = [
		// 	{ id: 0, value:"All" , label : 'All'},
		// 	{ id: 1, label: "Conference & Forum", value: "Conference & Forum"},
		// 	{ id: 2, label: "Exhibition", value: "Exhibition" },
			
		// 	 ];
		// var HKTDCCoulumList = [
		// 	{ id: 1, label: "Conference & Forum", value: "Conference & Forum"},
		// 	{ id: 2, label: "Exhibition", value: "Exhibition" },
			
		// 	 ];
        this.buttonList = buttonList;
	
    }
    componentDidMount(){
		
		this.searchData();
	}

    dataDiffHandler = () =>{
        var table1 = this.tables ? this.tables['event'] : null;
        var editingList = [];
        if(table1){
            editingList = table1.getEditDataList();
        }
        var backupData = [];
        for(var i = 0; i < editingList.length; i++){
            var data = editingList[i];
            backupData.push(data._rt_base_data);
        }
        var bothData = {thisData: backupData, stateData: editingList};
        console.log("########## Event dataDiffHandler is: ", bothData);
        return bothData;
    }

    /* Save you data when logout */
    saveHandler = (callBackHandler, isTimeout) => {
        var table1 = this.tables ? this.tables['event'] : null;
        var editingList = [];
        if(table1){
            editingList = table1.getEditDataList();
        }
        console.log("########## Event saveHandler editingList: ",editingList);        
        /* Save you data when logout */
        for(var i = 0; i < editingList.length; i++){
            var isOk = this.checkData(editingList[i]);
            if(!isOk){
                if(isTimeout == true && callBackHandler){
                    callBackHandler();
                }
                return;
            }
        }
        //check ok
        var name = this.props.loginUser.userName;
        var now = getDateStr();        
        var parentObj = this.props.parentObj;
        var promiseList = editingList.map((item, index) => {
            item.modifiedBy = name;
            item.updatedBy = name;
            item.updatedDateTime = now;
            item.modifiedDateTime = now;
            if(item.id == 0){
                item.createdBy = name;
                item.createdDateTime = now;
            }
            return new Promise(resolve => {
                post( LEVEL_UI_CONFIG.url + '/searchTagging/saveEventData', item, parentObj).then(data => {
                    resolve(data);
                }).catch((e) => {
                    resolve({});
                });
            });
        });
        
        Promise.all(promiseList).then((resultlist) => {
            //close edit model
            if(table1){
                editingList.map((item)=>{
                    table1.stopSaveEditRow(item);
                });
            }
            if(callBackHandler){
                callBackHandler();
            }
        });

    }

    searchData = () => {

    	var name = this.state.industry;
        
		var eventType = this.state.eventType;
       

		if('All' == eventType || !eventType){
			eventType="";
		}
		console.log("encodeURI " , name);
        get(LEVEL_UI_CONFIG.url + '/luceneTag/searchEvent?name='+ encodeURIComponent(name)+'&eventType='+eventType, this.props.parentObj).then(data => {
			this.setState({
				searchList: data.users,
				backUpSearchList: data.users
				
			});
         });
            
        this.setState({
            hideTable : false
        })
    }

	handlerSelectChange = (name, value) => {
		  var stateData = this.state;
		  lodash.set(stateData, name, value);
		  this.setState({ ...stateData });
	 }
	 
    clickRow = (header, rowData, index) => {
        var updateRowId = rowData.id;
        dataPutAndGo(this, '/TagEditPage', { id: updateRowId }, {});
    }

    handleClean = () => {

        //var backUpSearchList = this.state.backUpSearchList;
        this.setState({
            industry: "",
			eventType: 'All',
            searchList: this.state.backUpSearchList
        });
    }

    handleSearch = (hasChecking) => {
        var name = this.state.industry;
        
		var eventType = this.state.eventType;
        if(hasChecking && name == "" ){
			message.config({
			             top: '10%',
			       });
            
            Modal.info({
			    title: 'Please fill in at least one search field',
			    content: (
			      <div>
			        
			      </div>
			    ),
			    onOk() {},
			  });
            return;
        }
        if(!hasChecking && name == "" ){
        	this.searchData();
        	
        }

		if('All' == eventType || !eventType){
			eventType="";
		}
		console.log("encodeURI " , name);
        get(LEVEL_UI_CONFIG.url + '/luceneTag/searchEvent?name='+ encodeURIComponent(name)+'&eventType='+eventType, this.props.parentObj).then(data => {
			this.setState({
				searchList: data.users,
				backUpSearchList: data.users
				
			});
         });
            
        this.setState({
            hideTable : false
        })
    }


    handlerInputChange = (eventPar) => {
        var stateData = this.state;
        var target = eventPar.currentTarget;
        var fieldName = target.name;
        var value = target.value;
        lodash.set(stateData, fieldName, value);
        this.setState({ ...stateData });
    }


    handleNew = (tableObj, infoObj, item) => {

        var datev = getDateStr();
        var industryList = {
            createdBy : 'system',
            eventName: "",
            eventType: "",
            status: 'Active',
            createdDateTime : datev,
            updatedDateTime : datev ,
        };

   

        tableObj.createNewRecord(industryList);
    }

    handleEdit = (tableObj, infoObj, item) => {
        tableObj.openEditModel();
    }

    checkData = (item) => {
        var en = item.eventNameEn;
       
        var type = item.eventType;
        var messageStr = "";

        if( !type ){
            messageStr = messageStr + "Event Name";
        }
        if(!en ){
        	if (messageStr !=""){
        		messageStr = messageStr + ", ";
        	}
        	messageStr = messageStr + "Event Name";
//        	if(!en){
//	            if(messageStr.length != 0){
//	                messageStr = messageStr + ", ";
//	            }
//	            messageStr = messageStr + "Service/Event Name (EN)";
//	        }
//	        if(!tc){
//	            if(messageStr.length != 0){
//	                messageStr = messageStr + ", ";
//	            }
//	            messageStr = messageStr + 'Service/Event Name (\u7E41) ';
//	        }
//	        if(!sc){
//	            if(messageStr.length != 0){ 
//	                messageStr = messageStr + ", ";
//	            }
//	            messageStr = messageStr + 'Service/Event Name (\u7B80)';
//	        }
        }

        if(messageStr.length != 0){
            messageStr = messageStr + "  is required.";
//            message.config({ top: '10%',});
//            message.info(messageStr);
            
            Modal.info({
			    title: messageStr,
			    content: (
			      <div>
			        
			      </div>
			    ),
			    onOk() {},
			  });
            
            
            return false;
        }

        return true;
    }

    handleSave = (item,tableObj ) => {
//        var editList = tableObj.getEditDataList(); //get all edit data list
 		var name=this.props.loginUser.userName;
        var now = getDateStr();
       
//        var en = item.eventNameEn;
//        var sc = item.eventNameSc;
//        var tc = item.eventNameTc;
//        var type = item.eventType;

        if(this.checkData(item) == false){
            return;
        }
        // if( !en || !sc || !tc || !type){
        //     message.config({ top: '10%',});
        //     message.info('Service/Event Type, Service/Event Name (EN), Service/Event Name (�?, Service/Event Name (�? , is required');
        //     return;
        // }

        item.modifiedBy = name;
        item.updatedBy = name;
        item.updatedDateTime = now;
        item.modifiedDateTime = now;
		item.createdBy = name;
		item.createdDateTime = now;
		if (item.status == false || item.status  == "false" || item.status  == "Inactive"){
			item.status='Inactive';
		}else{
			item.status='Active';
		}
		var logEntity = {};
        if(item.id == undefined ){
			logEntity.activity = "Add Service/Event Record";
            logEntity.actionBy = name;
			logEntity.actionDate = now;
            logEntity.actionResult = "Service/Event Name "+item.eventNameEn+" "+item.eventNameTc+" "+item.eventNameSc+" ("+item.status+") has been added.";
            logEntity.leadId = null;
        }else{
			var oldStatus = this.state.oldStatus;
			if(oldStatus!==item.status){
				logEntity.activity = "Update Service/Event Record";
				logEntity.actionBy = name;
				logEntity.actionDate = now;
				logEntity.actionResult = "Service/Event Name "+item.eventNameEn+" "+item.eventNameTc+" "+item.eventNameSc+" ("+oldStatus+") has been updated.";
				logEntity.leadId = null;
			}
			
		}
        item.actionHistoryBean = logEntity;
        
        console.log("actionHistoryBean >>> " , item );
        post( LEVEL_UI_CONFIG.url + '/searchTagging/saveEventData?entity=', item, this.props.parentObj).then(data => {
           
		   tableObj.stopSaveEditRow(item);
		   if("SUCESS" == data.returnType){
			   this.messagesuccess('The Event Name is saved successfully.');
		   }else{
			   this.messageerror('The Event Name failed to be saved.');
		   }
		   this.handleSearch(false);
        }).catch((e) => {
            message.config({ top: '10%',});
            this.messageerror('The Event Name failed to be saved.');
            
        });


//        var promiseList = editList.map((item, index) => {
//            return new Promise(resolve => {
//
//                
//                // post('/event/save', item).then(data => {
//                //     resolve(data);
//                // }).catch((e) => {
//                //     resolve({});
//                // });
		

          

    }

    handlerSwitchChange = (newValue, col, rowData, tableObj) => {

        console.log("newValue >>> " , newValue);
        console.log("col >>> " , col);
        console.log("rowData >>> " , rowData);
        console.log("tableObj >>> " , tableObj);

        tableObj.setFieldValue(rowData, col, newValue);
    }

    handleTableRemove = (row, tableObj) => {
        tableObj.deleteOneRow(row);
        this.handleSave(tableObj);
    }

    handleTableCancel = (row, tableObj) => {
        console.log("# event row: ", row);
        var oriRow = row._rt_base_data;
        var bool = checkDataDiff(oriRow, row, CompareSpecials);
        if(bool){
            this.tableObj = tableObj;
            this.CancelRow = row;
            this.dialogshow12(this);
        }else{
            tableObj.stopCancelEditRow(row);
        }
        // tableObj.stopCancelEditRow(row);
        // this.handleSearch()
    }

    dataCancel = () =>{
        var tableObj = this.tableObj;
        var row = this.CancelRow;
       
        tableObj.stopCancelEditRow(row);
    }

    dataSave = () =>{
        var tableObj = this.tableObj;
        var row = this.CancelRow;
       
        this.handleTableSave(row,tableObj);     
    }

    dialogshow12 = (e) => {
//		this.setState({
//			control12: true
//		})
    	Modal.confirm({
    		title: 'Confirm',
			content: (<span className="dataDiffMessage">{DATA_SAVE_MSG}</span>),
      	  
			onOk() {e.dataSave()},
            closable:false,
            onCancel() {e.dataCancel()},
            okText:'OK',
            cancelText:"Cancel",
            width:"500px",
            //footer={null}
           
            maskClosable:false
      	});
		
    }
    
	
    handlerMySelectChanges = (newValue, col, rowData, tableObj) => {
        tableObj.setFieldValue(rowData, col, newValue);
    }
	
    handleTableSave = (row, tableObj) => {        
        this.handleSave(row,tableObj);        
    }

    handleTableEdit = (row, tableObj) => {
		this.setState({ oldStatus : row.status,  });
        tableObj.startEditRow(row);
    }
    

    render() {

        var tableDatas = this.state.searchList;

        var tableHeader = {
            columns: [
                { field: 'eventType', width: 5, title: 'Service/Event Type' ,
                    editModel: (value, col, rowData, tableObj) => {
                    	if (rowData.id == undefined || rowData.id == 'undefined'){
                    	    return (
                    	        <MySelect
                    	            pageObj={this}
                    	            name="selecType"
                    	            style={{ width: '100%' }}
                    	            placeholder="Select Event Type"
                    	            options={this.state.HKTDCCoulumList}
                    	            value={value=="" || value==null? undefined: value}
                    	            onChange={(name, newValue, pageObj) => this.handlerMySelectChanges(newValue, col, rowData, tableObj)}
                    	        />
                    	    ); 
                    	}else{
							return (<span>{value}</span>);
						}
                    }},
                { field: 'eventNameEn', width: 8, title: 'Service/Event Name',
                    editModel: (value, col, rowData, tableObj) => {
                    	
                    	if (rowData.id == undefined || rowData.id == 'undefined'){
	                        return (undefined); 
                    	}else{
                    		return (<span>{value}</span>);
                    	}
                    }},
                
               
                {
                    field: 'status', width: 2, title: 'Status',
                    editModel: (oldVal, col, rowData, tableObj) => {
                    	
                    	let swVal = true;
                    	console.log("oldVal ", oldVal);
                    	if(oldVal == "Inactive" || oldVal == false || oldVal == "false" ){
                    		swVal = false;
                            
                            
                        }
                    	console.log("swVal ", swVal);
                    	return (
                    			<div  className="_NMT_tableToogleListCell">
                                <Switch onClick={(newValue) => this.handlerSwitchChange(newValue, col, rowData, tableObj)}
                                    checked={swVal} checkedChildren="Active" unCheckedChildren="Inactive" />
                            </div>
                        );
                    },
                    format: (val) => {
                        if(val == false || val == "false" || val == "Inactive" ){
                            val = false;
                        }else{
                            val = true;
                        }
                        return val ? 'Active' : 'Inactive';
                    }
                },
                { field: 'modifiedBy', width: 3, title: 'Modified By',
                    format: (val)=>{
                        if(val){
                            val = val.split('(')[0]
                        }
                        return val;
                    },
                    editModel: (value, col, rowData, tableObj)=>{
                    	if(value){
                    		value = value.split('(')[0]
                        }
                    
                        return(
                            <span>{value}</span>
                        )
                    }
                 },
                { field: 'modifiedDateTime', width: 3, title: 'Modified Date',
                    format : (val) => {
                        var temp = moment(val, 'YYYY-MM-DD HH:mm'); //:ss
                        val = moment(temp ).format(GUI_DATE_FORMAT);
                        return val;
                    },
                    editModel: (value, col, rowData, tableObj)=>{
                        return(
                            <span></span>
                        )
                    },
                    sortHandler:(val)=>{
                        return sortDateStr(val);
					}
                 },
                { field: 'action', width: 3, title: 'Action', sort: false,
                    rowHandler: (col, rowData, tableObj)=>{//the value also can get from rowData
                        return (<div className="_NMT_tableButtonsListCell">
                            { <MyButton onClick={()=>this.handleTableEdit(rowData, tableObj)} type="primary">Edit</MyButton> }
                            {/* <MyButton onClick={()=>this.handleTableRemove(rowData, tableObj)} type="primary">Remove</MyButton> */}
                        </div>);
                    },
                    editModel: (value, col, rowData, tableObj)=>{//the value also can get from rowData
                        return (<div  className="_NMT_tableButtonsListCell">
                            <MyButton onClick={()=>this.handleTableSave(rowData, tableObj)} type="primary">Save</MyButton>&nbsp;
                            <MyButton onClick={()=>this.handleTableCancel(rowData, tableObj)} type="primary">Cancel</MyButton>
                        </div>);
                    },
                }
                
            ]
        };

        

        



        return (
            <div  >
                <Row className="searchPanelReport">
					
                    <Col  className="taggingMainlabel" xs={24} sm={4} md={4} lg={4} xl={4} xxl={4}>Keywords<span style={{color:'red'}}>*</span></Col>
                    <Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                        <Input name="industry"  placeholder="Keywords of Service/Event Type, Service/Event Name" onChange={this.handlerInputChange} value={this.state.industry} />
                    </Col>
					<Col className="label" xs={0} sm={0} md={0} lg={2} xl={2} xxl={2}></Col>
                </Row>
				
                <Row className="searchButtonPanel" >
                    
                        <div  >
                            <Button onClick={this.handleClean} className="oneButton" type="primary">Reset</Button>
                            &nbsp;
                            <Button onClick={() => this.handleSearch(false)} className="oneSearchButton" type="primary"><Icon type="search" className="oneSearchIcon"/><span className="oneSearchTitle">Search</span></Button>
                        </div>
                    
                </Row>
                <div className="leadResultTableList">
                <div style={{ display: !this.state.hideTable ? '' : 'none'}} >
                    <ConfigProvider locale={locale}>
                        <ResponseTable
                        sort={{index:0, orderBy:true}}
                            name="event"
                            pageObj={this}
                            //handlerClick={this.clickRow} 
                            //buttons={[{ title:'Create New',  handler: this.handleNew}]} 
                           genFooter={false}
                            data={tableDatas}
                        headerHeight={'60px'}
                            header={tableHeader}
                            pageMode={1} //0:不�?�? 1: 客户端�?�?(default), 2: ?�务端�?�?
                            //handlerClick={this.clickRow} 
                            onePageCount={25} //record count one page, default: 10
                            selectPageIndex={1} //default selected page index, default: 1
//                            selectMode={4}
                            // sort={{index:0, orderBy:true }}
                            buttons={this.buttonList}
                           
                        />
                    </ConfigProvider>
                </div>
                </div>
                <div class="normalFooter">Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>

                <Row>
				&nbsp;
				</Row>

                

            </div>
        );
    }
}

const ConnectedEvent = connect(mapStateToProps, mapDispatchToProps)(event);

export default withRouter(ConnectedEvent);
