import React, { Component } from 'react';
import moment from 'moment';
import { connect } from "react-redux";
//import { getLabel } from '../../Lang';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser, setUserPermission, setRoleCode } from '../../redux/actions';
import './css/SuccessStoryLeads.css';
import './css/SuccessStoryLeadsDetail.css';
import './css/head.css';
import { Row, Col, Button, Input, Tabs, Icon, Modal, Radio,  ConfigProvider,List, Tooltip } from 'antd';
//import MySelect from '../component/MySelect';
import BraftEditor from 'braft-editor';
import HeaderPage from '../HeaderPage';
import { bind, dataPutAndGo, STATUS_, Separator, getQueryVariable, getDateStr, getDepartmentName, getPermission, removeFooter } from '../../Utils';
//import Dialog from 'react-movable-modal';
// import "../../mock/SucessStroyApi";
import { post, get } from "../../services/AuthService";
import { successStory_UI_CONFIG, LEVEL_UI_CONFIG } from '../../../src/DataConfig'
import locale from 'antd/es/date-picker/locale/zh_CN';
import { checkDataDiff } from '../../UtilDataDiff';
import logoBackW from './img/btnBack_w.png';
import logoBackG from './img/btnBack_g.png';
import logoEditW from './img/btnEdit_w.png';
import logoEditG from './img/btnEdit_g.png';

import logoReviewW from './img/btnReview_w.png';
import logoReviewG from './img/btnReview_g.png';

import logoCrossW from './img/btnCross_w.png';
import logoCrossG from './img/btnCross_g.png';

import { Base64 } from 'js-base64';
moment.locale('en');

const { TabPane } = Tabs;

const mapStateToProps = (state) => {
	return {
		userRoleCode: state.userRoleCode,
		currentLang: state.currentLang,
		loginUser: state.loginUser,
		userPermission: state.userPermission
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		setCurrentLang: (info) => {
			dispatch(setCurrentLang(info));
		},
		setLoginUser: (info) => {
			dispatch(setLoginUser(info));
		},
		setUserPermission: (info) => {
			dispatch(setUserPermission(info));
		},
		setRoleCode: (info) => {
			dispatch(setRoleCode(info));
		}

	};
}

class SuccessStoryDetail extends Component {

	constructor(props) {
		super(props);

		this.state = {
			sessionOpenBasicInfo: true,
			sessionOpenCompanyInfo: true,
			sessionOpenHKTDCInvo: true,
			sessionOpenStoryDesc: true,
			sessionOpenStoryPublic: true,
			sessionOpenOtherInfo: true,
			showUsage: false,
			showSendSubRem: false,
			otherTypeOfSuccessCaseVisable: false,
			titleMessage: "",
			controlTierInfo: false,
			kpiTopicControlList:[],
			isTooltipOpen:false,
			gui: {
				serviceHide2: false, serviceHide3: false, serviceHide4: false, serviceHide5: false, showAdd: true, showAdd2: true,
				ChanHide2: false, ChanHide3: false, ChanHide4: false, ChanHide5: false, reject01: false, reject02: false,
				ChanHide6: false, ChanHide7: false, ChanHide8: false, ChanHide9: false, ChanHide10: false, cmpInfoHide: true, mandatoryKpi: true, mandatoryKpiOversea: true
			}
		}

		// var hisList = [{ value: 1, label: "Reject for info" }, { value: 2, label: "Reject for quality" }];

		// var ccList = [{ value: 1, label: "519-85125379" }, { value: 2, label: "519-85129131" }, { value: 3, label: "592-88887777" }];
		// this.state = { control4: false, hisList: hisList, ccList: ccList };
		// this.setState({ hisList: hisList, ccList: ccList });
		// var HKTDCList = [{ id: 1, label: "Chan Uk", value: "Chan Uk" }, { id: 2, label: "Hung Fa Leng Robin's Nest", value: "22" }, { id: 3, label: "Chau Tau", value: "33" }, { id: 4, label: "Nam Fung Wan", value: "44" }, { id: 5, label: "Ngau Liu", value: "55" }, { id: 6, label: "Pak Wan", value: "66" }];

		// this.setState({ HKTDCList: HKTDCList });


		bind(this, ['gotowriteStoryPage', 'handleCancel']);
		removeFooter();
		window.scrollTo(0, 0);
	}

	componentWillMount() {
		var leadId = getQueryVariable('leadId');
		//console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!@@@@@@@@@@@@@@leadId:", leadId);
		var dataObj = this.props.location.query;
		if (!dataObj) {
			dataObj = {};
		}
		var Oridata = dataObj.stateData;
		if (!Oridata) {
			Oridata = {};
			Oridata.item = {};
		}
		
		if(Oridata.isComingFromLeadStatusReport!=undefined){
			
			this.state.isComingFromLeadStatusReport=Oridata.isComingFromLeadStatusReport;
			console.log("isComingFromLeadStatusReport setState", this.state, Oridata);
		
		}
		console.log("isComingFromLeadStatusReport", this.state, Oridata);
		var returnMsg = Oridata.returnMsg;
		if (leadId == false) {
			leadId = Oridata.id;
		}
		get(LEVEL_UI_CONFIG.url + '/ssls/getKpiTopicControl', this).then(data => {

			if (data) {				

				this.state.kpiTopicControlList = data;
			}

		}).catch((e) => {
			console.log(e);
		});

		if (leadId != false && leadId != undefined) {
			var entity = {};
			entity.id = leadId;
			post(successStory_UI_CONFIG.url + '/ssls/searchByid?', entity, this).then(dataEntity => {
				console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", dataEntity);
				if (dataEntity.returnType === 'FAIL') {
					
					if(this.state.isComingFromLeadStatusReport!=undefined){
						dataPutAndGo(this, '/leadStatusReport', {}, {}, false);						
					}else{					
						dataPutAndGo(this, '/successStoryLeads', {}, {}, false);
					}



				} else {
					if (!dataEntity.users) {
						dataEntity.users = {}
					}
					var data = dataEntity.users[0];//dataObj.stateData;
					console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", data);
					if (!data) {
						data = {};
					}

					var isHongKongInd = data.isHongKongInd;
					if (isHongKongInd == "true" || isHongKongInd == true) {
						data.isHongKongInd = true;
					} else {
						data.isHongKongInd = false;
					}

					var isThereTdcInd = data.isThereTdcInd;
					if (isThereTdcInd == "true" || isThereTdcInd == true) {
						data.isThereTdcInd = true;
					} else {
						data.isThereTdcInd = false;
					}

					var usedLeadExternally = data.usedLeadExternally;
					if (usedLeadExternally == "true" || usedLeadExternally == true) {
						data.usedLeadExternally = true;
					} else {
						data.usedLeadExternally = false;
					}

					var usageContentUpdated = data.usageContentUpdated;
					if (usageContentUpdated == "true" || usageContentUpdated == true) {
						data.usageContentUpdated = true;
					} else {
						data.usageContentUpdated = false;
					}

					var sendSubRem = data.sendSubRem;
					if (sendSubRem == "true" || sendSubRem == true) {
						data.sendSubRem = true;
					} else {
						data.sendSubRem = false;
					}

					var expfurdev = data.expfurdev;
					if (expfurdev == "true" || expfurdev == true) {
						data.expfurdev = true;
					} else {
						data.expfurdev = false;
					}

					var status = data.status;
					var titleMessage = "";
					var selfTitle = "Success Story Detail #" + data.id;
					if (returnMsg) {
						titleMessage = returnMsg;
					} else {
						if (status == STATUS_.Reviewing) {
							titleMessage = "Success story lead ID #" + data.id + " being reviewed by "+data.lastModifiedBy.split('(')[0]+".";
						} else if (status == STATUS_.Submitted) {
							titleMessage = "Success story lead ID #" + data.id + " is pending review.";
						}
						else if (status == STATUS_.Reopened) {
							titleMessage = "Success story lead ID #" + data.id + " is reopened.";
						}
						else if (status == STATUS_.Approved) {
							titleMessage = "Success story lead ID #" + data.id + " is approved.";
						}
						else if (status == STATUS_.Published) {
							titleMessage = "Success story lead ID #" + data.id + " is published.";
						}
						else if (status == STATUS_.Archived) {
							titleMessage = "Success story lead ID #" + data.id + " is archived.";
						} else if (status == STATUS_.Closed) {
							titleMessage = "Success story lead ID #" + data.id + " is closed.";
						}
					}

					this.hidePart(data);

					var htmlContentn = BraftEditor.createEditorState(data.storyDescription).toHTML();
					console.log("htmlContentn", htmlContentn);

					while (htmlContentn.indexOf("<p></p>") >= 0) {
						htmlContentn = htmlContentn.replace("<p></p>", "<br/>");
					}

					let hasFocus3Deals = false;

					if (data.topicTheme1 &&
					//	(data.topicTheme1 == "Focus 3 Deals")
						(this.state.kpiTopicControlList.includes(data.topicTheme1))
						) {
							hasFocus3Deals = true;
					}
					if (data.topicTheme2 &&
						//(data.topicTheme2 == "Focus 3 Deals")
						(this.state.kpiTopicControlList.includes(data.topicTheme2))
						) {
							hasFocus3Deals = true;
					}
					if (data.topicTheme3 &&
						//(data.topicTheme3 == "Focus 3 Deals")
						(this.state.kpiTopicControlList.includes(data.topicTheme3))
						) {
							hasFocus3Deals = true;
					}
					if (data.topicTheme4 &&
						//(data.topicTheme4 == "Focus 3 Deals")
						(this.state.kpiTopicControlList.includes(data.topicTheme4))
						) {
							hasFocus3Deals = true;
					}
					if (data.topicTheme5 &&
						//(data.topicTheme5 == "Focus 3 Deals")
						(this.state.kpiTopicControlList.includes(data.topicTheme5))
						) {
							hasFocus3Deals = true;
					}
					
					let gui = this.state.gui;
					gui.mandatoryKpi=false;
					gui.mandatoryKpiOversea=false;
					if(hasFocus3Deals){
						gui.mandatoryKpiOversea=true;
					
					}		
				
				console.log("KPI Oversea Mand:", hasFocus3Deals, gui.mandatoryKpiOversea);


					this.setState({
						gui:gui,
						data: data,
						titleMessage: titleMessage,
						selfTitle: selfTitle,
						leadDesc: htmlContentn,
						allowUpdate: dataEntity.allowUpdate

					});
					setTimeout(() => {
						this.setState({ titleMessage: '' })
					}, 10000);
				}

				//this.setState({ data: data.users });
			});
		} else {
			this.setState({
				data: {}
			});
		}

	}
	showTierInfo = () => {
		this.setState({
			controlTierInfo: true,
		}
		);
	}
	closeTierInfo = () => {
		this.setState({
			controlTierInfo: false,
		}
		);
	}

	copyGotowriteStoryPage = (item, model) => {

		var parName = null;
		var loginUser = this.props.loginUser;
		//let departmentName = getDepartmentName(this.props);
		parName = loginUser.userName;
		get(successStory_UI_CONFIG.url + '/ssls/searchByidAndEditMode?id=' + item.id + '&&name=' + parName + '&&status=1', this).then(result => {
			item.dataType1 = "Copy";
			item.editorState = BraftEditor.createEditorState(item.storyDescription != undefined ? item.storyDescription : "");
			console.log("isComingFromLeadStatusReport", this.state);
			if(this.state.isComingFromLeadStatusReport!=undefined){
				item.isComingFromLeadStatusReport = "Y";				
			}
			if(this.state.isComingFromLeadStatusReport!=undefined){
				item.isComingFromLeadStatusReport="Y";
			}
			dataPutAndGo(this, '/writeStory', { item: item }, {});
		}).catch((e) => {
			this.setState({ titleMessage: "Story is failed to proceed, please contact system admin." });
			setTimeout(() => {
				this.setState({ titleMessage: '' })
			}, 10000);
		});



	}



	gotowriteStoryPage = (item, model) => {
		if (model && model == "updateUsageOnly") {
			item.dataType1 = "updateUsageOnly";
		}
		var ids = item.id;
		var userName = this.props.loginUser.userName;
		item.currUser = userName;
		console.log("Test Base 64", item.storyDescription);
		item.storyDescription = Base64.encode (item.storyDescription);
		item.url = Base64.encode (item.url);
		item.url1 = Base64.encode (item.url1);
		item.url2 = Base64.encode (item.url2);
		item.usageUrl1 = Base64.encode (item.usageUrl1);
		item.usageUrl2 = Base64.encode (item.usageUrl2);
		item.usageUrl3 = Base64.encode (item.usageUrl3);
		item.usageUrl4 = Base64.encode (item.usageUrl4);
		item.usageUrl5 = Base64.encode (item.usageUrl5);
		item.usageUrl6 = Base64.encode (item.usageUrl6);
		item.usageUrl7 = Base64.encode (item.usageUrl7);
		item.usageUrl8 = Base64.encode (item.usageUrl8);
		item.usageUrl9 = Base64.encode (item.usageUrl9);
		item.usageUrl10 = Base64.encode (item.usageUrl10);
		item.channelDesc1 = Base64.encode (item.channelDesc1);
		item.channelDesc2 = Base64.encode (item.channelDesc2);
		item.channelDesc3 = Base64.encode (item.channelDesc3);
		item.channelDesc4 = Base64.encode (item.channelDesc4);
		item.channelDesc5 = Base64.encode (item.channelDesc5);
		item.channelDesc6 = Base64.encode (item.channelDesc6);
		item.channelDesc7 = Base64.encode (item.channelDesc7);
		item.channelDesc8 = Base64.encode (item.channelDesc8);
		item.channelDesc9 = Base64.encode (item.channelDesc9);
		item.channelDesc10 = Base64.encode (item.channelDesc10);
		item.remarks = Base64.encode (item.remarks);
		item.rejectReopenRemake = Base64.encode (item.rejectReopenRemake);
		if(item.emailReceiver!=undefined){
			item.emailReceiver = Base64.encode (item.emailReceiver);
		}
		
		
		console.log("Test Base 64", item.storyDescription);
		if (ids != null) {
			post(successStory_UI_CONFIG.url + '/ssls/searchByidAndVersion', item).then(data => {
				if (data && data.returnType == "SUCESS") {
					if(this.state.isComingFromLeadStatusReport!=undefined){
						item.isComingFromLeadStatusReport="Y";
					}
					dataPutAndGo(this, '/writeStory', { item: item }, { num: 33 });
				} else {

					this.setState({ titleMessage: data.message, });
					if (this.timer) {
						clearTimeout(this.timer);
					}
					this.timer = setTimeout(() => {
						this.setState({
							titleMessage: ''
						});
					}, 10000);

				}
			});

		}

	}

	goRecevingStoryPage = (item, model) => {
		
		if(this.state.isComingFromLeadStatusReport!=undefined){
				item.isComingFromLeadStatusReport="Y";
		}
		dataPutAndGo(this, '/writeStory', { item: item }, { num: 33 });
	}

	messageinfo(msg) {
		Modal.info({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}

	genHKTDC = (id) => {

		var hkTdcList = this.state.HKTDCList;
		var result = '';
		if (!hkTdcList) {
			hkTdcList = [];
		}

		hkTdcList.map((item, index) => {
			if (id == item.value) {
				result = item.label;
			}
			return null;
		});

		return (
			<label>{result}</label>
		);
	}

	genLocation = (conntryIdList) => {

		var conntryList = this.state.conntryList;
		var result = '';
		if (!conntryIdList) {
			conntryIdList = [];
		}
		if (!conntryList) {
			conntryList = [];
		}
		conntryIdList.map((conntryId, index) => {
			conntryList.map((conntryItem, conntryIndex) => {
				if (conntryId == conntryItem.id) {
					if (index != 0) {
						result += ",";
					}
					result += conntryItem.label;
				}
				return null;
			});
			return null;
		});

		return (
			<label>{result}</label>
		);
	}

	genText = (datas) => {
		if (!datas) {
			datas = [];
		}

		return (
			<label>{datas}</label>
		);
		//});
	}

	handleCancel() {
		var stateData = this.state.data;
		if (stateData) {
			var item = stateData.item;
			if (item) {
				var pageSource = item.pageSource;
				if (pageSource) {
					if(this.state.isComingFromLeadStatusReport!=undefined){
						dataPutAndGo(this, '/leadStatusReport', {}, {});
					}else{
			
						dataPutAndGo(this, pageSource, {}, {});
					}
				}
			}
		}
	}
	backToTop = () => {

		window.scrollTo(0, 0);
	}


	dialogClose = () => {
		this.setState({
			control4: false
		})
	}


	gotoSearchStory = (item) => {
		if(this.state.isComingFromLeadStatusReport!=undefined){
			dataPutAndGo(this, '/leadStatusReport', {}, {});
		}else{
			dataPutAndGo(this, '/successStoryLeads', { item: item });
		}
	}

	isNotEmpty = (str) => {
		return (str == undefined || str == "" || str.length == 0) ? false : true;
	}
	cmpInfoHideCtr = () => {
		var gui = this.state.gui;
		gui.cmpInfoHide = !gui.cmpInfoHide;

		this.setState({
			gui: gui
		});
	}
	serviceAdd = () => {
		var data = this.state.data;
		var gui = this.state.gui;
		if (this.isNotEmpty(data.eventName2) || this.isNotEmpty(data.eventDate2) || this.isNotEmpty(data.eventType2)) {
			gui.serviceHide2 = true;
		}
		if (this.isNotEmpty(data.eventName3) || this.isNotEmpty(data.eventDate3) || this.isNotEmpty(data.eventType3)) {
			gui.serviceHide3 = true;
		}
		if (this.isNotEmpty(data.eventName4) || this.isNotEmpty(data.eventDate4) || this.isNotEmpty(data.eventType4)) {
			gui.serviceHide4 = true;
		}
		if (this.isNotEmpty(data.eventName5) || this.isNotEmpty(data.eventDate5) || this.isNotEmpty(data.eventType5)) {
			gui.serviceHide5 = true;
		}
		gui.showAdd = false;
		this.setState({
			gui: gui
		});
	}


	dialogClose5 = () => {
		this.setState({
			control5: false
		})
		var data = this.state.data;
		if(this.state.isComingFromLeadStatusReport!=undefined){
			data.isComingFromLeadStatusReport="Y";
		}
		dataPutAndGo(this, '/successStoryLeads/storyDetail', data);
	}

	serviceLess = () => {
		var gui = this.state.gui;
		gui.serviceHide2 = false;
		gui.serviceHide3 = false;
		gui.showAdd = true;
		this.setState({
			gui: gui
		});
	}


	chanLess() {
		var gui = this.state.gui;
		gui.ChanHide2 = false; gui.ChanHide3 = false;
		gui.ChanHide4 = false; gui.ChanHide5 = false;
		gui.ChanHide6 = false; gui.ChanHide7 = false;
		gui.ChanHide8 = false; gui.ChanHide9 = false;
		gui.ChanHide10 = false; gui.showAdd2 = true;
		this.setState({
			gui: gui
		});
	}

	chanAdd() {
		var data = this.state.data;
		var gui = this.state.gui;

		if (this.isNotEmpty(data.channels2) || this.isNotEmpty(data.usageAttachment2) || this.isNotEmpty(data.usageUrl2) || this.isNotEmpty(data.channelDesc2)) {
			gui.ChanHide2 = true;
		}
		if (this.isNotEmpty(data.channels3) || this.isNotEmpty(data.usageAttachment3) || this.isNotEmpty(data.usageUrl3) || this.isNotEmpty(data.channelDesc3)) {
			gui.ChanHide3 = true;
		}
		if (this.isNotEmpty(data.channels4) || this.isNotEmpty(data.usageAttachment4) || this.isNotEmpty(data.usageUrl4) || this.isNotEmpty(data.channelDesc4)) {
			gui.ChanHide4 = true;
		}
		if (this.isNotEmpty(data.channels5) || this.isNotEmpty(data.usageAttachment5) || this.isNotEmpty(data.usageUrl5) || this.isNotEmpty(data.channelDesc5)) {
			gui.ChanHide5 = true;
		}
		if (this.isNotEmpty(data.channels6) || this.isNotEmpty(data.usageAttachment6) || this.isNotEmpty(data.usageUrl6) || this.isNotEmpty(data.channelDesc6)) {
			gui.ChanHide6 = true;
		}
		if (this.isNotEmpty(data.channels7) || this.isNotEmpty(data.usageAttachment7) || this.isNotEmpty(data.usageUrl7) || this.isNotEmpty(data.channelDesc7)) {
			gui.ChanHide7 = true;
		}
		if (this.isNotEmpty(data.channels8) || this.isNotEmpty(data.usageAttachment8) || this.isNotEmpty(data.usageUrl8) || this.isNotEmpty(data.channelDesc8)) {
			gui.ChanHide8 = true;
		}
		if (this.isNotEmpty(data.channels9) || this.isNotEmpty(data.usageAttachment9) || this.isNotEmpty(data.usageUrl9) || this.isNotEmpty(data.channelDesc9)) {
			gui.ChanHide9 = true;
		}
		if (this.isNotEmpty(data.channels10) || this.isNotEmpty(data.usageAttachment10) || this.isNotEmpty(data.usageUrl10) || this.isNotEmpty(data.channelDesc10)) {
			gui.ChanHide10 = true;
		}

		gui.showAdd2 = false;
		this.setState({
			gui: gui
		});

	}

	hidePart = (data) => {
		var hidePart1 = false;
		//var data = this.state.data;
		//var gui  = this.state.gui;
		if (this.isNotEmpty(data.eventName2) || this.isNotEmpty(data.eventDate2) || this.isNotEmpty(data.eventType2)) {
			hidePart1 = true;
		}

		// else if( this.isNotEmpty(data.eventName3) || this.isNotEmpty(data.eventDate3) || this.isNotEmpty(data.eventType3) ){
		// 	hidePart1 = true;
		// }

		var hidePart2 = false;

		if (this.isNotEmpty(data.channels2) || this.isNotEmpty(data.usageAttachment2) || this.isNotEmpty(data.usageUrl2) || this.isNotEmpty(data.channelDesc2)) {
			hidePart2 = true;
		}



		// else if( this.isNotEmpty(data.channels3) || this.isNotEmpty(data.usageAttachment3) || this.isNotEmpty(data.usageUrl3) || this.isNotEmpty(data.channelDesc3) ){
		// 	hidePart2 = true;
		// }else if( this.isNotEmpty(data.channels4) || this.isNotEmpty(data.usageAttachment4) || this.isNotEmpty(data.usageUrl4) || this.isNotEmpty(data.channelDesc4) ){
		// 	hidePart2 = true;
		// }else if( this.isNotEmpty(data.channels5) || this.isNotEmpty(data.usageAttachment5) || this.isNotEmpty(data.usageUrl5) || this.isNotEmpty(data.channelDesc5) ){
		// 	hidePart2 = true;
		// }else if( this.isNotEmpty(data.channels6) || this.isNotEmpty(data.usageAttachment6) || this.isNotEmpty(data.usageUrl6) || this.isNotEmpty(data.channelDesc6) ){
		// 	hidePart2 = true;
		// }else if( this.isNotEmpty(data.channels7) || this.isNotEmpty(data.usageAttachment7) || this.isNotEmpty(data.usageUrl7) || this.isNotEmpty(data.channelDesc7) ){
		// 	hidePart2 = true;
		// }else if( this.isNotEmpty(data.channels8) || this.isNotEmpty(data.usageAttachment8) || this.isNotEmpty(data.usageUrl8) || this.isNotEmpty(data.channelDesc8) ){
		// 	hidePart2 = true;
		// }else if( this.isNotEmpty(data.channels9) || this.isNotEmpty(data.usageAttachment9) || this.isNotEmpty(data.usageUrl9) || this.isNotEmpty(data.channelDesc9) ){
		// 	hidePart2 = true;
		// }else if( this.isNotEmpty(data.channels10) || this.isNotEmpty(data.usageAttachment10) || this.isNotEmpty(data.usageUrl10) || this.isNotEmpty(data.channelDesc10) ){
		// 	hidePart2 = true;
		// }




		this.setState({
			hidePart1: hidePart1,
			hidePart2: hidePart2
		});
	}


	gotoHistory = (item) => {
		//dataPutAndGo(this, '/successStoryLeads/History', { item: item });

		//window.location.href = "";

		window.open("/History?id=" + item.id);

	}

	gotoLeadPage = () => {
		if(this.state.isComingFromLeadStatusReport!=undefined){
			dataPutAndGo(this, '/leadStatusReport',);
		}else{
			dataPutAndGo(this, '/successStoryLeads',);
		}
	}
	genHeaderMenuEditUsageOnly(itemIn, editLeadsIndIn) {
		return (

			<div className="leadDtlTopBtnsbar">
				<Row>
					<Col className="leadDtlTopBtnsbar1" xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}  >


						<img className="leadDtlTopBack" onClick={() => this.gotoSearchStory()}
							src={logoBackW}
							onMouseOver={
								e => (e.currentTarget.src = logoBackG)
							}
							onMouseOut={
								e => (e.currentTarget.src = logoBackW)
							}
						/>




					</Col>


					<Col className="leadDtlTopBtnsbar4" xs={14} sm={14} md={14} lg={14} xl={14} xxl={14}  >
						<div className="leadDtlTopBtnsbar4Img" >
							<img className="leadDtlTopImg" onClick={() => this.gotowriteStoryPage(itemIn, "updateUsageOnly")} style={{ display: editLeadsIndIn ? '' : 'none' }}
								src={logoEditW}
								onMouseOver={
									e => (e.currentTarget.src = logoEditG)
								}
								onMouseOut={
									e => (e.currentTarget.src = logoEditW)
								}
							/>

						</div>
					</Col>

				</Row>
			</div>



		)
	}
	genHeaderMenuEdit(itemIn, editLeadsIndIn) {
		return (

			<div className="leadDtlTopBtnsbar">
				<Row>
					<Col className="leadDtlTopBtnsbar1" xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}  >


						<img className="leadDtlTopBack" onClick={() => this.gotoSearchStory()}
							src={logoBackW}
							onMouseOver={
								e => (e.currentTarget.src = logoBackG)
							}
							onMouseOut={
								e => (e.currentTarget.src = logoBackW)
							}
						/>




					</Col>


					<Col className="leadDtlTopBtnsbar4" xs={14} sm={14} md={14} lg={14} xl={14} xxl={14}  >
						<div className="leadDtlTopBtnsbar4Img" >
							<img className="leadDtlTopImg" onClick={() => 						
							(this.isSameLoginUserAsLastModified())?	this.gotowriteStoryPage(itemIn):this.setState({control12: true})
							
							} style={{ display: editLeadsIndIn ? '' : 'none' }}
								src={logoEditW}
								onMouseOver={
									e => (e.currentTarget.src = logoEditG)
								}
								onMouseOut={
									e => (e.currentTarget.src = logoEditW)
								}
							/>

						</div>
					</Col>

				</Row>
			</div>



		)
	}
	isSameLoginUserAsLastModified(){
		console.log("isSameLoginUserAsLastModified",this.props.loginUser.userName, this.state.data.lastModifiedBy);
		if (this.props.loginUser.userName == this.state.data.lastModifiedBy){
			return true;
		}
		return false;
		
	}
	genHeaderMenuReview(itemIn, reviewLeadsIndIn) {
		return (

			<div className="leadDtlTopBtnsbar">
				<Row>
					<Col className="leadDtlTopBtnsbar1" xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}  >

						<img className="leadDtlTopBack" onClick={() => this.gotoSearchStory()}
							src={logoBackW}
							onMouseOver={
								e => (e.currentTarget.src = logoBackG)
							}
							onMouseOut={
								e => (e.currentTarget.src = logoBackW)
							}
						/>
					</Col>


					<Col className="leadDtlTopBtnsbar4" xs={14} sm={14} md={14} lg={14} xl={14} xxl={14}  >
						<div className="leadDtlTopBtnsbar4Img" >
							<img className="leadDtlTopImg" onClick={() => this.updateStatusToReviewing(itemIn)} style={{ display: reviewLeadsIndIn ? '' : 'none' }}
								src={logoReviewW}
								onMouseOver={
									e => (e.currentTarget.src = logoReviewG)
								}
								onMouseOut={
									e => (e.currentTarget.src = logoReviewW)
								}
							/>

						</div>
					</Col>

				</Row>
			</div>



		)
	}
	genHeaderMenuBackOnly() {
		return (

			<div className="leadDtlTopBtnsbar">
				<Row>
					<Col className="leadDtlTopBtnsbar1" xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}  >
						<img className="leadDtlTopBack" onClick={() => this.gotoSearchStory()}
							src={logoBackW}
							onMouseOver={
								e => (e.currentTarget.src = logoBackG)
							}
							onMouseOut={
								e => (e.currentTarget.src = logoBackW)
							}
						/>
					</Col>




				</Row>
			</div>



		)
	}
	genHeaderMenu(item, status) {

		//var reviewLeads = this.state.reviewLeads;
		var editLeadsInd = false;
		//var viewLeadsInd = false;
		var reviewLeadsInd = false;
		var roleCode = null;
		if (this.props.userPermission) {
			editLeadsInd = this.props.userPermission.editLeadsInd;
			reviewLeadsInd = this.props.userPermission.reviewLeadsInd;
			roleCode = this.props.userRoleCode;
		}

		var oldDepartment = item.department;
		let userDepartment = getDepartmentName(this.props);
		//item.lastModifiedBy=userName;

		// console.log(" oldName >>>>> " , oldName );
		// console.log(" item.revRightInd >>>>> " , item.revRightInd );


		//System Admin
		if (roleCode == "1") {
			if (((status == STATUS_.New || status == STATUS_.Reopened) && userDepartment == oldDepartment)
				|| (status == STATUS_.Reviewing && item.revRightInd === "Y")
				|| (status == STATUS_.Approved)
				|| (status == STATUS_.Published)
				|| (status == STATUS_.Archived)
			) {
				console.log("System Admin Status genHeaderMenuEdit ", status);
				return (

					this.genHeaderMenuEdit(item, editLeadsInd)

				)
			} else if (status == STATUS_.Submitted) {
				console.log("System Admin Status genHeaderMenuReview ", status);
				return (

					this.genHeaderMenuReview(item, reviewLeadsInd)

				)
			} else if (status == STATUS_.Closed || status == STATUS_.Reopened) {
				console.log("System Admin Status genHeaderMenuBackOnly ", status);
				return (
					this.genHeaderMenuBackOnly()
				)


			} else {
				console.log("System Admin Status" +
					" defaultgenHeaderMenuBackOnly ", status);
				return (
					this.genHeaderMenuBackOnly()
				)


			}
		} else if (roleCode == "2") {  //Content Approver
			if (((status == STATUS_.New || status == STATUS_.Reopened) && userDepartment == oldDepartment)
				|| (status == STATUS_.Reviewing && item.revRightInd === "Y")
				|| (status == STATUS_.Approved)
				|| (status == STATUS_.Published)
				|| (status == STATUS_.Archived)
			) {
				return (
					this.genHeaderMenuEdit(item, editLeadsInd)

				)
			} else if (status == STATUS_.Submitted) {
				return (
					this.genHeaderMenuReview(item, reviewLeadsInd)


				)
			} else if (status == STATUS_.Closed) {
				console.log("Status ", status);
				return (
					this.genHeaderMenuBackOnly()

				)


			} else {
				console.log("System Admin Status genHeaderMenuBackOnly ", status);
				return (
					this.genHeaderMenuBackOnly()
				)


			}
		} else if (roleCode == "3" && (oldDepartment == userDepartment || (this.state.allowUpdate!=undefined && this.state.allowUpdate=="Y"))) {	//Power User

			if (status == STATUS_.Archived) {
				return (
					this.genHeaderMenuEditUsageOnly(item, editLeadsInd)

				)


			} else if (status == STATUS_.Submitted || status == STATUS_.New || status == STATUS_.Reopened) {
				return (
					this.genHeaderMenuEdit(item, editLeadsInd)

				)


			} else if (status == STATUS_.Reviewing || status == STATUS_.Closed) {
				console.log("Status Reviewing", status);
				return (
					this.genHeaderMenuBackOnly()

				)



			} else {
				console.log("System Admin Status genHeaderMenuBackOnly ", status);
				return (
					this.genHeaderMenuBackOnly()
				)


			}
		} else {
			console.log("General User", status);
			return (
				this.genHeaderMenuBackOnly()

			)
		}

	}




	copyJson = (baseData) => {
		var datasStr = JSON.stringify(baseData);
		if (datasStr) {
			return JSON.parse(datasStr);
		} else {
			return null;
		}
	}

	updateStatus = (statusStr, emailStatus) => {

		var datev = getDateStr();	//now

		var data = this.copyJson(this.state.data);

		var id = this.state.id;
		if (id != undefined && id != "") {
			data.id = id;
		}

		var logEntity = {};
		var activity = null;
		var actionMessage = null;
		var oriStatus = data.status;
		var userName = this.props.loginUser.userName;
		//var Tier = null;
		//var discloseToPublic = this.state.data.discloseToPublic;


		// if(statusStr == STATUS_.Approved ){
		// 	if(discloseToPublic == "Disclosable"){
		// 		statusStr = STATUS_.Approved;
		// 		Tier = 3;
		// 	}else if(discloseToPublic == "Cannot be Disclosed"){
		// 		statusStr = STATUS_.Archived;
		// 		Tier = 1;
		// 	}
		// }else

		if (statusStr == STATUS_.Reopened) {
			//Tier = 2;

			activity = "Reopen Story";
			actionMessage = "The lead is reopened";
			actionMessage += "</br>";
			actionMessage += "Story lead status changes from \"" + oriStatus + "\" to \"Reopened\"";

		} else if (statusStr == STATUS_.Closed) {
			//
		}


		logEntity.emailStatus = emailStatus;
		logEntity.activity = activity;
		logEntity.actionResult = Base64.encode (actionMessage);
		logEntity.actionBy = userName;
		logEntity.actionDate = datev;


		//data.leadId = Tier;
		data.status = statusStr;
		//data.emailStatus=emailStatus;



		let departmentName = getDepartmentName(this.props);

		data.lastModifiedBy = userName;
		data.lastModifiedDateTime = datev;
		data.updateByDept = departmentName;

		data.actionHistoryBean = logEntity;
		//SSS-335 For Handling blockage of WAF 
        data.storyDescription = Base64.encode (data.storyDescription);
		if(data.url!=undefined){
			data.url = Base64.encode (data.url);
		}
		if(data.url1!=undefined){
		data.url1 = Base64.encode (data.url1);
		}
		if(data.url2!=undefined){
		data.url2 = Base64.encode (data.url2);
		}
		if(data.usageUrl1!=undefined){
		data.usageUrl1 = Base64.encode (data.usageUrl1);
		}
		if(data.usageUrl2!=undefined){
		data.usageUrl2 = Base64.encode (data.usageUrl2);
		}
		if(data.usageUrl3!=undefined){
		data.usageUrl3 = Base64.encode (data.usageUrl3);
		}
		if(data.usageUrl4!=undefined){
		data.usageUrl4 = Base64.encode (data.usageUrl4);
		}
		if(data.usageUrl5!=undefined){
		data.usageUrl5 = Base64.encode (data.usageUrl5);
		}
		if(data.usageUrl6!=undefined){
		data.usageUrl6 = Base64.encode (data.usageUrl6);
		}
		if(data.usageUrl7!=undefined){
		data.usageUrl7 = Base64.encode (data.usageUrl7);
		}
		if(data.usageUrl8!=undefined){
		data.usageUrl8 = Base64.encode (data.usageUrl8);
		}
		if(data.usageUrl9!=undefined){
		data.usageUrl9 = Base64.encode (data.usageUrl9);
		}
		if(data.usageUrl10!=undefined){
		data.usageUrl10 = Base64.encode (data.usageUrl10);
		}
		if(data.channelDesc1!=undefined){
		data.channelDesc1 = Base64.encode (data.channelDesc1);
		}
		if(data.channelDesc2!=undefined){
		data.channelDesc2 = Base64.encode (data.channelDesc2);
		}
		if(data.channelDesc3!=undefined){
		data.channelDesc3 = Base64.encode (data.channelDesc3);
		}
		if(data.channelDesc4!=undefined){
		data.channelDesc4 = Base64.encode (data.channelDesc4);
		}
		if(data.channelDesc5!=undefined){
		data.channelDesc5 = Base64.encode (data.channelDesc5);
		}
		if(data.channelDesc6!=undefined){
		data.channelDesc6 = Base64.encode (data.channelDesc6);
		}
		if(data.channelDesc7!=undefined){
		data.channelDesc7 = Base64.encode (data.channelDesc7);
		}
		if(data.channelDesc8!=undefined){
		data.channelDesc8 = Base64.encode (data.channelDesc8);
		}
		if(data.channelDesc9!=undefined){
		data.channelDesc9 = Base64.encode (data.channelDesc9);
		}
		if(data.channelDesc10!=undefined){
		data.channelDesc10 = Base64.encode (data.channelDesc10);
		}
		if(data.remarks!=undefined){
		data.remarks = Base64.encode (data.remarks);
		}
		if(data.rejectReopenRemake!=undefined){
		data.rejectReopenRemake = Base64.encode (data.rejectReopenRemake);
		}
		if(data.emailReceiver!=undefined){
			data.emailReceiver = Base64.encode (data.emailReceiver);
		}

		post(successStory_UI_CONFIG.url + '/ssls/saveData?entity=', data).then(data2 => {
			//console.log(" data2 > " , data2 );

			if (data2 && data2.returnType == "SAVE SUCESS") {

				if (statusStr == STATUS_.Submitted) {
					this.messageinfo("The lead is submitted");
					if(this.state.isComingFromLeadStatusReport!=undefined){
							data.isComingFromLeadStatusReport="Y";
					}
		
					dataPutAndGo(this, '/successStoryLeads/storyDetail', data);
				} else if (statusStr == STATUS_.Reopened) {
					//
				} else if (statusStr == STATUS_.Approved || statusStr == STATUS_.Archived) {
					this.messageinfo("The lead is approved.");
				} else if (statusStr == STATUS_.Published) {
					this.messageinfo("The lead is published");
				} else {
					this.messageinfo("The lead is " + statusStr);
				}

				if (statusStr == STATUS_.Reopened || statusStr == STATUS_.Closed) {
					this.setState({
						control5: false
					})
					this.setState({
						control10: false
					})
					this.setState({
						control11: false
					})

					if (statusStr == STATUS_.Reopened) {
						this.setState({
							control10: false
						})
						this.goRecevingStoryPage(data);
						//dataPutAndGo(this, '/successStoryLeads/storyDetail' ,   );
					} else if (statusStr == STATUS_.Closed) {
						this.setState({
							control11: false
						})
						//dataPutAndGo(this, '/successStoryLeads/storyDetail' , data  );
					}

				} else if (statusStr == STATUS_.Approved || statusStr == STATUS_.Archived) {
					this.setState({
						control8: false
					});
				} else if (statusStr == STATUS_.Published) {
					this.setState({
						control9: false
					});
				}
			} else {
				this.setState({ titleMessage: "Story is failed to proceed, please contact system admin." });
				setTimeout(() => {
					this.setState({ titleMessage: '' })
				}, 10000);
			}

			this.setState({
				data: data
			})
		}).catch((e) => {
			this.setState({ titleMessage: "Story is failed to proceed, please contact system admin." });
			setTimeout(() => {
				this.setState({ titleMessage: '' })
			}, 10000);
		});

	}

	updateStatusToReopen = (statusStr, emailStatus) => {

		var datev = getDateStr();
		var data = this.copyJson(this.state.data);
		var id = this.state.id;
		if (id != undefined && id != "") {
			data.id = id;
		}
		var logEntity = {};
		var activity = null;
		var actionMessage = null;
		var oriStatus = data.status;
		var userName = this.props.loginUser.userName;
		if (statusStr == STATUS_.Reopened) {
			activity = "Reopen Story";
			actionMessage = "The lead is reopened";
			actionMessage += "</br>";
			actionMessage += "Story lead status changes from \"" + oriStatus + "\" to \"Reopened\"";
		}
		logEntity.emailStatus = emailStatus;
		logEntity.activity = activity;
		logEntity.actionResult = Base64.encode (actionMessage);
		logEntity.actionBy = userName;
		logEntity.actionDate = datev;
		data.status = statusStr;

		let departmentName = getDepartmentName(this.props);

		data.lastModifiedBy = userName;
		data.lastModifiedDateTime = datev;
		data.updateByDept = departmentName;
		var reminder = data.reminder;
		if (reminder) {
			data.reminderInd = 'Y';
		}
		data.actionHistoryBean = logEntity;
		
		
		//SSS-335 For Handling blockage of WAF 
		data.storyDescription = Base64.encode (data.storyDescription);
		if(data.url!=undefined){
			data.url = Base64.encode (data.url);
		}
		if(data.url1!=undefined){
		data.url1 = Base64.encode (data.url1);
		}
		if(data.url2!=undefined){
		data.url2 = Base64.encode (data.url2);
		}
		if(data.usageUrl1!=undefined){
		data.usageUrl1 = Base64.encode (data.usageUrl1);
		}
		if(data.usageUrl2!=undefined){
		data.usageUrl2 = Base64.encode (data.usageUrl2);
		}
		if(data.usageUrl3!=undefined){
		data.usageUrl3 = Base64.encode (data.usageUrl3);
		}
		if(data.usageUrl4!=undefined){
		data.usageUrl4 = Base64.encode (data.usageUrl4);
		}
		if(data.usageUrl5!=undefined){
		data.usageUrl5 = Base64.encode (data.usageUrl5);
		}
		if(data.usageUrl6!=undefined){
		data.usageUrl6 = Base64.encode (data.usageUrl6);
		}
		if(data.usageUrl7!=undefined){
		data.usageUrl7 = Base64.encode (data.usageUrl7);
		}
		if(data.usageUrl8!=undefined){
		data.usageUrl8 = Base64.encode (data.usageUrl8);
		}
		if(data.usageUrl9!=undefined){
		data.usageUrl9 = Base64.encode (data.usageUrl9);
		}
		if(data.usageUrl10!=undefined){
		data.usageUrl10 = Base64.encode (data.usageUrl10);
		}
		if(data.channelDesc1!=undefined){
		data.channelDesc1 = Base64.encode (data.channelDesc1);
		}
		if(data.channelDesc2!=undefined){
		data.channelDesc2 = Base64.encode (data.channelDesc2);
		}
		if(data.channelDesc3!=undefined){
		data.channelDesc3 = Base64.encode (data.channelDesc3);
		}
		if(data.channelDesc4!=undefined){
		data.channelDesc4 = Base64.encode (data.channelDesc4);
		}
		if(data.channelDesc5!=undefined){
		data.channelDesc5 = Base64.encode (data.channelDesc5);
		}
		if(data.channelDesc6!=undefined){
		data.channelDesc6 = Base64.encode (data.channelDesc6);
		}
		if(data.channelDesc7!=undefined){
		data.channelDesc7 = Base64.encode (data.channelDesc7);
		}
		if(data.channelDesc8!=undefined){
		data.channelDesc8 = Base64.encode (data.channelDesc8);
		}
		if(data.channelDesc9!=undefined){
		data.channelDesc9 = Base64.encode (data.channelDesc9);
		}
		if(data.channelDesc10!=undefined){
		data.channelDesc10 = Base64.encode (data.channelDesc10);
		}
		if(data.remarks!=undefined){
		data.remarks = Base64.encode (data.remarks);
		}
		if(data.rejectReopenRemake!=undefined){
		data.rejectReopenRemake = Base64.encode (data.rejectReopenRemake);
		}
		if(data.emailReceiver!=undefined){
			data.emailReceiver = Base64.encode (data.emailReceiver);
		}

		post(successStory_UI_CONFIG.url + '/ssls/saveAndVliData?entity=', data).then(data2 => {
			if (data2 && data2.returnType == "SAVE SUCESS") {
				if (statusStr == STATUS_.Reopened || statusStr == STATUS_.Closed) {
					this.setState({
						control5: false
					})
					this.setState({
						control10: false
					})
					this.setState({
						control11: false
					})

					if (statusStr == STATUS_.Reopened) {
						this.setState({
							control10: false
						})
						data.returnMsg = "The lead is reopened.";
						this.goRecevingStoryPage(data);
					}
				}
			} else {

				this.setState({ titleMessage: data2.message, });
				if (this.timer) {
					clearTimeout(this.timer);
				}
				this.timer = setTimeout(() => {
					this.setState({
						titleMessage: ''
					});
				}, 10000);

			}

		}).catch((e) => {
			this.setState({ titleMessage: "Story is failed to proceed, please contact system admin." });
			setTimeout(() => {
				this.setState({ titleMessage: '' })
			}, 10000);
		});

	}

	updateStatusToReviewing(item) {

		item = this.copyJson(item);

		item.status = STATUS_.Reviewing;

		var datev = getDateStr();


		var userName = this.props.loginUser.userName;
		item.contentApprover = userName;
		let departmentName = getDepartmentName(this.props);



		item.lastModifiedBy = userName;
		item.lastModifiedDateTime = datev;
		item.updateByDept = departmentName;
		
		//SSS-335 For Handling blockage of WAF 
		item.storyDescription = Base64.encode (item.storyDescription);
		if(item.url!=undefined){
			item.url = Base64.encode (item.url);
		}
		if(item.url1!=undefined){
		item.url1 = Base64.encode (item.url1);
		}
		if(item.url2!=undefined){
		item.url2 = Base64.encode (item.url2);
		}
		if(item.usageUrl1!=undefined){
		item.usageUrl1 = Base64.encode (item.usageUrl1);
		}
		if(item.usageUrl2!=undefined){
		item.usageUrl2 = Base64.encode (item.usageUrl2);
		}
		if(item.usageUrl3!=undefined){
		item.usageUrl3 = Base64.encode (item.usageUrl3);
		}
		if(item.usageUrl4!=undefined){
		item.usageUrl4 = Base64.encode (item.usageUrl4);
		}
		if(item.usageUrl5!=undefined){
		item.usageUrl5 = Base64.encode (item.usageUrl5);
		}
		if(item.usageUrl6!=undefined){
		item.usageUrl6 = Base64.encode (item.usageUrl6);
		}
		if(item.usageUrl7!=undefined){
		item.usageUrl7 = Base64.encode (item.usageUrl7);
		}
		if(item.usageUrl8!=undefined){
		item.usageUrl8 = Base64.encode (item.usageUrl8);
		}
		if(item.usageUrl9!=undefined){
		item.usageUrl9 = Base64.encode (item.usageUrl9);
		}
		if(item.usageUrl10!=undefined){
		item.usageUrl10 = Base64.encode (item.usageUrl10);
		}
		if(item.channelDesc1!=undefined){
		item.channelDesc1 = Base64.encode (item.channelDesc1);
		}
		if(item.channelDesc2!=undefined){
		item.channelDesc2 = Base64.encode (item.channelDesc2);
		}
		if(item.channelDesc3!=undefined){
		item.channelDesc3 = Base64.encode (item.channelDesc3);
		}
		if(item.channelDesc4!=undefined){
		item.channelDesc4 = Base64.encode (item.channelDesc4);
		}
		if(item.channelDesc5!=undefined){
		item.channelDesc5 = Base64.encode (item.channelDesc5);
		}
		if(item.channelDesc6!=undefined){
		item.channelDesc6 = Base64.encode (item.channelDesc6);
		}
		if(item.channelDesc7!=undefined){
		item.channelDesc7 = Base64.encode (item.channelDesc7);
		}
		if(item.channelDesc8!=undefined){
		item.channelDesc8 = Base64.encode (item.channelDesc8);
		}
		if(item.channelDesc9!=undefined){
		item.channelDesc9 = Base64.encode (item.channelDesc9);
		}
		if(item.channelDesc10!=undefined){
		item.channelDesc10 = Base64.encode (item.channelDesc10);
		}
		if(item.remarks!=undefined){
		item.remarks = Base64.encode (item.remarks);
		}
		if(item.rejectReopenRemake!=undefined){
		item.rejectReopenRemake = Base64.encode (item.rejectReopenRemake);
		}
		if(item.emailReceiver!=undefined){
			item.emailReceiver = Base64.encode (item.emailReceiver);
		}

		var pageObj = this;
		post(successStory_UI_CONFIG.url + '/ssls/saveAndVliData', item, pageObj).then(data2 => {
			if (data2 && data2.returnType == "FAIL") {


				this.setState({ titleMessage: data2.message, });
				if (this.timer) {
					clearTimeout(this.timer);
				}
				this.timer = setTimeout(() => {
					this.setState({
						titleMessage: ''
					});
				}, 10000);

			} else {

				// var str = "";
				// if(departmentName != null ){
				// 	str = "("+ departmentName +")";
				// }

				var logEntity = {
					activity: "Review Story",
					actionDate: datev,
					actionBy: userName,	// + str 
					actionResult: Base64.encode ("The story being reviewed"),
					leadId: item.id,
				};
				post(LEVEL_UI_CONFIG.url + '/insertActiveLog?entity=', logEntity, pageObj).then(data => {		// insert Active Log 
					//console.log("return data >>> " , data );
				}).catch((e) => {
					this.setState({ titleMessage: "Story is failed to proceed, please contact system admin." });
					setTimeout(() => {
						this.setState({ titleMessage: '' })
					}, 10000);
				});

				this.goRecevingStoryPage(item);
			}

		}).catch((e) => {
			this.setState({ titleMessage: "Story is failed to proceed, please contact system admin." });
			setTimeout(() => {
				this.setState({ titleMessage: '' })
			}, 10000);
		});


	}



	updateStatusReopen = (emailStatus) => {
		var thisObj = this;
		var backupData = this.backupData;
		var newData = this.state.data;
		var isUpdated = checkDataDiff(backupData, newData, []);
		if (isUpdated) {
			Modal.confirm({
				title: 'Confirm',
				content: (<span className="dataDiffMessage">
					Are you sure you want to reopen the story lead?
				</span>),
				okText: 'OK',
				cancelText: 'Cancel',
				onOk() {
					thisObj.updateStatusToReopen(STATUS_.Reopened);
				},
				onCancel() {
					//do noting
				},
			});
		} else {
			thisObj.updateStatus(STATUS_.Reopened);
		}
	}



	genFootButton = (status, item) => {

		var loginUser = this.props.loginUser;
		if (!loginUser) {
			loginUser = {};
		}



		var copyLeadsInd = getPermission(this.props, "copyLeadsInd");
		var reopenLeadsInd = getPermission(this.props, "reopenLeadsInd");


		//console.log("copyLeadsInd >>>> " , copyLeadsInd );
		//console.log("status >>>> " , status );

		var roleCode = loginUser.roleId;
		//var oldName = item.createdBy;
		//var userName = loginUser.userName;
		var oldDepartment = item.department;
		var userDepartment = getDepartmentName(this.props);

		//console.log("oldDepartment >>>> " , oldDepartment );
		//console.log("userDepartment >>>> " , userDepartment );


		if (roleCode == "3" && (oldDepartment == userDepartment || (this.state.allowUpdate!=undefined && this.state.allowUpdate=="Y"))) { //Power User

			if (status == STATUS_.Published || status == STATUS_.Archived || status == STATUS_.Approved) {

				return (
					<div>

						<div className="backToTopEdit" >
							<a onClick={() => this.backToTop()}>Back to Top</a>
						</div>
						<div class="normalFooter">Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>
						<div className="writeSSButtons" style={{ display: reopenLeadsInd ? '' : 'none' }}  >
							<div style={{ paddingTop: '20px', display: reopenLeadsInd ? '' : 'none' }}  >
								<Button onClick={() => this.updateStatusReopen(STATUS_.Reopened)} className="oneButton" type="primary" style={{ color: '#FC5004', marginLeft: '10px', marginTop: '5px' }} >Reopen</Button>
							</div>
						</div>
						<br />
						<br />
						<br />
					</div>
				)
			}
			else if (status == STATUS_.Closed) {

				return (
					<div>

						<div className={copyLeadsInd ? "backToTopEdit" : "backToTopView"}  >
							<a onClick={() => this.backToTop()}>Back to Top</a>
						</div>
						<div class="normalFooter">Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>
						<div className="writeSSButtons" style={{ display: copyLeadsInd ? '' : 'none' }}>
							<div style={{ paddingTop: '20px', display: copyLeadsInd ? '' : 'none' }}>
								<Button onClick={(e) => this.copyGotowriteStoryPage(item, "COPY")} className="oneButton" type="primary" style={{ color: '#FC5004', marginLeft: '10px', marginTop: '5px' }} >Copy</Button>
							</div>
						</div>
						<br />
						<br />
						<br />
					</div>
				)
			}

		} else if (roleCode == "1" || roleCode == "2") {

			if (status == STATUS_.Closed) {

				return (
					<div>

						<div className={copyLeadsInd ? "backToTopEdit" : "backToTopView"}>
							<a onClick={() => this.backToTop()}>Back to Top</a>
						</div>
						<div class="normalFooter">Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>
						<div className="writeSSButtons" style={{ display: copyLeadsInd ? '' : 'none' }} >
							<div style={{ paddingTop: '20px', display: copyLeadsInd ? '' : 'none' }}>
								<Button onClick={(e) => this.copyGotowriteStoryPage(item, "COPY")} className="oneButton" type="primary" style={{ color: '#FC5004', marginLeft: '10px', marginTop: '5px' }} >Copy</Button>
							</div>
						</div>
						<br />
						<br />
						<br />
					</div>
				)
			}

		}


	}

	formatDate = (dateStr) => {
		if (!dateStr) {
			dateStr = null;
		} else {
			var temp = moment(dateStr, 'YYYY-MM-DD HH:mm:ss');
			dateStr = moment(temp).format('D-MMM-YYYY');
		}
		return dateStr;
	}

	formatDate2 = (dateStr) => {
		if (!dateStr) {
			dateStr = null;
		} else {
			var temp = moment(dateStr, 'YYYY-MM-DD HH:mm:ss');
			dateStr = moment(temp).format('D-MMM-YYYY HH:mm');
		}
		return dateStr;
	}

	genUrlbyUrlList = (urlListStr) => {
		if (urlListStr) {
			var list = urlListStr.split(',');
			return list.map((item, index) => {
				var top1 = item.substring(0, 4);

				if (top1 != "http") {
					item = "http://" + item;
				}
				return (
					<a href={item} target={'_blank'} key={item}>{item}<br /></a>
				)
			});
		}
	}

	downloadFile = (url) => {
		//console.log(" BLOB_UI_CONFIG.url + url >>>>> " , BLOB_UI_CONFIG.url + url );
		var downloadUrl = successStory_UI_CONFIG.url + '/file/down?dirStr=' + this.state.data.attPrefixCode + '&name=' + url;
		//window.location.href = downloadUrl;
		//		window.open(downloadUrl, "_blank");

		//		var index = url.lastIndexOf('.');
		//		var type = url.substring((index+1));
		//		if( type.toLowerCase() == "jpg" || type.toLowerCase() == "jpeg" || type.toLowerCase() == "png"  ){
		window.open(downloadUrl, "_blank");
		//		}
		//		else  if (
		//			     type.toLowerCase() == "pdf"||
		//		     type.toLowerCase() == "doc" || type.toLowerCase() == "docx" || type.toLowerCase() == "pdf"){
		//			window.open(downloadUrl, "_blank");
		//		}


	}

	genAttbyAttList = (attListStr) => {
		if (attListStr) {
			var list = attListStr.split(Separator);
			return list.map((item, index) => {
				return (
					<a download onClick={() => this.downloadFile(item)} title={'download'} >{item}<br /></a> 	//   href={ BLOB_UI_CONFIG.url+ item}
				)
			});
		}
	}



	changeRadio4(str, e) {
		var gui = this.state.gui;
		gui.reject01 = false;
		gui.reject02 = false;
		if (str == "01") {
			gui.reject01 = true;
		} else {
			gui.reject02 = true;
		}
		this.setState({ gui: gui });
	}

	genEmailReceiver = (emailList) => {
		if (!emailList) {
			emailList = "";
		}
		var List = emailList.split(Separator);
		var emailStr = "";
		List.map((item, index) => {
			emailStr = emailStr + item;
			if (index != (List.length - 1)) {
				emailStr = emailStr + ", ";
			}
			return null;
		})
		return (
			<span>{emailStr}</span>
		)
	}
	
	genEmailReminder = (reminderEmailSch, reminderEmailAtt) => {
		switch (reminderEmailSch){
		  case "MONTHLY":
			
			if (reminderEmailAtt == 'Y'){
				return (
					<span>Monthly with attached Excel</span>
				)
			}else{
				return (
					<span>Monthly</span>
				);
			}
			
			break;
		  case "WEEKLY":
			if (reminderEmailAtt == 'Y'){
				return (
					<span>Weekly with attached Excel</span>
				)
			}else{
				return (
					<span>Weekly</span>
				);
			}
			
			break;
		  default:
			return (
				<span>No</span>
			);
		}
		
	}
	changeSessionBasicInfo = () => {

		this.setState({ sessionOpenBasicInfo: !this.state.sessionOpenBasicInfo });

	}
	changeSessionCompanyInfo = () => {

		this.setState({ sessionOpenCompanyInfo: !this.state.sessionOpenCompanyInfo });

	}
	changeSessionHKTDCInvo = () => {

		this.setState({ sessionOpenHKTDCInvo: !this.state.sessionOpenHKTDCInvo });

	}
	changeSessionStoryDesc = () => {

		this.setState({ sessionOpenStoryDesc: !this.state.sessionOpenStoryDesc });

	}
	changeSessionStoryPublic = () => {

		this.setState({ sessionOpenStoryPublic: !this.state.sessionOpenStoryPublic });

	}
	changeSessionOtherInfo = () => {

		this.setState({ sessionOpenOtherInfo: !this.state.sessionOpenOtherInfo });

	}

	tooltipInfo = () => {
		this.setState({isTooltipOpen:true})
	};
	handleTooltipInfoCancel = () =>{
		this.setState({isTooltipOpen:false})
	}
	render() {
		if (!this.state.data) {
			this.state.data = {};
		}
		var item = this.state.data;



		var clientHeight = document.body.clientHeight;
		clientHeight = clientHeight - 180;
		if (clientHeight < 100) {
			clientHeight = 100;
		}

		console.log(" item >>>> ", item);


		//var desc = item.enss;


		var otherTypeOfSuccessCase = item.otherTypeOfSuccessCase;
		if (otherTypeOfSuccessCase && otherTypeOfSuccessCase != "") {
			item.otherTypeOfSuccessCaseVisable = true;
		}

		var typeOfSuccessCaseText = item.typeOfSuccessCase;

		if (typeOfSuccessCaseText && (typeOfSuccessCaseText == "Others")) {
			//
		} else {
			item.otherTypeOfSuccessCaseVisable = false;
		}

		
		var leadId = item.leadId;
		var tierDesc = "";
		var tierTooltipDesc = "";
		if (leadId === "1" || leadId === 1) {
			tierDesc = "Tier 1 - Internal Reference";
			tierTooltipDesc = "Tier 1 leads can never be disclosed or are unlikely to ever mature enough to become full stories. But they may still be useful to retain for internal reference or to use as part of an aggregate.";
		} else if (leadId === "2" || leadId === 2) {
			tierDesc = "Tier 2 - Pending";
			tierTooltipDesc = "Tier 2 leads have the potential to be developed into stories but more information is required. Leads in this tier are pending.";
		} else if (leadId === "3" || leadId === 3) {
			tierDesc = "Tier 3 - Mature Lead";
			tierTooltipDesc = "Tier 3 leads are mature enough to be developed into publishable stories or used in articles, press releases, sales kits, etc. Tier 3 leads are always fully disclosable.";
		}


		var eventDate1 = this.formatDate(this.state.data.eventDate1);
		var eventDate2 = this.formatDate(this.state.data.eventDate2);
		var eventDate3 = this.formatDate(this.state.data.eventDate3);
		var eventDate4 = this.formatDate(this.state.data.eventDate4);
		var eventDate5 = this.formatDate(this.state.data.eventDate5);
		var reminder = this.formatDate(this.state.data.reminder);

		var submittedDateTime = this.formatDate2(this.state.data.submittedDateTime);
		var lastModifiedDateTime = this.formatDate2(this.state.data.lastModifiedDateTime);
		var lastModifiedBy = this.state.data.lastModifiedBy;


		var titleMessage = this.state.titleMessage;

		var showUsage = false;
		var showSendSubRem = false;
		var userRoleCode = this.props.userRoleCode;
		//console.log("userRoleCode >>> "  , userRoleCode );
		if (userRoleCode === "1" || userRoleCode === "2" || userRoleCode === 1 || userRoleCode === 2) {
			showUsage = true;
			showSendSubRem = true;
		}



		const { TextArea } = Input;
		const data = [
			{
			  title: 'Collaboration',
			  text: 'MoUs, cooperation and partnership agreements, business matching, deal-making'
			},
			{
			  title: 'Market expansion',
			  text: 'Support businesses to launch products, services or open offices in other markets'
			},
			{
			  title: 'Transaction',
			  text: 'Exhibitor-buyer transactions, sales at fairs'
			},
			{
			  title: 'Other HK success cases',
			  text: 'Hong Kong success stories that did not involve HKTDC'
			},
		  ];
		let textArr = ["Collaboration - MoUs, cooperation and partnership agreements, business matching, deal-making;", 
			"Market expansion - Support businesses to launch products, services or open offices in other markets;", 
			"Transaction - Exhibitor-buyer transactions, sales at fairs;",
			"Other HK success cases - Hong Kong success stories that did not involve HKTDC;"];
		return (
			<div className="pageMain" >
				<HeaderPage parentObj={this} showLoading={true} telescopic={false} />



				<Row type="flex">
					<Col className="pageLift" style={this.state.pageLeftScreenWidthStyle} >{this.genMenu2 && this.genMenu2()}</Col>
					<Col className="pageRight" style={this.state.pageRightScreenWidthStyle}>
						{this.genPathDiv && this.genPathDiv()}
						{/* ---begin--- */}

						<div style={{ textAlign: 'left', borderColor: '1px solid #DCDCDC' }} >
							<div style={{ backgroundColor: '#9EC7F5', color: '#fff', padding: '5px', display: this.state.titleMessage == undefined || this.state.titleMessage == '' ? 'none' : '' }} >
								<Icon type="info-circle" style={{ fontSize: '18px' }} /> {titleMessage}
							</div>



							{this.genHeaderMenu(item, item.status)}





							<div style={{ textAlign: 'left', border: '1px solid #DCDCDC' }} >

								<div className="leadDtlHeadingOrangPinBox">
									<div className="leadDtlHeadingOrangPin" id="item_1" onClick={this.changeSessionBasicInfo}>
										<h4 className="leadDtlHeadingOrangPinText">{(this.state.sessionOpenBasicInfo) ? <Icon type="minus" /> : <Icon type="plus" />} Basic Information</h4>
									</div>
								</div>

								<div className="leadDtlNormal" style={{ display: this.state.sessionOpenBasicInfo ? '' : 'none' }}>

									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Lead ID</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}  >
											<div className="wordBreak"><label>{item.id}</label></div>
										</Col>
									</Row>
									

									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Lead Status</Col>
										<Col className="label" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   ><div className="wordBreak"><label>{item.status}</label></div></Col>
									</Row>

									
									<Row className="editPanel">
										{/* <Col className="label" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   ><Tooltip  placement="right"  title="Can this lead be shared publicly? Please choose an option. ">Disclose to Public</Tooltip></Col> */}
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Disclose to Public<span style={{ color: 'red' }}>*</span></Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
											<div className="wordBreak"><label>{item.discloseToPublic}</label></div>
										</Col>
									</Row>
									
									<Row className="editPanel" style={{ display: item.infoDiscDesc ? '' : 'none' }} >
										<div id="title_a">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Disclosable Information<span style={{ color: 'red' }}>*</span></Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
												{/* <Input placeholder="" name="data.TypeOfSuccessCase" onChange={this.handlerInputChange} value={this.state.data.TypeOfSuccessCase} /> */}
												<div className="wordBreak" style={{ paddingRight: '10px !important', paddingLeft: '10px !important', marginRight: '10px !important' }}>{item.infoDiscDesc}</div>
												{/* <a onClick={this.displayItema}> <Button className="oneButton" type="primary" style={{ paddingRight: '10px', paddingLeft: '10px', marginBottom: '10px', height: '30px' }}><img src={this.state.control1 ? shang : xia} /></Button>  </a> */}
											</Col>
										</div>
									</Row>
									
									<Row className="editPanel">
										{/* <Col className="label" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   ><Tooltip  placement="right"  title="Can this lead be shared publicly? Please choose an option. ">Disclose to Public</Tooltip></Col> */}
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Development Status<span style={{ color: 'red' }}>*</span></Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
											<div className="wordBreak"><label>{item.devStatus}</label></div>
										</Col>
									</Row>

									<Row className="editPanel">
										{/* <Col className="label" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   ><Tooltip  placement="right"  title="Can this lead be shared publicly? Please choose an option. ">Disclose to Public</Tooltip></Col> */}
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Nature of Story Lead<span style={{ color: 'red' }}>*</span>
										<Icon type="info-circle" style={{ fontSize: '18px' }} onClick={()=>{this.tooltipInfo()}}/>
										</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
											<div className="wordBreak">{item.leadNatureCaseDesc}
													{(item.leadNatureCaseDesc1 !== undefined && item.leadNatureCaseDesc1 !== null && item.leadNatureCaseDesc1 !== "") ? ', ' : ''}
													{item.leadNatureCaseDesc1}
													{(item.leadNatureCaseDesc2 !== undefined && item.leadNatureCaseDesc2 !== null && item.leadNatureCaseDesc2 !== "") ? ', ' : ''}
													{item.leadNatureCaseDesc2}
												</div>
										</Col>
									</Row>

									<Row className="editPanel">
										<div id="title_a">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Types of Story Lead<span style={{ color: 'red' }}>*</span></Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
												{/* <Input placeholder="" name="data.TypeOfSuccessCase" onChange={this.handlerInputChange} value={this.state.data.TypeOfSuccessCase} /> */}
												<div className="wordBreak">{item.typeOfSuccessCase}
													{(item.typeOfSuccessCase1 != undefined && item.typeOfSuccessCase1 != null && item.typeOfSuccessCase1 != "") ? ', ' : ''}
													{item.typeOfSuccessCase1}
													{(item.typeOfSuccessCase2 != undefined && item.typeOfSuccessCase2 != null && item.typeOfSuccessCase2 != "") ? ', ' : ''}
													{item.typeOfSuccessCase2}
													{(item.typeOfSuccessCase3 != undefined && item.typeOfSuccessCase3 != null && item.typeOfSuccessCase3 != "") ? ', ' : ''}
													{item.typeOfSuccessCase3}
													{(item.typeOfSuccessCase4 != undefined && item.typeOfSuccessCase4 != null && item.typeOfSuccessCase4 != "") ? ', ' : ''}
													{item.typeOfSuccessCase4}


												</div>

												{/* <a onClick={this.displayItema}> <Button className="oneButton" type="primary" style={{ paddingRight: '10px', paddingLeft: '10px', marginBottom: '10px', height: '30px' }}><img src={this.state.control1 ? shang : xia} /></Button>  </a> */}
											</Col>
										</div>
									</Row>

									<Row className="editPanel" style={{ display: item.otherTypeOfSuccessCaseVisable ? '' : 'none' }} >
										<div id="title_a">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Other Type of Story Lead<span style={{ color: 'red' }}>*</span></Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
												{/* <Input placeholder="" name="data.TypeOfSuccessCase" onChange={this.handlerInputChange} value={this.state.data.TypeOfSuccessCase} /> */}
												<div className="wordBreak" style={{ paddingRight: '10px !important', paddingLeft: '10px !important', marginRight: '10px !important' }}>{item.otherTypeOfSuccessCase}</div>
												{/* <a onClick={this.displayItema}> <Button className="oneButton" type="primary" style={{ paddingRight: '10px', paddingLeft: '10px', marginBottom: '10px', height: '30px' }}><img src={this.state.control1 ? shang : xia} /></Button>  </a> */}
											</Col>
										</div>
									</Row>

									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Topics/Themes<span style={{ color: 'red' }}>*</span></Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}  >
											<div className="wordBreak">{item.topicTheme1}
												{(item.topicTheme2 != undefined && item.topicTheme2 != null && item.topicTheme2 != "") ? ', ' : ''}
												{item.topicTheme2}
												{(item.topicTheme3 != undefined && item.topicTheme3 != null && item.topicTheme3 != "") ? ', ' : ''}
												{item.topicTheme3}
												{(item.topicTheme4 != undefined && item.topicTheme4 != null && item.topicTheme4 != "") ? ', ' : ''}
												{item.topicTheme4}
												{(item.topicTheme5 != undefined && item.topicTheme5 != null && item.topicTheme5 != "") ? ', ' : ''}
												{item.topicTheme5}</div>
										</Col>
									</Row>

									<Row className="editPanel">
										{/* <Col className="label" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   ><Tooltip  placement="right"   title="Please select up to 3 locations involved in this case,including the markets and origin of the companies involved.">Locations involved</Tooltip></Col> */}
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Locations Involved<span style={{ color: 'red' }}>*</span></Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}  >
											<div className="wordBreak">
												{item.location1}
												{(item.location2 != undefined && item.location2 != null && item.location2 != "") ? ', ' : ''}
												{item.location2}
												{(item.location3 != undefined && item.location3 != null && item.location3 != "") ? ', ' : ''}
												{item.location3}
												{(item.location4 != undefined && item.location4 != null && item.location4 != "") ? ', ' : ''}
												{item.location4}
												{(item.location5 != undefined && item.location5 != null && item.location5 != "") ? ', ' : ''}
												{item.location5}
											</div>
										</Col>
									</Row>

									<Row className="editPanel">

										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Industries Involved<span style={{ color: 'red' }}>*</span></Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
											<div className="wordBreak">
												{item.industry1}
												{(item.industry2 != undefined && item.industry2 != null && item.industry2 != "") ? ', ' : ''}
												{item.industry2}
												{(item.industry3 != undefined && item.industry3 != null && item.industry3 != "") ? ', ' : ''}
												{item.industry3}
												{(item.industry4 != undefined && item.industry4 != null && item.industry4 != "") ? ', ' : ''}
												{item.industry4}
												{(item.industry5 != undefined && item.industry5 != null && item.industry5 != "") ? ', ' : ''}
												{item.industry5}
											</div>
										</Col>
									</Row>
									
									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >“Focus 3 Deals” KPI Endorsed by DED/M (For <span className="leadDtlOrangeLabel" ><u>Overseas</u></span> Offices Only)<span hidden={!this.state.gui.mandatoryKpiOversea} style={{ color: 'red' }}>*</span></Col>
										<Col className="label" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   ><div className="wordBreak"><label>{item.kpiOversea=='YES'? 'Yes':(item.kpiOversea=='NO'?'No':'')}</label></div></Col>
									</Row>	

								</div>

								<div className="leadDtlHeadingOrangPinBox">
									<div className="leadDtlHeadingOrangPin" id="item_1" onClick={this.changeSessionCompanyInfo}>
										<h4 className="leadDtlHeadingOrangPinText">{(this.state.sessionOpenCompanyInfo) ? <Icon type="minus" /> : <Icon type="plus" />} Company Information
										</h4>
									</div>
								</div>

								<div id="service2" className="leadDtlBlockBox"
									style={{ display: this.state.sessionOpenCompanyInfo && item.companyName != undefined && item.companyName != '' ? '' : 'none' }}>
									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Company/Brand Name<span style={{ color: 'red' }}>*</span></Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											
											<div className="wordBreak">{item.companyName}</div>
										</Col>
									</Row>


									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>Headquarters Location<span style={{ color: 'red' }}>*</span></Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											<div className="wordBreak">{item.headquartersCountry}</div>
										</Col>
									</Row>

									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Industry</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											<div className="wordBreak">
												{item.cmpInd01}
												{(item.cmpInd02 != undefined && item.cmpInd02 != null && item.cmpInd02 != "") ? ', ' : ''}
												{item.cmpInd02}
												{(item.cmpInd03 != undefined && item.cmpInd03 != null && item.cmpInd03 != "") ? ', ' : ''}
												{item.cmpInd03}
												{(item.cmpInd04 != undefined && item.cmpInd04 != null && item.cmpInd04 != "") ? ', ' : ''}
												{item.cmpInd04}
												{(item.cmpInd05 != undefined && item.cmpInd05 != null && item.cmpInd05 != "") ? ', ' : ''}
												{item.cmpInd05}
											</div>
										</Col>
									</Row>
									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>URL</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											<div className="wordBreak">{this.genUrlbyUrlList(item.url)}</div>
										</Col>
									</Row>
								</div>

								<div id="service2" className="leadDtlBlockBox"
									style={{
										display: this.state.sessionOpenCompanyInfo && !this.state.gui.cmpInfoHide
											&& item.companyName1 != undefined && item.companyName1 != '' ? '' : 'none'
									}}>
									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Company/Brand Names<span style={{ color: 'red' }}>*</span></Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											{/* <Input name="data.companyName" placeholder="Company/Brand Names" onChange={this.handlerInputChange} value={this.state.data.companyName} /> */}
											<div className="wordBreak">{item.companyName1}</div>
										</Col>
									</Row>


									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>Headquarters Location<span style={{ color: 'red' }}>*</span></Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											<div className="wordBreak">{item.headquartersCountry1}</div>
										</Col>
									</Row>

									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Industry</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											<div className="wordBreak">
												{item.cmpInd11}
												{(item.cmpInd12 != undefined && item.cmpInd12 != null && item.cmpInd12 != "") ? ', ' : ''}
												{item.cmpInd12}
												{(item.cmpInd13 != undefined && item.cmpInd13 != null && item.cmpInd13 != "") ? ', ' : ''}
												{item.cmpInd13}
												{(item.cmpInd14 != undefined && item.cmpInd14 != null && item.cmpInd14 != "") ? ', ' : ''}
												{item.cmpInd14}
												{(item.cmpInd15 != undefined && item.cmpInd15 != null && item.cmpInd15 != "") ? ', ' : ''}
												{item.cmpInd15}
											</div>
										</Col>
									</Row>
									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>URL</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											<div className="wordBreak">{this.genUrlbyUrlList(item.url1)}</div>
										</Col>
									</Row>
								</div>

								<div id="service2" className="leadDtlBlockBox"
									style={{
										display: this.state.sessionOpenCompanyInfo && !this.state.gui.cmpInfoHide
											&& item.companyName2 != undefined && item.companyName2 != '' ? '' : 'none'
									}}>
									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Company/Brand Names<span style={{ color: 'red' }}>*</span></Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											{/* <Input name="data.companyName" placeholder="Company/Brand Names" onChange={this.handlerInputChange} value={this.state.data.companyName} /> */}
											<div className="wordBreak">{item.companyName2}</div>
										</Col>
									</Row>


									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>Headquarters Location<span style={{ color: 'red' }}>*</span></Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											<div className="wordBreak">{item.headquartersCountry2}</div>
										</Col>
									</Row>

									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Industry</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											<div className="wordBreak">
												{item.cmpInd21}
												{(item.cmpInd22 != undefined && item.cmpInd22 != null && item.cmpInd22 != "") ? ', ' : ''}
												{item.cmpInd22}
												{(item.cmpInd23 != undefined && item.cmpInd23 != null && item.cmpInd23 != "") ? ', ' : ''}
												{item.cmpInd23}
												{(item.cmpInd24 != undefined && item.cmpInd24 != null && item.cmpInd24 != "") ? ', ' : ''}
												{item.cmpInd24}
												{(item.cmpInd25 != undefined && item.cmpInd25 != null && item.cmpInd25 != "") ? ', ' : ''}
												{item.cmpInd25}
											</div>
										</Col>
									</Row>
									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>URL</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
											<div className="wordBreak">{this.genUrlbyUrlList(item.url2)}</div>
										</Col>
									</Row>
								</div>

								<div style={{
									display: this.state.sessionOpenCompanyInfo &&
										((item.companyName1 != undefined && item.companyName1 != '')
											|| (item.companyName2 != undefined && item.companyName2 != '')) ? '' : 'none'
								}} className="leadDtlMoreLess">
									<div style={{ display: this.state.gui.cmpInfoHide ? '' : 'none' }}  >
										<a onClick={() => this.cmpInfoHideCtr()} >More options</a>
									</div>

									<div style={{ display: this.state.gui.cmpInfoHide ? 'none' : '' }}>
										<a onClick={() => this.cmpInfoHideCtr()} >Less Options</a>
									</div>
								</div>





								<div className="leadDtlHeadingOrangPinBox">
									<div className="leadDtlHeadingOrangPin" id="item_1" onClick={this.changeSessionHKTDCInvo}>
										<h4 className="leadDtlHeadingOrangPinText">{(this.state.sessionOpenHKTDCInvo) ? <Icon type="minus" /> : <Icon type="plus" />} HKTDC Involvement
											</h4>
									</div>
								</div>


								<div style={{ display: this.state.sessionOpenHKTDCInvo ? '' : 'none' }}>
									<div className="leadDtlNormal"  >
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}    >Is there an HKTDC event or service involved?<span style={{ color: 'red' }}>*</span></Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
												{/* <Switch checkedChildren="Y" unCheckedChildren="N" /> */}
												<div className="wordBreak"><label>{item.isThereTdcInd ? "Yes" : "No"}</label></div>
											</Col>
										</Row>
									</div>

									<ConfigProvider locale={locale}>
										<div className="leadDtlBlockBox" >
											<Row className="editPanel">
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5} >Service/Event Type</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}  ><div className="wordBreak">{item.eventType1} </div></Col>
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5} >Service/Event Date</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}    ><div className="wordBreak">{eventDate1}		</div></Col>
											</Row>
											<Row className="editPanel">
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Service/Event Name</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
													<div className="wordBreak">{item.eventName1}</div>
												</Col>
											</Row>
										</div>
										<div id="service2" className="leadDtlBlockBox" style={{ display: this.state.gui.serviceHide2 ? '' : 'none' }}>
											<Row className="editPanel">
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5} >Service/Event Type</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}  ><div className="wordBreak">{item.eventType2} </div></Col>
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5} >Service/Event Date</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7} ><div className="wordBreak">{eventDate2}	</div>	</Col>
											</Row>
											<Row className="editPanel">
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Service/Event Name</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}  >
													<div className="wordBreak">{item.eventName2}</div>
												</Col>
											</Row>
										</div>
										<div id="service3" className="leadDtlBlockBox" style={{ display: this.state.gui.serviceHide3 ? '' : 'none' }}>
											<Row className="editPanel">
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Service/Event Type</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}  ><div className="wordBreak">{item.eventType3}</div> </Col>
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Service/Event Date</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}   ><div className="wordBreak">{eventDate3}	</div>	</Col>
											</Row>
											<Row className="editPanel">
												<Col className="leadDtlLabel" xs={24} sm={24} md={4} lg={7} xl={5} xxl={5}  >Service/Event Name</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
													<div className="wordBreak">{item.eventName3}</div>
												</Col>
											</Row>
										</div>
										<div id="service4" className="leadDtlBlockBox" style={{ display: this.state.gui.serviceHide4 ? '' : 'none' }}>
											<Row className="editPanel">
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Service/Event Type</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}  ><div className="wordBreak">{item.eventType4}</div> </Col>
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Service/Event Date</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}   ><div className="wordBreak">{eventDate4}	</div>	</Col>
											</Row>
											<Row className="editPanel">
												<Col className="leadDtlLabel" xs={24} sm={24} md={4} lg={7} xl={5} xxl={5}  >Service/Event Name</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
													<div className="wordBreak">{item.eventName4}</div>
												</Col>
											</Row>
										</div>
										<div id="service5" className="leadDtlBlockBox" style={{ display: this.state.gui.serviceHide5 ? '' : 'none' }}>
											<Row className="editPanel">
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Service/Event Type</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}  ><div className="wordBreak">{item.eventType5}</div> </Col>
												<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Service/Event Date</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}   ><div className="wordBreak">{eventDate5}	</div>	</Col>
											</Row>
											<Row className="editPanel">
												<Col className="leadDtlLabel" xs={24} sm={24} md={4} lg={7} xl={5} xxl={5}  >Service/Event Name</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >
													<div className="wordBreak">{item.eventName5}</div>
												</Col>
											</Row>
										</div>

									</ConfigProvider>


									<div style={{ display: this.state.hidePart1 ? '' : 'none' }} className="leadDtlMoreLess">
										<div style={{ display: this.state.gui.showAdd ? '' : 'none' }}  >
											<a onClick={() => this.serviceAdd()} >More options</a>
										</div>

										<div style={{ display: this.state.gui.showAdd ? 'none' : '' }}>
											<a onClick={() => this.serviceLess()} >Less Options</a>
										</div>
									</div>
								</div>

								<div className="leadDtlHeadingOrangPinBox">
									<div className="leadDtlHeadingOrangPin" id="item_1" onClick={this.changeSessionStoryDesc}>
										<h4 className="leadDtlHeadingOrangPinText">{(this.state.sessionOpenStoryDesc) ? <Icon type="minus" /> : <Icon type="plus" />} Story Description</h4>
									</div>
								</div>

								<div className="leadDtlNormal" style={{ display: this.state.sessionOpenStoryDesc ? '' : 'none' }} >
									<Row className="editPanel">
										<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >
											Please describe the lead or case<span style={{ color: 'red' }}>*</span>
											<br /><br />
											<span className="sectionSubRef">Provide details of the story behind this lead, such as when it occurred, what products or services were involved, who was involved and in what capacity, were there any deals involved, how much were they worth, etc.</span>

										</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}  >

											<div className="wordBreakLeadDesc" style={{
												border: '0px solid', paddingLeft: '10px !important', overflow: 'auto',
												marginRight: '10px !important'
											}}
												dangerouslySetInnerHTML={{ __html: this.state.leadDesc }}>



											</div>

											{/* <a onClick={this.viewDetailLeadCase} style={{color:'#2FB3EC', fontSize:'14px' }}>View the lead or case</a> */}
											{/* <span dangerouslySetInnerHTML={{__html:item.storyDescription}}></span> */}
										</Col>
									</Row>
								</div>



								<div className="leadDtlHeadingOrangPinBox">
									<div className="leadDtlHeadingOrangPin" id="item_1" onClick={this.changeSessionStoryPublic}>
										<h4 className="leadDtlHeadingOrangPinText">{(this.state.sessionOpenStoryPublic) ? <Icon type="minus" /> : <Icon type="plus" />} Story Publicity</h4>
									</div>
								</div>
								<div style={{ display: this.state.sessionOpenStoryPublic ? '' : 'none' }} >
									<div className="leadDtlNormal"  >
										<Row className="editPanel"  >
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   ><Tooltip  placement="right"  title="Please select the external channels in which you have already used this lead. ">Have you used this lead<br />externally?</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Has this lead been promoted externally?<span style={{ color: 'red' }}>*</span></Col>
											<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}  ><div className="wordBreak">{item.usedLeadExternally ? "Yes" : "No"}</div></Col>
											<Col className="leadDtlLabel" xs={24} sm={24} lg={5} xl={5} xxl={5} style={{ display: showUsage ? '' : 'none' }} >Get publicity updates?</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}  ><div className="wordBreak" style={{ display: showUsage ? '' : 'none' }}>{item.usageContentUpdated ? "Yes" : "No"}</div></Col>

										</Row>
									</div>


									<div className="leadDtlBlockBox"  >
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{item.channels1} </div>
											</Col>
										</Row>
										<Row className="editPanel" style={{ display: item.channels1 == 'Others' ? '' : 'none' }}>
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Other Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak"> {item.otherChannel1}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Format: jpg, jpeg, png, doc, docx, pdf Maximum file size 10MB. ">Attachments</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Attachment</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genAttbyAttList(item.usageAttachment1)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Please put audio or video URL here.">URL</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >URL</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genUrlbyUrlList(item.usageUrl1)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Description</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >

												<div style={{ whiteSpace: 'pre-wrap' }} className="wordBreak">{item.channelDesc1}</div>
											</Col>
										</Row>
									</div>


									<div className="leadDtlBlockBox" id="chan2" style={{ margin: '10px 20px', backgroundColor: '#E2E2E2', display: this.state.gui.ChanHide2 ? '' : 'none' }} >
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{item.channels2} </div>
											</Col>
										</Row>
										<Row className="editPanel" style={{ display: item.channels2 == 'Others' ? '' : 'none' }}>
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Other Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak"> {item.otherChannel2}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Format: jpg, jpeg, png, doc, docx, pdf Maximum file size 10MB. ">Attachment</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Attachment</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genAttbyAttList(item.usageAttachment2)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Please put audio or video URL here.">URL</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >URL</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												{/* <a>{item.usageUrl2}</a> <br/><a></a> */}
												<div className="wordBreak">{this.genUrlbyUrlList(item.usageUrl2)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Description</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div style={{ whiteSpace: 'pre-wrap' }} className="wordBreak"><text>{item.channelDesc2}</text></div>
											</Col>
										</Row>
									</div>


									<div className="leadDtlBlockBox" id="chan3" style={{ margin: '10px 20px', backgroundColor: '#E2E2E2', display: this.state.gui.ChanHide3 ? '' : 'none' }} >
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{item.channels3} </div>
											</Col>
										</Row>
										<Row className="editPanel" style={{ display: item.channels3 == 'Others' ? '' : 'none' }}>
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Other Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak"> {item.otherChannel3}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Format: jpg, jpeg, png, doc, docx, pdf Maximum file size 10MB. ">Attachment</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Attachment</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genAttbyAttList(item.usageAttachment3)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Please put audio or video URL here.">URL</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >URL</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												{/* <a>{}</a> <br/><a></a> */}
												<div className="wordBreak">{this.genUrlbyUrlList(item.usageUrl3)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Description</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div style={{ whiteSpace: 'pre-wrap' }} className="wordBreak">{item.channelDesc3}</div>
											</Col>
										</Row>
									</div>



									<div className="leadDtlBlockBox" id="chan4" style={{ margin: '10px 20px', backgroundColor: '#E2E2E2', display: this.state.gui.ChanHide4 ? '' : 'none' }} >
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{item.channels4}</div>
											</Col>
										</Row>
										<Row className="editPanel" style={{ display: item.channels4 == 'Others' ? '' : 'none' }}>
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Other Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak"> {item.otherChannel4}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Format: jpg, jpeg, png, doc, docx, pdf Maximum file size 10MB. ">Attachment</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Attachment</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genAttbyAttList(item.usageAttachment4)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Please put audio or video URL here.">URL</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >URL</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												{/* <a>{}</a> <br/><a></a> */}
												<div className="wordBreak">{this.genUrlbyUrlList(item.usageUrl4)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Description</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div style={{ whiteSpace: 'pre-wrap' }} className="wordBreak">{item.channelDesc4}</div>
											</Col>
										</Row>
									</div>


									<div className="leadDtlBlockBox" id="chan5" style={{ margin: '10px 20px', backgroundColor: '#E2E2E2', display: this.state.gui.ChanHide5 ? '' : 'none' }} >
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{item.channels5} </div>
											</Col>
										</Row>
										<Row className="editPanel" style={{ display: item.channels5 == 'Others' ? '' : 'none' }}>
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Other Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak"> {item.otherChannel5}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Format: jpg, jpeg, png, doc, docx, pdf Maximum file size 10MB. ">Attachment</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Attachment</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genAttbyAttList(item.usageAttachment5)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Please put audio or video URL here.">URL</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >URL</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												{/* <a>{item.usageUrl5}</a> <br/><a></a> */}
												<div className="wordBreak">{this.genUrlbyUrlList(item.usageUrl5)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Description</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div style={{ whiteSpace: 'pre-wrap' }} className="wordBreak">{item.channelDesc5}</div>
											</Col>
										</Row>
									</div>


									<div className="leadDtlBlockBox" id="chan6" style={{ margin: '10px 20px', backgroundColor: '#E2E2E2', display: this.state.gui.ChanHide6 ? '' : 'none' }} >
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{item.channels6} </div>
											</Col>
										</Row>
										<Row className="editPanel" style={{ display: item.channels6 == 'Others' ? '' : 'none' }}>
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Other Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak"> {item.otherChannel6}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Format: jpg, jpeg, png, doc, docx, pdf Maximum file size 10MB. ">Attachment</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Attachment</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genAttbyAttList(item.usageAttachment6)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Please put audio or video URL here.">URL</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >URL</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												{/* <a>{item.usageUrl6}</a> <br/><a></a> */}
												<div className="wordBreak">{this.genUrlbyUrlList(item.usageUrl6)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Description</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div style={{ whiteSpace: 'pre-wrap' }} className="wordBreak">{item.channelDesc6}</div>
											</Col>
										</Row>
									</div>


									<div className="leadDtlBlockBox" id="chan7" style={{ margin: '10px 20px', backgroundColor: '#E2E2E2', display: this.state.gui.ChanHide7 ? '' : 'none' }} >
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{item.channels7} </div>
											</Col>
										</Row>
										<Row className="editPanel" style={{ display: item.channels7 == 'Others' ? '' : 'none' }}>
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Other Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak"> {item.otherChannel7}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Format: jpg, jpeg, png, doc, docx, pdf Maximum file size 10MB. ">Attachment</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Attachment</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genAttbyAttList(item.usageAttachment7)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Please put audio or video URL here.">URL</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >URL</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												{/* <a>{item.usageUrl7}</a> <br/><a></a> */}
												<div className="wordBreak">{this.genUrlbyUrlList(item.usageUrl7)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Description</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div style={{ whiteSpace: 'pre-wrap' }} className="wordBreak">{item.channelDesc7}</div>
											</Col>
										</Row>
									</div>


									<div className="leadDtlBlockBox" id="chan8" style={{ margin: '10px 20px', backgroundColor: '#E2E2E2', display: this.state.gui.ChanHide8 ? '' : 'none' }} >
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{item.channels8}</div>
											</Col>
										</Row>
										<Row className="editPanel" style={{ display: item.channels8 == 'Others' ? '' : 'none' }}>
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Other Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak"> {item.otherChannel8}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Format: jpg, jpeg, png, doc, docx, pdf Maximum file size 10MB. ">Attachment</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Attachment</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genAttbyAttList(item.usageAttachment8)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Please put audio or video URL here.">URL</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >URL</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												{/* <a>{item.usageUrl8}</a> <br/><a></a> */}
												<div className="wordBreak">{this.genUrlbyUrlList(item.usageUrl8)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Description</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div style={{ whiteSpace: 'pre-wrap' }} className="wordBreak">{item.channelDesc8}</div>
											</Col>
										</Row>
									</div>


									<div className="leadDtlBlockBox" id="chan9" style={{ margin: '10px 20px', backgroundColor: '#E2E2E2', display: this.state.gui.ChanHide9 ? '' : 'none' }} >
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{item.channels9} </div>
											</Col>
										</Row>
										<Row className="editPanel" style={{ display: item.channels9 == 'Others' ? '' : 'none' }}>
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Other Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak"> {item.otherChannel9}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Format: jpg, jpeg, png, doc, docx, pdf Maximum file size 10MB. ">Attachment</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Attachment</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genAttbyAttList(item.usageAttachment9)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Please put audio or video URL here.">URL</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >URL</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												{/* <a>{item.usageUrl9}</a> <br/><a></a> */}
												<div className="wordBreak">{this.genUrlbyUrlList(item.usageUrl9)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Description</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div style={{ whiteSpace: 'pre-wrap' }} className="wordBreak">{item.channelDesc9}</div>
											</Col>
										</Row>
									</div>



									<div className="leadDtlBlockBox" id="chan10" style={{ margin: '10px 20px', backgroundColor: '#E2E2E2', display: this.state.gui.ChanHide10 ? '' : 'none' }} >
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{item.channels10} {item.channel10}</div>
											</Col>
										</Row>
										<Row className="editPanel" style={{ display: item.channels10 == 'Others' ? '' : 'none' }}>
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Other Channel</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak"> {item.otherChannel10}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Format: jpg, jpeg, png, doc, docx, pdf Maximum file size 10MB. ">Attachment</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Attachment</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genAttbyAttList(item.usageAttachment10)}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											{/* <Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ><Tooltip  placement="right"  title="Please put audio or video URL here.">URL</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >URL</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												{/* <a>{item.usageUrl10}</a> <br/><a></a> */}
												<div style={{ whiteSpace: 'pre-wrap' }} className="wordBreak">{item.channelUrl10}</div>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Description</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{item.channelDesc10}</div>
											</Col>
										</Row>
									</div>

									<div style={{ display: this.state.hidePart2 ? '' : 'none' }} className="leadDtlMoreLess">
										<div style={{ display: this.state.gui.showAdd2 ? '' : 'none' }}>
											<a onClick={() => this.chanAdd()} >More options</a>
										</div>

										<div style={{ display: this.state.gui.showAdd2 ? 'none' : '' }}>
											<a onClick={() => this.chanLess()} >Less Options</a>
										</div>
									</div>
								</div>
								<div className="leadDtlHeadingOrangPinBox">
									<div className="leadDtlHeadingOrangPin" id="item_1" onClick={this.changeSessionOtherInfo}>
										<h4 className="leadDtlHeadingOrangPinText">{(this.state.sessionOpenOtherInfo) ? <Icon type="minus" /> : <Icon type="plus" />} Other Information</h4>
									</div>
								</div>
								<div style={{ display: this.state.sessionOpenOtherInfo ? '' : 'none' }}>
									<div className="leadDtlNormal"  >
										<Row className="editPanel">
											{/* <Col className="label" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   ><Tooltip  placement="right"  title="Format: jpg, jpeg, png, doc, docx, pdf, Maximum file size 10MB." >Attachments</Tooltip></Col> */}
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Is there any other reference information that would be useful to understanding this lead?</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19} >
												<div className="wordBreak">{this.genAttbyAttList(item.attachment)}</div>
											</Col>
										</Row>

										<Row className="editPanel"  >

											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >Send update reminders?<span style={{ color: 'red' }}>*</span></Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   ><div className="wordBreak">{this.genEmailReminder(item.reminderEmailSch, item.reminderEmailAtt)}</div></Col>
											

										</Row>


										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}      >Who should be copied on follow-up emails?<span style={{ color: 'red' }}>*</span></Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}   >

												<div className="wordBreak">{this.genEmailReceiver(item.emailReceiver)}</div>
											</Col>
										</Row>




										<Row className="editPanel">
											<Col className="leadDtlLabel" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}    >
												Remarks (if any)
										</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}  >
												{/* <BraftEditor style={{height:'300px' ,overflowX:'hidden',overflowY:'scroll'}} value={} onChange={this.handleChanges} language="en" excludeControls={excludeControls}/> */}
												<div className="wordBreak">{item.remarks}</div>
											</Col>
										</Row>

									</div>
								</div>


								<div className="leadDtlHeadingOrangPinBox">
									<div className="leadDtlHeadingOrangPin" id="item_1">
										<h4 className="leadDtlHeadingOrangPinText">Work Log</h4>
									</div>
								</div>
								<div className="leadDtlNormal"  >
									<ConfigProvider locale={locale}>


										<table style={{ width: '100%' }}>
											<tbody>
											<tr style={{ backgroundColor: '#DDDDDD', height: '45px' }}  >
												<td style={{ padding: '10px', width: '20%' }} className="leadDtlLabel" >Submitted By</td>
												{/* <td style={{ padding: '10px' }}  >Created Department</td> */}
												<td style={{ padding: '10px', width: '20%' }} className="leadDtlLabel"  >Submission Date</td>

												<td style={{ padding: '10px', width: '20%' }} className="leadDtlLabel"  >Last Modified By</td>
												{/* <td style={{ padding: '10px' }}  >Last Modified Department</td> */}
												<td style={{ padding: '10px', width: '20%' }} className="leadDtlLabel"  >Last Modification Date</td>
												<td style={{ padding: '10px', width: '20%' }} className="leadDtlLabel"  >Content Approver</td>
											</tr>
											<tr style={{ borderTop: '1px solid #fff', backgroundColor: '#F2F2F2', height: '45px' }}>
												<td style={{ padding: '10px', width: '20%' }}  >{item.submittedBy}</td>			
												<td style={{ padding: '10px', width: '20%' }}  >{submittedDateTime}</td>
												<td style={{ padding: '10px', width: '20%' }}  >{lastModifiedBy}</td>	
												<td style={{ padding: '10px', width: '20%' }}  >{lastModifiedDateTime}</td>
												<td style={{ padding: '10px', width: '20%' }}  >{item.contentApprover}  </td>
											</tr>
											</tbody>
										</table>

									</ConfigProvider>
								</div>
								<div className="leadDtlHeadingOrangPinBox">
									<div className="leadDtlHeadingOrangPin" id="item_1">
										<h4 className="leadDtlHeadingOrangPinText">Audit</h4>
									</div>
								</div>
								<div className="leadDtlNormal"  >
									<ConfigProvider locale={locale}>
										<table style={{ width: '100%' }}>
											<tbody>
											<tr style={{ backgroundColor: '#DDDDDD', height: '45px' }}  >
												<td style={{ paddingtop: '10px' }} ><span className="leadDtlLabelAudit" >Click &#x22;</span><a onClick={(e) => this.gotoHistory(item)} style={{ fontweight: '400 !important' }} >Audit Trail History Page</a><span className="leadDtlLabelAuditNoPad" >&#x22; to check</span></td>

											</tr>
											</tbody>
										</table>
									</ConfigProvider>
								</div>
								<div style={{ marginBottom: '70px' }}>
								</div>
								{/* <Row className="editPanel_detail" style={{ textAlign: 'center', height: '60px', backgroundColor: '#DDDDDD',marginTop:'5px' }}>
								
							</Row> */}


							



								{/* ---end--- */}
							</div>
							{this.genFootButton(item.status, item)}
						</div>
					</Col>
				</Row>



				<div className="backToTopView" style={{ display: (this.genFootButton(item.status, item) != undefined && this.genFootButton(item.status, item) != null && this.genFootButton(item.status, item) != "") ? "none" : "" }}   >
					<a onClick={() => this.backToTop()}>Back to Top</a>
				</div>
				<div className="normalFooter" style={{ display: (this.genFootButton(item.status, item) != undefined && this.genFootButton(item.status, item) != null && this.genFootButton(item.status, item) != "") ? "none" : "" }} >Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>


				<Modal
					title="Approve"
					visible={this.state.controlTierInfo} //						
					closable={false}
					width="60%"
					footer={null}
					title={null}
					maskClosable={false}
				>


					<div>
						<Row className="closeTierInfo">
							<a onClick={() => this.closeTierInfo()} >
								<img style={{ height: '30px' }}
									src={logoCrossW}

									onMouseOver={
										e => (e.currentTarget.src = logoCrossG)
									}
									onMouseOut={
										e => (e.currentTarget.src = logoCrossW)
									}

								/>
							</a>
						</Row>

						<Row className="tierInfoTitle">
							Tier 1 - Internal Reference
			      </Row>
						<Row className="tierInfoDtl">
							Tier 1 leads can never be disclosed or are unlikely to ever mature enough to become full stories. But they may still be useful to retain for internal reference or to use as part of an aggregate.
			      </Row>
						<Row className="tierInfoTitle">
							Tier 2 - Pending
			      </Row>
						<Row className="tierInfoDtl">
							Tier 2 leads have the potential to be developed into stories but more information is required. Leads in this tier are pending.
			      </Row>
						<Row className="tierInfoTitle">
							Tier 3 - Mature Lead
			      </Row>
						<Row className="tierInfoDtl">
							Tier 3 leads are mature enough to be developed into publishable stories or used in articles, press releases, sales kits, etc. Tier 3 leads are always fully disclosable.
			      </Row>
						<div> &nbsp;</div>
					</div>
				</Modal>


				<Modal
					title="Reject Detail"
					visible={this.state.control5}
					onOk={this.handleSave}
					closable={false}
					onCancel={this.dialogClose5}
					okText='Send'
					cancelText="Cancel"
					width="500px"
					footer={null}
					title={null}
					maskClosable={false}
				>
					<a onClick={() => this.dialogClose5()} ><Icon type="close" style={{ position: 'relative', top: '-15px', left: '-15px', fontSize: '16px', color: '#000' }} /></a>

					<div>


						<Col className="field" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}  >
							<Row className="editPanel">
								<div id="title_a">
									<Col className="label" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >Please choose your notification and add remarks:</Col>

								</div>
							</Row>

							<Row className="editPanel">
								<div id="title_a">
									<Col className="field" xs={20} sm={20} md={20} lg={24 - 7} xl={18} xxl={18}  >
										<Radio checked={this.state.gui.reject01} onClick={() => this.changeRadio4("01")} /><strong>Request more information</strong>
									</Col>
								</div>
							</Row>

							<Row className="editPanel">
								<div id="title_a">
									<Col className="field" xs={20} sm={20} md={20} lg={24 - 7} xl={18} xxl={18}  >
										<Radio checked={this.state.gui.reject02} onClick={() => this.changeRadio4("02")} /><strong>Reject</strong>{/*Reject for Quality */}
									</Col>
								</div>
							</Row>

							<Row className="editPanel">
								<div id="title_a">
									<Col className="field" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}  >
										<TextArea rows={2} placeholder="Add remark here..." style={{ border: '2px solid #B8B8B8' }} />
									</Col>
								</div>
							</Row>
							<Button onClick={() => this.updateStatus(this.state.gui.reject01 == true ? STATUS_.Reopened : STATUS_.Closed, this.state.gui.reject01 == true ? "type_4" : "type_3")} style={{ float: 'right', marginTop: '20px' }} className="oneButton" >Send</Button>
						</Col>
						<div> &nbsp;</div>
					</div>
				</Modal>



				<Modal
					title="Reject Detail"
					visible={this.state.control4}
					onOk={this.handleSave}
					closable={false}
					onCancel={this.dialogClose}
					okText='Send'
					cancelText="Cancel"
					width="500px"
					footer={null}
					title={null}
					maskClosable={false}
				>
					<a onClick={() => this.dialogClose()} ><Icon type="close" style={{ position: 'relative', top: '-15px', left: '-15px', fontSize: '16px', color: '#000' }} /></a>

					<div>


						<Col className="field" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}  >
							<Row className="editPanel">
								<div id="title_a">
									<Col className="label" xs={24} sm={24} md={24} lg={18} xl={18} xxl={18} >Please choose your notification:</Col>
									<Col className="field" xs={20} sm={20} md={20} lg={24 - 7} xl={18} xxl={18}  >
										{/* <MySelect
											name="data.his"
											mode="single"
											style={{ width: '100%' }}
											placeholder=""
											options={this.state.hisList}
											value={this.state.data.his}
											onChange={this.handlerSelectChange}
										/> */}
									</Col>
								</div>
							</Row>

							<Row className="editPanel">
								<div id="title_a">
									<Col className="field" xs={20} sm={20} md={20} lg={24 - 7} xl={18} xxl={18}  >
										<Radio /><strong>Option 1:</strong> Reject for info
									</Col>
								</div>
							</Row>

							<Row className="editPanel">
								<div id="title_a">
									<Col className="field" xs={20} sm={20} md={20} lg={24 - 7} xl={18} xxl={18}  >
										<Radio /><strong>Option 2:</strong> Reject for quality
									</Col>
								</div>
							</Row>

							<Row className="editPanel">
								<div id="title_a">
									<Col className="field" xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}  >
										<TextArea rows={2} placeholder="Add remark here..." style={{ border: '2px solid #B8B8B8' }} />
									</Col>
								</div>
							</Row>

							<Button style={{ float: 'right', marginTop: '20px' }} className="oneButton" >Send</Button>
							<Button onClick={() => this.dialogClose()} style={{ float: 'right', marginRight: '5px', marginTop: '20px' }} className="oneButton" >Cancel</Button>



						</Col>
						<div> &nbsp;</div>
					</div>
				</Modal>


				<Modal
					title="Review Detail"
					visible={this.state.control12}
					onOk={() => this.gotowriteStoryPage(item)}
					closable={false}
					onCancel={this.dialogClose12}
					okText='OK'
					cancelText="Cancel"
					width="500px"
					//footer={null}
					title={null}
					maskClosable={false}
				>
					This Success Story Lead ID {item.id} is being reviewed by {lastModifiedBy}, are you sure you want to review it?
				</Modal>
				<Modal
					title="Reject Detail"
					visible={this.state.control8}
					onOk={() => this.updateStatus(STATUS_.Approved, "type_1")}
					closable={false}
					onCancel={this.dialogClose8}
					okText='OK'
					cancelText="Cancel"
					width="500px"
					//footer={null}
					title={null}
					maskClosable={false}
				>
					Do you want to change status to "Approved"?
				</Modal>

				<Modal
					title="Reject Detail"
					visible={this.state.control9}
					onOk={() => this.updateStatus(STATUS_.Published, "type_1")}
					closable={false}
					onCancel={this.dialogClose9}
					okText='OK'
					cancelText="Cancel"
					width="500px"
					//footer={null}
					title={null}
					maskClosable={false}
				>
					Do you want to change status to "Published"?
				</Modal>

				<Modal
					title="Reject Detail"
					visible={this.state.control10}  //
					onOk={() => this.updateStatus(STATUS_.Reopened, "")}
					closable={false}
					onCancel={this.dialogClose10}
					okText='Send'
					cancelText=""
					width="500px"
					footer={null}
					title={null}
					maskClosable={false}
				>
					<a onClick={() => this.dialogClose10()} ><Icon type="close" style={{ position: 'relative', top: '-15px', left: '-15px', fontSize: '16px', color: '#000' }} /></a>
					<Row className="editPanel">
						<div id="title_a">
							<Col className="label" xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} ></Col>
							<Col className="label" xs={23} sm={23} md={23} lg={23} xl={23} xxl={23} ><p style={{ fontSize: '16px' }}>Please let us know why you are reopening this<br />success story lead:</p></Col>
						</div>
					</Row>

					<Row className="editPanel">
						<div id="title_a">
							<Col className="label" xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} ></Col>
							<Col className="field" xs={23} sm={23} md={23} lg={23} xl={23} xxl={23}  >
								<TextArea id="remark1" rows={5} placeholder="Add remark here..." style={{ border: '2px solid #B8B8B8' }} />
							</Col>
						</div>
						<Button onClick={() => this.updateStatus(STATUS_.Reopened, "")} style={{ float: 'right', marginTop: '20px' }} className="oneButton" >Send</Button>
					</Row>
				</Modal>

				<Modal
					title="Reject Detail"
					visible={this.state.control11}
					onOk={() => this.updateStatus(STATUS_.Closed, "")}
					closable={false}
					onCancel={this.dialogClose11}
					okText='OK'
					cancelText="Cancel"
					width="500px"
					//footer={null}
					title={null}
					maskClosable={false}
				>
					Do you want to change status to "Closed"?
				</Modal>

				<Modal
					//maskClosable={false} 
					centered
					title="View the lead or case"
					visible={this.state.showViewDetail}
					footer={null}
					width="90%"
					onCancel={this.closeViewDetail}
				>
					<div style={{ width: '100%', height: clientHeight, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: item.storyDescription }}></div>
				</Modal>
				<Modal title="" visible={this.state.isTooltipOpen} 
				onCancel={this.handleTooltipInfoCancel}
				footer={''}
				>
					<List
						itemLayout="horizontal"
						dataSource={data}
						renderItem={(item, index) => (
						<List.Item>
							<List.Item.Meta
							title={item.title}
							description={item.text}
							/>
						</List.Item>
						)}
					/>
				</Modal>
			</div>
		);
	}



	dialogClose8 = () => {
		this.setState({
			control8: false
		})
	}

	dialogClose12 = () => {
		this.setState({
			control12: false
		})
	}



}

const ConnectedDetailPage = connect(mapStateToProps, mapDispatchToProps)(SuccessStoryDetail);
export default withRouter(ConnectedDetailPage);
