import { Button } from 'antd';
import React, { Component } from 'react';
//import lodash from 'lodash';
import './MyComponent.css';

class MyButton extends Component {
    
        constructor(props) {
          super(props);
          this.state = {
          };
          this.handleClick = this.handleClick.bind(this);
        }
    
        componentWillMount() {
        }
    
        componentWillReceiveProps(nextProps) {
        }

        handleClick = () => {
            var hander = this.props.onClick;
            if(hander){
                hander(this.props.name);
            }
        }

        render() {
            return (
                <Button 
                    {...this.props}
                    onClick={this.handleClick} 
                    shape="round"
                    size="small"
                    className={"_NMT_mybutton " + this.props.className}
                >{this.props.children}</Button>
            );
        }
    }
 
export default MyButton;
