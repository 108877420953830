import React, { Component } from 'react';
import moment from 'moment';
import { connect } from "react-redux";
//import { getLabel } from '../../Lang';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser } from '../../redux/actions';
import '../../index.css';
//import HeaderPage from '.././HeaderPage';
import { dataPutAndGo ,sortDateStr} from '../../Utils';
import { get, post } from "../../services/AuthService";
import ResponseTable from '../component/ResponseTable';
//import MySelect from '../component/MySelect';
import { Tabs,  Form,  Row, Col, Select,  ConfigProvider } from 'antd';
//import { Modal, ModalHeader, ModalFooter, Switch } from 'antd';
import {successStory_UI_CONFIG} from '../../../src/DataConfig'


//import Table from 'braft-extensions/dist/table';

import locale from 'antd/es/date-picker/locale/en_US';

moment.locale('en');

const { TabPane } = Tabs;
const { Option } = Select;
const { Item } = Form;

const mapStateToProps = (state) => {
	return {
		currentLang: state.currentLang,
		loginUser: state.loginUser,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		setCurrentLang: (info) => {
			dispatch(setCurrentLang(info));
		},
		setLoginUser: (info) => {
			dispatch(setLoginUser(info));
		},
	};
}

class History extends Component {
	constructor(props) {
		super(props);

		this.state = {
			control1: true,
			control2: false,
			control3: false
		};
	}


	handleAddSubmit = () => {
		var value = this.state.data;

		post('/sucessStroy/edit', value, this).then(data => {
			dataPutAndGo(this, '/successStoryLeads', {}, {});
		});
	};

	handleChanges = (editorState) => {
		const htmlString = editorState.toHTML()
		var value = this.state.data;
		value.enss = htmlString;

	}

	handleScChanges = (editorState) => {
		const htmlString = editorState.toHTML()
		var value = this.state.data;
		value.scss = htmlString;

	}

	handleTcChanges = (editorState) => {
		const htmlString = editorState.toHTML()
		var value = this.state.data;
		value.tcss = htmlString;

	}

	copyJson = (baseData) =>{
		var datasStr = JSON.stringify(baseData);
		if (datasStr) {
			  return JSON.parse(datasStr);
		}else{
			return null;
		}
	}

	componentWillMount() {
		// var dataObj = this.props.location.query;
		// if (!dataObj) {
		// 	dataObj = {};
		// } 
		// var stateData = dataObj.stateData;
		// if (!stateData) {
		// 	stateData = {};
		// 	stateData.item = {};
		// }
		// var data = stateData.item;
		// var id = data.id ;
		

		var parametersStr = this.props.location.search;
		var arr = [];
		var id = null;
		if(parametersStr != undefined && parametersStr != ""){
			arr = parametersStr.split('=')
			id = arr[1];
		}

		console.log(" this.props.loginUser > " , this.props.loginUser );
		var entity = {};
		entity.id = id;
		post(successStory_UI_CONFIG.url + '/ssls/searchByid?' , entity , this).then(result=>{
			console.log(" this result >>> " , result );

			if(result.returnType === "SUCESS"){
				this.setState({
					data : result.users[0]
				})
			}

			
		});
		

	
		get(successStory_UI_CONFIG.url +'/searchActiveLogbyId?id=' + id, this).then(data =>{
			
			if(data.activeEntity){

				var result = data.activeEntity;
				console.log(" result data >>> " , result );

		
				console.log("oldList >>>>> " , this.copyJson(result) );

				var buttonList = [];
				var emailList  = [];
				
				for( var i = 0 ; i < result.length ; i ++ ){
					if(result[i].parentId == undefined){
						buttonList.push(result[i]);
					}else{
						emailList.push(result[i]);
					}
				}

				for( let e = 0 ; e < buttonList.length ; e ++ ){
					var buttonEntity = buttonList[e];
					for(let v = 0 ; v < emailList.length ; v ++){
						if( buttonEntity.id === emailList[v].parentId ){
							buttonEntity.actionResult += "   </br> " + emailList[v].actionResult;
							//emailList.splice(v,1);
						}
					}
					buttonList[e].id = e+1; // temp id
				}


				
				// for(var i = 0 ; i < result.length ; i ++ ){
				// 	if(i != result.length-1 ){
				// 		let e = i+1;
				// 		if( result[i].activity == result[e].activity ){
				// 			result[i].actionResult += "   </br> " + result[e].actionResult;
				// 			result.splice(e,1);
				// 			i--;
				// 		}
				// 	}
				// }


				console.log("newList >>>>> " , this.copyJson(buttonList) );


				for(var c = 0 ; c < buttonList.length ; c ++ ){

					let newVal = "";
					let resultList = buttonList[c].actionResult.split("</br>");
					console.log(" buttonList[c] >>> " , buttonList[c] );
					console.log(" resultList >>> " , resultList );
					for(let v = 0 ; v < resultList.length ; v ++ ){
						resultList[v] = (v+1) + '. '+resultList[v]+"</br>";
						newVal += resultList[v];
					}

					buttonList[c].actionResult = newVal; //+ " </br> ";
				}

				this.setState({
					list : buttonList
				});
			}
			
		})




		// this.setState({
		// 	data : data
		// });

	}

	clickRow = (header, rowData, index) => {
		dataPutAndGo(this, '/successStoryLeads/storyDetail',  rowData );
	}

	eventDateChange = (value, mode) => {
		var data = this.state.data;
		data.eventDate = mode
		this.setState({ data: data });
	}

	lastModifiedDateOnChange = (value, mode) => {
		var data = this.state.data;
		data.lastModifiedDate = mode
		this.setState({ data: data });
	}

	createdAtOnChange = (value, mode) => {
		var data = this.state.data;
		data.submissionDate = mode
		this.setState({ data: data });
	}

	handleNew = () => {
		dataPutAndGo(this, '/writeStory', {}, {});
	}

	gotoDetailPage = (item) => {
		dataPutAndGo(this, '/successStoryLeads/storyDetail', { item: item });
	}





	beforeUpload = (file) => {
		const isLt100M = file.size / 1024 / 1024 < 100;

		return isLt100M;
	};
	handleChange = ({ fileList }) => this.setState({ fileList });

	handleCancel = (item) => {
		this.setState({
			showEditDialog: false,
		});
	}

	gotoStoryPage = (item) => {
		this.setState({
			showEditDialog: true,
		});
	}

	gotoBack = (item) => {
		dataPutAndGo(this, '/successStoryLeads/storyDetail',  item );
	}


	handleData(time) {
		if (!time) {
			return false
		} else {
			// 大于当前日期不能选 time > moment()
			// 小于当前日期不能选 time < moment().subtract(1, "days")
			// 只能选前7后7 time < moment().subtract(7, "days") || time > moment().add(7, 'd')
			return time < moment().subtract(7, "days") || time > moment().add(7, 'd')
		}
	}

	formatDate2 = (dateStr) => {
		if (!dateStr) {
			dateStr = null;
		} else {
			var temp = moment(dateStr, 'YYYY-MM-DD HH:mm:ss');
			dateStr = moment(temp ).format('D-MMM-YYYY HH:mm');
		}
		return dateStr;
	}

	render() {

			console.log(" this.state >>>>>>>>>>>>>>>>>> " , this.state.list );
			var tableDatas = []; 
			var list = this.state.list;
			if(list != undefined){
				tableDatas = list ;
			}
			

		var tableHeader = {
			columns: [
				{ field: 'id', width: 1, title: 'ID' },
				{ field: 'activity', width: 3, title: 'Action Type' ,
				//  format : (val) => {
				// 	 if(val){
				// 		 return val + " Story";
				// 	 }
				//  }
				},
				{ field: 'actionResult', width: 14, title: 'Action Result' ,html:true,
					// format : (val) => {
					// 	return "write later";
					// }
				},
				{ field: 'actionBy', width: 3, title: 'Action By' },
				{ field: 'actionDate', width: 3, title: 'Action Date/Time',
				format : (val) =>{
					if (!val) {
						val = null;
					} else {
						var temp = moment(val, 'YYYY-MM-DD HH:mm:ss');
						val = moment(temp ).format('D-MMM-YYYY HH:mm');  //:ss
					}
					return val;
				},
				sortHandler:(val)=>{
					return sortDateStr(val);
				}
			 },
			]
		};



		if(!this.state.data){
			this.state.data = {};
		}

		var createdDateTime = this.formatDate2(this.state.data.createdDateTime);

//		var departmentStr = "";
//		if(this.state.data.department){
//			departmentStr = "("+ this.state.data.department +")";
//		}	

		console.log("this.state.data >>>>>>>>>> " , this.state.data ) ;

		var leadId = this.state.data.leadId;
		var tierDesc = "";
		if(leadId === "1" || leadId === 1 ){
			tierDesc = "(Tier 1 - Internal Reference)";
		}else if(leadId === "2" || leadId === 2 ){
			tierDesc = "(Tier 2 - Pending)";
		}else if(leadId === "3" || leadId === 3 ){
			tierDesc = "(Tier 3 – Mature Lead)";
		}


		return (
				<div className="pageRight">
		<ConfigProvider locale={locale}>


			<div style={{ border: '3px solid #ECECEC' }}>

			<Row className="headerTop3">
							<Col className="path" >
			 					<span>Story Lead Audit Trail History Page</span>
			 				</Col>
			</Row>


			<div style={{  backgroundColor: '#F1F1F1' }}  >
				<Row className="searchPanelReportUp">
					<Col className="searchlabel5pxLeftHist" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}  >Lead ID</Col>
					<Col className="field" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}  >{this.state.data.id}</Col>
					
					<Col className="searchlabelLeftHist" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}   >Status</Col>
					<Col className="field" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}  >{this.state.data.status} </Col>

				</Row>
				

				<Row className="searchPanelReportBottom">
					<Col className="searchlabel5pxLeftHist" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}   >Created By</Col>
					<Col className="field" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}  >{this.state.data.createdBy}</Col>
					
					<Col className="searchlabelLeftHist" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}   >Created Date</Col>
					<Col className="field" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}  >{createdDateTime}</Col>

				</Row>
			</div>
			
			<div style={{ border: '1px solid #B6B6B6' }}>
				<h4 style={{ borderLeft: '3px solid #FF7F27', color:'#F5791F', marginTop: '10px' ,float:'left', paddingLeft:'10px' }}><strong>Audit Trail History Page</strong></h4>
			<div style={{clear:'both'}}>

				<div className="leadResultTableList">
				<ResponseTable
					//buttons={[{ title:'Create New',  handler: this.handleNew}]} 
					sort={{index:4,orderBy:false}}
					response={false}
					data={tableDatas}
					header={tableHeader}
					pageMode={1} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
					//handlerClick={this.clickRow} 
					onePageCount={25} //record count one page, default: 10
					selectPageIndex={1} //default selected page index, default: 1
					selectMode={0}
				headerHeight={'60px'}
				showMatch={false}
				// buttons={[
				// 	{ title: 'Create New', handler: this.handleNew },
				// 	{ title: 'Edit', handler: this.handleEdit },
				// 	{ title: 'Save', handler: this.handleSave }
				// ]}
				/>
				
				</div>
				

				</div>
			</div>

		</div>
		<div class="normalFooter">Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>
	</ConfigProvider>

	</div>
		);
	}
}

const ConnectedHistory = connect(mapStateToProps, mapDispatchToProps)(History);

export default withRouter(ConnectedHistory);