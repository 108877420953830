import {
    SET_CURRENT_LANG,SET_LOGIN_USER,SET_USER_PERMISSION,SET_ROLE_CODE
    ,SET_SYS_VERSION
} from "./actionTypes";

export const setRoleCode = (list) => {
    //console.log('redux: setRoleCode()');
    //console.log(user);
    return { type: SET_ROLE_CODE , payload: list};
}

export const setUserPermission = (list) => {
    //console.log('redux: setUserPermission()');
    //console.log(user);
    return { type: SET_USER_PERMISSION, payload: list};
}

export const setLoginUser = (user) => {
    //console.log('redux: setLoginUser()');
    //console.log(user);
    return { type: SET_LOGIN_USER, payload: user};
}

export const setCurrentLang = (lang) => {
    //console.log('redux: setCurrentLang(), set value:', lang);
    return { type: SET_CURRENT_LANG, payload: lang};
}

export const setSysVersion = (versionInfo) => {
    //console.log('redux: setSysVersion(), set value:', versionInfo);
    return { type: SET_SYS_VERSION, payload: versionInfo};
}

