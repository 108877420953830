import React, { Component } from 'react';
import { connect } from "react-redux";

import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser , setUserPermission } from '../../redux/actions';

import { Row, Col, Switch, Input, Button, message, Upload, Icon, Modal } from 'antd';
import HeaderPage from '.././HeaderPage';
import { bind, dataPutAndGo,dataGet, getPermission, Separator } from '../../Utils';

import { get, post, refreshAccessTokenTime, getAccessToken } from "../../services/AuthService";

import {  DATA_UI_CONFIG,  } from '../../../src/DataConfig';
import { Base64 } from 'js-base64';

const { TextArea } = Input;
const mapStateToProps = (state) => {
    return {
        currentLang: state.currentLang,
        loginUser: state.loginUser,
        userPermission : state.userPermission,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLang: (info) => {
            dispatch(setCurrentLang(info));
        },
        setLoginUser: (info) => {
            dispatch(setLoginUser(info));
        },
        setUserPermission:(info) =>{
            dispatch(setUserPermission(info));
        },
    };
}

class TrainingMaterialAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
        		userPermissionList: [],
                permissionTitleList: [],
        		data: {},
        };

        console.log("this.props" , this.props);
        
        get(DATA_UI_CONFIG.url + '/ssls/getTrainingMaterial', this).then(reval => {
		console.log("Training Material reval.entity" , reval);
			if (reval && reval != '' && reval.entity !=undefined) {
				
				reval.entity.fileList = this.genFileList(reval.entity.attPrefixCode, reval.entity.attname, "file");
				 
				this.setState({ data: reval.entity });
				 
			}
		});
        bind(this, ['saveHandler', 'handlerInputChange']);
        this.accessToken = getAccessToken();
    }

    componentWillMount() {
    	dataGet(this);
        this.initData();
    }
    initData =() => {
        get( DATA_UI_CONFIG.url + '/searchAll2', this.props.parentObj).then(data => {
            //console.log("searchAll2 >>> ", data.users  );
              this.setState({ userPermissionList: data.users });

             
        });

        get( DATA_UI_CONFIG.url + '/searchAll3', this.props.parentObj).then(data2 => {
            console.log("searchAll3 >>>", data2 );
              this.setState({ permissionTitleList: data2.users });
        });
    }
    
    changeSwitch(e, str) {
    	console.log("changeSwitch",e,  str);
    	
    	var data = this.state.data;
    	data.enable=(''+e);
    	
    	this.setState({
			data: data
		});
    }
    handlerInputChange = (event) => {
    	console.log("handlerInputChange" , this.state.data);
		refreshAccessTokenTime();

		
		var target = event.currentTarget;
		
		var value = target.value;
		var data = this.state.data;
    	data.sourceEnValue=value;
    	
    	this.setState({
			data: data
		});
	}
    saveHandler =()=>{
    	console.log("saveTrainingMaterial target" , this.state.data);
		var encodeDesc = Base64.encode (this.state.data.sourceEnValue);
		
		this.state.data.sourceEnValue = encodeDesc;
		console.log("saveTrainingMaterial target" , this.state.data);

		post(DATA_UI_CONFIG.url + '/ssls/saveTrainingMaterial', this.state.data, this).then(data2 => {
			console.log("saveTrainingMaterial reval" , data2);
			
			data2.entity.fileList = this.genFileList(data2.entity.attPrefixCode, data2.entity.attname, "file");
			
			
			
			this.setState({ data: data2.entity });
			
			
		});
    }
    genFileList = (dirStr, fileListStr, index) => {
		var attachments = fileListStr;
		if (attachments) {
			var attachmentList = attachments.split(Separator);
			var fileList = [];
			attachmentList.map((item, subIndex) => {
			
				if(item !=null && item!=""){
					fileList.push({
						uid: subIndex,
						name: item,
						status: 'done',
						url: DATA_UI_CONFIG.url + '/file/down?dirStr=' + dirStr + '&name=' + item,
						header: { 'X-Requested-With': 'XMLHttpRequest', 'Authorization': this.accessToken },
					});
				}
			});
			console.log("genFileList", fileList);
			return fileList;
		}
	}
    handlePreview = (info) => {
		console.log("handlePreview " + info.name);

		//console.log(" BLOB_UI_CONFIG.url + url >>>>> " , BLOB_UI_CONFIG.url + url );
		var downloadUrl = DATA_UI_CONFIG.url + '/file/down?dirStr=' + this.state.data.attprefix + '&name=' + info.name;
		//window.location.href = downloadUrl;
		window.open(downloadUrl, "_blank");


	}
    handleRemove = (file) => {
		let uri = DATA_UI_CONFIG.url + '/file/remove?dirStr=' + this.state.data.attprefix + '&name=' + file.name;
		get(uri, this).then(data => {

			console.log("handleRemove", data);
			if (data.returnType === "SUCCESS") {
				message.config({ top: '10%', });
				this.messagesuccess(file.name + ' removed successfully.');
				this.saveHandler();
			} else {
				this.messageerror("Attachment failed to be removed. Please contact system admin.");

			}
		}).catch((e) => {
			this.messageerror("Attachment failed to be removed. Please contact system admin.");
		});
	};
	messageerror(msg) {
		Modal.error({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}
	messagesuccess(msg) {
		Modal.success({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}
    handleChange = (info, str) => {
		let fileList = [...info.fileList];

		fileList = fileList.slice(-100);
		console.log("fileList size", fileList.length);


		fileList = fileList.map(file => {
			file.url = DATA_UI_CONFIG.url + '/file/down?dirStr=' + this.state.data.attprefix + '&name=' + file.name;
			//file.url = BLOB_UI_CONFIG.url + file.name;   //BLOB_UI_CONFIG.url + file.name
			console.log("this.state.data. file >>>>>>>>>>>>>>>>>>> ", file);
			// if (file.response) { 
			//   file.url = file.response.url; 
			// }
			return file;
		});


		var type = "";
		if (info.file != undefined) {
			var fileName = info.file.name;
			var index = fileName.lastIndexOf('.');
			type = info.file.name.slice((index + 1), fileName.length);
		}
		var match = (new RegExp('[~#%\&{}+\|]|\\.\\.|^\\.|\\.$')).test(info.file.name);
		if (match) {
			this.messageerror("A file name can't contain any of the following characters: ~ # % & * { } \ : < > ? / + | \"");
			return false;
		}
		else if (type.toLowerCase() != "pdf" && type.toLowerCase() != "mov" && type.toLowerCase() != "mp4") {
			message.config({ top: '10%', });
			this.messageerror("Format: pdf, mov, mp4. Maximum file size 50MB.");
			return false;
		} else if (info.file.size > 52428800) {	// 50M 
			message.config({ top: '10%', });
			this.messageerror(info.file.name + ' upload failure as file size exceeds 50MB.');
			return false;
		} else if (info.file.response && info.file.response != 'success') {
			message.config({ top: '10%', });
			var data = this.state.data;
			const matchKey = info.file.uid !== undefined ? 'uid' : 'name';
			console.log("matchKey", matchKey, info.file[matchKey]);
			const removed = fileList.filter(item => item[matchKey] != info.file[matchKey]);


			if (str == "file") {
				data.fileList = removed;
			} 

			console.log("matchKey", data.fileList);
			this.setState({ data: data });
			if (info.file.response != undefined && info.file.response != null && info.file.response != "fail" && info.file.response != "") {
				this.messageerror(info.file.response);

			} else {
				this.messageerror("Attachment failed to be uploaded. Please contact system admin.");
			}
			
			return false;

		} else {
			if (info.file.status === "done") {
				message.config({ top: '10%', });
				this.messagesuccess(info.file.name + ' uploaded successfully.');
				
				
					
				this.saveHandler();
			}
			if (str == "file") {
				var dataFile = this.state.data;
				var attNames = "";
				dataFile.fileList = fileList;
				
				
				//dataFile.attname = info.file.name;
				
				fileList.forEach(function(item, index, array){
					  console.log("fileList Save", item, index, array); 
					  attNames = attNames + item.name+ Separator;
					  return ;                     
					});
					
				console.log("Train Material attNames: "+attNames);
				dataFile.attname = attNames;
				
				
				this.setState({ data: dataFile });
			}
			
		}

		console.log("Train Material this.state.data.fileList >>>>>>>>>>>>>>>>>>> ", this.state.data.fileList);
	};

    render() {
    	const fileprops = {
    			action: DATA_UI_CONFIG.url + '/file/uploadLargeFile?dirStr=' + this.state.data.attprefix,
    			headers: { 'X-Requested-With': 'XMLHttpRequest', 'Authorization': this.accessToken },
    			onChange: e => this.handleChange(e, "file"),
    			onPreview: e => this.handlePreview(e),
    			multiple: true,

    			defaultFileList: this.state.data.fileList,
    			onRemove: e => this.handleRemove(e)
    		};

    	
        return (
            <div className="pageMain">
                <HeaderPage parentObj={this} telescopic={false} />
               
                
                <Row type="flex">
                    <Col className="pageLift" style={this.state.pageLeftScreenWidthStyle} >{this.genMenu2 && this.genMenu2()}</Col>
                    <Col className="pageRight" style={this.state.pageRightScreenWidthStyle}>
                    {this.genPathDiv && this.genPathDiv()}
	                    <Row className="editPanel">
	                    	<div style={{ textAlign: 'left' }} className="pageMainIn" >
		                    	
								<Row className="editPanel_create" >
									<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >Content<span style={{ color: 'red' }}>*</span></Col>
									<Col className="EditLeadAppDiafield" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}  >
									<TextArea style={{ height: '200px' }} rows={15} name="data.sourceEnValue"
									maxLength={7000} 
									 onChange={this.handlerInputChange} value={this.state.data.sourceEnValue} />
									</Col>
								</Row>
								<Row className="editPanel_create" style={{ height: '2000px' }}>
									
									<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}   >
									Training PDF/Videos</Col>
									<Col className="EditLeadAppDiafield" xs={24} sm={24} md={24}  lg={8} xl={8} xxl={8}  >
									<Upload 
										{...fileprops}
										fileList={this.state.data.fileList}
									>
										<button className="dftant-btn"  
											disabled={(this.state.data.fileList!=undefined && this.state.data.fileList.length>99)}>
											<Icon type="upload" /> Click to Upload
										</button>
									</Upload>
									</Col>
								</Row>
								
								
							</div>
						</Row>
						<Row className="editPanel">
                        <div className="writeSSButtons" style={{ display : getPermission(this.props,"trainingMaintenanceWrite") ? '' : 'none' }} >
                            <div style={{  paddingTop:'15px' }}  >
                                <Button style={{ }} className="oneButton" type="primary" onClick={ ()=> this.saveHandler()}>Save</Button>
                            </div>
						</div>

						 </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

const ConnectedReferenceDataPage = connect(mapStateToProps, mapDispatchToProps)(TrainingMaterialAdmin);

export default withRouter(ConnectedReferenceDataPage);
