import React, { Component } from 'react';
import { connect } from "react-redux";
//import { getLabel } from '../../Lang';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser , setUserPermission } from '../../redux/actions';
import '../../index.css';

import {  Row, Col, Button,  DatePicker,  message,ConfigProvider, Modal } from 'antd';
//import HeaderPage from '.././HeaderPage';
import { bind, dataPutAndGo, dataGet, handlerMyDatePickerChange ,sortDateStr2 ,getPermission } from '../../Utils';
import { get, post } from "../../services/AuthService";
import ResponseTable from '../component/ResponseTable';
import { Tabs } from 'antd';
import lodash from 'lodash';
import { REPORT_UI_CONFIG } from '../../DataConfig';
import { Date } from 'core-js';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/en_US';

const { TabPane } = Tabs;

const mapStateToProps = (state) => {
    return {
        currentLang: state.currentLang,
        loginUser: state.loginUser,
        userPermission : state.userPermission,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLang: (info) => {
            dispatch(setCurrentLang(info));
        },
        setLoginUser: (info) => {
            dispatch(setLoginUser(info));
        },
        setUserPermission:(info) =>{
            dispatch(setUserPermission(info));
        },
    };
}

class taxonomy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exportForm : {},
            tagList : [],
            userName: props.loginUser.userName
        };
        
//        this.setState({userName: this.props.loginUser});
        console.log("this.props.loginUser.userName" , this.props.loginUser,  this.state.userName);
        bind(this, ['handleClean', 'handleSearch']);
    }

    componentWillMount() {
        dataGet(this);
     

        var adminMaintenanceReportWriteInd = getPermission(this.props,"adminMaintenanceReportWriteInd")
        this.adminMaintenanceReportWriteInd = adminMaintenanceReportWriteInd;
        
        //var today = new Date();
		//var datev = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours()+':'+today.getMinutes()+':'+today.getSeconds();

    }
    
    componentDidMount() {
        this.initDataTable();
        
    }

    messageinfo (msg) {
		Modal.info({
		    title: msg,
		    content: (
		      <div>
		        
		      </div>
		    ),
		    onOk() {},
		  });
	}
    clickRow = (header, rowData, index) => {
        // console.log("####### header  ", header);
        // console.log("####### rowData ", rowData);
        // console.log("####### index  ", index);
        var updateRowId = rowData.id;
        dataPutAndGo(this, '/TagEditPage', { id: updateRowId }, {});
    }

    initDataTable = () => {
    	console.log(" initDataTable "  );
        var nowdate = new Date();
        var to = moment(nowdate);
        nowdate.setTime(nowdate.getTime() - 1000 * 60 * 60 * 24 * 90);
        /* nowdate.setMonth(nowdate.getDate() - 3); */
        var from = moment(nowdate);
        this.setState({
            exportForm : {
                subMissionDateFrom : from,
                subMissionDateTo : to
            }
        });
       
        get(REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/getReportAllDataList", this.props.parentObj).then(data => {
            console.log(" repoert result >>>>> " , data );
            this.setState({
                tagList: data.result,
                backUpTagList: data.result
            });
        });
    }

    handleClean = () => {
        var nowdate = new Date();
        var to = moment(nowdate);
        nowdate.setTime(nowdate.getTime() - 1000 * 60 * 60 * 24 * 90);
        /* nowdate.setMonth(nowdate.getDate() - 3); */
        var from = moment(nowdate);
        this.setState({
            exportForm : {
                subMissionDateFrom : from,
                subMissionDateTo : to
            }
        });
    }
    exportReportVerify = () => {
        message.config({
            top: '10%',
        });
        console.log("this.state.exportForm @@@@@@@@!! :", this.state.exportForm);
        if(this.state.exportForm.subMissionDateFrom == "" || this.state.exportForm.subMissionDateFrom == undefined) {
        this.messageinfo("\"Submission Date From\" is required.");
        return false;
        }
        if(this.state.exportForm.subMissionDateTo == "" || this.state.exportForm.subMissionDateTo == undefined) {
        	this.messageinfo("\"Submission Date To\" is required.");
        return false;
        }
        var formStrDate = moment(this.state.exportForm.subMissionDateFrom).format('YYYY-MM-DD');
        var toStrDate = moment(this.state.exportForm.subMissionDateTo).format('YYYY-MM-DD');

        console.log("formStrDate and toStrDate:", formStrDate, toStrDate, new Date(formStrDate).getTime() > new Date(toStrDate).getTime());
        if(new Date(formStrDate).getTime() > new Date(toStrDate).getTime()) {
        	this.messageinfo("\"Submission Date From\" cannot be later than \"Submission Date To\".");
        return false;
        }
        return true;
    }
    saveExportRecord = () => {
		if (!this.exportReportVerify()) {
            return;
        }
        this.dialogExportClose();
        this.state.exportForm.createdBy = "admin";
        var jsonData = this.state.exportForm;
        post(REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/createEXPortReport",jsonData, this.props.parentObj).then(data => {
            if (data.result == undefined) {
				message.config({
				             top: '10%',
				       });
				this.messageinfo(data.message);
                return;
            }
            var resultList = this.state.tagList;
            resultList.push(data.result);
            this.setState({tagList : resultList});
        });
    }
    downloadExportReport = () => {
        if (!this.exportReportVerify()) {
            return;
        }
        this.dialogExportClose();
        //this.state.exportForm.createdBy = "admin";
        var jsonData = this.state.exportForm;
        var fromDatetime = moment(jsonData.subMissionDateFrom).format('YYYY-MM-DD');
        var toDatetime = moment(jsonData.subMissionDateTo).format('YYYY-MM-DD');
        var userName =this.state.userName;
        console.log("checkGenReportByBetweenIsEmpty userName" ,  this.state.userName);
        get(REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/checkGenReportByBetweenIsEmpty/"+ fromDatetime + "/" + toDatetime+ "/" + userName, this.props.parentObj).then(data => {
            if (data.result && data.result.notGenReport == true) {
				message.config({
				             top: '10%',
				       });
				this.messageinfo(data.message);
                return;
            }
            console.log("!!!!!!!!!!!!!!!!!!!!!!!!@@@@@@@@@@@@@data:", data);
            if (data.returnType == undefined || 
                data.result.encodeFilePath == undefined || 
                data.result.key == undefined ||
                data.result.downloadFileName == undefined) {
                message.config({
                    top: '10%',
                });
                this.messageinfo("Session timeout.");
                return;
            }
            var downloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/downloadReportByPath/" + data.result.encodeFilePath + "/" + data.result.key + "/" + data.result.downloadFileName+ "/" + userName;
            window.location.href = downloadUrl;
        }).catch((e)=>{
        	this.messageinfo("Session timeout.");
		});
    }

    handlerInputChange = (event) => {
        var stateData = this.state;
        var target = event.currentTarget;
        var fieldName = target.name;
        var value = target.value;
        lodash.set(stateData, fieldName, value);
        this.setState({ ...stateData });
    }


    handleNew = (tableObj, infoObj, item) => {
        var tagList = {
            id: null,
            status: true,
            tag: "",
            synonymList: []
        };

        tableObj.createNewRecord(tagList);
    }

    handleEdit = (tableObj, infoObj, item) => {
        tableObj.openEditModel();
    }

    handleSave = (tableObj, infoObj, item) => {

    }

    handlerSwitchChange = (newValue, col, rowData, tableObj) => {
        tableObj.setFieldValue(rowData, col, newValue);
    }

    datePickerOnChange = (value, name) => {
        console.log("parmas ==>>:",name);
        console.log("params1 ==>>:",value);
       this.setState({submissionDateFrom: value});
    }

	disabledSubmissionStartDate = itemDate => {
		var nowDate = new Date();
		if(itemDate > nowDate){
			return true;
		}
		var jsonData = this.state.exportForm;
		var endValue = jsonData.subMissionDateTo;

			itemDate  = moment(itemDate).format('YYYY-MM-DD');
			itemDate  = moment(itemDate,'YYYY-MM-DD');

		if( endValue != undefined ){
			endValue  = moment(endValue).format('YYYY-MM-DD');
			endValue  = moment(endValue,'YYYY-MM-DD');
		}

		if(endValue && itemDate > endValue){
			return true;
		}
		return false;
	};

	disabledSubmissionEndDate = itemDate => {
		var nowDate = new Date();
		if(itemDate > nowDate){
			return true;
		}
		var jsonData = this.state.exportForm;
		var startValue =jsonData.subMissionDateFrom;

			itemDate  = moment(itemDate).format('YYYY-MM-DD');
			itemDate  = moment(itemDate,'YYYY-MM-DD');

		if( startValue != undefined ){
			startValue  = moment(startValue).format('YYYY-MM-DD');
			startValue  = moment(startValue,'YYYY-MM-DD');
		}	

		//console.log("itemDate >>> " , itemDate );
		//console.log("startValue >>> " , startValue );

		if(startValue && itemDate < startValue){
			return true;
		}		
		return false;
	};
	
    dialogExportShow = () => {
        this.setState({dialogControlExport : true});
    }
    dialogExportClose = () => {
        this.setState({dialogControlExport : false});
    }
    downloadById = (id) => {
        if(  getPermission(this.props,"adminMaintenanceReportWriteInd")  ){
        	var userName =this.state.userName;
            console.log("checkGenReportByBetweenIsEmpty  userName" ,  this.state.userName);
            get(REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/checkGenReportByIdIsEmpty/" + id, this.props.parentObj).then(data => {
                console.log("checkGenReportByIdIsEmpty》》》" , data );
                if (data.result && data.result.notGenReport == true) {
                    message.config({
                                top: '10%',
                        });
                    this.messageinfo(data.message);
                    return;
                }
                if (!data.result.encodeFilePath && !data.result.key && !data.result.downloadFileName) {
                    message.config({
                        top: '10%',
                    });
                    this.messageinfo("Session timeout.");
                    return;
                }
                var downloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/downloadReportByPath/" + data.result.encodeFilePath + "/" + data.result.key + "/" + data.result.downloadFileName+ "/" + userName;
                window.location.href = downloadUrl;
            });

        }
        
    }
    render() {

        var tableDatas = this.state.tagList;

        var tableHeader = {
            columns: [
                { field: 'exportDate', width: 4, title: 'Export Date', sort: true ,
                    sortHandler:(val)=>{
                        return sortDateStr2(val);
                    }
                },
                { field: 'fileName', width: 12, title: 'File Name', sort: true,
                    rowHandler:(col, rowData, thisObj) => {
                        if( getPermission(this.props,"adminMaintenanceReportWriteInd") ){
                            return (<span>
                                <a onClick={() => this.downloadById(rowData.id)}>{rowData.fileName}</a>
                            </span>);
                        }else{
                            return (
                                <span>
                                    {rowData.fileName}
                                </span>
                            );
                        }
                       
                    }
                },
                { field: 'subMissionDateFrom', width: 4, title: 'Submission Date (From)', sort: true ,
                    sortHandler:(val)=>{
                        return sortDateStr2(val);
                    }
                },
                { field: 'subMissionDateTo', width: 4, title: 'Submission Date (To)', sort: true ,
                    sortHandler:(val)=>{
                        return sortDateStr2(val);
                    }
                }
            ]
        };

//        function callback(key) {
//            console.log(key);
//        }

//        const style = {
//            fontWeight: 'normal',
//            fontSize: 14
//        };

        return (

            <div>
            <Row className="searchPanelReportCriteriaLabel">
            
	            
	            <Col className="searchlabel1" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}><strong>Submission Date Range</strong></Col>
	           
	            
	        </Row>
                <Row className="searchPanelReport">
                
                
                    <Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Submission Date From:</Col>
                    <Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                        <ConfigProvider locale={locale}>
                            <DatePicker
								disabledDate={this.disabledSubmissionStartDate}
                                name="exportForm.subMissionDateFrom"
                                	 placeholder="Lead Submission Date (From)"
                                format="D-MMM-YYYY"
                                onChange={(date, dateString) => handlerMyDatePickerChange(date, dateString, "exportForm.subMissionDateFrom", this)}
                            style={{ width: '100%' }}
                                value={this.state.exportForm.subMissionDateFrom}
                            />
                        </ConfigProvider>
                    </Col>
                    
                    <Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4} style={{marginleft:'15px !important'}}>Submission Date To:</Col>
                    <Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                        <ConfigProvider locale={locale}>
                            <DatePicker
								disabledDate={this.disabledSubmissionEndDate}
                                name="exportForm.subMissionDateTo"
                                	 placeholder="Lead Submission Date (To)"
                                format="D-MMM-YYYY"
                                onChange={(date, dateString) => handlerMyDatePickerChange(date, dateString, "exportForm.subMissionDateTo", this)}
                            style={{ width: '100%' }}
                                value={this.state.exportForm.subMissionDateTo}
                            />
                        </ConfigProvider>
                        
                    </Col>
                    
                </Row>
                <Row className="searchButtonPanel" >
                    
                        <div >
                            <Button onClick={this.handleClean} className="oneButton" type="primary">Reset</Button>
                            &nbsp;
                            <Button style={{ display: getPermission(this.props,"adminMaintenanceReportWriteInd") ? '' : 'none' }} onClick={this.downloadExportReport} className="oneButton" type="primary">Export</Button>

                        </div>
                    
                </Row>
                <Row className="searchPanelReportResult" style={{display: (tableDatas== undefined || tableDatas.length==0)?'none':'' }}>
                    <Col className="searchlabel1" span="24"><strong>System-generated reports</strong></Col>
                </Row>
                <div className="leadResultTableList">

                <ResponseTable
                    //handlerClick={this.clickRow} 
                    //buttons={[{ title:'Create New',  handler: this.handleNew}]} 
                    data={tableDatas}
                    header={tableHeader}
                    pageMode={1} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                    //handlerClick={this.clickRow} 
                    onePageCount={25} //record count one page, default: 10
                    selectPageIndex={1} //default selected page index, default: 1
                headerHeight={'60px'}
                showMatch={false}
                tableTopClassName={"searchPanelReportResultTable"}
				
                sort={{index:0, orderBy:false}}
                   /*  selectMode={4} */
                    // buttons={[
                        // { title: 'Create New',},
                        // { title: 'Edit', handler: this.handleEdit },
                        // { title: 'Save', handler: this.handleSave }
                    // ]}
                />

                </div>
                <div class="normalFooter">Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>


            </div>
        );
    }
}

const ConnectedHomePage = connect(mapStateToProps, mapDispatchToProps)(taxonomy);

export default withRouter(ConnectedHomePage);
