import { DB_DATE_FORMAT, OPEN_WINDOW_PREFIX, LOGIN_PAGE_URL } from './Config';
import { checkDataDiff } from './UtilDataDiff';
import moment from 'moment';
import lodash from 'lodash';
import React from 'react';
import { Modal } from 'antd';
import { get, clearAccessToken } from "./services/AuthService";
import {successStory_UI_CONFIG} from './DataConfig'
//
export const menuArray = [
    { id:1, icon: 'search', width: 3, url: '/successStoryLeads', title: 'Search Story Lead' },
    { id:2, icon: 'file-add', width: 3, url: '/writeStory', title: 'CREATE STORY LEAD' },
    { id:3, icon: 'read', width: 3, url: '/report', title: 'Report'},
	{ id:4, icon: 'tag', width: 3, url: '/referenceData', title: 'Tagging Maintenance' },
    { id:5, icon: 'setting', width: 3, url: '/admin', title: 'User Role Management' },
    { id:6, icon: 'setting', width: 3, url: '/annoBox', title: 'Announcement Box' },
    { id:7, icon: 'solution', width: 3, url: '/trainingMaterial', title: 'Training Materials' },
    { id:8, icon: 'setting', width: 3, url: '/trainingMaterialAdmin', title: 'Training Maintenance' },
    { id:9, icon: 'file-search', width: 3, url: '/leadStatusReport', title: 'Lead Status Report' },
];

export const DATA_SAVE_MSG = "Do you want to save the changes?";

export const CompareSpecials = [
    {
        keys: ['_rt_checked', "_rt_in_edit","_rt_base_data",'_rt_new_data'],
        isChanged: (data1, data2) => {
            return false;
        }
    }
];



export const STATUS_ = {
    New : 'New',
    Submitted : 'Submitted',
    Reviewing : 'Reviewing',
    Approved : 'Approved',
    Published : 'Published',
    Archived : 'Archived',
    Release : 'Release',
    Reopened : 'Reopened',
    Closed : 'Closed',
}

export const Separator = '<||>';

export const getPermission = ( props , key ) =>{
	
    let have = false;
        if(!props.loginUser){
            return false;
        }else{
            if( !props.userPermission ){
                return false;
           }else{
            have = props.userPermission[key];
           }
        }	
    return have;
}

export const getDepartmentName = (props) =>{
    let departmentName = null;
        if(!props.loginUser){
            return null;
        }else{
            if( !props.loginUser.successStoryDepartment ){
                return null;
           }else{
                departmentName = props.loginUser.successStoryDepartment.name;
           }
        }	
    return departmentName;
}

export const getDepartmentCode = (props) =>{
    let departmentCode = null;
        if(!props.loginUser){
            return null;
        }else{
            if( !props.loginUser.successStoryDepartment ){
                return null;
           }else{
                departmentCode = props.loginUser.successStoryDepartment.code;
           }
        }	
    return departmentCode;
}

export const formartDateTime = (date) => {
    if (date) {
        var m = moment(date);
        return m.format(DB_DATE_FORMAT);
    }
    return null;
}

export const trim = (value) => {
    if (value) {
        return value.replace(/^\s+|\s+$/g, "");
    }
    return value;
};


export const sortDateStr = (value) => {
    //console.log("# sortDateStr value is: ",value);
    if (value) {
        try{
            var tmpDate = moment(value, 'YYYY-MM-DD HH:mm:ss');
            var dateStr = moment(tmpDate).format('YYYYMMDDHHmmss');
            ////console.log("# sortDateStr dateStr is: ",dateStr);
            return dateStr;
        }catch(e){
            console.error(value + ' change to sortDateStr error.', e);
        }
    }
    return value;
};

export const sortDateStr2 = (value) => {
    ////console.log("# sortDateStr value is: ",value);
    if (value) {
        try{
            var tmpDate = moment(value, 'DD-MMM-YYYY HH:mm:ss');
            var dateStr = moment(tmpDate).format('YYYYMMDDHHmmss');
            ////console.log("# sortDateStr dateStr is: ",dateStr);
            return dateStr;
        }catch(e){
            console.error(value + ' change to sortDateStr error.', e);
        }
    }
    return value;
};


export const bind = (thisObj, methodArray) => {
    if (!thisObj || !methodArray) {
        return;
    }
    methodArray.forEach(method => {
        if (thisObj[method]) {
            thisObj[method] = thisObj[method].bind(thisObj);
        }
    });
}

export const bindParent = (thisObj, parentObj, methodArray) => {
    if (!thisObj || !parentObj || !methodArray) {
        return;
    }
    methodArray.forEach(method => {
        if (thisObj[method]) {
            parentObj[method] = thisObj[method].bind(parentObj);
        }
    });
}

const goNow = (thisObj, url, stateData, thisData) => {
    var path = {
        pathname: url,
        query: { stateData: stateData, thisData: thisData }
    };
    ////console.log("# dataPutAndGo url/data: ", path.pathname, path.query);
    thisObj.props.history.push(path);
}

export const openWindow = (url, width, height) => {

    url = OPEN_WINDOW_PREFIX + url;
    
    if(!width){
        width = 1000;
    }
    if(!height){
        height = 500;
    }
    var left = (window.screen.width - width)/2;
    if(left < 0){
        left = 10;
    }
    var top = (window.screen.height - height)/2;
    top = top - 50;
    if(top < 0){
        top = 10;
    }
    window.open(url, 'newwindow', 'height='+height+', width='+width+', top='+top+', left='+left+', toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no');
}


export const dataPutAndGo = (thisObj, url, stateData, thisData, chkDataDiff) => {
    var isGoNow = true;
    var dataDiff = null;
    var pageObj = thisObj.props.parentObj;
	var editId = null;
    if(pageObj){
        dataDiff = pageObj.DATA_DIFF;
    }
    if(!dataDiff){
        dataDiff = thisObj.DATA_DIFF;
        pageObj = thisObj;
    }
    //console.log("# checkDataDiff -> chkDataDiff/dataDiff = ", chkDataDiff, dataDiff);
    if((chkDataDiff != false) && dataDiff){
        var thisValue = undefined;
        var stateValue = undefined;
        var dataHandler = dataDiff.dataHandler;
        var diffHandler = dataDiff.diffHandler;
        if(dataHandler){//have top priority
            var bothData = dataHandler();
            thisValue = bothData.thisData;
            stateValue = bothData.stateData;
        }else{
            var thisKey = dataDiff.thisKey;
            var stateKey = dataDiff.stateKey;
            //console.log("# checkDataDiff -> thisKey/stateKey = ", thisKey, stateKey);
            thisValue = thisKey ? pageObj[thisKey] : undefined;
            stateValue = (stateKey && stateKey != '') ? pageObj.state[stateKey] : pageObj.state;
        }

        if(stateValue && thisValue && pageObj.CURRENT_PAGE_CODE == 'WriteSuccessStory'){
            stateValue.editMode=thisValue.editMode;
            editId = thisValue.id;
        }		
		////console.log("# checkDataDiff -> thisValue/stateValue = ", thisValue, stateValue);

        var dataUpdated = false;
        if(diffHandler){//?��?义对比方�?
            //console.log("# checkDataDiff -> user selft diff method.");
            dataUpdated = diffHandler(thisValue, stateValue, dataDiff.compareSpecials);
        }else{
            dataUpdated = checkDataDiff(thisValue, stateValue, dataDiff.compareSpecials);
        }
        //console.log("# checkDataDiff -> dataUpdated = ", dataUpdated);
        if(dataUpdated){
            isGoNow = false;
            //confirm
            Modal.confirm({
                title: 'Confirm',
                content: (<span className="dataDiffMessage">{DATA_SAVE_MSG}</span>),
                okText: 'OK',
                cancelText: 'Cancel',
                onOk() {
                    if(pageObj.saveHandler){
                        pageObj.saveHandler(()=>{
                            if(thisObj && thisObj.CURRENT_PAGE_CODE=="WriteSuccessStory" &&editId!=null){
                                get(successStory_UI_CONFIG.url + '/ssls/searchByidAndEditMode?id='+editId+'&&name=admin&&status=2', pageObj).then(data=>{});
                            }
                            goNow(thisObj, url, stateData, thisData);
                        });
                    }
                },
                onCancel() {
					if(thisObj && thisObj.CURRENT_PAGE_CODE=="WriteSuccessStory" &&editId!=null){
						get(successStory_UI_CONFIG.url + '/ssls/searchByidAndEditMode?id='+editId+'&&name=admin&&status=2', pageObj).then(data=>{});
					}
					goNow(thisObj, url, stateData, thisData);
                },
            });
        }else{
			if(editId!=null){
				get(successStory_UI_CONFIG.url + '/ssls/searchByidAndEditMode?id='+editId+'&&name=admin&&status=2', pageObj).then(data=>{});				
			}
		}
    }

    if(isGoNow){
		if(thisObj && thisObj.CURRENT_PAGE_CODE=="WriteSuccessStory" && thisObj.backupData!=null){
			get(successStory_UI_CONFIG.url + '/ssls/searchByidAndEditMode?id='+thisObj.backupData.id+'&&name=admin&&status=2', pageObj).then(data=>{});				
		}
        goNow(thisObj, url, stateData, thisData);
    }
};


export const dataGet = (thisObj, handerMethod) => {
    var dataObj = thisObj.props.location.query;
    if (!dataObj) {
        dataObj = {};
        ////console.log("### props.location.query is not set.");
    }

    

    var stateData = dataObj.stateData;
    ////console.log("### stateData ''''''''''''''''''''''''''' " , stateData );
    if (!stateData) {
        stateData = {};
    }
    var thisData = dataObj.thisData;
    if (!thisData) {
        thisData = {};
    }

   // //console.log("# dataGet stateData/thisData: ", stateData, thisData);

    thisObj.baseDataObj = JSON.parse(JSON.stringify(dataObj));
    thisObj.setState({ data: JSON.parse(JSON.stringify(stateData)) }, () => {
        if (handerMethod) {
            handerMethod();
        }
    });
    thisObj.data = JSON.parse(JSON.stringify(thisData));
    return true;
};

export const urlAddRandom = (url) => {
    if (url) {
        var p = '_q=';
        if (url.indexOf(p) < 0) {// not exist
            var index = url.indexOf('?');
            if (index > -1) { // exist ?
                url = url + "&" + p + Math.random();
            } else { // not exist ?
                url = url + "?"+ p + Math.random();
            }
        }
    }
    return url;
}

export const handlerMyDatePickerChange = (date, dateString, name, pageObj) => {
    //console.log("# handlerMyDatePickerChange: ", date, dateString, name);
    if(!pageObj){
        console.error("# EventUtil error: handlerMyDatePickerChange pageObj is null.");
        return;
    }
    var stateData = pageObj.state;
    lodash.set(stateData, name, date);
    pageObj.setState({...stateData});
}

/* "2019-12-24 17:25:30" */
export const getDateStr = () => {
    var today = new Date();
    var now = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() 
        + " " + today.getHours()+':'+today.getMinutes()+':'+today.getSeconds();
    return now;
}


export const getTextFromHtml = (htmlStr) => {
    if(!htmlStr){
        return "";
    }
    let reg=/<\/?.+?\/?>/g;
    return htmlStr.replace(reg,'');
}

export const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
    }
    return(false);
}

export const getLabelFromList = (List,id) =>{
    if(List != undefined && List.length > 0 ){
        for(var i = 0 ; i < List.length ; i ++ ){
            if( List[i].id == id ){
                return List[i].label;
            }
        }
    }
    return null;
}

export const removeRepeat = (itemList) => {
    var resultList = [];
    if(!itemList){
        itemList = [];
    }
    itemList.map((item)=>{
        var exist = false;
        for(var a1 = 0; a1 < resultList.length; a1++){
            if(item.value == resultList[a1].value){
                exist = true;
                break;
            }
        }
        if(!exist){
            resultList.push(item);
        }
        return null;
    });
    return resultList;
}
export function fireWinResizeEv(){
	
	var evt = window.document.createEvent('UIEvents'); 
	evt.initUIEvent('resize', true, false, window, 0); 
	window.dispatchEvent(evt);
	
}
export function removeFooter(hasButtonBar){
	
	var footer = document.getElementsByTagName("footer");
    //console.log ("footer hasWriteSSButtons", hasButtonBar);
    
    if (footer!= undefined && footer.length !=0){
    	for (var i = 0; i < footer.length; i++) {

    	    var f = footer[i];
    	        
    	    f.parentNode.removeChild(f);
    	}
    	
    }
}
export function addFooter(hasButtonBar){
	
	
    
    var footer = document.getElementsByTagName("footer");
    //console.log ("footer hasWriteSSButtons", hasButtonBar);
    
    if (footer!= undefined && footer.length !=0){
    	for (var i = 0; i < footer.length; i++) {

    	    var f = footer[i];
    	        
    	    document.body.removeChild(f);
    	}
    	
    }
    
    footer = document.createElement("footer");  
    
    //console.log ("footer add",footer, footer.style);
    if(hasButtonBar){
    	footer.innerHTML  = '<footer class="fixFooter">Copyright &copy; '+new Date().getFullYear()+' Hong Kong Trade Development Council. All rights reserved.</footer>';
            	
    }else {
    	footer.innerHTML  = '<footer class="relFooter">Copyright &copy; '+new Date().getFullYear()+' Hong Kong Trade Development Council. All rights reserved.</footer>';
        
	     
    }
    document.body.appendChild(footer); 
    //console.log ("footer add", footer);
    
}
export function objectToParamString(data) {
    var ret = '';
	if (data!=undefined) {
		for (var key in data) {
			ret = ret + key + '=' + encodeURIComponent(data[key]) + '&';
		}
	}
    ret = ret.substr(0, ret.length - 1); // remove last '&'
    return ret;
}
export function escape2Html(str){
    var arrEntities={'lt':'<','gt':'>','nbsp':'','amp':'&','quot':'"'};
    return str.replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];});
}
export function deleteHtmlElement(str, maxSize) {
    if(!str) return "";
    str = str.replaceAll("&#x27;","'");

    str = str.replaceAll(/<[^>]+>/g,"");
    str = escape2Html(str);//.trim().replaceAll(" ","");
    if (maxSize && maxSize > 0 && str.length > maxSize) {
        str = str.substring(0, maxSize) + "...";
    }
    return str;
 }

export function timeoutChkDataDiff(thisObj){ 
    var pageObj = thisObj.props.parentObj;
    if(!pageObj){
        pageObj = thisObj;
    }
    var dataDiff = pageObj.DATA_DIFF;
    //console.log("# timeoutChkDataDiff -> dataDiff/thisObj = ", dataDiff, thisObj);
    var saveAction = pageObj.saveHandler;
    if(dataDiff){
        var thisValue = undefined;
        var stateValue = undefined;
        var dataHandler = dataDiff.dataHandler;
        var diffHandler = dataDiff.diffHandler;
        if(dataHandler){//have top priority
            var bothData = dataHandler();
            if(!bothData){
                bothData = {};
            }
            thisValue = bothData.thisData;
            stateValue = bothData.stateData;
        }else{
            var thisKey = dataDiff.thisKey;
            var stateKey = dataDiff.stateKey;
            //console.log("# timeoutChkDataDiff -> thisKey/stateKey = ", thisKey, stateKey);
            thisValue = thisKey ? pageObj[thisKey] : undefined;
            stateValue = (stateKey && stateKey != '') ? pageObj.state[stateKey] : pageObj.state;
        }
        var dataUpdated = false;
        if(diffHandler){
            //console.log("# timeoutChkDataDiff -> user selft diff method.");
            dataUpdated = diffHandler(thisValue, stateValue, dataDiff.compareSpecials);
        }else{
            dataUpdated = checkDataDiff(thisValue, stateValue, dataDiff.compareSpecials);
        }
        //var dataUpdated = checkDataDiff(thisValue, stateValue, dataDiff.compareSpecials);
        //console.log("# timeoutChkDataDiff -> dataUpdated = ", dataUpdated);
        if(dataUpdated){
            if(saveAction){
                saveAction(() => timeoutLogout(pageObj), true);//callback mehotd
            }else{
                console.error("# timeoutChkDataDiff -> saveAction is undefined.");
            }
        }else{
            timeoutLogout(pageObj);
        }
    }else{
        timeoutLogout(pageObj);
    }
}
function isIOSDevice(){
	return ( navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i));
}
export const beforeunload = (e) => {
	
	 if(navigator.userAgent.match(/Chrome/i)){
		if (!window.confirm(DATA_SAVE_MSG)) {
		    return false;
		  }
	
	 }
	 
    e.returnValue = DATA_SAVE_MSG;
    
    

    
    return e.returnValue;
}

export function addEventBeforeunloadUtil(){
    
    var isOnIOS = isIOSDevice();
    var eventName = isOnIOS ? "pagehide" : "beforeunload";
    console.log("addEventBeforeunloadUtil",navigator.userAgent,  eventName);
    
    if (isOnIOS){
    	console.log("isOnIOS docuemnt unload",navigator.userAgent);
    	document.unload = undefined;
    	document.unload = beforeunload;
    }
    else if(navigator.userAgent.match(/Chrome/i)){
    	console.log("onbeforeunload=",navigator.userAgent);
    	window.onbeforeunload = undefined;
   	 	window.onbeforeunload = beforeunload;
    }else{
    	 console.log("addEventListener",navigator.userAgent);
    	 window.removeEventListener(eventName, beforeunload);
     	window.addEventListener(eventName, beforeunload);
    	
    }
    window.addEventListener("unload", beforeunload);
    
}
export function removeEventBeforeunloadUtil(){
	
	 var isOnIOS = isIOSDevice();
	 var eventName = isOnIOS ? "pagehide" : "beforeunload";
	    
	    console.log("removeEventBeforeunloadUtil",isOnIOS,  eventName);
	    
	    if (isOnIOS){
	    	console.log("isOnIOS docuemnt unload",navigator.userAgent);
	    	document.unload = undefined;

	    }
	    else if(navigator.userAgent.match(/Chrome/i)){
	    	console.log("onbeforeunload=",navigator.userAgent);
	   	 	window.onbeforeunload = undefined;
	    }else{
	    	 console.log("addEventListener",navigator.userAgent);
	    	 window.removeEventListener(eventName, beforeunload);
	    	
	    }
	    
	    
    
}
export function timeoutLogout(pageObj){
	
	//console.log("timeoutLogout Goto Logout!");
	localStorage.setItem('access_token', "");
	localStorage.removeItem('access_token');
	localStorage.removeItem('access_token_timeout');
	localStorage.removeItem('target_url');
	localStorage.clear();
	window.localStorage.clear();
	window.sessionStorage.clear();
	//console.log("timeoutLogout Goto Logout, Clear!", window.localStorage);
	//console.log("timeoutLogout Goto Logout, Clear!", window.sessionStorage);
	
	var cookie = document.cookie.split(';');

	for (var i = 0; i < cookie.length; i++) {

	    var chip = cookie[i],
	        entry = chip.split("="),
	        name = entry[0];
	    //console.log("timeoutLogout Goto Logout, Clear!", name);
	    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}
	
    pageObj.props.setLoginUser(null);
    //pageObj.props.setUserPermission(null);
    clearAccessToken();
    removeEventBeforeunloadUtil();

    //warning
    Modal.warning({
        title: 'Warning',
        content: 'Session Timeout. Please login again.',
        onOk: ()=>{
        	
        	
            
            if(LOGIN_PAGE_URL.disable == false){
                if(LOGIN_PAGE_URL.routeUrl == true){
                    dataPutAndGo(pageObj, LOGIN_PAGE_URL.logoutPageUrl, null, null, false);
                }else{
                    if(pageObj.removeEventBeforeunloadUtil){
                        pageObj.removeEventBeforeunloadUtil();
                    }
                    //window.removeEventListener('beforeunload', beforeunload);
                    window.location = LOGIN_PAGE_URL.logoutPageUrl;
//                    window.open(LOGIN_PAGE_URL.logoutPageUrl,"_self");
                }            
            }else{
                dataPutAndGo(pageObj, '/blank', {item:{}}, {}, false);
            } 
        },
    });
}

export function sortingLabel(targetObj){ 
	if(targetObj != undefined){
		targetObj.sort(function(a,b){ 
			let comparison = 0;
			  if (a.label > b.label) {
			    comparison = 1;
			  } else if (a.label < b.label) {
			    comparison = -1;
			  }
			  return comparison;
			
		});
	
	}
	
}

export function sortingValue(targetObj){ 
	if(targetObj != undefined){
		targetObj.sort(function(a,b){ 
			let comparison = 0;
			  if (a.label > b.label) {
			    comparison = 1;
			  } else if (a.label < b.label) {
			    comparison = -1;
			  }
			  return comparison;
			
		});
	
	}
	
}

export function isEmpty(targetObj){ 
	const isArray = targetObj instanceof Array;
    if (isArray) {
        if (targetObj.length === 0) {
            return true;
        }
    } else {
        if (targetObj === undefined || targetObj === null || trim(targetObj) === "") {
            return true;
        }
    }
    return false;
}


export function messageerror(msg) {
		Modal.error({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}
	
export function messagesuccess(msg) {
		Modal.success({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}