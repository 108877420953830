import React from 'react';
//import logo from './logo.svg';
import './App.css';
//import { withRouter } from 'react-router';
import { BrowserRouter,Route, Switch } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import AnnoBoxPage from './pages/AnnoBox/AnnoBoxPage';
import TrainingMaterialAdmin from './pages/training/TrainingMaterialAdmin';
import TrainingMaterial from './pages/training/TrainingMaterial';
//import RolePage from './pages/RolePage';
// import RoleEditPage from './pages/RoleEditPage';
//import UserPage from './pages/UserPage';
// import UserEditPage from './pages/UserEditPage';
//import GroupPage from './pages/GroupPage';
// import GroupEditPage from './pages/GroupEditPage';
//import DepartmentPage from './pages/DepartmentPage';
// import DepartmentEditPage from './pages/DepartmentEditPage';

//import AlertPage from './pages/AlertPage';
import ReferenceDataPage from './pages/referenceData/ReferenceDataPage';
import ReportPage from './pages/report/ReportPage';
import SuccessStoryLeads from './pages/successStory/SuccessStoryLeads';
import LeadStatusReportPage from './pages/lead/LeadStatusReportPage';
import successStoryDetail from './pages/successStory/SuccessStoryDetail';
import WriteSuccessStory from './pages/successStory/WriteSuccessStory';
import TokenPage from './pages/TokenPage';



import HistoryNew from './pages/successStory/History_new';
 
function App() {
  return (
    <div className="pageMain">
    <BrowserRouter>
      <Switch>
        <Route path="/token" component={TokenPage} />
        <Route path="/blank" component={HomePage} />
        {/* 在这里放新加的 route */}
        {/* <Route path="/admin/department/edit" component={DepartmentEditPage} />
        <Route path="/admin/group/edit" component={GroupEditPage} />
        <Route path="/admin/user/edit" component={UserEditPage} />
        <Route path="/admin/role/edit" component={RoleEditPage} /> */}
        {/* AdminPage */}
        <Route path="/admin" component={()=>{
          return (<AdminPage key={Math.random()} />);
        }} />
        {/* ReferenceDataPage */}
        <Route path="/referenceData" component={()=>{
          return (<ReferenceDataPage key={Math.random()} />);
        }} />
        {/* ReportPage */}
        <Route path="/report" component={()=>{
          return (<ReportPage key={Math.random()} />);
        }} />

        {/* AnnoBoxPage */}
        <Route path="/annoBox" component={()=>{
          return (<AnnoBoxPage key={Math.random()} />);
        }} />
        
        {/* TrainingMaterial */}
        <Route path="/trainingMaterial" component={()=>{
          return (<TrainingMaterial key={Math.random()} />);
        }} />
        
         {/* TrainingMaterialAdmin */}
        <Route path="/trainingMaterialAdmin" component={()=>{
          return (<TrainingMaterialAdmin key={Math.random()} />);
        }} />


        <Route path="/successStoryLeads/storyDetail" component={successStoryDetail} />
        {/* SuccessStoryLeads */}
        <Route path="/successStoryLeads" component={()=>{
          return (<SuccessStoryLeads key={Math.random()} />);
        }} />

        {/* LeadStatusReportPage */}
        <Route path="/leadStatusReport" component={()=>{
          return (<LeadStatusReportPage key={Math.random()} />);
        }} />

        {/* WriteSuccessStory */}
        <Route path="/writeStory" component={()=>{
          return (<WriteSuccessStory key={Math.random()} />);
        }} />


        <Route path="/History" component={HistoryNew} />

        

        <Route path="/" component={HomePage} />
        
      </Switch>
        </BrowserRouter>
    </div>
  );
}

export default App;
