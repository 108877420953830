import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser, setUserPermission } from '../../redux/actions';
import '../../index.css';
import '../component/MyComponent.css';
import { Input, Row, Col, Button, Select, DatePicker, Icon, Modal, message, ConfigProvider, Switch, Upload } from 'antd';
import HeaderPage from '.././HeaderPage';
import { sortingValue, sortingLabel, dataPutAndGo, dataGet, STATUS_, sortDateStr, getDepartmentName, removeFooter, fireWinResizeEv, messageerror, messagesuccess } from '../../Utils';
import { get, post, getAccessToken } from "../../services/AuthService";

import ResponseTable from '../component/ResponseTableFixColWidth.js';
import { Tabs, Tooltip, List } from 'antd';
import MySelect from '../component/MySelect';
import { successStory_UI_CONFIG, DATA_UI_CONFIG, LEVEL_UI_CONFIG, REPORT_UI_CONFIG } from '../../../src/DataConfig'
import lodash from 'lodash';
//import { setServers } from 'dns';
import locale from 'antd/es/date-picker/locale/en_US';
import BraftEditor from 'braft-editor';
import { GUI_DATE_FORMAT } from '../../Config'
import logoCrossW from './img/btnCross_w.png'
import logoCrossG from './img/btnCross_g.png'

moment.locale('en');

const { TabPane } = Tabs;
const { Option } = Select;
const LEAD_SEARCH_HIST = 'LeadSearchHist';
const LEAD_SEARCH_ANNO_CLOSE = 'LeadSearchAnnoCLose';

const mapStateToProps = (state) => {
	//console.log("mapStateToProps >>> " , state );
	return {
		currentLang: state.currentLang,
		loginUser: state.loginUser,
		userPermission: state.userPermission
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		setCurrentLang: (info) => {
			dispatch(setCurrentLang(info));
		},
		setLoginUser: (info) => {
			dispatch(setLoginUser(info));
		},
		setUserPermission: (info) => {
			dispatch(setUserPermission(info));
		}
	};
}

class SuccessStoryLeads extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isShowAllCriteria: true,
			//gui : {storyId : "", keywords : "" , userName :"" ,eventName:"",tier:"" ,status : "" ,}
			gui: {isThereTdcInd: false},
			titleMessage: "",
			hideTable: false,
			isShowAnnouncement: true,
			annoBox: "",
			controlTierInfo: false,
			departmentList: [],
			ssInfoList: [],
			isTooltipOpen:false,
			attprefix: "batchUploadLeads"
		};

		this.accessToken = getAccessToken();

	}


	// searchHandlerPromiseTest = (key) => {
	// 	return new Promise(resolve => {
	// 		get( DATA_UI_CONFIG.url + '/userServer/saveData', null).then(data => {
	// 			resolve(data);
	// 		}).catch((e) => {
	// 			resolve([]);
	// 		});
	// 	}); 
	// }

	searchHandlerPromiseName = (key) => {
		return new Promise(resolve => {
			get(DATA_UI_CONFIG.url + '/userServer/searchAllUserName?userName=' + key, null).then(data => {
				//console.log(" searchAllEmail >>>>> " , data );
				if (data.returnType === "SUCCESS") {
					var options = [];
					options = data.emailList.map((item, index) => {
						return { value: item.split('(')[0], label: item.split('(')[0] }
					})
					resolve(options);
				}
			}).catch((e) => {
				resolve([]);
			});
		});
	}
	getTableButtons(d) {
		var exportLeadsInd = false;
		var createLeadsInd = false;
		var bulkUploadLeads = false;
		if (this.props.userPermission) {
			exportLeadsInd = this.props.userPermission.exportLeadsInd;
			createLeadsInd = this.props.userPermission.createLeadsInd;
			bulkUploadLeads = this.props.userPermission.bulkUploadLeadsInd;
		}
		
		var buttonsList = [];

		if (createLeadsInd) {
			buttonsList.push({ title: 'Create New Lead', handler: this.handleNew, style: { cursor: 'pointer' }, icon: 'file-add' });
		}


		if (exportLeadsInd) {

			if (d == undefined || d.length == 0) {
				
				buttonsList.push({ title: 'Export', handler: this.handleExport, style: { cursor: 'default', color: '#7F7F7F' }, icon: 'export' });
			} else {
				
				buttonsList.push({ title: 'Export', handler: this.handleExport, style: { cursor: 'pointer' }, icon: 'export' });
			}


		}
		var userId = (this.props.loginUser!=undefined && this.props.loginUser.loginId!=undefined?this.props.loginUser.loginId.replace("\\", "_"):"");
		
		
		var userName =  (this.props.loginUser!=undefined && this.props.loginUser.userName!=undefined?this.props.loginUser.userName:"");
		
		console.log("userId", userId, userName );
		
		if (bulkUploadLeads) {
			buttonsList.push({ title: 'Batch Upload Template', handler: this.handleTemplate, style: { cursor: 'pointer' }, icon: 'book' });
			
			var fileprops = {
				action: DATA_UI_CONFIG.url + '/file/uploadBatchLeadsFile?dirStr=' + this.state.attprefix+"&user="+userName+"&userId="+userId,
				headers: { 'X-Requested-With': 'XMLHttpRequest', 'Authorization': this.accessToken },
				onChange: e => this.handleChange(e, "file"),
				multiple: false,
				showUploadList: false
			};
			
			buttonsList.push({ title: 'Batch Upload', handler: this.handleBatchUpload, style: { cursor: 'pointer' }, icon: 'upload', 
			customize: (
				<Upload 
					{...fileprops}
					fileList={this.state.fileList}
				>
					<div className="_NMT_tableButItem">
						<Icon type="upload" /> Batch Leads Upload
					</div>
				</Upload>
			
			
			)

			});
			


		}
		return buttonsList;
	}
	
	handleChange = (info, str) => {
		let fileList = [...info.fileList];

		fileList = fileList.slice();
		console.log("fileList size", fileList.length);


		var type = "";
		if (info.file != undefined) {
			var fileName = info.file.name;
			var index = fileName.lastIndexOf('.');
			type = info.file.name.slice((index + 1), fileName.length);
		}
		var match = (new RegExp('[~#%\&{}+\|]|\\.\\.|^\\.|\\.$')).test(info.file.name);
		if (match) {
			messageerror("A file name can't contain any of the following characters: ~ # % & * { } \ : < > ? / + | \"");
			return false;
		}
		else if (type.toLowerCase() != "xlsm") {
			message.config({ top: '10%', });
			messageerror("Format: xlsm. Maximum file size 50MB.");
			return false;
		} else if (info.file.size > 52428800) {	// 50M 
			message.config({ top: '10%', });
			messageerror(info.file.name + ' upload failure as file size exceeds 50MB.');
			return false;
		} else if (info.file.response && info.file.response != 'success') {
			message.config({ top: '10%', });
			var data = this.state.data;
			const matchKey = info.file.uid !== undefined ? 'uid' : 'name';
			console.log("matchKey", matchKey, info.file[matchKey]);
			const removed = fileList.filter(item => item[matchKey] != info.file[matchKey]);


			if (str == "file") {
				data.fileList = removed;
			} 

			console.log("matchKey", data.fileList);
			this.setState({ data: data });
			if (info.file.response != undefined && info.file.response != null && info.file.response != "fail" && info.file.response != "") {
				messageerror(info.file.response);

			} else {
				messageerror("File failed to be uploaded. Please contact system admin.");
			}
			
			return false;

		} else {
			if (info.file.status === "done") {
				message.config({ top: '10%', });
				messagesuccess(info.file.name + ' uploaded successfully.');
			
			}
			
		}
		this.setState( {fileList});
		console.log("Batch Leads Upload this.state.data.fileList >>> ", info);
	};
	

	filterOptionTopic = (inputValue, option) => {

		return true;

	}
	searchHandlerPromiseTopic = (key) => {
		console.log(" searchAllTopic >>>>>", key);
		this.setState({ TopicList: [] });
		if (key == undefined || key == null || key.trim() == "") {
			console.log(" searchAllTopic >> retrun >>>", key);

			return;
		}



		return new Promise(resolve => {
			var lanCode = "EN";

			if (!key || key == undefined || key == null || key.trim() == "") {
				return;
			}

			get(DATA_UI_CONFIG.url + '/searchtopicByLabel?lanCode=' + lanCode + '&label=' + key, null).then(data => {
				console.log(" searchAllTopic >>>>> ", data);
				if (data.returnType == "SUCCESS") {
					var options = [];
					options = data.topicThemeList.map((item, index) => {
						return { value: item.topicTheme, label: item.topicTheme }
					})
					sortingValue(options);


					console.log(" searchAllTopic >>options>>> ", options);
					this.setState({ TopicList: options });

				}
			}).catch((e) => {
				resolve([]);
			});
		});
	}

	componentDidMount() {
		console.log("this.props.loginUser ", this.props.loginUser);
		if (localStorage.getItem(LEAD_SEARCH_HIST) && this.props.loginUser != null && this.props.loginUser != undefined) {
			console.log("componentDidMount Search ");
			setTimeout(() => {
			  console.log("Delayed for 1 second to Search.");
			  this.handleSearch();
			}, "1000");
			
		}
		removeFooter();

		console.log(LEAD_SEARCH_ANNO_CLOSE, localStorage.getItem(LEAD_SEARCH_ANNO_CLOSE));
		if (localStorage.getItem(LEAD_SEARCH_ANNO_CLOSE) != null ||
			localStorage.getItem(LEAD_SEARCH_ANNO_CLOSE) == '') {
			console.log(LEAD_SEARCH_ANNO_CLOSE, "Set false");

			this.setState({ isShowAnnouncement: false });
		}
	}
	componentWillMount() {
		if (this.props.loginUser != null && this.props.loginUser != undefined) {

			dataGet(this);
			//this.searchData();
			var dataObj = this.props.location.query;
			if (!dataObj) {
				dataObj = {};
			}
			var oridata = dataObj.stateData;
			if (!oridata) {
				oridata = {};
				oridata.item = {};
			}
			var titleMessage = "";
			var returnMsg = oridata.returnMsg;
			if (returnMsg) {
				titleMessage = returnMsg;
			}
			var departmentList = [];
			get(DATA_UI_CONFIG.url + '/searchAllDepartment', this).then(data => {
				//console.log(" department data ------------->>> " , data );
				if (data) {
					departmentList = data.users.map((item) => {
						item.value = item.name;
						item.label = item.name;
						
						if(item.similarname){
							item.similar=item.similarname;
						}
						
						return item;
					});
					
					departmentList = this.removeRepeat(departmentList);
					get(DATA_UI_CONFIG.url + '/ssls/getOffices', this).then(data1 => {
						
						
						if (data1) {
							
							data1.reverse();
							data1.map((item) => {
								
								departmentList.unshift({value:item, label:item});
							
								
								return ;
							});
							
							
						}
                        console.log("departmentList >>>>> " , departmentList );
                        this.setState({ departmentList: departmentList });
                        
					});
					
				}
			});

			
			
			

			//		get(successStory_UI_CONFIG.url + '/ssls/searchSslsData', this).then(data => {
			//			this.setState({ ssInfoList: data.users });
			//		});
			//Get Announcement
			get(DATA_UI_CONFIG.url + '/ssls/getAnnoBox', this).then(data => {

				if (data && data != '') {

					this.setState({ annoBox: data.entity });
				}
			});


			// get( DATA_UI_CONFIG.url + '/userServer/searchByNameLike?name=' + "" + '&email=' + "" + '&role='  + "" + '&department=' + "", this).then(data => {

			// 	//console.log("searchByNameLike ------------->>> " , data );
			// 	if(data.returnType === "SUCCESS" ){
			// 		var newList = [];
			// 		data.users.map((item) => {
			// 			newList.push({ id: item.id , value : item.userName , label : item.userName });

			// 		})
			// 		this.setState({ userList : newList });
			// 	}

			// });

			get(LEVEL_UI_CONFIG.url + '/searchAllreferenceDataBySearch', this).then(data => {

				var typeOfSuccessCaseList = [];
				var IndustryList = [];
				var locationList = [];
				var TopicList = [];
				var leadNatureList = [];
				
				var eventList = [];
				// console.log("data22222 ------------->>> " , data );

				if (data.returnType == "SUCCESS") {
					typeOfSuccessCaseList = data.typeOfSuccessCaseList;
					IndustryList = data.industryList;
					leadNatureList = data.leadNatureList;

					locationList = data.locationList.map((item) => {
						item.value = item.id;
						item.label = item.nameEn;
						return item;
					});

					if (!data.eventList) {
						data.eventList = [];
					}
					eventList = data.eventList.map((item, index) => {
						item.value = item.id;
						item.label = item.eventNameEn;
						return item;
					});
					sortingLabel(eventList);

				}

				typeOfSuccessCaseList = this.splitByLanguage(typeOfSuccessCaseList, 'nameEn', 'nameTc', 'nameSc');
				leadNatureList = this.splitByLanguage(leadNatureList, 'nameEn', 'nameTc', 'nameSc');
				var othersItem = undefined;

				typeOfSuccessCaseList = typeOfSuccessCaseList.filter(function (item) {
					if (item.label == "Others") {
						othersItem = item;
					}

					return (item.label != "Others");
				});

				if (othersItem != undefined) {
					typeOfSuccessCaseList.push(othersItem);
				}

				if (leadNatureList) sortingLabel(leadNatureList);
				this.setState({
					typeOfSuccessCaseList: typeOfSuccessCaseList,
					IndustryList: this.splitByLanguage(IndustryList, 'nameEn', 'nameTc', 'nameSc'),
					locationList: this.splitByLanguage(locationList, 'nameEn', 'nameTc', 'nameSc'),
					TopicList: this.removeRepeat(TopicList),
					titleMessage: titleMessage,
					leadNatureList: leadNatureList,
					eventNameList: this.splitByLanguage(eventList, 'eventNameEn', 'eventNameTc', 'eventNameSc')
				});
				setTimeout(() => {
					this.setState({ titleMessage: '' })
				}, 10000);
			});
			var kpiList = [
				{ id: 1, value: "YES", label: "Yes" },
				{ id: 2, value: "NO", label: "No" },
				
			];
			var leadDiscPublicList = [
				{ id: 1, value: "Disclosable", label: "Disclosable" },
				{ id: 2, value: "Cannot be Disclosed", label: "Cannot be Disclosed" },
				{ id: 2, value: "Partially disclosable", label: "Partially disclosable" },
			];
			
			var devStatusList = [
				{ id: 1, value: "Developed", label: "Developed" },
				{ id: 2, value: "Developing", label: "Developing" },
			];
			var statusList = [
				{ id: 1, value: "New", label: "New" },
				{ id: 2, value: "Submitted", label: "Submitted" },
				{ id: 3, value: "Reopened", label: "Reopened" },
				{ id: 4, value: "Reviewing", label: "Reviewing" },
				// { id: 5, value: "Approved" , label: "Approved"  },
				// { id: 6, value: "Published", label: "Published" },
				{ id: 7, value: "Archived", label: "Archived" },
				{ id: 8, value: "Closed", label: "Closed" },
			];
			var tierList = [{ id: 1, value: "1", label: "Tier 1 - Internal Reference" },
			{ id: 2, value: "2", label: "Tier 2 - Pending" },
			{ id: 3, value: "3", label: "Tier 3 - Mature Lead" }
			];


			var usageChannelList = [

			];

			this.setState({ usageChannelList: usageChannelList, statusList: statusList, tierList: tierList, kpiList:kpiList, leadDiscPublicList:leadDiscPublicList, devStatusList:devStatusList });

			get(LEVEL_UI_CONFIG.url + '/searchTagging/SearchAllChannels', this).then(data => {

				console.log("searchTagging 6", data);
				if (data && data.returnType == "SUCESS") {

					var tempList = [];
					var othersItem = undefined;
					var anotherItem = undefined;

					tempList = data.channels.map((item) => {



						item.label = item.channelNameEn;
						item.value = item.channelNameEn;
						return item;
					});

					sortingLabel(tempList);



					tempList = tempList.filter(function (item) {
						if (item.label == "Others") {
							othersItem = item;
						}
						if (item.label == "Another HKTDC Platform") {
							anotherItem = item;
						}

						return (item.status == 'Active' && item.label != "Others" && item.label != "Another HKTDC Platform");
					});
					
					var tempList2 = [];
				
				
					tempList.map((item) => {
	
						tempList2.push(	item);
						if (item.label == "HKTDC Website/App (other than HKMB)") {
							tempList2.push(	anotherItem);
						}
						
						return item;
					});

					if (othersItem != undefined) {
						tempList2.push(othersItem);
					}

					this.state.usageChannelList = tempList2;
					console.log("searchTagging 7", this.state.usageChannelList);

				}

			}).catch((e) => {
				console.log(e);
			});


			//		this.setState({  gui: JSON.parse(localStorage.getItem("LeadSearchGui")) });
			var seadSearchTableResultHist = JSON.parse(localStorage.getItem(LEAD_SEARCH_HIST));
console.log( " seadSearchTableResultHist ", seadSearchTableResultHist);
			if (seadSearchTableResultHist) {
				this.setState(seadSearchTableResultHist);
				//			console.log( " this.state.current ", this.state);

			}
		}
	}


	splitByLanguage(list, lanEnCode, lanTCcode, lanScCode) {
		var enList = []; var tcList = []; var scList = [];

		list.map((item, index) => {
			//console.log("splitByLanguage", item, item[lanEnCode]);
			if (item[lanEnCode] && item[lanEnCode] != '') {
				enList.push({ value: item.id + '_en', label: item[lanEnCode], similar: (item[lanEnCode].toLowerCase().indexOf("mainland china")==0?"china":item[lanEnCode]) });
			}
			if (item[lanTCcode] && item[lanTCcode] != '') {
				tcList.push({ value: item.id + '_tc', label: item[lanTCcode] });
			}
			if (item[lanScCode] && item[lanScCode] != '') {
				scList.push({ value: item.id + '_sc', label: item[lanScCode] });
			}
		});

		enList.push.apply(enList, tcList);
		enList.push.apply(enList, scList);
		sortingLabel(enList);
		return enList;
	}


	messageinfo(msg) {
		Modal.info({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}
	messageerror(msg) {
		Modal.error({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}

	//	searchData(){

	//		var storyId = this.state.gui.storyId;
	//		var keywords = this.state.gui.keywords;

	//		get(successStory_UI_CONFIG.url + '/ssls/searchSslsData', this).then(data => {
	//			this.setState({ ssInfoList: data.users });
	//		});
	//		
	//	}



	clickRow = (header, rowData, index) => {
		var viewLeadsInd = false;
		if (this.props.userPermission) {
			viewLeadsInd = this.props.userPermission.viewLeadsInd;
		}
		if (viewLeadsInd) {
			var pathname = this.props.location.pathname;
			rowData.pageSource = pathname;
			dataPutAndGo(this, '/successStoryLeads/storyDetail', rowData, null, null);
		}
	}

	clickRow2 = (header, rowData, parentIndex) => {
		//console.log(" rowData " , rowData );

		var gui = this.state.gui;
		var topiclList = gui.topicl;
		if (topiclList == undefined) {
			topiclList = [];
		}
		if (topiclList.length == 0) {
			topiclList.push(rowData.label);
		}

		var check = 0;
		if (topiclList.length < 3) {
			topiclList.map((item, index) => {
				if (item == rowData.label) {
					check = 1;
				}
				return null;
			});

			if (check == 0) {
				topiclList.push(rowData.label);
			}
		}



		gui.topicl = topiclList;

		if (rowData) {
			this.setState({
				gui: gui,
				visible: false,
			});
		}
	}



	clickId = (entity) => {
		//var list = this.state.ssInfoList;
		//var entity = {};
		// list.map((item,index)=>{
		// 	if(item.id === id){
		// 		entity = item;
		// 	}
		// });
		var viewLeadsInd = false;
		var searchLeadsAllDepartment = false;

		if (this.props.userPermission) {
			viewLeadsInd = this.props.userPermission.viewLeadsInd;
			searchLeadsAllDepartment = this.props.userPermission.searchLeadsAllDepartment;
		}
		if (viewLeadsInd || searchLeadsAllDepartment) {
			let roleCode = this.props.loginUser.roleId;

			//let departmentName = getDepartmentName(this.props);

			//			var userName = this.props.loginUser.userName;
			if (entity && (roleCode == '2' || roleCode == '1') && entity.status == STATUS_.Reviewing && entity.revRightInd === "Y") {

				var pathname = this.props.location.pathname;
				entity.pageSource = pathname;
				dataPutAndGo(this, '/writeStory', { item: entity });
				window.scrollTo(0, 0)
			} else {
				pathname = this.props.location.pathname;
				entity.pageSource = pathname;
				dataPutAndGo(this, '/successStoryLeads/storyDetail/?leadId=' + entity.id, entity);
				window.scrollTo(0, 0)
			}

		}
	}

	spliteAndGetIdArr2 = (oriId) => {
		//console.log("oriId arr >>> "  ,oriId );
		var newArr = "";
		if (oriId != undefined && oriId.length > 0) {
			oriId.map((item) => {
				var arr = item.split('_');
				newArr += arr[0] + '<|>';
			})
		}
		return newArr;
	}
	spliteAndGetIdArr = (oriId) => {
		//console.log("oriId arr >>> "  ,oriId );
		var newArr = "";
		if (oriId != undefined && oriId.length > 0) {
			oriId.map((item) => {
				var arr = item.split('_');
				newArr += arr[0] + ',';
			})
		}
		return newArr;
	}

	spliteAndGetIdStr = (oriId) => {
		//console.log("oriId str >>> "  ,oriId );
		var arr = oriId.split('_');
		return arr[0];
	}

	splitByComma = (dataList) => {
		var str = "";
		dataList.map((item, index) => {
			str += item + ',';
		})
		return str;
	}


	copyJson = (baseData) => {
		var datasStr = JSON.stringify(baseData);
		if (datasStr) {
			return JSON.parse(datasStr);
		} else {
			return null;
		}
	}

	handleSearch = () => {
		// get('/sucessStroy/search', this).then(data => {
		// 	this.setState({ ssInfoList: data });
		// });

		var gui = this.copyJson(this.state.gui);
		console.log(" gui before >>> ", gui);



		var storyId = gui.storyId == undefined ? "" : Number(gui.storyId);
		let cc = [storyId]
		gui.storyId = cc;
		var keywords = gui.keywords == undefined ? "" : gui.keywords;
		var userName = gui.userName == undefined ? "" : gui.userName;
		var department = gui.department == undefined ? "" : this.spliteAndGetIdArr2(gui.department);
		gui.department = department;
		var typeOfSuccessCase = gui.typeOfSuccessCase == undefined ? "" : this.spliteAndGetIdArr(gui.typeOfSuccessCase);
		gui.typeOfSuccessCase = typeOfSuccessCase;
		var leadNature = gui.leadNature === undefined ? "" : this.spliteAndGetIdArr(gui.leadNature);
		gui.leadNature = leadNature;
		var topicl = gui.topicl == undefined ? "" : this.splitByComma(gui.topicl);
		gui.topicl = topicl;
		var industry = gui.industry == undefined ? "" : this.spliteAndGetIdArr(gui.industry);
		gui.industry = industry;
		var eventId = gui.eventId == undefined ? "" : this.spliteAndGetIdArr(gui.eventId);
		gui.eventId = eventId;
		var location = gui.location == undefined ? "" : this.spliteAndGetIdArr(gui.location);
		gui.location = location;
		var storyTier = gui.storyTier == undefined ? "" : gui.storyTier;
		var status = gui.status == undefined ? "" : gui.status;
		var kpi = gui.kpi == undefined ? "" : gui.kpi;
		var kpiOversea = gui.kpiOversea == undefined ? "" : gui.kpiOversea;
		var discloseToPublic = gui.discloseToPublic == undefined?"": gui.discloseToPublic;
		var devStatus = gui.devStatus == undefined?"": gui.devStatus;
		var usageChannel = gui.usageChannel == undefined ? "" : this.spliteAndGetIdArr(gui.usageChannel);

		gui.usageChannel = usageChannel;

		var submissionDateFrom = gui.submissionDateFrom == undefined ? "" : gui.submissionDateFrom;
		var submissionDateTo = gui.submissionDateTo == undefined ? "" : gui.submissionDateTo;
		var lastmodifiedFrom = gui.lastmodifiedFrom == undefined ? "" : gui.lastmodifiedFrom;
		var lastmodifiedTo = gui.lastmodifiedTo == undefined ? "" : gui.lastmodifiedTo;
		
		
		

		console.log(" gui >>> " , gui );

		if ("{}" === JSON.stringify(gui)) {


			this.messageinfo("Please fill in at least one search field");


			return;
		}
		if (storyId == "" && keywords == "" && userName == "" && department == "" 
		&& typeOfSuccessCase == "" && topicl == "" && industry == "" && eventId == "" 
		&& location == "" && storyTier == "" && status == "" && usageChannel == "" 
		&& submissionDateFrom == "" && submissionDateTo == "" && lastmodifiedFrom == "" 
		&& lastmodifiedTo == "" && kpi == "" && kpiOversea == "" && discloseToPublic=="" && devStatus=="" && leadNature==="" ) {

			this.messageinfo("Please fill in at least one search field");
			return;
		}

		var loginUser = this.props.loginUser;


		var roleCode = loginUser.roleId;
		var departmentName = getDepartmentName(this.props);
		gui.currentDepartment = departmentName;
		gui.currentRole = roleCode;
		if (keywords != "") {
			gui.keywords = keywords;
		}
		console.log(" gui data >>> ", gui);
		localStorage.removeItem(LEAD_SEARCH_HIST);		
		
		this.state.ssInfoList=[];
		
		localStorage.setItem(LEAD_SEARCH_HIST, JSON.stringify(this.state));
		
		post(successStory_UI_CONFIG.url + '/ssls/searchByCriteria', gui, this).then(data => {
			console.log(" gui data >>> " , data );
			
			
			
			var ssInfoList = this.formatDataForGui(data.users);


			this.setState({ ssInfoList: ssInfoList });

			
			fireWinResizeEv();
			console.log("dispatchEvent");
		});


		this.setState({
			hideTable: false
		})

	}

	formatDataForGui(data) {
		if (!data) {
			data = [];
		}
		var dataList = [];

		data.map((item, index) => {
			var location1 = item.location1;
			var location2 = item.location2;
			var location3 = item.location3;
			var location4 = item.location4;
			var location5 = item.location5;
			var location = "";
			if (location1) { location += location1; }
			if (location2) { location += ", " + location2; }
			if (location3) { location += ", " + location3; }
			if (location4) { location += ", " + location4; }
			if (location5) { location += ", " + location5; }
			item.location = location;

			var industry1 = item.industry1;
			var industry2 = item.industry2;
			var industry3 = item.industry3;
			var industry4 = item.industry4;
			var industry5 = item.industry5;

			var industry = "";
			if (industry1) { industry += industry1; }
			if (industry2) { industry += ", " + industry2; }
			if (industry3) { industry += ", " + industry3; }
			if (industry4) { industry += ", " + industry4; }
			if (industry5) { industry += ", " + industry5; }
			item.industry = industry;

			var topicTheme1 = item.topicTheme1;
			var topicTheme2 = item.topicTheme2;
			var topicTheme3 = item.topicTheme3;
			var topicTheme4 = item.topicTheme4;
			var topicTheme5 = item.topicTheme5;

			var topStr = "";
			if (topicTheme1) { topStr += topicTheme1; }
			if (topicTheme2) { topStr += ", " + topicTheme2; }
			if (topicTheme3) { topStr += ", " + topicTheme3; }
			if (topicTheme4) { topStr += ", " + topicTheme4; }
			if (topicTheme5) { topStr += ", " + topicTheme5; }
			item.topicTheme = topStr;

			dataList.push(item);
			return null;
		}
		);



		return dataList;
	}

	handleNew = () => {
		dataPutAndGo(this, '/writeStory', { item: {} });
	}
	handleBatchUpload= () => {
		
	}
	
	handleTemplate	= () => {
		
		
		var departmentName = getDepartmentName(this.props);
		var isSameDepInd = 'Y';
		let roleCode = this.props.loginUser.roleId;
		let sslsList = this.state.ssInfoList;
		let ids = "";
		
		var		checkDownloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/checkGenByIdsReportXLSX";
			

		if (checkDownloadUrl != "") {
			
			var postData = {ids: ids, rolecode: roleCode};
			post(checkDownloadUrl, postData, this).then(data => {
				if (data.result && data.result.notGenReport == true) {
					message.config({
						top: '10%',
					});
					this.messageinfo(data.message);
					return;
				}
				if (data.result == undefined) {
					message.config({
						top: '10%',
					});
					this.messageerror("The lead failed to export. Please try again. If the problem persists, please contact system support.");
					return;
				}
				var downloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/downloadLeadPageReportByPath/" + data.result.encodeFilePath + "/" + data.result.key + "/" + data.result.downloadFileName;
				window.open(downloadUrl);
			}).catch((e) => {

				this.messageerror("The lead failed to export. Please try again. If the problem persists, please contact system support.");
				return;
			});

		}
		
		
	}
	handleExport = () => {
		//		var table = this.tables ? this.tables['leadTable'] : null;
		//		var headerCheckboxStatus = null;
		//		if(table != null){
		//			headerCheckboxStatus = table.getHeaderCheckboxStatus() ? true : false;
		//		}

		var checkDownloadUrl = "";
		if (!this.state.ssInfoList || this.state.ssInfoList.length <= 0) {
			return;
		}
		var departmentName = getDepartmentName(this.props);
		var isSameDepInd = 'Y';
		let roleCode = this.props.loginUser.roleId;
		let sslsList = this.state.ssInfoList;
		let ids = "";
		for (let i = 0; i < sslsList.length; i++) {
			if (sslsList[i]._rt_checked) {
				ids += sslsList[i].id + ",";
				var curDepartment = sslsList[i].department;
//				if (!curDepartment || (curDepartment && curDepartment != departmentName)) {
//					isSameDepInd = 'N';
//				}
			}
		}
		ids = ids.substring(0, ids.length - 1);
		if (isSameDepInd === 'N' && (roleCode == '3' || roleCode == '4')) {
			this.messageinfo("Export records must be the same department.");
		} else {
			if (ids != "") {
				// checkDownloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/checkGenByIdsReportXLSX/" + ids + "/" + roleCode;
				checkDownloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/checkGenByIdsReportXLSX";
			} else {
				message.config({
					top: '10%',
				});
				this.messageinfo("No record is selected.");
			}
		}


		if (checkDownloadUrl != "") {
			// get(checkDownloadUrl, this).then(data => {
			// 	if (data.result && data.result.notGenReport == true) {
			// 		message.config({
			// 			top: '10%',
			// 		});
			// 		this.messageinfo(data.message);
			// 		return;
			// 	}
			// 	if (data.result == undefined) {
			// 		message.config({
			// 			top: '10%',
			// 		});
			// 		this.messageerror("The lead failed to export. Please try again. If the problem persists, please contact system support.");
			// 		return;
			// 	}
			// 	var downloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/downloadLeadPageReportByPath/" + data.result.encodeFilePath + "/" + data.result.key + "/" + data.result.downloadFileName;
			// 	window.open(downloadUrl);
			// }).catch((e) => {

			// 	this.messageerror("The lead failed to export. Please try again. If the problem persists, please contact system support.");
			// 	return;
			// });
			var postData = {ids: ids, rolecode: roleCode};
			post(checkDownloadUrl, postData, this).then(data => {
				if (data.result && data.result.notGenReport == true) {
					message.config({
						top: '10%',
					});
					this.messageinfo(data.message);
					return;
				}
				if (data.result == undefined) {
					message.config({
						top: '10%',
					});
					this.messageerror("The lead failed to export. Please try again. If the problem persists, please contact system support.");
					return;
				}
				var downloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/downloadLeadPageReportByPath/" + data.result.encodeFilePath + "/" + data.result.key + "/" + data.result.downloadFileName;
				window.open(downloadUrl);
			}).catch((e) => {

				this.messageerror("The lead failed to export. Please try again. If the problem persists, please contact system support.");
				return;
			});

		}
	}


	gotoDetailPage = (item) => {
		dataPutAndGo(this, '/successStoryLeads/storyDetail', { item: item });
	}

	formatNorList = (vals) => {
		var items;
		if (!vals) {
			items = vals;
		} else {
			var val = vals.split(",");
			val.map((item, index) => {
				if (index == 0) {
					items = item + ' \r\n '
				} else {
					items = items + item + ' \r\n '
				}

			});
		}
		return items;
	}

	changeSwitch(e, str) {
		var guiTemp = this.state.gui;
		if ("isThereTdcInd" == str) {
			guiTemp.isThereTdcInd = e;
			

		} 
		
		this.setState({
			gui: guiTemp
		});
	}

	formatIndustryList = (vals) => {
		var result = "";
		if (!vals) {
			vals = [];
		}
		var IndustryList = this.state.IndustryList;
		if (!IndustryList) {
			IndustryList = [];
		}
		var length = vals.length;
		for (var i = 0; i < length; i++) {
			var userId = vals[i];
			for (var j = 0; j < IndustryList.length; j++) {
				if (userId == IndustryList[j].id) {
					if (result == "") {
						result = IndustryList[j].label;
					} else {
						result = result + "; " + IndustryList[j].label;
					}
					break;
				}
			}
		}

		return result;
	}
	

	formatSingle = (vals) => {
		var result = "";
		if (!vals) {
			vals = [];
		}
		var stageList = [{ value: 1, label: "Stage 1" }, { value: 2, label: "Stage 2" }];

		var userId = vals;
		for (var j = 0; j < stageList.length; j++) {
			if (userId == stageList[j].value) {
				result = stageList[j].label;
				break;
			}
		}



		return result;
	}

	handleClean = () => {
		localStorage.removeItem(LEAD_SEARCH_HIST)
		//		var isShowAllCriteria = this.state.isShowAllCriteria;
		var gui = this.state.gui;


		gui.storyId = "";
		gui.keywords = undefined;
		gui.userName = "";
		gui.department = undefined;
		gui.typeOfSuccessCase = undefined;
		gui.leadNature = undefined;
		gui.topicl = undefined;
		gui.industry = undefined;
		gui.eventId = undefined;

		gui.location = undefined;

		gui.storyTier = "";
		gui.status = "";
		gui.usageChannel = undefined;
		gui.submissionDateFrom = "";
		gui.submissionDateTo = "";
		gui.lastmodifiedFrom = "";
		gui.lastmodifiedTo = "";
		gui.isThereTdcInd = false;
		gui.kpi = "";
		gui.kpiOversea = "";
		gui.discloseToPublic = "";
		gui.devStatus = "";

		


		this.setState({
			gui: gui
		});
	}

	datePickerOnChange = (value, mode) => {
		var gui = this.state.gui;
		gui.submissionDateFrom = mode
		this.setState({ gui: gui });
	}

	datePickerOnChange2 = (value, mode) => {
		var gui = this.state.gui;
		gui.submissionDateTo = mode
		this.setState({ gui: gui });
	}

	datePickerOnChange3 = (value, mode) => {
		var gui = this.state.gui;
		gui.lastmodifiedFrom = mode
		this.setState({ gui: gui });
	}

	datePickerOnChange4 = (value, mode) => {
		var gui = this.state.gui;
		gui.lastmodifiedTo = mode
		this.setState({ gui: gui });
	}

	showTierInfo = () => {
		this.setState({
			controlTierInfo: true,
		}
		);
	}
	closeTierInfo = () => {
		this.setState({
			controlTierInfo: false,
		}
		);
	}

	displayAllCriteria = () => {

		//		var showAllCriteriaObj = document.getElementById("showAllCriteria").style.display;
		//		if (showAllCriteriaObj == "none") {
		//			showAllCriteriaObj = "";
		//		} else if (showAllCriteriaObj == "") {
		//			showAllCriteriaObj = "none";
		//		}
		var isShowAllCriteria = this.state.isShowAllCriteria;
		var gui = this.state.gui;

		//		if(!isShowAllCriteria){
		//			let departmentName = getDepartmentName(this.props);
		//			gui.department = departmentName;
		//		}else{
		//			gui.department = null;
		//			delete gui.department;
		//		}

		this.setState({
			isShowAllCriteria: !isShowAllCriteria,
			gui,
		});
		fireWinResizeEv();
	}


	handleCancel = e => {
		this.setState({
			visible: false,
		});
	};

	handlerInputChange2 = (event) => {
		var stateData = this.state;
		var target = event.currentTarget;
		var fieldName = target.name;
		var value = target.value;
		lodash.set(stateData, fieldName, value);
		this.setState({ ...stateData });
	};

	removeRepeatSingleStr = (itemList) => {
		var resultList = [];
		if (!itemList) {
			itemList = [];
		}
		itemList.map((item) => {
			var exist = false;
			for (var a1 = 0; a1 < resultList.length; a1++) {
				if (item == resultList[a1]) {
					exist = true;
					break;
				}
			}
			if (!exist) {
				resultList.push(item);
			}
			return null;
		});
		return resultList;
	}

	removeRepeat = (itemList) => {
		var resultList = [];
		if (!itemList) {
			itemList = [];
		}
		itemList.map((item) => {
			var exist = false;
			for (var a1 = 0; a1 < resultList.length; a1++) {
				if (item.value == resultList[a1].value) {
					exist = true;
					break;
				}
			}
			if (!exist) {
				resultList.push(item);
			}
			return null;
		});
		return resultList;
	}

	handleCloseAnno = () => {

		this.setState({ isShowAnnouncement: false });
		localStorage.setItem(LEAD_SEARCH_ANNO_CLOSE, "Closed");
	}
	tooltipInfo = () => {
		this.setState({isTooltipOpen:true})
	};
	handleTooltipInfoCancel = () =>{
		this.setState({isTooltipOpen:false})
	}
	render() {


		let roleCode = "";
		if(this.props.loginUser!=undefined){
			roleCode =this.props.loginUser.roleId;
		}
		
		
		
		var ssInfoDatas = this.state.ssInfoList;

		var ssInfoHeader = {
			columns: [
				{
					field: 'id', width: '97px', title: 'Lead ID',
					rowHandler: (col, rowData, tableObj) => {//the value also can get from rowData
						var val = rowData.id;
						return (<a title={val} onClick={() => this.clickId(rowData)}>{val}</a>);
					},
				},
				{
					field: 'companyName', width: '170px', title: 'Company Name',
					format: (val) => {
						return this.formatNorList(val);
					}
				},
				
				{
					field: 'location', width: '200px', title: 'Location',
					rowHandler: (col, rowData, tableObj) => {

						var noTagVal = rowData.location;
						//console.log("######## vvvvv val: ", val);
						var len = 50;
						var showVal = noTagVal;
						if (noTagVal && noTagVal.length > len) {
							showVal = noTagVal.substring(0, len) + "...";
						}

						return (<span title={noTagVal}>{showVal}</span>);
					},
				},
				{
					field: 'industry', width: '200px', title: 'Industry',
					rowHandler: (col, rowData, tableObj) => {

						var noTagVal = rowData.industry;
						//console.log("######## vvvvv val: ", val);
						var len = 50;
						var showVal = noTagVal;
						if (noTagVal && noTagVal.length > len) {
							showVal = noTagVal.substring(0, len) + "...";
						}

						return (<span title={noTagVal}>{showVal}</span>);
					},
				},
				{
					field: 'topicTheme', width: '210px', title: 'Topic / Theme',
					rowHandler: (col, rowData, tableObj) => {

						var noTagVal = rowData.topicTheme;
						//console.log("######## vvvvv val: ", val);
						var len = 50;
						var showVal = noTagVal;
						if (noTagVal && noTagVal.length > len) {
							showVal = noTagVal.substring(0, len) + "...";
						}

						return (<span title={noTagVal}>{showVal}</span>);
					},
				},


				{
					field: 'discloseToPublic', width: '210px', title: 'Disclose to Public',
					format: (val) => {
						if (val != undefined && val != "") {
							return val;
						} else {
							return null
						}
					}
				},
				{
					field: 'devStatus', width: '110px', title: 'Developing Status',
					format: (val) => {
						if (val != undefined && val != "") {
							return val;
						} else {
							return null
						}
					}
				},
				
				{
					field: 'status', width: '130px', title: 'Lead Status',
					rowHandler: (col, rowData, tableObj) => {		//the value also can get from rowData
						if(STATUS_.Reviewing==rowData.status ){
							return (
									<span>{rowData.status} by {rowData.lastModifiedBy.split('(')[0]}</span>
								);
						}else{						
						
							return (
								<span>{rowData.status}</span>
							);
						}


					}
				},
				{
					field: 'submittedByDept', width: '190px', title: 'Submission Department',
					// format: (val) => {
					// 	return this.formatDepartmentList(val);;
					// }
				},
				{
					field: 'submittedBy', width: '184px', title: 'Submitted By',
					rowHandler: (col, rowData, tableObj) => {
						

						var submittedBy = rowData.submittedBy == undefined ? "" : rowData.submittedBy;
						// var dept = rowData.submittedByDept == null ? null : rowData.submittedByDept;
						// var str = "";
						// if(dept != null ){
						// 	str = "("+ dept +")";
						// }
						// var value = "";
						// if( submittedBy != "" ){
						// 	value = submittedBy + str;
						// }


						return submittedBy.split('(')[0];
					}
				},
				{
					field: 'submittedDateTime', width: '193px', title: 'Submission Date',
					format: (val) => {
						if (!val) {
							val = null;
						} else {
							var temp = moment(val, 'YYYY-MM-DD HH:mm:ss');
							val = moment(temp).format(GUI_DATE_FORMAT);
						}
						return val;
					},
					sortHandler: (val) => {
						return sortDateStr(val);
					}
				},
				{
					field: 'lastModifiedBy', width: '184px', title: 'Last Modified By',
					rowHandler: (col, rowData, tableObj) => {
						var lastModifiedBy = rowData.lastModifiedBy == undefined ? "" : rowData.lastModifiedBy;
						// var dept = rowData.updateByDept == null ? null : rowData.updateByDept;
						// var str = "";
						// if(dept != null ){
						// 	str = "("+ dept +")";
						// }

						// var value = lastModifiedBy + str;
						return lastModifiedBy.split('(')[0];
					}
				},
				{
					field: 'lastModifiedDateTime', width: '193px', title: 'Last Modification Date',
					format: (val) => {
						if (!val) {
							val = null;
						} else {
							var temp = moment(val, 'YYYY-MM-DD HH:mm:ss');
							val = moment(temp).format(GUI_DATE_FORMAT);
						}
						return val;
					},
					sortHandler: (val) => {
						return sortDateStr(val);
					}
				},
				{
					field: 'storyDescription', width: '260px', title: 'Lead Description',
					rowHandler: (col, rowData, tableObj) => {//the value also can get from rowData
						//						var val = rowData.storyDescription;
						var noTagVal = BraftEditor.createEditorState(rowData.storyDescription).toText();// deleteHtmlElement(val);//getTextFromHtml(val);
						//console.log("######## vvvvv val: ", val);
						var len = 50;
						var showVal = noTagVal;
						if (noTagVal && noTagVal.length > len) {
							showVal = noTagVal.substring(0, len) + "...";
						}
						//console.log("######## vvvvv noTagVal/showVal: ", noTagVal,showVal);
						return (<span title={noTagVal}>{showVal}</span>);
					},
				},
				
				{
					field: 'kpiOversea', width: '470px', title: '“Focus 3 Deals” KPI Endorsed by DED/M (For Overseas Offices Only)',
					format: (val) => {
						if (val == 'YES' ) {
							return 'Yes';
						} else if (val == 'NO' ) {
							return 'No';
						} else {
							return null
						}
					}
				},
				// { field: 'submiteByAndDate', width: 2, title: 'Submission' },
				// { field: 'lastModifiedByAndDate', width: 3, title: 'Last Modification' },
			]
		};


		//console.log("this.props.userPermission >>>>> " , this.props.userPermission );






		var lastPublishingDate = this.state.gui.submissionDateFrom;
		if (!lastPublishingDate) {
			lastPublishingDate = null;
		} else {
			lastPublishingDate = moment(lastPublishingDate, 'YYYY-MM-DD');
		}

		var lastPublishingDate2 = this.state.gui.submissionDateTo;
		if (!lastPublishingDate2) {
			lastPublishingDate2 = null;
		} else {
			lastPublishingDate2 = moment(lastPublishingDate2, 'YYYY-MM-DD');
		}

		var lastPublishingDate3 = this.state.gui.lastmodifiedFrom;
		if (!lastPublishingDate3) {
			lastPublishingDate3 = null;
		} else {
			lastPublishingDate3 = moment(lastPublishingDate3, 'YYYY-MM-DD');
		}

		var lastPublishingDate4 = this.state.gui.lastmodifiedTo;
		if (!lastPublishingDate4) {
			lastPublishingDate4 = null;
		} else {
			lastPublishingDate4 = moment(lastPublishingDate4, 'YYYY-MM-DD');
		}
		
		let storyTierMessage = "Tier 1 - Internal Reference <br/>  Tier 2 - Pending <br/> Tier 3 - Mature Lead";

		var titleMessage = this.state.titleMessage;
		var imgBkPath ="";
		
		if(this.state.annoBox.attprefix!=undefined && this.state.annoBox.attname!=undefined){		
		
			imgBkPath = encodeURI(LEVEL_UI_CONFIG.url +"/file/down?dirStr="+this.state.annoBox.attprefix+"&name="+this.state.annoBox.attname);
		}
		console.log("annoBox.enable", (this.state.annoBox && this.state.annoBox.enable != 'false'));
		const style = {
				backgroundImage: "url("+imgBkPath+")",
				display: (this.state.isShowAnnouncement && this.state.annoBox.enable != 'false') ? 'block' : 'none'
			    };
		let textArr = ["Collaboration - MoUs, cooperation and partnership agreements, business matching, deal-making;", 
			"Market expansion - Support businesses to launch products, services or open offices in other markets;", 
			"Transaction - Exhibitor-buyer transactions, sales at fairs;",
			"Other HK success cases - Hong Kong success stories that did not involve HKTDC;"];
			const data = [
				{
				  title: 'Collaboration',
				  text: 'MoUs, cooperation and partnership agreements, business matching, deal-making'
				},
				{
				  title: 'Market expansion',
				  text: 'Support businesses to launch products, services or open offices in other markets'
				},
				{
				  title: 'Transaction',
				  text: 'Exhibitor-buyer transactions, sales at fairs'
				},
				{
				  title: 'Other HK success cases',
				  text: 'Hong Kong success stories that did not involve HKTDC'
				},
			  ];
		return (


			<div className="pageMain">
				<HeaderPage parentObj={this} showLoading={true} telescopic={false} />
				<Row type="flex">
					<Col className="pageLift" style={this.state.pageLeftScreenWidthStyle} >{this.genMenu2 && this.genMenu2()}</Col>
					<Col className="pageRightLead" style={this.state.pageRightScreenWidthStyle}>
						<div className="announcementBackground" style={style}>
							<Row >


								<Col xs={23} sm={23} md={23} lg={23} xl={23} xxl={23} >
									<div className="announcement" dangerouslySetInnerHTML={{ __html: this.state.annoBox.sourceEnValue }}></div>
								</Col>
								<Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} >
									<div className="announcementClose"><Icon type="close" onClick={this.handleCloseAnno} /></div>

								</Col>
							</Row>
						</div>
						<div className={ (this.state.isShowAnnouncement && this.state.annoBox.enable != 'false') ?"announcementBackgroundBlock":"announcementBackgroundNormal"} style={this.state.pageRightScreenWidthStyle}>
						<div>

						{this.genPathDiv && this.genPathDiv()}


						<div style={{ textAlign: 'left' }} className="pageMainIn" >
							<div style={{ backgroundColor: '#9EC7F5', color: '#fff', padding: '5px', display: this.state.titleMessage == undefined || this.state.titleMessage == '' ? 'none' : '' }} >
								<Icon type="info-circle" style={{ fontSize: '18px' }} /> {titleMessage}
							</div>
							<div>
								<Row className="searchPanelLeadUp">
									<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Lead ID</Col>
									<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}><Input name="gui.storyId" placeholder="Lead Reference No." onChange={this.handlerInputChange2} value={this.state.gui.storyId} /></Col>

									<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
										<Tooltip placement="right" title="Keywords of story lead Description, Industry, Topic/Theme, Location, Event name, Company Name.">Keywords</Tooltip></Col>

									<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>


										<MySelect name="gui.keywords"
											style={{ width: '100%' }}
											options={this.state.TopicList}
											allowClear="true"
											mode="tags"
											filterOption={this.filterOptionTopic}
											value={this.state.gui.keywords}
											onChange={this.handlerSelectChange}
											searchHandlerPromise={this.searchHandlerPromiseTopic}
										/>


									</Col>

								</Row>

							</div>
							<div id="showAllCriteria" style={{ display: this.state.isShowAllCriteria ? 'block' : 'none' }}>
								<Row className="editPanel">
									<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Submitter</Col>
									<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
										{/* <Input name="gui.userName" placeholder="" onChange={this.handlerInputChange2} value={this.state.gui.userName} /> */}
										<MySelect
											name="gui.userName"
											mode="single"
											allowClear="true"
											//topMode={{topCount:5, initAll: false}}
											searchHandlerPromise={this.searchHandlerPromiseName}
											style={{ width: '100%' }}
											options={this.state.userList}
											value={this.state.gui.userName}
											onChange={this.handlerSelectChange}
										/>
									</Col>

									<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Source Department</Col>
									<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
										<MySelect
											name="gui.department"
											mode="multiple"
											allowClear="true"
											topMode={{ topCount: 5, initAll: true }}
											style={{ width: '100%' }}
											options={this.state.departmentList}
											value={this.state.gui.department}
											onChange={this.handlerSelectChange}
										/>
									</Col>
								</Row>
								<Row className="editPanel">
									<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
										<span>Types of Story Lead</span>
									</Col>
									<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
										<MySelect
											name="gui.typeOfSuccessCase"
											mode="multiple"
											allowClear="true"
											maxSelectCount="5"
											topMode={{ topCount: 5, initAll: true }}
											style={{ width: '100%' }}

											options={this.state.typeOfSuccessCaseList}
											value={this.state.gui.typeOfSuccessCase}
											onChange={this.handlerSelectChange}
											placeholder="Select one or more options"
										/>
									</Col>

									<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Service/Event Name</Col>
									<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
										<MySelect
											name="gui.eventId"
											mode="multiple"
											allowClear="true"
											maxSelectCount="5"
											topMode={{ topCount: 5, initAll: true }}
											style={{ width: '100%' }}
											options={this.state.eventNameList}
											value={this.state.gui.eventId}
											onChange={this.handlerSelectChange}
											placeholder="Select one or more options"
										/>
									</Col>


								</Row>
								<Row className="editPanel">
									<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Industries</Col>
									<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
										<MySelect
											name="gui.industry"
											mode="multiple"
											topMode={{ topCount: 5, initAll: true }}
											style={{ width: '100%' }}
											maxSelectCount="5"
											options={this.state.IndustryList}
											value={this.state.gui.industry}
											onChange={this.handlerSelectChange}
											placeholder="Select one or more options"
										/>
									</Col>

									<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Story Publicity</Col>
									<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
										<MySelect
											name="gui.usageChannel"
											mode="multiple"
											allowClear="true"
											maxSelectCount="10"
											style={{ width: '100%' }}
											options={this.state.usageChannelList}
											value={this.state.gui.usageChannel}
											onChange={this.handlerSelectChange}
											placeholder="Select one or more options"
										/>
									</Col>
								</Row>
								<Row className="editPanel">
									<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Locations</Col>
									<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
										<MySelect
											name="gui.location"
											mode="multiple"
											topMode={{ topCount: 5, initAll: true }}
											maxSelectCount="5"
											style={{ width: '100%' }}
											options={this.state.locationList}
											value={this.state.gui.location}
											onChange={this.handlerSelectChange}
											placeholder="Select one or more options"
										/>
									</Col>

									<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Lead Status</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}> <MySelect
											name="gui.status"
											mode="single"
											allowClear="true"
											//maxSelectCount="3"
											style={{ width: '100%' }}
											options={this.state.statusList}
											value={this.state.gui.status}
											onChange={this.handlerSelectChange}
										/>
									</Col>

								</Row>

								<ConfigProvider locale={locale}>
									<Row className="editPanel">
										<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Submission Date From</Col>
										<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>
											<DatePicker
												className="_NMT_mydatepicker"
												disabledDate={this.disabledSubmissionStartDate}
												name="gui.submissionDateFrom"
												placeholder=""
												value={lastPublishingDate}
												format="D-MMM-YYYY"
												onChange={this.submissionDataFromChange}
												style={{ width: '100%', cursor: 'pointer' }}
											/>
										</Col>
										<Col className="searchlabel1Center" xs={6} sm={6} md={6} lg={2} xl={2} xxl={2}>To</Col>
										<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>

											<DatePicker
												className="_NMT_mydatepicker"
												disabledDate={this.disabledSubmissionEndDate}
												name="gui.submissionDateTo"
												value={lastPublishingDate2}
												placeholder=""
												format="D-MMM-YYYY"
												onChange={this.submissionDateToChange}

											/>
										</Col>

										<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>HKTDC Involvement</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}> 
										<Switch  name="gui.isThereTdcInd" checked={this.state.gui.isThereTdcInd} onChange={(e) => { this.changeSwitch(e, "isThereTdcInd") }} checkedChildren="Y" unCheckedChildren="ALL" />
										
										
										</Col>	
									</Row>
									<Row className="editPanel">
										<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Last Modification Date From</Col>
										<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>
											<DatePicker
												className="_NMT_mydatepicker"
												disabledDate={this.disabledLastModifiedDateFrom}
												name="gui.lastmodifiedFrom"
												value={lastPublishingDate3}
												placeholder=""
												format="D-MMM-YYYY"
												onChange={this.dateChange3}

											/>
										</Col>
										<Col className="searchlabel1Center" xs={6} sm={6} md={6} lg={2} xl={2} xxl={2}>To</Col>
										<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>
											<DatePicker
												className="_NMT_mydatepicker"
												disabledDate={this.disabledLastModifiedDateTo}
												name="gui.lastmodifiedFromTo"
												value={lastPublishingDate4}
												placeholder=""
												format="D-MMM-YYYY"
												onChange={this.dateChange4}

											/>
										</Col>
										<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4} >“Focus 3 Deals” KPI Endorsed by DED/M (For Overseas Offices Only)</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
										<MySelect
											name="gui.kpiOversea"
											mode="single"
											allowClear="true"
											//maxSelectCount="3"
											style={{ width: '100%' }}
											options={this.state.kpiList}
											value={this.state.gui.kpiOversea}
											onChange={this.handlerSelectChange}
										/>
										</Col>	

										
									</Row>
									<Row className="editPanel" roleCode hidden={roleCode==4} >
										<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Disclose to Public</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
										<MySelect
											name="gui.discloseToPublic"
											mode="single"
											allowClear="true"
											//maxSelectCount="3"
											style={{ width: '100%' }}
											options={this.state.leadDiscPublicList}
											value={this.state.gui.discloseToPublic}
											onChange={this.handlerSelectChange}
										/>
										</Col>

										
										
										<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4} >Developing Status</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
										<MySelect
											name="gui.devStatus"
											mode="single"
											allowClear="true"
											//maxSelectCount="3"
											style={{ width: '100%' }}
											options={this.state.devStatusList}
											value={this.state.gui.devStatus}
											onChange={this.handlerSelectChange}
										/>
										</Col>
									</Row>
									<Row className="editPanel"  >
										<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
											<span>Nature of Story Lead</span>&nbsp;&nbsp;
											<Icon type="info-circle" style={{ fontSize: '18px' }} onClick={()=>{this.tooltipInfo()}}/>
										</Col>
										<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
											<MySelect
												name="gui.leadNature"
												mode="multiple"
												allowClear="true"
												maxSelectCount="5"
												topMode={{ topCount: 5, initAll: true }}
												style={{ width: '100%' }}
												options={this.state.leadNatureList}
												value={this.state.gui.leadNature}
												onChange={this.handlerSelectChange}
												placeholder="Select one or more options"
											/>
										</Col>
									</Row>
								</ConfigProvider>



							</div>

							<Row className="searchButtonMoreLessPanel" >


								<div>
									{/* <Icon className="oneIcon" onClick={this.displayAllCriteria} type="up-circle" style={{ fontSize: '25px', color: '#FB4F03' }} rotate={this.state.isShowAllCriteria ? 0 : 180} /> */}
									<a onClick={this.displayAllCriteria}  >{!this.state.isShowAllCriteria ? "Show more" : "Show Less"} &nbsp;&nbsp;</a>
								</div>


							</Row>


							<Row className="searchButtonPanel" >



								<div>
									<Button onClick={this.handleClean} className="oneButton" type="primary">Reset</Button>
									&nbsp;
									<Button onClick={this.handleSearch} className="oneSearchButton" type="primary"><Icon type="search" className="oneSearchIcon" /><span className="oneSearchTitle">Search</span></Button>
								</div>


							</Row>

							<div className="leadResultTableList">
								<div style={{ display: !this.state.hideTable ? '' : 'none' }} >
									{/* <div style={{overflowY: 'auto'}}> */}
									<ResponseTable
										name="leadTable"
										pageObj={this}
										response={false}
										selectMode={3}
										onePageCount={10} //record count one page, default: 10
										selectPageIndex={1}
										pageMode={1}
										sort={{ index: 0, orderBy: false }}

										showPageSelect={false}
										//									tableWidth={{ width: '1000'  }}
										width={'3040px'}
										isIconChangeLine={false}


										style={{ overflow: 'auto' }}
										buttons={this.getTableButtons(ssInfoDatas)}
										//handlerClick={this.clickRow}
										data={ssInfoDatas}
										header={ssInfoHeader}
									/>
									{/* </div> */}
								</div>
							</div>
						</div>
						<div class="normalFooter">Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>
						<Modal
							title="Approve"
							visible={this.state.controlTierInfo} //						
							closable={false}
							width="60%"
							footer={null}
							title={null}
							maskClosable={false}
						>


							<div>
								<Row className="closeTierInfo">
									<a onClick={() => this.closeTierInfo()} >
										<img style={{ height: '30px' }}
											src={logoCrossW}

											onMouseOver={
												e => (e.currentTarget.src = logoCrossG)
											}
											onMouseOut={
												e => (e.currentTarget.src = logoCrossW)
											}

										/>
									</a>
								</Row>

								<Row className="tierInfoTitle">
									Tier 1 - Internal Reference
					      </Row>
								<Row className="tierInfoDtl">
									Tier 1 leads can never be disclosed or are unlikely to ever mature enough to become full stories. But they may still be useful to retain for internal reference or to use as part of an aggregate.
					      </Row>
								<Row className="tierInfoTitle">
									Tier 2 - Pending
					      </Row>
								<Row className="tierInfoDtl">
									Tier 2 leads have the potential to be developed into stories but more information is required. Leads in this tier are pending.
					      </Row>
								<Row className="tierInfoTitle">
									Tier 3 - Mature Lead
					      </Row>
								<Row className="tierInfoDtl">
									Tier 3 leads are mature enough to be developed into publishable stories or used in articles, press releases, sales kits, etc. Tier 3 leads are always fully disclosable.
					      </Row>
								<div> &nbsp;</div>
							</div>
						</Modal>
						</div>
						</div>
					</Col>
				</Row>
				<Modal title="" visible={this.state.isTooltipOpen} 
				onCancel={this.handleTooltipInfoCancel}
				footer={''}
				>
					<List
						itemLayout="horizontal"
						dataSource={data}
						renderItem={(item, index) => (
						<List.Item>
							<List.Item.Meta
							title={item.title}
							description={item.text}
							/>
						</List.Item>
						)}
					/>
				</Modal>


			</div>
		);
	}

	disabledSubmissionStartDate = itemDate => {
		var nowDate = new Date();
		if (itemDate > nowDate) {
			return true;
		}
		var endValue = this.state.gui.submissionDateToMoment;

		itemDate = moment(itemDate).format('YYYY-MM-DD');
		itemDate = moment(itemDate, 'YYYY-MM-DD');

		if (endValue != undefined) {
			endValue = moment(endValue).format('YYYY-MM-DD');
			endValue = moment(endValue, 'YYYY-MM-DD');
		}

		if (endValue && itemDate > endValue) {
			return true;
		}
		return false;
	};

	disabledSubmissionEndDate = itemDate => {
		var nowDate = new Date();
		if (itemDate > nowDate) {
			return true;
		}
		var startValue = this.state.gui.submissionDateFromMoment;

		itemDate = moment(itemDate).format('YYYY-MM-DD');
		itemDate = moment(itemDate, 'YYYY-MM-DD');

		if (startValue != undefined) {
			startValue = moment(startValue).format('YYYY-MM-DD');
			startValue = moment(startValue, 'YYYY-MM-DD');
		}

		//console.log("itemDate >>> " , itemDate );
		//console.log("startValue >>> " , startValue );

		if (startValue && itemDate < startValue) {
			return true;
		}
		return false;
	};


	disabledLastModifiedDateFrom = itemDate => {
		var nowDate = new Date();
		if (itemDate > nowDate) {
			return true;
		}
		var endValue = this.state.gui.LastmodifiedToMoment;

		itemDate = moment(itemDate).format('YYYY-MM-DD');
		itemDate = moment(itemDate, 'YYYY-MM-DD');

		if (endValue != undefined) {
			endValue = moment(endValue).format('YYYY-MM-DD');
			endValue = moment(endValue, 'YYYY-MM-DD');
		}

		if (endValue && itemDate > endValue) {
			return true;
		}
		return false;
	};

	disabledLastModifiedDateTo = itemDate => {
		var nowDate = new Date();
		if (itemDate > nowDate) {
			return true;
		}
		var startValue = this.state.gui.LastmodifiedFromMoment;

		itemDate = moment(itemDate).format('YYYY-MM-DD');
		itemDate = moment(itemDate, 'YYYY-MM-DD');

		if (startValue != undefined) {
			startValue = moment(startValue).format('YYYY-MM-DD');
			startValue = moment(startValue, 'YYYY-MM-DD');
		}

		if (startValue && itemDate < startValue) {
			return true;
		}
		return false;
	};




	submissionDataFromChange = (value, mode) => {
		//console.log("value >>> " , value );
		//console.log("mode >>> " , mode );

		var gui = this.state.gui;
		var newDate = moment(mode, 'DD-MMM-YYYY').locale('en');
		var date = moment(newDate).format('YYYY-MM-DD');
		gui.submissionDateFromMoment = value;
		gui.submissionDateFrom = date;
		if (date == "Invalid date") {
			gui.submissionDateFrom = null;
		}
		this.setState({ gui: gui });
	}

	submissionDateToChange = (value, mode) => {
		var gui = this.state.gui;
		var newDate = moment(mode, 'DD-MMM-YYYY').locale('en');
		var date = moment(newDate).format('YYYY-MM-DD');
		gui.submissionDateToMoment = value;
		gui.submissionDateTo = date;
		if (date == "Invalid date") {
			gui.submissionDateTo = null;
		}
		this.setState({ gui: gui });
	}

	dateChange3 = (value, mode) => {
		var gui = this.state.gui;
		var newDate = moment(mode, 'DD-MMM-YYYY').locale('en');
		var date = moment(newDate).format('YYYY-MM-DD');
		gui.LastmodifiedFromMoment = value;
		gui.lastmodifiedFrom = date;
		if (date == "Invalid date") {
			gui.lastmodifiedFrom = null;
		}
		this.setState({ gui: gui });
	}
	dateChange4 = (value, mode) => {
		var gui = this.state.gui;
		var newDate = moment(mode, 'DD-MMM-YYYY').locale('en');
		var date = moment(newDate).format('YYYY-MM-DD');
		gui.LastmodifiedToMoment = value;
		gui.lastmodifiedTo = date;
		if (date == "Invalid date") {
			gui.lastmodifiedTo = null;
		}
		this.setState({ gui: gui });
	}


}

const ConnectedSuccessStoryLeads = connect(mapStateToProps, mapDispatchToProps)(SuccessStoryLeads);

export default withRouter(ConnectedSuccessStoryLeads);
