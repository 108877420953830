import {DATA_UI_CONFIG} from './DataConfig'

export const DATA_SERVER_URL = "http://localhost:3000";

export const OPEN_WINDOW_PREFIX = "http://localhost:3000";

export const REQUEST_TIMEOUT = 60 * 60 * 1000;  // 1 hour

//GUI date formart
export const GUI_DATE_FORMAT = "D-MMM-YYYY HH:mm";

//database date formart
export const DB_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const LOGIN_PAGE_URL = {
    disable: false,
    loginAppUrl: DATA_UI_CONFIG.loginAppUrl,
    routeUrl: false,
    loginPageUrl: DATA_UI_CONFIG.loginPageUrl,
    logoutPageUrl: DATA_UI_CONFIG.logoutPageUrl,
    ignorePageUrls: [], //visitor url, no need check
};

export const TOKEN_COOKIE = 'x-access-token';