import React, { Component } from 'react';
import { connect } from "react-redux";
//import { getLabel } from '../Lang';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser ,setUserPermission,setRoleCode} from '../redux/actions';

import { Input, Row, Col, Button, Tabs , Icon,message ,ConfigProvider , Modal } from 'antd';
//import HeaderPage from './HeaderPage';
import { bind,  dataGet, getDateStr ,sortDateStr,  CompareSpecials ,getPermission, fireWinResizeEv, isEmpty} from '../Utils';
import moment from 'moment';
import { get, post } from "../services/AuthService";
// import "../mock/RoleApi";
// import "../mock/UserApi";
// import "../mock/DepartmentApi";
import ResponseTable from './component/ResponseTableSelect';
import MySelect from './component/MySelect';
import MyButton from './component/MyButton';
import {DATA_UI_CONFIG } from '../DataConfig'
import {GUI_DATE_FORMAT} from '../Config'
//import axios from 'axios';
import lodash from 'lodash';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { checkDataDiff } from '../UtilDataDiff';
moment.locale('en');

const { TabPane } = Tabs;

const mapStateToProps = (state) => {
    return {
        userRoleCode: state.userRoleCode,
        currentLang: state.currentLang,
        loginUser: state.loginUser,
        userPermission : state.userPermission
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLang: (info) => {
            dispatch(setCurrentLang(info));
        },
        setLoginUser: (info) => {
            dispatch(setLoginUser(info));
        },
        setUserPermission:(info) =>{
            dispatch(setUserPermission(info));
        },
        setRoleCode:(info) =>{
            dispatch(setRoleCode(info));
        }
    };
}

// export const handlerMySelectChanges_ = (name, value, pageObj) => {
//         console.log("# handlerMySelectChange: ", name, value);
//         if(!pageObj){
//             console.error("# EventUtil error: handlerMySelectChange pageObj is null.");
//             return;
//         }
//         var stateData = pageObj.state;
//         lodash.set(stateData, name, value);
//         pageObj.setState({...stateData});
//     }

class UserPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userAccount: null,
            userName: "",
            email : "",
            department: '',
            status: '',
            role : 'All',
            hideTable : true,
            control11 : false,
            control12 : false,
            f1 :false,
            f2 : true,
            departmentList : []
        };
        bind(this, ['handleExport', 'dataDiffHandler', 'saveHandler', 'clickRow', 'handleNew', 'formatGroup', 'handleClean', 'handleSearch', 'handlerInputChange', 'searchRefData', 'handlerSelectChange', 'handlerStatusSelectChange']);
        this.props.parentObj.UserPage = this;
    }



    componentWillMount() {
        dataGet(this);
        // {id:0 , value:"All" , label : 'All'} ,
		var roleList = [  {id:0 , value:"All" , label : 'All'}, {id:2, value: 2, label: "Content Approver" }, {id:4, value: 4, label: "General User" }, {id:3, value: 3, label: "Power User" }  ,{ id:5,value:5, label: "Special User" },{ id:1,value:1, label: "System Admin" }]
        this.setState({ roleList: roleList });

        var roleList2 = [ {id:2, value: "Content Approver", label: "Content Approver" }, {id:4, value: "General User", label: "General User" },  {id:3, value: "Power User", label: "Power User" }, { id:5,value:"Special User", label: "Special User" }, { id:1,value:"System Admin", label: "System Admin" }]
        this.setState({ roleList2: roleList2 });

        this.userMaintenanceWriteInd = getPermission(this.props,"userMaintenanceWriteInd")

        this.state.sort = {index:1, orderBy:true};
        
        this.searchRefData();
    }

    dataDiffHandler = () =>{
        var table1 = this.tables ? this.tables['userTable'] : null;
        var editingList = [];
        if(table1){
            editingList = table1.getEditDataList();
        }
        var backupData = [];
        for(var i = 0; i < editingList.length; i++){
            var data = editingList[i];
            backupData.push(data._rt_base_data);
        }
        var bothData = {thisData: backupData, stateData: editingList};
        console.log("########## UserPage dataDiffHandler is: ", bothData);
        return bothData;
    }

    /* Save you data when logout */
    saveHandler = (callBackHandler, isTimeout) => {
        var table1 = this.tables ? this.tables['userTable'] : null;
        var editingList = [];
        if(table1){
            editingList = table1.getEditDataList();
        }
        console.log("########## UserPage saveHandler editingList: ",editingList);        
        //-----------------
       // let now = getDateStr();
        //var roleList = this.state.roleList;
        
//        if(!roleList){
//            roleList = [];
//        }
        
        var promiseList = editingList.map((row, index) => {
            var rowId=row.roleId;
            if(row.successStoryRole){
                var roleName = row.successStoryRole.roleName;
                if(roleName=='System Admin'){
                    rowId=1;
                }else if(roleName=='Content Approver'){
                    rowId=2;
                }else if(roleName=='Power User'){
                    rowId=3;
                }else if(roleName=='General User'){
                    rowId=4;
                }else if(roleName=='Special User'){
                    rowId=5;
                }  
            }
            row.roleId=rowId;
            var roles = row.role;
            var roleList = this.state.roleList;
            //var roles = this.state.selecttablerole;
            roleList.map((item) =>   {
                var value = item.id;
                if(roles === item.label){
                    row.roleId=value;
                    row.role = roles;
                }
            }); 
            
            row.departmentId = null;
            if(row.successStoryDepartment != undefined  ){
                row.departmentId = row.successStoryDepartment.id;
            }

            var now = getDateStr();
            var userName =this.props.loginUser.userName;
            row.updatedBy = userName;
            row.updatedDateTime = now;


            return new Promise(resolve => {
                post( DATA_UI_CONFIG.url + '/userServer/saveData', row, this.props.parentObj).then(data => {
                    resolve(data);
                }).catch((e) => {
                    resolve({});
                });
            });            
        })


        
        Promise.all(promiseList).then((resultlist) => {
            //close edit model
            if(table1){
                editingList.map((item)=>{
                    table1.stopSaveEditRow(item);
                });
            }
            if(callBackHandler){
                callBackHandler();
            }
        });
    }

    searchDepartMentList = () => {


    }
    

    // isEmpty = (str) =>{
    //     return (str == undefined  || str == "" || str.length == 0 ) ?  true : false;
    // }

    componentDidMount = () => {
        this.findDepts();
    }

    findDepts = () => {
        get(DATA_UI_CONFIG.url + '/searchAllDepartment', this).then(data => {
            if (data) {
                var departmentList = data.users.map((item) => {
                    item.value = item.id;
                    item.label = item.name;
                    if(item.similarname){
							item.similar=item.similarname;
						}
                    return item;
                });
                console.log("departmentList....", departmentList);
                this.setState({ departmentList: this.removeRepeat(departmentList) });
            }
        });
    }
    removeRepeat = (itemList) => {
		var resultList = [];
		if (!itemList) {
			itemList = [];
		}
		itemList.map((item) => {
			var exist = false;
			for (var a1 = 0; a1 < resultList.length; a1++) {
				if (item.value === resultList[a1].value) {
					exist = true;
					break;
				}
			}
			if (!exist) {
				resultList.push(item);
			}
			return null;
		});
		return resultList;
	}

    searchAllData = () =>{

        var userName = this.state.userName;
        var email = this.state.email;
        var department = this.state.department;
        var role = this.state.role?this.state.role:"";
        if(userName==="" && email==="" && department==="" && role===""){
           message.config({ top: '10%',   });
//			message.info("Please fill in at least one search field.");
			
			Modal.info({
			    title: 'Please fill in at least one search field.',
			    content: (
			      <div>
			        
			      </div>
			    ),
			    onOk() {},
			  });
            return;
        }
        if( role==="All" ){
            role = "";
        }
        // var bool = true;
        // if( !this.isEmpty(department) ){
        //     var allDepartmentList = this.state.allDepartmentList;
        //     if(!this.isEmpty(allDepartmentList)){
        //         allDepartmentList.map((item,index) =>{
        //             if(item.label === department ){
        //                 department = item.id;
        //                 bool = false;
        //             }
        //         });
        //     }
        // }
        // if(bool){
        //     department = -1;
        // }

        console.log("get data >>>>>  " , userName + "," + email + "," + department + "," + role);
        get( DATA_UI_CONFIG.url + '/userServer/searchByNameLike?name=' + userName + '&email=' + email + '&role='  + role + '&department=' + encodeURIComponent(department), this.props.parentObj).then(data => {
            console.log("searchByNameLike >>> " , data );
             var lists =[];
             if(data.returnType == "SUCCESS"){
                if(!data.userViews){
                    data.userViews = [];
                }
                data.userViews.map((data) =>{
                    let item = data.user;
                    let shwoUserDeptsIdList = [];
                    let shwoUserDeptsNameList = [];
                    if("Active" == item.status){
                        if (isEmpty(data.userRespDeptsList)) {
                            data.userRespDeptsList = [];
                        } else {
                            let deptsList = data.userRespDeptsList;
                            if (deptsList.length > 0) {
                                for (var depts of deptsList) {
                                    shwoUserDeptsIdList.push(depts.successStoryDepartment.id);
                                    shwoUserDeptsNameList.push(depts.successStoryDepartment.name);
                                }
                            }
                        }
                        shwoUserDeptsNameList.sort();
                        item.showDeptsVal = shwoUserDeptsNameList.join(", ");
                        item.shwoUserDeptsIdList = shwoUserDeptsIdList;
                       lists.push(item);
                    }
                });
                    this.backData = this.copyJson(lists);
                    //this.tables['userTable'].stopSort();
                    console.log("tables['userTable'] >>> " , this.tables['userTable'] );
                this.setState({
                    list: lists,
                    //backList : lists
                });
                if(this.state.list!=null && this.state.list!=undefined && this.state.list.length>0   ){  
                	this.setState({
                		f1 :false,
                        f2 : true
                    });
                }else{
                	
                	this.setState({
                		f1 :true,
                        f2 : false
                    });
                	
                	
                }
             }
        });
        fireWinResizeEv();
        this.setState({
            hideTable : false
        })
    }

    copyJson = (baseData) =>{
        var datasStr = JSON.stringify(baseData);
        if (datasStr) {
              return JSON.parse(datasStr);
        }else{
            return null;
        }
    }

    handleClean = () => {
        //var backUpList = this.state.backUpList;
        this.setState({
            userAccount: null,
            userName: '',
            department: '',
            status: '',
            email: '',
            role: 'All',
            //list: backUpList
        });
    }

    // handleSearch = () => {

    //     var backUpList = this.state.backUpList;

    //     var userAccount = this.state.userAccount;
    //     var userName = this.state.userName;
    //     var department = this.state.department;
    //     var status = this.state.status;

    //     var list = this.state.backUpList;
    //     var searchResult = [];

    //     for (var j = 0; j < list.length; j++) {
    //         var itme = list[j];

    //         var isUserAccountMapping = false;
    //         var isUserNameMapping = false;
    //         var isDepartmentMapping = false;
    //         var isStatusMapping = false;

    //         var itemAccount = itme.account.toLowerCase();
    //         var itemCreatedBy = itme.createdBy.toLowerCase();
    //         var itemDepartments = itme.departments;
    //         var itemActive = itme.isActive;

    //         if (userAccount) {
    //             if (itemAccount.indexOf(userAccount.toLowerCase()) != -1) {
    //                 isUserAccountMapping = true;
    //             }
    //         } else {
    //             isUserAccountMapping = true;
    //         }

    //         if (userName) {
    //             if (itemCreatedBy.indexOf(userName.toLowerCase()) != -1) {
    //                 isUserNameMapping = true;
    //             }
    //         } else {
    //             isUserNameMapping = true;
    //         }

    //         if (department && department != 'NA') {
    //             if (itemDepartments) {
    //                 for (var i = 0; i < itemDepartments.length; i++) {
    //                     if (itemDepartments[i] == department) {
    //                         isDepartmentMapping = true;
    //                     }
    //                 }
    //             }
    //         } else {
    //             isDepartmentMapping = true;
    //         }

    //         if (status && status != 'NA') {
    //             if (status == 'ACTIVE' && itemActive == true) {
    //                 isStatusMapping = true;
    //             } else if (status == 'INACTIVE' && itemActive == false) {
    //                 isStatusMapping = true;
    //             }
    //         } else {
    //             isStatusMapping = true;
    //         }

    //         if (isUserAccountMapping && isUserNameMapping && isDepartmentMapping && isStatusMapping) {
    //             searchResult.push(itme);
    //         }

    //     }
    //     this.setState({ list: searchResult });
    //     console.log('searchResult>>>>>>>>>>>>', searchResult);
    // }

    formatDepartment = (departmentIdList) => {

        var departmentIdListStr = "";
        var allDepartmentList = this.state.allDepartmentList;
        if (!allDepartmentList) {
            allDepartmentList = [];
        }

        for (var i = 0; i < departmentIdList.length; i++) {
            var id = departmentIdList[i];

            for (var j = 0; j < allDepartmentList.length; j++) {
                if (id == allDepartmentList[j].value) {
                    if (i != 0) {
                        departmentIdListStr += ", ";
                    }
                    departmentIdListStr += allDepartmentList[j].label;
                }
            }
        }
        return departmentIdListStr;
    }

    formatRoles = (rolesIdList) => {

        var rolesIdListStr = "";
        var allRoleList = this.state.allRoleList;
        if (!allRoleList) {
            allRoleList = [];
        }

        for (var i = 0; i < rolesIdList.length; i++) {
            var id = rolesIdList[i];

            for (var j = 0; j < allRoleList.length; j++) {
                if (id == allRoleList[j].value) {
                    if (i != 0) {
                        rolesIdListStr += ", ";
                    }
                    rolesIdListStr += allRoleList[j].label;
                }
            }
        }
        return rolesIdListStr;
    }


    searchRefData = () => {

    
        get( DATA_UI_CONFIG.url + '/searchAllDepartment', this.props.parentObj).then(data => {
            console.log("# /department/search result data: ", data);

            if(data.returnType === "SUCESS" ){
                var allDepartmentList = [];

                data.users.map((item) => {
//                    var value = item.id;
//                    var label = item.name;
                    allDepartmentList.push({ id : item.id , value: item.name, label: item.name });
                });

            }
           

            this.setState({ allDepartmentList: allDepartmentList });
            fireWinResizeEv();
        });
    }

    handlerInputChange = (event) => {
        ///////////////////////////////////////////////////////Reference by HeaderPage.js
        // console.log("# handlerInputChange: ", event);
        var stateData = this.state;
        var target = event.currentTarget;
        var fieldName = target.name;
        var value = target.value;
        // console.log("# handlerInputChange, fieldName = ", fieldName);
        // console.log("# handlerInputChange, value = ", value);
        lodash.set(stateData, fieldName, value);
        this.setState({ ...stateData });
    }

    handlerSelectChange = (name, value) => {//Reference by HeaderPage.js
        var stateData = this.state;
        lodash.set(stateData, name, value);
        this.setState({ role: value });
    }

    handlerStatusSelectChange = (name, value) => {//Reference by HeaderPage.js
        console.log("# handlerStatusSelectChange: ", name, value);
        var stateData = this.state;
        lodash.set(stateData, name, value);
        this.setState({ status: value });
    }

    handlerSwitchChange = (newValue, col, rowData, tableObj) => {
        tableObj.setFieldValue(rowData, col, newValue);
    }

    handleTableRemove = (row, tableObj) => {
        console.log(" into remove >> ", row );
        this.deleteRow = row;
        this.deleteUserName = row.userName;
        this.dialogshow11(this);
       
    }

    handleTableCancel = (row, tableObj) => {

        var backList = this.backData;
        var oriRow = {};
        backList.map((item,index)=>{
            if(item.id == row.id){
                oriRow = item;
            }
        });
        var bool = checkDataDiff(oriRow, row, CompareSpecials);
        console.log("bool", bool , row);
        if(bool){
            this.tableObj = tableObj;
            this.CancelRow = row;
            this.dialogshow12(this);
        }else{
            tableObj.stopCancelEditRow(row);
        }
    }

    dataCancel = () =>{
        console.log("into Cancel >>>> "  );
        var tableObj = this.tableObj;
        var row = this.CancelRow;
        this.dialogClose12();
        tableObj.stopCancelEditRow(row);
    }
    dataSave = () =>{
        var tableObj = this.tableObj;
        var row = this.CancelRow;
        this.dialogClose12();
        this.handleTableSave(row,tableObj);     
    }

    handleTableSave = (row, tableObj) => {
        console.log("into save >>>> " , row );
        tableObj.stopSaveEditRow(row);
        this.handleSave2(row,"updated");
    }

    handleTableEdit = (row, tableObj) => {
        tableObj.startEditRow(row);
    }

    dialogshow11 = (e) => {
//		this.setState({
//			control11: true
//		})
		Modal.confirm({
    		title: 'Confirm',
			content: (<span className="dataDiffMessage">Do you want to remove {this.deleteUserName.split('(')[0]}?</span>),
      	  
			onOk() {e.deleteRowf()},
            closable:false,
            onCancel() {e.dialogClose11()},
            okText:'OK',
            cancelText:"Cancel",
            width:"500px",
            //footer={null}
           
            maskClosable:false
      	});
		
		
		
	}
    dialogClose11 = () => {
		this.setState({
			control11: false
		})
    }
    dialogshow12 = (e) => {
//		this.setState({
//			control12: true
//		})
    
    	Modal.confirm({
    		title: 'Confirm',
			content: (<span className="dataDiffMessage">Do you want to save the changes?</span>),
      	  
			onOk() {e.dataSave()},
            closable:false,
            onCancel() {e.dataCancel()},
            okText:'OK',
            cancelText:"Cancel",
            width:"500px",
            //footer={null}
           
            maskClosable:false
      	});
	}
   
	dialogClose12 = () => {
		this.setState({
			control12: false
		})
    }
	getTableButtons(d) {
		

		var buttonsList = [];

		buttonsList.push({ title: 'Export', handler: this.handleExport, style: { cursor: 'pointer' }, icon: 'export' });
		return buttonsList;
	}
    handleExport= () => {
		let selDataList = this.state.list;
		let ids = "";
		for (let i = 0; i < selDataList.length; i++) {
			if (selDataList[i]._rt_checked) {
				ids += selDataList[i].id + ",";
				

			}
		}
		if (ids != "") {
			let roleCode = this.props.loginUser.roleId;
			var postData = {ids: ids, rolecode: roleCode};
			post(DATA_UI_CONFIG.url + '/userServer/checkGenUserRoleByIdsReportXLSX', postData, this.props.parentObj).then(data => {
				
					
					if (data.returnType == 'FAIL') {
						message.config({
							top: '10%',
						});
						this.messageerror("The User(s) failed to export. Please try again. If the problem persists, please contact system support.");
						return;
					}
					var downloadUrl = DATA_UI_CONFIG.url + "/userServer/downloadUserRoleReportByPath/" + data.view.encodeFilePath + "/" + data.view.key + "/" + data.view.downloadFileName;
					window.open(downloadUrl);
				}).catch((e) => {

					this.messageerror("The User(s)  failed to export. Please try again. If the problem persists, please contact system support.");
					return;
				});
		} else {
			message.config({
				top: '10%',
			});
			this.messageinfo("No record is selected.");
		}


	}
	messageerror(msg) {
		Modal.error({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}
	messageinfo(msg) {
		Modal.info({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}
    deleteRowf = () => {

        var row = this.deleteRow;

        if(row == undefined ){
            return;
        }

		row.roleId=null;
        var today = new Date();
        var now = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours()+':'+today.getMinutes()+':'+today.getSeconds();
        var userName =this.props.loginUser.userName;
        row.updatedBy = userName;
        row.updatedDateTime = now;
        row.shwoUserDeptsIdList = [];
		
		console.log(" row >>> " , row );
		post( DATA_UI_CONFIG.url + '/userServer/saveData?entity=', row, this.props.parentObj).then(data => {
            console.log(" delete data >> " , data );
			message.config({  top: '10%',  });
            //message.info(name+' Data Success.');
//			message.info('User role is remove successfully.');
			
			Modal.success({
			    title: 'The user role is removed successfully.',
			    content: (
			      <div>
			        
			      </div>
			    ),
			    onOk() {},
			  });
			
			
            this.dialogClose11();
			this.searchAllData();
		});
    }


    handleSave2(row,name,callBackHandler){
		var rowId=row.roleId;
		if(row.successStoryRole){
			var roleName = row.successStoryRole.roleName;
			if(roleName=='System Admin'){
				rowId=1;
			}else if(roleName=='Content Approver'){
				rowId=2;
			}else if(roleName=='Power User'){
				rowId=3;
			}else if(roleName=='General User'){
				rowId=4;
			} else if(roleName=='Special User'){
				rowId=5;
			} 
		}
		row.roleId=rowId;
        var roles = row.role;
        var roleList = this.state.roleList;
		//var roles = this.state.selecttablerole;
		roleList.map((item) =>   {
		    var value = item.id;
			if(roles === item.label){
                row.roleId=value;
                row.role = roles;
			}
        }); 


          row.departmentId = null;
            if(row.successStoryDepartment != undefined  ){
                row.departmentId = row.successStoryDepartment.id;
            }



        var now = getDateStr();
        var userName =this.props.loginUser.userName;
        row.updatedBy = userName; 
        row.updatedDateTime = now;
		
		console.log(" row >>> " , row );
		post( DATA_UI_CONFIG.url + '/userServer/saveData?entity=', row, this.props.parentObj).then(data => {
            console.log(" save data >> " , data );
			message.config({
			             top: '10%',
			       });
            //message.info(name+' Data Success.');
//            message.info('User role is '+name+' successfully.');
            Modal.success({
			    title: 'The user role is saved successfully.',
			    content: (
			      <div>
			        
			      </div>
			    ),
			    onOk() {},
			  });
            
            
            if(name === "callBack"){
                callBackHandler();
            }
			this.searchAllData();
		});
        
       

    }
    

    handlerMySelectChanges = (newValue, col, rowData, tableObj) => {
        tableObj.setFieldValue(rowData, col, newValue);
    }

    handlerSelectChanges = (newValue, name, rowData, tableObj) => {
        if (name === "shwoUserDeptsIdList") {
            lodash.set(rowData, name, newValue);
            this.setState({...tableObj})
        }
    }

    render() {
        var tableDatas = this.state.list;
        console.log("tableDatas >>>>> " , tableDatas );

        console.log("getPermission(this.props,'userMaintenanceWriteInd') >>>>>>> " ,  );

        var tableHeader = {
            columns: [
                { field: 'successStoryRole.roleName', width: 3, title: 'Role',
                    editModel: (value, col, rowData, tableObj)=>{//the value also can get from rowData
                        return (<span>
                            <MySelect
                                pageObj={this}
                                name="selecttablerole"
                                style={{ width: '100%' }}
                                dropdownMatchSelectWidth={false}
                                placeholder="Select Role"
                                options={this.state.roleList2}
                                //value={this.state.selecttablerole}
                                value={value}
                                onChange={(name, newValue, pageObj) => this.handlerMySelectChanges(newValue, col, rowData, tableObj)}
                                //onChange={handlerMySelectChanges}
                            />
                        </span>);
                    }
                },
                { field: 'userName', width: 3, title: 'User Name',
                	format: (val)=>{
                        if(val){
                            val = val.split('(')[0]
                        }
                        return val;
                    },
                	
                editModel: (value, col, rowData, tableObj)=>{//the value also can get from rowData
                    return (<span>{value}</span>);
                }},
                { field: 'email', width: 3, title: 'Email',
                editModel: (value, col, rowData, tableObj)=>{//the value also can get from rowData
                    return (<span>{value}</span>);
                }},
                { field: 'successStoryDepartment.code', width: 2, title: 'Dept',
                editModel: (value, col, rowData, tableObj)=>{//the value also can get from rowData
                    return (<span>{value}</span>);
                }},
                { field: 'showDeptsVal', width: 3, title: 'Departments of Responsibility',
                    editModel: (value, col, rowData, tableObj)=>{//the value also can get from rowData
                        return (<span>
                            <MySelect
                                pageObj={this}
                                name="shwoUserDeptsIdList"
                                mode="multiple"
                                dropdownMatchSelectWidth={false}
                                topMode={{ topCount: 5, initAll: true }}
                                style={{ width: '100%' }}
                                options={this.state.departmentList}
                                value={rowData.shwoUserDeptsIdList}
                                onChange={(name, newValue, pageObj) => this.handlerSelectChanges(newValue, name, rowData, tableObj)}
                                placeholder="Select one or more options"
                            />
                        </span>);
                    }
                },
                { field: 'updatedBy', width: 3, title: 'Modified By' ,
                    format: (val)=>{
                        if(val){
                            val = val.split('(')[0]
                        }
                        return val;
                    },
                    editModel: (value, col, rowData, tableObj)=>{//the value also can get from rowData
                        return (<span>{value}</span>);
                    },
                
                },
                { field: 'updatedDateTime', width: 3, title: 'Modified Date' , 
                    format : (val) => {
                        var temp = moment(val, 'YYYY-MM-DD HH:mm:ss');
                        val = moment(temp ).format(GUI_DATE_FORMAT);
                        if(val == "" || val == "Invalid date"){
                            return null;
                        }
                        return val;
                    },
                    editModel: (value, col, rowData, tableObj)=>{//the value also can get from rowData
                        var temp = moment(value, 'YYYY-MM-DD HH:mm:ss');
                        value = moment(temp ).format(GUI_DATE_FORMAT);
                        return (<span>{value}</span>);
                    },
					sortHandler:(val)=>{
                        return sortDateStr(val);
					}
                 },
               
                { field: 'action', width: 3, title: 'Action', sort: false,
                    rowHandler: (col, rowData, tableObj)=>{//the value also can get from rowData
                        return (<div className="_NMT_tableButtonsListCell"  style={{ display : this.userMaintenanceWriteInd ? '' : 'none' }} >
                            <MyButton onClick={()=>this.handleTableEdit(rowData, tableObj)} type="primary">Edit</MyButton> &nbsp;
                            {<MyButton onClick={()=>this.handleTableRemove(rowData, tableObj)} type="primary">Remove</MyButton> }
                        </div>);
                    },
                    editModel: (value, col, rowData, tableObj)=>{//the value also can get from rowData
                        return (<div className="_NMT_tableButtonsListCell" >
                            <MyButton onClick={()=>this.handleTableSave(rowData, tableObj)} type="primary">Save</MyButton>&nbsp;
                            <MyButton onClick={()=>this.handleTableCancel(rowData, tableObj)} type="primary">Cancel</MyButton>
                        </div>);
                    },
                }   
              
            ]
        };

        

            return (
                <div>
                    {/* <Tabs defaultActiveKey="b">
                        <TabPane tab="Basic Search" key="b" > */}
                            <div style={{marginBottom: '15px'}}>
                                <Row className="searchPanelReportUp">
                                    <Col  className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>User Role</Col>
                                    <Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                        <MySelect
                                                name="role"
                                                style={{ width: '100%' }}
                                                placeholder="Select role"
                                                options={this.state.roleList}
                                                value={this.state.role}
                                                onChange={this.handlerSelectChange}
                                            />
                                    </Col>
                                    <Col  className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>User Name</Col>
                                    <Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                        <Input name="userName" onChange={this.handlerInputChange} value={this.state.userName} />
                                    </Col>
                                </Row>
                                <Row className="searchPanelReportBottom">
                                    <Col  className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Email</Col>
                                    <Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                        <Input name="email" onChange={this.handlerInputChange} value={this.state.email} />
                                    </Col>
    
                                    

                                    <Col  className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Department</Col>
                                    <Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                        <Input name="department" onChange={this.handlerInputChange} value={this.state.department} />
                                    </Col>
                                </Row>
                                <Row className="searchButtonPanel"  >
	                                
	                                    
	                                        <div  >
	                                            <Button onClick={this.handleClean} className="oneButton" type="primary">Reset</Button>
	                                            &nbsp;
	                                            <Button onClick={this.searchAllData} className="oneSearchButton" type="primary"><Icon type="search" className="oneSearchIcon"/><span className="oneSearchTitle">Search</span></Button>
	                                        </div>
	                                    
                                    
                                </Row>
                            </div>
                            <div className="leadResultTableList">
                        <div style={{display: !this.state.hideTable ? '' : 'none'}} > 
                        <ConfigProvider locale={locale}>                
                            <ResponseTable
								name="userTable"
                                pageObj={this}
								//response={false}
                                sort={{index:0, orderBy:true}}
                                selectMode={3}
								//selectPageIndex={1}
								//pageMode={1}
								showPageSelect={false}
                            headerHeight={'60px'}
							//width={'1040px'}
                                onePageCount={25} 
                                handlerClick={this.clickRow}
                                data={tableDatas}
                                header={tableHeader}
                            	genFooter = {false}
								//style={{ overflow: 'auto' }}
								buttons={this.getTableButtons(tableDatas)}
                                //sort={this.state.sort}
                            />
                        </ConfigProvider>

                        </div>
                        </div>
                        <div className="normalFooter" >Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>
                       
                        <Row>
        				&nbsp;
        				</Row>


               

               
                 


                </div>

            );
        
        
        
    }
}
//==
const ConnectedUserPage = connect(mapStateToProps, mapDispatchToProps)(UserPage);

export default withRouter(ConnectedUserPage);
