import React, { Component } from 'react';

import { withRouter } from 'react-router';
import { setAccessToken } from "../services/AuthService";

class TokenPage extends Component {

    constructor(props) {
      super(props);
    }

    componentWillMount() {
        //?token=xxx
        var currUrlPath = this.props.location.search;
        var token = currUrlPath.substring(7);
        if(token && token.length > 0){
            setAccessToken(token);
        }
    }

    render() {
        return (
            <div className="pageMain">
            </div>
        );
    }
}

export default withRouter(TokenPage);
