import React, { Component } from 'react';
import { connect } from "react-redux";
//import { getLabel } from '../Lang';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser } from '../redux/actions';
import { Row, Col  } from 'antd';
import HeaderPage from './HeaderPage';
import { bind, dataPutAndGo } from '../Utils';
import { get } from "../services/AuthService";
import { successStory_UI_CONFIG } from '../DataConfig'

const mapStateToProps = (state) => {
    return {
        currentLang: state.currentLang,
        loginUser: state.loginUser,
    }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentLang: (info) => {
        dispatch(setCurrentLang(info));
    },
    setLoginUser: (info) => {
        dispatch(setLoginUser(info));
    },
  };
}

class HomePage extends Component {

    constructor(props) {
      super(props);
      this.state = {
      };

      bind(this, ['gotoTestPage']);
    }

    componentWillMount() {
        setTimeout(() => {
            //this.setState({ data: {} });
            get(successStory_UI_CONFIG.url + '/getTDCVersion', this).then(data =>{
                console.log("# getTDCVersion >>>>>  " , data );
            });
        }, 200);
    }
    componentDidMount() {
    	dataPutAndGo(this, '/successStoryLeads', null, null);
    }

    gotoTestPage = () => {
        dataPutAndGo(this, '/test', {name:'Van Liao'}, {age: 33});
    }

    render() {
        return (
            <div className="pageMain">
                <HeaderPage parentObj={this} telescopic={false} />
                <Row type="flex">
                    <Col className="pageLift" style={this.state.pageLeftScreenWidthStyle} >{this.genMenu2 && this.genMenu2()}</Col>
                    <Col className="pageRight" style={this.state.pageRightScreenWidthStyle}>
                        { this.genPathDiv && this.genPathDiv() }
                        {/* ---begin--- */}
                        <h1></h1>
                        {/* <Button onClick={this.gotoTestPage} >Goto Test Page</Button> */}
                        {/* ---end--- */}
                    </Col>
                </Row>
            </div>
        );
    }
}

const ConnectedHomePage = connect(mapStateToProps, mapDispatchToProps)(HomePage);

export default withRouter(ConnectedHomePage);
