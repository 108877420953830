/* public method */
/*
    const compareSpecials = [
        {
            keys: ["expenseSummary.totalAirFarePayable"],
            isChanged: (data1, data2) => {         
                var d1 = 0;
                var d2 = 0;
                if(data1 !== undefined && data1 !== null){
                    d1 = parseFloat(data1+"");
                }
                if(data2 !== undefined && data2 !== null){
                    d2 = parseFloat(data2+"");
                }
                return d1 !== d2;
            }
        },
        {
            keys: [".isPcard",".cardBoderColor"],
            isChanged: (data1, data2) => {
                return false;
            }
        }
    ];
*/
export const checkDataDiff = (data1, data2, compareSpecials) => {
    console.log("#Utils checkDataDiff, data1 = ", data1);
    console.log("#Utils checkDataDiff, data2 = ", data2);
    // console.log("#Utils checkDataDiff, compareSpecials = ", compareSpecials);
    var isDiff = checkDataChanged("", "", data1, data2, compareSpecials, true);
    if(!isDiff){
        isDiff = checkDataChanged("", "", data2, data1, compareSpecials, false);
    }
    console.log("#Utils checkDataDiff, isDiff = ", isDiff);
    return isDiff;
}

/* private method */
const getUpKeyName = (upKey, currentKey) => {
    if((upKey === undefined || upKey === null || upKey === "") ) {
    	if (currentKey === undefined || currentKey === null || currentKey === "") {
        	return "";
    	} else {
    		 return currentKey;
    	}
    }

    return upKey+"."+currentKey;
}

/* private method */
const checkDataChanged = (upKey, currentKey, data1, data2, compareSpecials, type) => {
    var isChanged = false;
    var isSpecial = false;
    var isAllEmpty = false;
    var fullKey = getUpKeyName(upKey,currentKey);
    //var mappedSpecialKey = "";
    //Special case
    if(compareSpecials){
        for(var i = 0; i < compareSpecials.length; i++){
            var keys = compareSpecials[i].keys;        
            for(var j = 0; j < keys.length; j++){
                if(fullKey.indexOf(keys[j]) > -1){
                   // mappedSpecialKey = keys[j];
                    isSpecial = true;
                    break;
                }
            }
            if(isSpecial){
                isChanged = compareSpecials[i].isChanged(data1, data2);
                break;
            }
        }
    }

    //Normal case
    if(!isSpecial){
        var d1Empty = false;
        var d2Empty = false;
        if(data1 === undefined || data1 === null){
            d1Empty = true;
        }
        //Object.keys(obj)
        var tmpKeys = [];
        if(typeof(data1) === "object"){
            tmpKeys = [];
            for (var p in data1){
                tmpKeys.push(p);
            }
            if(tmpKeys.length < 1){
                d1Empty = true;
            }
        }else if(typeof(data1) === "number"){
            if(data1 === 0){
                d1Empty = true;
            }
        }else if(typeof(data1) === "string"){
            if(data1 === ""){
                d1Empty = true;
            }
        }

        if(data2 === undefined || data2 === null){
            d2Empty = true;
        }
        if(typeof(data2) === "object"){
            tmpKeys = [];
            for (var p1 in data2){
                tmpKeys.push(p1);
            }
            if(tmpKeys.length < 1){
                d2Empty = true;
            }
        }else if(typeof(data2) === "number"){
            if(data2 === 0){
                d2Empty = true;
            }
        }else if(typeof(data2) === "string"){
            if(data2 === ""){
                d2Empty = true;
            }
        }

        if(d1Empty !== d2Empty){
            isChanged = true;
        }
    
        if(d1Empty === true && d2Empty === true){
            //not Changed
            isAllEmpty = true;
        }else{
            //Not same type
            if(!isChanged  && typeof(data1) !== typeof(data2)){
                isChanged = true;
            }
        
            if(!isChanged && typeof(data1) !== "object"){
                if(data1 !== data2){
                    isChanged = true;
                }
            }
        }
    }

    if(!isChanged && !isSpecial && !isAllEmpty && typeof(data1) === "object"){
        for (var key in data1) {
            if(checkDataChanged(fullKey, key, data1[key], data2[key], compareSpecials, type)){
                console.log("# diff fullKey/key", fullKey, "/", key);
                console.log("# diff data1[key]", data1[key]);
                console.log("# diff data2[key]", data2[key]);
                isChanged = true;
                return isChanged;
            }
        }
    }

    // if(isChanged){
    //     console.log("#Utils checkDataDiff, isFirst="+type+", fullKey="+fullKey+", currentKey="+currentKey+", isSpecial="+isSpecial+", mappedSpecialKey="+mappedSpecialKey);
    //     console.log("#Utils checkDataDiff, data1 type= "+typeof(data1)+" : ", data1);
    //     console.log("#Utils checkDataDiff, data2 type= "+typeof(data2)+" : ", data2);
    // }
    return isChanged;
}
