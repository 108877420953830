import {
    SET_CURRENT_LANG,SET_LOGIN_USER,SET_USER_PERMISSION,SET_ROLE_CODE
    ,SET_SYS_VERSION
} from "./actionTypes";

//localStorage
//sessionStorage
const getDataFromStorage = (key, defaultValue) => {
    var valueStr = localStorage.getItem(key);
    //console.log("&&&&&&&&&&&&&&&&&&&&& "+key + "=", valueStr);
    if(valueStr == 'undefined'){
        valueStr = 'null';
    }
    if(key === "SET_CURRENT_LANG"){
        return valueStr ? valueStr : defaultValue;
    }else{
        return valueStr ? JSON.parse(valueStr) : defaultValue;
    }
}

const initialState = {
    userRoleCode: getDataFromStorage('SET_ROLE_CODE'),
    userPermission: getDataFromStorage('SET_USER_PERMISSION'),
    loginUser: getDataFromStorage('SET_LOGIN_USER'),
    currentLang: getDataFromStorage('SET_CURRENT_LANG','zh'),
    sysVersion: getDataFromStorage('SET_SYS_VERSION',null),
};

const rootReducer = (state , action) => {
	
	if (!state) {
		state= initialState;
	}
	
    switch (action.type) {
        case SET_ROLE_CODE:
            localStorage.setItem('SET_ROLE_CODE', JSON.stringify(action.payload));
            return { ...state, userRoleCode: action.payload };
        case SET_USER_PERMISSION:
            localStorage.setItem('SET_USER_PERMISSION', JSON.stringify(action.payload));
            return { ...state, userPermission: action.payload };
        case SET_LOGIN_USER:
            localStorage.setItem('SET_LOGIN_USER', JSON.stringify(action.payload));
            return { ...state, loginUser: action.payload };
        case SET_CURRENT_LANG:
            localStorage.setItem('SET_CURRENT_LANG', JSON.stringify(action.payload));
            return { ...state, currentLang: action.payload };
        case SET_SYS_VERSION:
            localStorage.setItem('SET_SYS_VERSION', JSON.stringify(action.payload));
            return { ...state, sysVersion: action.payload };
        default:
            return state;
    }
};

export default rootReducer;