import React, { Component } from 'react';
import { connect } from "react-redux";
//import { getLabel } from '../../Lang';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser,setUserPermission } from '../../redux/actions';
import { Row, Col, Tabs, Modal } from 'antd';
import HeaderPage from '.././HeaderPage';
import { bind, DATA_SAVE_MSG ,getPermission } from '../../Utils';
import TaxonomyAndTagging from './TaxonomyAndTagging';

import LeadInfoDisc from './LeadInfoDisclosable';
import LeadNature from './LeadNature';

//import Industry from './Industry';
import Event from './Event';
import EventType from './EventType';
import StoryCaseType from './StoryCaseType';
import Channel from './Channel';
//import {DATA_UI_CONFIG} from '../../../src/DataConfig'
//import { get, post } from "../../services/AuthService";
import { checkDataDiff } from '../../UtilDataDiff';
import { get } from "../../services/AuthService";
import { successStory_UI_CONFIG } from '../../DataConfig'

const { TabPane } = Tabs;

const mapStateToProps = (state) => {
    return {
        currentLang: state.currentLang,
        loginUser: state.loginUser,
        userPermission : state.userPermission
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLang: (info) => {
            dispatch(setCurrentLang(info));
        },
        setLoginUser: (info) => {
            dispatch(setLoginUser(info));
        },
        setUserPermission:(info) =>{
            dispatch(setUserPermission(info));
        }
    };
}

const CompareSpecials = [
    {
        keys: ['_rt_checked', "_rt_in_edit","_rt_base_data",'_rt_new_data', 'rd1', 'rd2', 'rd3'],
        isChanged: (data1, data2) => {
            return false;
        }
    }
];

class ReferenceDataPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 'Event'
        };

        bind(this, ['dataDiffHandler', 'changeTab', 'saveHandler']);
        this.DATA_DIFF = { dataHandler: this.dataDiffHandler, compareSpecials:CompareSpecials};
    }

    componentWillMount() {

        var eventMaintenanceReadInd = getPermission(this.props,"eventMaintenanceReadInd");
        var eventMaintenanceWriteInd = getPermission(this.props,"eventMaintenanceWriteInd");

        var TopicThemeMaintenanceReadInd = getPermission(this.props,"TopicThemeMaintenanceReadInd");
        var TopicThemeMaintenanceWriteInd = getPermission(this.props,"TopicThemeMaintenanceWriteInd");
        
        var channelMaintenanceReadInd = getPermission(this.props,"channelMaintenanceReadInd");
        var channelMaintenanceWriteInd = getPermission(this.props,"channelMaintenanceWriteInd");
        
        var eventTypeMaintenanceReadInd = getPermission(this.props,"eventTypeMaintenanceReadInd");
        var eventTypeMaintenanceWriteInd = getPermission(this.props,"eventTypeMaintenanceWriteInd");
        
        var storyCaseTypeMaintenanceReadInd = getPermission(this.props,"storyCaseTypeMaintenanceReadInd");
        var storyCaseTypeMaintenanceWriteInd = getPermission(this.props,"storyCaseTypeMaintenanceWriteInd");

		var leadInfoDiscMaintenanceReadInd = getPermission(this.props,"leadInfoDiscMaintenanceReadInd");
        var leadInfoDiscMaintenanceWriteInd = getPermission(this.props,"leadInfoDiscMaintenanceWriteInd");
		
		var leadNatureMaintenanceReadInd = getPermission(this.props,"leadNatureMaintenanceReadInd");
        var leadNatureMaintenanceWriteInd = getPermission(this.props,"leadNatureMaintenanceWriteInd");
        console.log("leadInfoDiscMaintenanceReadInd", leadInfoDiscMaintenanceReadInd);
        
        if( ( storyCaseTypeMaintenanceReadInd || storyCaseTypeMaintenanceWriteInd ) ){this.state.selectedTab = 'storyCaseType';
        }else if( channelMaintenanceReadInd || channelMaintenanceWriteInd ) {this.state.selectedTab = 'channel'; 
        }else if( eventTypeMaintenanceReadInd || eventTypeMaintenanceWriteInd ) {this.state.selectedTab = 'EventType';
        }else if( ( eventMaintenanceReadInd || eventMaintenanceWriteInd ) ){  this.state.selectedTab = 'Event';
        }else if( ( TopicThemeMaintenanceReadInd || TopicThemeMaintenanceWriteInd ) ){ this.state.selectedTab = 'TopicTheme';
		}else if( ( leadInfoDiscMaintenanceReadInd || leadInfoDiscMaintenanceWriteInd ) ){this.state.selectedTab = 'leadInfoDisc';
        }else if( ( leadNatureMaintenanceReadInd || leadNatureMaintenanceWriteInd ) ){this.state.selectedTab = 'leadNature';
        } 
        
        
        
        
        
        
        

        this.eventMaintenanceReadInd = eventMaintenanceReadInd;
        this.eventMaintenanceWriteInd = eventMaintenanceWriteInd;
        this.TopicThemeMaintenanceReadInd = TopicThemeMaintenanceReadInd;
        this.TopicThemeMaintenanceWriteInd = TopicThemeMaintenanceWriteInd;
        
        this.storyCaseTypeMaintenanceReadInd = storyCaseTypeMaintenanceReadInd;
        this.storyCaseTypeMaintenanceWriteInd = storyCaseTypeMaintenanceWriteInd;
        
        this.eventTypeMaintenanceReadInd = eventTypeMaintenanceReadInd;
        this.eventTypeMaintenanceWriteInd = eventTypeMaintenanceWriteInd;
        
        this.channelMaintenanceReadInd = channelMaintenanceReadInd;
        this.channelMaintenanceWriteInd = channelMaintenanceWriteInd;
		
		this.leadInfoDiscMaintenanceReadInd = leadInfoDiscMaintenanceReadInd;
        this.leadInfoDiscMaintenanceWriteInd = leadInfoDiscMaintenanceWriteInd;
		
		this.leadNatureMaintenanceReadInd = leadNatureMaintenanceReadInd;
        this.leadNatureMaintenanceWriteInd = leadNatureMaintenanceWriteInd;



        this.tabKey = Math.random();
        setTimeout(() => {
            //this.setState({ data: {} });
            get(successStory_UI_CONFIG.url + '/getTDCVersion', this).then(data =>{
                console.log("# getTDCVersion >>>>>  " , data );
            });
        }, 200);
    }

    dataDiffHandler = () =>{
        if(this.state.selectedTab == "TopicTheme"){
            if(this.TaxonomyAndTagging && this.TaxonomyAndTagging.dataDiffHandler){
                return this.TaxonomyAndTagging.dataDiffHandler();
            }
        }else{
            if(this.Event && this.Event.dataDiffHandler){
                return this.Event.dataDiffHandler();
            }
        }
        return {};
    }

    saveHandler = (callBackHandler, isTimeout) => {
        if(this.state.selectedTab == "TopicTheme"){
            if(this.TaxonomyAndTagging && this.TaxonomyAndTagging.saveHandler){
                this.TaxonomyAndTagging.saveHandler(callBackHandler, isTimeout);
            }
        }else{
            if(this.Event && this.Event.saveHandler){
                this.Event.saveHandler(callBackHandler, isTimeout);
            }
        }
    }

    changeTab(tabKey){
        var thisObj = this;
        var diffDatas = this.dataDiffHandler();
        var isUpdated = checkDataDiff(diffDatas.thisData, diffDatas.stateData, CompareSpecials);
        if(isUpdated){
            Modal.confirm({
                title: 'Confirm',
                content: (<span className="dataDiffMessage">{DATA_SAVE_MSG}</span>),
                okText: 'OK',
                cancelText: 'Cancel',
                onOk() {
                    //save action
                    thisObj.saveHandler(()=>{
                        thisObj.tabKey = Math.random();
                        thisObj.setState({selectedTab: tabKey});
                    });
                },
                onCancel() {
					// thisObj.tabKey = Math.random();
                    // thisObj.setState({selectedTab: tabKey});
                },
            });
        }else{
            thisObj.tabKey = Math.random();
            thisObj.setState({selectedTab: tabKey});
        }
    }
    
    genChannelPart = () =>{
        
        if( this.channelMaintenanceReadInd || this.channelMaintenanceWriteInd ){
            return(
                <TabPane tab="Story Publicity Channel" key="channel" >
                     <Channel parentObj={this} key={this.tabKey+"_channel"}/> 
                </TabPane>
            )
        }
    }
    genStoryCaseTypePart = () =>{
        
        if( this.storyCaseTypeMaintenanceReadInd || this.storyCaseTypeMaintenanceWriteInd ){
            return(
                <TabPane tab="Types of Story Lead" key="storyCaseType" >
                     <StoryCaseType parentObj={this} key={this.tabKey+"_storyCaseType"}/> 
                </TabPane>
            )
        }
    }
    genEventTypePart = () =>{
        
        if( this.eventTypeMaintenanceReadInd || this.eventTypeMaintenanceWriteInd ){
            return(
                <TabPane tab="Service/Event Type" key="EventType" >
                     <EventType parentObj={this} key={this.tabKey+"_EventType"}/> 
                </TabPane>
            )
        }
    }
    genEventPart = () =>{
        
        if( this.eventMaintenanceReadInd || this.eventMaintenanceWriteInd ){
            return(
                <TabPane tab="Service/Event Name" key="Event" >
                     <Event parentObj={this} key={this.tabKey+"_Event"}/> 
                </TabPane>
            )
        }
    }
    genTopicPart = () =>{
       
        if( this.TopicThemeMaintenanceReadInd || this.TopicThemeMaintenanceWriteInd ){
            return(
                <TabPane tab="Topics/Themes" key="TopicTheme">
                        <TaxonomyAndTagging parentObj={this} key={this.tabKey+"_TaxonomyAndTagging"}/>
                </TabPane>
            )
        }
    }
	
	genLeadInfoDiscPart = () =>{
        
        if( this.leadInfoDiscMaintenanceReadInd || this.leadInfoDiscMaintenanceWriteInd ){
            return(
                <TabPane tab="Types of Lead Information Disclosable"  key="leadInfoDisc" >
                     <LeadInfoDisc parentObj={this} key={this.tabKey+"_leadInfoDisc"}/> 
                </TabPane>
            )
        }
    }

	genLeadNaturePart = () =>{
        
        if( this.leadNatureMaintenanceReadInd || this.leadNatureMaintenanceWriteInd ){
            return(
                <TabPane tab="Nature of Lead"  key="leadNature" >
                     <LeadNature parentObj={this} key={this.tabKey+"_leadNature"}/> 
                </TabPane>
            )
        }
    }


    render() {

            return (
                <div className="pageMain">
                    <HeaderPage parentObj={this} telescopic={false}  />
                    <Row type="flex">
                        <Col className="pageLift" style={this.state.pageLeftScreenWidthStyle} >{this.genMenu2 && this.genMenu2()}</Col>
                        <Col className="pageRight" style={this.state.pageRightScreenWidthStyle}>
                            {this.genPathDiv && this.genPathDiv()}
                            <Tabs activeKey={this.state.selectedTab} onChange={this.changeTab}>
                            	{this.genStoryCaseTypePart()}
                            	{this.genChannelPart()}
                            	{this.genEventTypePart()}
                            	
                            	
                                {this.genEventPart()}

                                {this.genTopicPart()}
								
								{this.genLeadInfoDiscPart()}
								
								
                                
                            </Tabs>

                        </Col>
                    </Row>
                </div>
            );
        
    }
}

const ConnectedReferenceDataPage = connect(mapStateToProps, mapDispatchToProps)(ReferenceDataPage);

export default withRouter(ConnectedReferenceDataPage);
