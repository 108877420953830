
import lodash from 'lodash';

/* for MySelect */
export const handlerMySelectChange = (name, value, pageObj) => {
    console.log("# handlerMySelectChange: ", name, value);
    if(!pageObj){
        console.error("# EventUtil error: handlerMySelectChange pageObj is null.");
        return;
    }
    var stateData = pageObj.state;
    lodash.set(stateData, name, value);
    pageObj.setState({...stateData});
}

/* For MyInput */
export const handlerMyInputChange = (event, pageObj) => {
    if(!pageObj){
        console.error("# EventUtil error: handlerMyInputChange pageObj is null.");
        return;
    }
    var stateData = pageObj.state;
    var target = event.currentTarget;
    var inputName = target.name;
    var fieldName = inputName;
    var actions = "";
    var value = target.value;
    console.log("# handlerMyInputChange: ", inputName, value);
    if(inputName.indexOf('|') > -1){//exist Actions
        var index = inputName.indexOf('|');
        actions = inputName.substring(index + 1);
        fieldName = inputName.substring(0, index);
        if(actions.indexOf('U') > -1 || actions.indexOf('u') > -1){ //toUpperCase
            value = value.toUpperCase();
        }
    }
    lodash.set(stateData, fieldName, value);
    pageObj.setState({...stateData});
}

/* For MySwitch */
export const handlerMySwitchChange = (val, event, pageObj) => {
    if(!pageObj){
        console.error("# EventUtil error: handlerMySwitchChange pageObj is null.");
        return;
    }
    var stateData = pageObj.state;
    var target = event.currentTarget;
    var fieldName = target.name;
    console.log("# handlerMySwitchChange: ", fieldName, val);
    lodash.set(stateData, fieldName, val);
    pageObj.setState({...stateData});
}

/* For MyCheckbox */
export const handlerMyCheckboxChange = (event, pageObj) => {
    console.log("# handlerMySwitchChange event: ", event);
    if(!pageObj){
        console.error("# EventUtil error: handlerMyCheckboxChange pageObj is null.");
        return;
    }
    var stateData = pageObj.state;
    var target = event.target;
    var fieldName = target.name;
    var checked = target.checked;
    console.log("# handlerMySwitchChange: ", fieldName, checked);
    lodash.set(stateData, fieldName, checked);
    pageObj.setState({...stateData});
}

/* For MyDatePicker */
export const handlerMyDatePickerChange = (date, dateString, name, pageObj) => {
    console.log("# handlerMyDatePickerChange: ", date, dateString, name);
    if(!pageObj){
        console.error("# EventUtil error: handlerMyDatePickerChange pageObj is null.");
        return;
    }
    var stateData = pageObj.state;
    lodash.set(stateData, name, date);
    pageObj.setState({...stateData});
}

/* MyRangePicker */
export const handlerMyRangePickerChange = (dates, dateStrings, name, pageObj) => {
    console.log("# handlerMyRangePickerChange: ", dates, dateStrings, name);
    if(!pageObj){
        console.error("# EventUtil error: handlerMyRangePickerChange pageObj is null.");
        return;
    }
    var fromFieldName = "";
    var toFieldName = "";
    if(name.indexOf('|') > -1){
        var index = name.indexOf('|');
        fromFieldName = name.substring(0, index);
        toFieldName = name.substring(index + 1);
    }
    var stateData = pageObj.state;
    lodash.set(stateData, fromFieldName, dates[0]);
    lodash.set(stateData, toFieldName, dates[1]);
    pageObj.setState({...stateData});
}