import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser ,setUserPermission,setRoleCode } from '../redux/actions';
import '../index.css';
import { Row, Col, Tabs, Modal } from 'antd';
import HeaderPage from './HeaderPage';
import { bind, DATA_SAVE_MSG, CompareSpecials , getPermission,removeFooter  } from '../Utils';
import { checkDataDiff } from '../UtilDataDiff';
import RolePage from './RolePage';
// import GroupPage from './GroupPage';
// import DepartmentPage from './DepartmentPage';
import UserPage from './UserPage';

const { TabPane } = Tabs;

const mapStateToProps = (state) => {
    return {
        userRoleCode: state.userRoleCode,
        currentLang: state.currentLang,
        loginUser: state.loginUser,
        userPermission : state.userPermission
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLang: (info) => {
            dispatch(setCurrentLang(info));
        },
        setLoginUser: (info) => {
            dispatch(setLoginUser(info));
        },
        setUserPermission:(info) =>{
            dispatch(setUserPermission(info));
        },
        setRoleCode:(info) =>{
            dispatch(setRoleCode(info));
        }
    };
}



class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab:'role'
        };

        bind(this, ['changeTab', 'dataDiffHandler', 'saveHandler']);

        this.DATA_DIFF = { dataHandler: this.dataDiffHandler, compareSpecials:CompareSpecials};
        removeFooter();
    }

    componentWillMount() {
        this.tabKey = Math.random();
        setTimeout(() => {
            this.setState({ data: {} });
        }, 200);
        
        
    }
   

    dataDiffHandler = () =>{
        if(this.state.selectedTab == "role"){
            if(this.RolePage && this.RolePage.dataDiffHandler){
                return this.RolePage.dataDiffHandler();
            }
        }else if(this.state.selectedTab == "user"){
            if(this.UserPage && this.UserPage.dataDiffHandler){
                return this.UserPage.dataDiffHandler();
            }
        }
        return {};
    }

    saveHandler = (callBackHandler, isTimeout) => {
        if(this.state.selectedTab == "role"){
            if(this.RolePage && this.RolePage.saveHandler){
                this.RolePage.saveHandler(callBackHandler, isTimeout);
            }
        }else if(this.state.selectedTab == "user"){
            if(this.UserPage && this.UserPage.saveHandler){
                this.UserPage.saveHandler(callBackHandler, isTimeout);
            }
        }
    }

    changeTab(tabKey){
        var thisObj = this;
        var diffDatas = this.dataDiffHandler();
        var isUpdated = checkDataDiff(diffDatas.thisData, diffDatas.stateData, CompareSpecials);
        if(isUpdated){
            Modal.confirm({
                title: 'Confirm',
                content: (<span className="dataDiffMessage">{DATA_SAVE_MSG}</span>),
                okText: 'OK',
                cancelText: 'Cancel',
                onOk() {
                    //save action
                    thisObj.saveHandler(()=>{
                        thisObj.tabKey = Math.random();
                        thisObj.setState({selectedTab: tabKey});
                    });
                },
                onCancel() {
					//thisObj.tabKey = Math.random();
                    //thisObj.setState({selectedTab: tabKey});
                },
            });
        }else{
            thisObj.tabKey = Math.random();
            thisObj.setState({selectedTab: tabKey});
        }
        
        
        
    }

    genTable =() =>{
        var path = this.props.location.pathname;
        var tabKey = path.substring(path.lastIndexOf('/') + 1);

        var userRoleMaintenanceReadInd = getPermission(this.props,"userRoleMaintenanceReadInd");
        var userRoleMaintenanceWriteInd = getPermission(this.props,"userRoleMaintenanceWriteInd");

        var userMaintenanceReadInd = getPermission(this.props,"userMaintenanceReadInd");
        var userMaintenanceWriteInd = getPermission(this.props,"userMaintenanceWriteInd");

        if( ( userRoleMaintenanceReadInd || userRoleMaintenanceWriteInd ) && ( userMaintenanceReadInd || userMaintenanceWriteInd ) ){
            return(
                <Tabs activeKey={this.state.selectedTab} onChange={this.changeTab} >
                    <TabPane tab="Role" key="role" style={{paddingLeft:'0px' ,paddingRight:'0px'}} className="editPanel">
                        <RolePage parentObj={this} key={this.tabKey+"_RolePage"}/>
                    </TabPane> 
                    <TabPane tab="User" key="user">
                        <UserPage parentObj={this} key={this.tabKey+"_UserPage"}/>
                    </TabPane>
                </Tabs>  
            )
        }else if( ( userRoleMaintenanceReadInd || userRoleMaintenanceWriteInd ) ){
            return(
            <Tabs defaultActiveKey={tabKey} >
                <TabPane tab="Role" key="role" style={{marginLeft:'0px' ,paddingRight:'0px'}} className="editPanel">
                    <RolePage parentObj={this} key={this.tabKey+"_RolePage"}/>
                </TabPane> 
             </Tabs>
            )
        }else if ( ( userMaintenanceReadInd || userMaintenanceWriteInd ) ){
            this.state.selectedTab = "user";
            return(
            <Tabs defaultActiveKey={tabKey} >
                 <TabPane tab="User" key="user" style={{marginLeft:'0px' ,paddingRight:'0px'}} className="editPanel">
                      <UserPage parentObj={this} key={this.tabKey+"_UserPage"}/>
                </TabPane> 
             </Tabs>  
            )
        }
    }

    render() {
        
        //var path = this.props.location.pathname;
        //var tabKey = path.substring(path.lastIndexOf('/') + 1);


        

        return (
            <div className="pageMain">
                <HeaderPage parentObj={this} telescopic={false} />
                <Row type="flex">
                    <Col className="pageLift" style={this.state.pageLeftScreenWidthStyle} >{this.genMenu2 && this.genMenu2()}</Col>
                    <Col className="pageRight" style={this.state.pageRightScreenWidthStyle}>
                        {this.genPathDiv && this.genPathDiv()}
                        {/* ---begin--- */}
                            {this.genTable()}


                        {/* ---end--- */}
                    </Col>
                </Row>
            </div>
        );





    }
}

const ConnectedHomePage = connect(mapStateToProps, mapDispatchToProps)(HomePage);

export default withRouter(ConnectedHomePage);
