import { Button, Icon } from 'antd';
import React, { Component } from 'react';
//import lodash from 'lodash';
import './MyComponent.css';

class MyPagination extends Component {
    
        constructor(props) {
          super(props);
          this.state = {
            disabled: this.props.disabled,
            current: this.props.current,
            pageSize: this.props.pageSize,
            total: this.props.total,
            showCount: this.props.showCount,
          };

          this.handleClick = this.handleClick.bind(this);
          this.gen = this.gen.bind(this);
        }

        /*
        //size="small"
        //className="_NMT_Pagination"
        disabled={this.state.isEditMode}
        current={selectPageIndex}
        onChange={this.handlerPageChange}
        pageSize={onePageCount}
        total={totalCount}
        */
    
        componentWillMount() {
        }
    
        componentWillReceiveProps(nextProps) {
            this.setState({
                disabled: nextProps.disabled,
                current: nextProps.current,
                pageSize: nextProps.pageSize,
                total: nextProps.total,
                showCount: nextProps.showCount,
            });
        }

        handleClick = (value, isStepType) => {
            console.log("# handleClick value/isStepType = ", value, isStepType);
            var gotoPageIndex = value;
            var currentPageIndex = this.state.current;
            var pageSize = this.state.pageSize;
            if(isStepType){
                gotoPageIndex = currentPageIndex + value;
            }
            var hander = this.props.onChange;
            if(hander){
                hander(gotoPageIndex, pageSize);
            }
        }

        gen = () => {
            var pageSize = this.state.pageSize;
            var totalCount = this.state.total;
            var selectedPageIndex = this.state.current;
            var showCount = this.state.showCount;
            if(!showCount || showCount < 2){
                showCount = 5;
            }
            var oneSideShowCount = Math.ceil((showCount - 1)/2)
            //console.log("# pageSize/oneSideShowCount/totalCount/selectedPageIndex = ", pageSize, oneSideShowCount, totalCount, selectedPageIndex);
            
            if(!selectedPageIndex || selectedPageIndex < 1){
                selectedPageIndex = 1;
            }
            if(!pageSize || pageSize < 1){
                pageSize = 10;
            }
            if(!totalCount || totalCount < 1){
                totalCount = 0;
            }
            var pageCount = Math.ceil(totalCount / pageSize);

            if(selectedPageIndex > pageCount){
                selectedPageIndex = pageCount;
            }
            
            var leftFromIndex = selectedPageIndex - oneSideShowCount;
            var rightToIndex = selectedPageIndex + oneSideShowCount;

            if(leftFromIndex < 1){
                leftFromIndex = 1;
            }
            
            if(rightToIndex > pageCount){
                rightToIndex = pageCount;
            }

            while((rightToIndex - leftFromIndex) < (oneSideShowCount * 2)){
                var adj = false;
                if(leftFromIndex > 1){
                    adj = true;
                    leftFromIndex--;
                }
                if(rightToIndex < pageCount){
                    adj = true;
                    rightToIndex++;
                }
                if(!adj){
                    break;
                }
            }

            //console.log("# selectedPageIndex/leftFromIndex/rightToIndex/pageCount = ", selectedPageIndex, leftFromIndex, rightToIndex, pageCount);
            var disabled = this.state.disabled;
            var indexList = [];
            for(var i = leftFromIndex; i <= rightToIndex; i++){
                indexList.push(i);
            }
            var pageButtonList = indexList.map((item)=>{
                var className = "_NMT_page_item_def _NMT_page_item";
                if(item == selectedPageIndex){
                    className = "_NMT_page_item_def _NMT_page_item_selected";
                    if(disabled == true){
                        className = "_NMT_page_item_def _NMT_page_item_selected_disable";
                    }
                }else{
                    if(disabled == true){
                        className = "_NMT_page_item_def _NMT_page_item_disable";
                    }
                }
                return (<Button onClick={()=>this.handleClick(item, false)} key={"_pil_"+item} disabled={disabled} className={className}>{item}</Button>);
            });
                

            if(pageCount < 1){
                disabled = true;
            }
            var disabled1 = selectedPageIndex==1?true:false;
            var disabled2 = selectedPageIndex==pageCount?true:false;

            var className1 = "_NMT_page_item_def _NMT_page_item";
            if(disabled1 || disabled){
                className1 = "_NMT_page_item_def _NMT_page_item_disable";
            }

            var className2 = "_NMT_page_item_def _NMT_page_item";
            if(disabled2 || disabled){
                className2 = "_NMT_page_item_def _NMT_page_item_disable";
            }

            return (
                <span>
                    <Button onClick={()=>this.handleClick(1, false)} disabled={disabled1 || disabled} className={className1}><Icon type="double-left" /></Button>
                    <Button onClick={()=>this.handleClick(-1, true)} disabled={disabled1 || disabled} className={className1}><Icon type="left" /></Button>
                    { pageButtonList } 
                    <Button onClick={()=>this.handleClick(1, true)} disabled={disabled2 || disabled} className={className2}><Icon type="right" /></Button>
                    <Button onClick={()=>this.handleClick(pageCount, false)} disabled={disabled2 || disabled} className={className2}><Icon type="double-right" /></Button>
                </span>
            );
        }

        render() {
            return (
                <div className="_NMT_page"> 
                    { this.gen() } 
                </div>
            );
        }
    }
 
export default MyPagination;
