import 'babel-polyfill';
import  "react-app-polyfill/ie11";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';

import 'antd/dist/antd.css';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from "react-redux";
import store from "./redux/store";
import 'raf/polyfill';

import 'moment/locale/zh-cn';

import 'raf/polyfill';


 
//ReactDOM.render(<App />, document.getElementById('root'));
try {
ReactDOM.render(<Provider store={store}>
    <BrowserRouter><App /></BrowserRouter>
</Provider>, document.getElementById('root'));
} catch (e) {
	  console.log(e);
	  window.location = '/';
	}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
