import React, { Component } from 'react';
import { connect } from "react-redux";
//import { getLabel } from '../../Lang';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser ,setUserPermission } from '../../redux/actions';
import '../../index.css';
import {LEVEL_UI_CONFIG} from '../../DataConfig';
import {GUI_DATE_FORMAT} from '../../Config';
import { Input, Row, Col, Button, Switch , Icon, Radio,message , Modal, Tabs} from 'antd';
//import HeaderPage from '.././HeaderPage';
import { bind, dataPutAndGo, dataGet, getDateStr ,sortDateStr, DATA_SAVE_MSG ,getPermission } from '../../Utils';
import { checkDataDiff } from '../../UtilDataDiff';
import { get, post, getWithoutLoading } from "../../services/AuthService";
import ResponseTable from '../component/ResponseTable';

import lodash from 'lodash';
// import "../../mock/TagApi";
import MyButton from '../component/MyButton';
import moment from 'moment';
//import locale from 'antd/es/date-picker/locale/zh_CN';

import { Base64 } from 'js-base64';


moment.locale('en');



const mapStateToProps = (state) => {
    return {
        currentLang: state.currentLang,
        loginUser: state.loginUser,
        userPermission : state.userPermission
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLang: (info) => {
            dispatch(setCurrentLang(info));
        },
        setLoginUser: (info) => {
            dispatch(setLoginUser(info));
        },
        setUserPermission:(info) =>{
            dispatch(setUserPermission(info));
        }
    };
};

const CompareSpecials = [
    {
        keys: ['_rt_checked', "_rt_in_edit","_rt_base_data",'_rt_new_data', 'rd1', 'rd2', 'rd3'],
        isChanged: (data1, data2) => {
            return false;
        }
    }
];

class taxonomy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tag : "",
            hideTable : true,
            control11 : false
        };

        bind(this, ['dataDiffHandler', 'handleClean', 'handleSearch', 'saveHandler']);
        this.props.parentObj.TaxonomyAndTagging = this;
    }

    componentWillMount() {
        dataGet(this);

        var buttonList = null;

        if( getPermission(this.props,"TopicThemeMaintenanceWriteInd") ){
            buttonList =   [{ title: 'Create New', handler: this.handleNew , style: {cursor: 'pointer'}, icon: 'file-add'},];
        }
            
        this.buttonList = buttonList;


    }
    componentDidMount(){
    	
    	
    	this.searchData();
    }

    dataDiffHandler = () =>{
        var table1 = this.tables ? this.tables['taxonomyAndTagging'] : null;
        var editingList = [];
        if(table1){
            editingList = table1.getEditDataList();
        }
        var backupData = [];
        for(var i = 0; i < editingList.length; i++){
            var data = editingList[i];
            backupData.push(data._rt_base_data);
        }
        var bothData = {thisData: backupData, stateData: editingList};
        console.log("########## TaxonomyAndTagging dataDiffHandler is: ", bothData);
        return bothData;
    }

    /* Save you data when logout */
    saveHandler = (callBackHandler, isTimeout) => {
        var table1 = this.tables ? this.tables['taxonomyAndTagging'] : null;
        var editingList = [];
        if(table1){
            editingList = table1.getEditDataList();
        }
        console.log("########## TaxonomyAndTagging saveHandler editingList: ",editingList);
        for(var i = 0; i < editingList.length; i++){
            var isOk = this.checkData(editingList[i]);
            if(!isOk){
                if(isTimeout == true && callBackHandler){
                    callBackHandler();
                }
                return;
            }
        }
        //check ok
        var name = this.props.loginUser.userName;
        var now = getDateStr();
        //var parentObj = this.props.parentObj;
        var promiseList = editingList.map((item, index) => {
            item.modifiedBy = name;
            item.updatedBy = name;
            item.updatedDateTime = now;
            item.modifiedDateTime = now;
            if(item.id == 0){
                item.createdBy = name;
                item.createdDateTime = now;
            }            
            
                item.language =  'EN';
           
            
            return new Promise(resolve => {
                post( LEVEL_UI_CONFIG.url + '/saveTopicData', item, this.props.parentObj).then(data => {
                    resolve(data);
                }).catch((e) => {
                    resolve({});
                });
            });            
        });
        
        Promise.all(promiseList).then((resultlist) => {
            //close edit model
            if(table1){
                editingList.map((item)=>{
                    table1.stopSaveEditRow(item);
                });
            }
            if(callBackHandler){
                callBackHandler();
            }
        });

    }

    searchData = () => {
        get( LEVEL_UI_CONFIG.url + '/luceneTop/searchTopic?name=' + encodeURIComponent(this.state.tag) , this.props.parentObj).then(data => {
            console.log("###########searchData:", data);
            this.setState({
                tagList: data.users,
                backUpTagList: data.users
            });
        });
        this.setState({
            hideTable : false
        });
    }


    clickRow = (header, rowData, index) => {
        // console.log("####### header  ", header);
        // console.log("####### rowData ", rowData);
        // console.log("####### index  ", index);
        var updateRowId = rowData.id;
        dataPutAndGo(this, '/TagEditPage', { id: updateRowId }, {});
    }

    // handleNew = () => {
    //     //dataPutAndGo(this, '/TagEditPage', {}, {});
    //     dataPutAndGo(this, '/TagEditPage', { name: '', isActive: true }, {});
    // }

    handleClean = () => {

        var backUpTagList = this.state.backUpTagList;
        this.setState({
            tag: "",
            tagList: backUpTagList
        });
    }

    handleSearch = (hasChecking) => {

        if(hasChecking && this.state.tag===""){
			message.config({
			             top: '10%',
			       });

            
            Modal.info({
			    title: 'Please fill in at least one search field.',
			    content: (
			      <div>
			        
			      </div>
			    ),
			    onOk() {},
			  });
            return;
        }
        
       
        
        this.searchData();
    }


    handlerInputChange = (event) => {
        var stateData = this.state;
        var target = event.currentTarget;
        var fieldName = target.name;
        var value = target.value;
        lodash.set(stateData, fieldName, value);
        this.setState({ ...stateData });
    }

    handlerTableInputChange = (event, col, rowData, tableObj) => {
        var target = event.currentTarget;
        var value = target.value;
        tableObj.setFieldValue(rowData, col, value);
    }

    handleNew = (tableObj, infoObj, item) => {

        //var today = new Date();
        //var datev = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + " " + today.getHours()+':'+today.getMinutes()+':'+today.getSeconds();
        var datev = getDateStr();

        var tagList = {
            createdDateTime : datev , 
            updatedDateTime : datev ,
            modifiedDateTime : datev,
            updatedBy : 'admin' ,
            modifiedBy : 'admin',
            createdBy : 'admin' ,
            status: 'Active',
            language: 'EN'

        };
        // var allTagList = this.state.tagList;
        // allTagList.push(tagList);

        // this.setState({
        //     tagList : allTagList
        // });

        tableObj.createNewRecord(tagList);
    }

    handleEdit = (tableObj, infoObj, item) => {
        tableObj.openEditModel();
    }

    checkData = (item) => {
        if(!item.topicTheme){
            message.config({ top: '10%', });
//            message.info("Topic/Theme is required.");
            Modal.info({
			    title: 'Topic/Theme is required.',
			    content: (
			      <div>
			        
			      </div>
			    ),
			    onOk() {},
			  });
            return false;
        }
        return true;
    }

    handleSave = (tableObj, item) => {
        console.log(" item >>>>> " , item );
        if(this.checkData(item) == true){            
	        var name=this.props.loginUser.userName;
	        var datev = getDateStr();
	        item.createdBy=name;
			item.updatedBy=name;
			item.updatedDateTime=datev;
			item.createdDateTime=datev;
			
				item.language =  'EN';
			
			 console.log("item.status " , item.status , " id ",item.id);
	            if (item.status == false || item.status  == "false" || item.status  == "Inactive"){
	            	item.status='Inactive';
	            }else{
	            	item.status='Active';
	            }
			var logEntity = {};
	        if(item.id == undefined ){
	            item.createdBy = name;
	            item.createdDateTime = datev;
				logEntity.activity = "Add Topic/Theme Record";
	            logEntity.actionBy = name;
	        	logEntity.actionDate = datev;
	            logEntity.actionResult = Base64.encode("Topic/Theme "+item.topicTheme+" has been added.");
	            logEntity.leadId = null;
	        }else{
				
				var oldTopicTheme = this.state.oldTopicTheme;
				var oldLanguage = this.state.oldLanguage;
				var oldSimilarWords = this.state.oldSimilarWords;
				var oldStatus = this.state.oldStatus;
				var resultTtStr = "";
				var resultLanStr = "";
				var resultSimStr = "";
				var resultStaStr = "";
				var ttMsg = "";
				if(oldTopicTheme!==item.topicTheme){
					resultTtStr="'Topic/Theme' - '"+item.topicTheme+"'";
				}
				if(oldLanguage!==item.language){
					resultLanStr="'Language' - '"+item.language+"'";
				}
				if(oldSimilarWords!==item.similarWords){
					resultSimStr="'Similar Words' - '"+item.similarWords+"'";
				}
				if(oldStatus!==item.status){
					resultStaStr="'Status' - '"+item.status+"'";
				}
				if(resultTtStr !==""){
					ttMsg+=resultTtStr;
				}
				if(resultLanStr !==""){
					if(ttMsg===""){
						ttMsg+=resultLanStr;
					}else{
						ttMsg+=", "+resultLanStr;
					}
				}
				if(resultSimStr !==""){
					if(ttMsg===""){
						ttMsg=resultSimStr;
					}else{
						ttMsg+=", "+resultSimStr;
					}
				}
				if(resultStaStr !==""){
					if(ttMsg===""){
						ttMsg=resultStaStr;
					}else{
						ttMsg+=", "+resultStaStr;
					}
				}
				if(ttMsg!=""){
					item.createdBy = name;
					item.createdDateTime = datev;
					logEntity.activity = "Update Topic/Theme Record";
					logEntity.actionBy = name;
					logEntity.actionDate = datev;
					logEntity.actionResult = Base64.encode("Topic/Theme "+oldTopicTheme+" has been updated with "+ttMsg+".");
					logEntity.leadId = null;
				}
				
			}
			item.actionHistoryBean = logEntity;
			 post( LEVEL_UI_CONFIG.url + '/saveTopicData?entity=', item, this.props.parentObj).then(data => {
		            console.log("data >>> " , data );
		            tableObj.stopSaveEditRow(item);
		            if("SUCESS" == data.returnType){
		            	this.messagesuccess('The Topic/Theme is saved successfully.');
		            	console.log("updateLeadByTopicTheme Start >>> " , item.topicTheme );
		            	getWithoutLoading( LEVEL_UI_CONFIG.url + '/ssls/updateLeadByTopicTheme?topicTheme='+item.topicTheme, this.props.parentObj);
		            	console.log("updateLeadByTopicTheme End>>> " , item.topicTheme );
		            }else{
		            	this.messageerror('The Topic/Theme failed to be saved.');
		 		   }
		            this.handleSearch(false);
		        }).catch((e) => {
		        	console.log("e >>> " , e );
		        	this.messageerror('The Topic/Theme failed to be saved.');
		        
		        });
        }
    }
    messageerror (msg) {
		Modal.error({
		    title: msg,
		    content: (
		      <div>
		        
		      </div>
		    ),
		    onOk() {},
		  });
	}
    messagesuccess (msg) {
		Modal.success({
		    title: msg,
		    content: (
		      <div>
		        
		      </div>
		    ),
		    onOk() {},
		  });
	}
    handlerSwitchChange = (newValue, col, rowData, tableObj) => {
        tableObj.setFieldValue(rowData, col, newValue);
    }

    handleTableRemove = (row, tableObj) => {
        tableObj.deleteOneRow(row);
        
        this.handleSave(tableObj,row);
    }

    isNotEmpty = (str) =>{
		return (str == undefined || str == "" || str.length == 0 ) ? false : true;
    }
    
    handleTableCancel = (row, tableObj) => {
        console.log("# TaxonomyAndTagging row: ", row);
        var oriRow = row._rt_base_data;
        var bool = checkDataDiff(oriRow, row, CompareSpecials);
        if(bool){
            this.tableObj = tableObj;
            this.CancelRow = row;
            this.dialogshow11(this);
        }else{
            tableObj.stopCancelEditRow(row);
        }
    }

    handleTableSave = (row, tableObj) => {
        
        this.handleSave(tableObj,row);
    }

    handleTableEdit = (row, tableObj) => {
		this.setState({ oldTopicTheme : row.topicTheme, oldLanguage : row.language,
		oldSimilarWords : row.similarWords,oldStatus : row.status, });
        tableObj.startEditRow(row);
    }
    languageChange=(newVal, col, rowData, tableObj) => {

        rowData.language = newVal;
        rowData.rd1 = false;
        rowData.rd2 = false;
        rowData.rd3 = false;

        if(newVal == "EN"){
            rowData.rd1 =  true;
        }else if(newVal == "繁"){
            rowData.rd2 =  true;
        }else if(newVal == "簡"){
            rowData.rd3 =  true;
        } 
        
        tableObj.setFieldValue(rowData,col,newVal);

    }

    render() {

        var tableDatas = this.state.tagList;
        console.log("tableDatas >>> " , tableDatas );

        var tableHeader = {
            columns: [
                //{ field: 'id', width: 2, title: 'ID' },
                { field: 'topicTheme', width: 6, title: 'Topic/Theme' ,
                    editModel :(oldVal, col, rowData, tableObj) =>{
                        return (
                            <span>
                                <Input name="topic"
                                     maxLength={50} 
                                     onChange={(event) => this.handlerTableInputChange(event, col, rowData, tableObj)}
                                     value={oldVal} />
                            </span>
                        );
                    }
                },
                {
                    field: 'similarWords', width: 7, title: 'Similar Words',
                },
                
                {
                    field: 'status', width: 2, title: 'Status',
                    editModel: (oldVal, col, rowData, tableObj) => {
                        
                    	console.log("oldVal ", oldVal);
                    	if(oldVal == false || oldVal == "false" || oldVal == "Inactive"){
                            oldVal = false;
                        }else{
                            oldVal = true;
                        }
                        return (
                        		<div  className="_NMT_tableToogleListCell">
                                <Switch onClick={(newValue) => this.handlerSwitchChange(newValue, col, rowData, tableObj)}
                                    checked={oldVal} checkedChildren="Active" unCheckedChildren="Inactive" />
                            </div>
                        );
                    },
                    format: (val) => {
                    	console.log("val ", val);
                    
                        if(val == false || val == "false"|| val == "Inactive" ){
                            val = false;
                        }else{
                            val = true;
                        }
                        return val ? 'Active' : 'Inactive';
                    }
                },
                { field: 'updatedBy', width: 3, title: 'Modified By',
                    format: (val)=>{
                        if(val){
                            val = val.split('(')[0];
                        }
                        return val;
                    },
                    editModel: (value, col, rowData, tableObj)=>{
                        return(
                            <span></span>
                        );
                    }
                },
				{ field: 'updatedDateTime', width: 3, title: 'Modified Date',
                    format : (val) => {
                        var temp = moment(val, 'YYYY-MM-DD HH:mm'); //:ss
                        val = moment(temp ).format(GUI_DATE_FORMAT);
                        return val;
                    },
                    editModel: (value, col, rowData, tableObj)=>{
                        return(
                            <span></span>
                        );
                    },
                    sortHandler:(val)=>{
                        return sortDateStr(val);
					}
				},
                { field: 'action', width: 3, title: 'Action', sort: false,
                    rowHandler: (col, rowData, tableObj)=>{//the value also can get from rowData
                        return (
                        		<div className="_NMT_tableButtonsListCell" 
                        			style={{ display: getPermission(this.props,"TopicThemeMaintenanceWriteInd") ? '' : 'none' }}>
                        		
                            <MyButton onClick={()=>this.handleTableEdit(rowData, tableObj)} type="primary">Edit</MyButton>
                            {/* <MyButton onClick={()=>this.handleTableRemove(rowData, tableObj)} type="primary">Remove</MyButton> */}
                        
                        </div>);
                    },
                    editModel: (value, col, rowData, tableObj)=>{//the value also can get from rowData
                        return (<div className="_NMT_tableButtonsListCell">
                            <MyButton onClick={()=>this.handleTableSave(rowData, tableObj)} type="primary">Save</MyButton>&nbsp;
                            <MyButton onClick={()=>this.handleTableCancel(rowData, tableObj)} type="primary">Cancel</MyButton>
                        </div>);
                    },
                }
            ]
        };

//        function callback(key) {
//            //console.log(key);
//        }

       
        return (

            <div>
                <Row className="searchPanelReport">
                    <Col  className="taggingMainlabel" xs={24} sm={4} md={4} lg={4} xl={4} xxl={4}>Keywords<span style={{color:'red'}}>*</span></Col>
                    <Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}><Input name="tag" placeholder="Keywords of Topic/ Theme, similar words" onChange={this.handlerInputChange} value={this.state.tag} /></Col>
                </Row>
                <Row className="searchButtonPanel" >
                   
                        <div  >
                            <Button onClick={this.handleClean} className="oneButton" type="primary">Reset</Button>
                            &nbsp;
                            <Button onClick={() => this.handleSearch(false)} className="oneSearchButton" type="primary"><Icon type="search" className="oneSearchIcon"/><span className="oneSearchTitle">Search</span></Button>
                        </div>
                   
                </Row>
                <div className="leadResultTableList">
                <div style={{  display: !this.state.hideTable ? '' : 'none'}} >
                    <ResponseTable
                        name="taxonomyAndTagging"
                        pageObj={this}
                    headerHeight={'50px'}
                        data={tableDatas}
                        header={tableHeader}
                        pageMode={1} //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
                        //handlerClick={this.clickRow} 
                        onePageCount={25} //record count one page, default: 10
                        selectPageIndex={1} //default selected page index, default: 1
//                        selectMode={4}
                        buttons={this.buttonList}
                    headerHeight={'60px'}
                        sort={{index:0, orderBy:true}}
                    />
                </div>
                    </div>
                    <div class="normalFooter">Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>

                <Row>
				&nbsp;
				</Row>
				
                



            </div>
        );
    }


    dialogshow11 = (e) => {

		Modal.confirm({
    		title: 'Confirm',
			content: (<span className="dataDiffMessage">{DATA_SAVE_MSG}</span>),
      	  
			onOk() {e.dialogClose11Ok()},
            closable:false,
            onCancel() {e.dialogClose11Cancel()},
            okText:'OK',
            cancelText:"Cancel",
            width:"500px",
            //footer={null}
           
            maskClosable:false
      	});
    }
    
    dialogClose11Ok = () => {
		
        var tableObj = this.tableObj;
        var Cancelrow = this.CancelRow;
        this.handleSave(tableObj,Cancelrow);
    }

	dialogClose11Cancel = () => {
		
        var tableObj = this.tableObj;
        var Cancelrow = this.CancelRow;
        tableObj.stopCancelEditRow(Cancelrow);
    }

    
}

const ConnectedHomePage = connect(mapStateToProps, mapDispatchToProps)(taxonomy);

export default withRouter(ConnectedHomePage);
