
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter'
import { DATA_SERVER_URL, REQUEST_TIMEOUT, LOGIN_PAGE_URL } from '../Config';
import { CLIENT_TIMEOUT_SET } from '../DataConfig';
import { dataPutAndGo, objectToParamString, timeoutChkDataDiff, timeoutLogout } from '../Utils';
//import cookie from 'react-cookies';
//import Cookies from 'universal-cookie';
//localStorage
//const cookies = new Cookies();
const ACCESS_TOKEN_KEY = 'access_token';
const ACCESS_TOKEN_KEY_TIMEOUT = 'access_token_timeout';
const TARGET_URL = 'target_url';

const HTTP_UNAUTHORIZED = 401;

export function axiosInstance() {
    const cache = setupCache({
        maxAge: CLIENT_TIMEOUT_SET * 60 * 1000,
    });
    var config = {
        baseURL: `${DATA_SERVER_URL}`,
        timeout: REQUEST_TIMEOUT,
        adapter: cache.adapter
    };

    var accessToken = getAccessToken();
    /*
    //cookies.set(ACCESS_TOKEN_KEY, accessToken, { path: '/' });
    //var cookieValue = cookies.get(TOKEN_COOKIE, { path: '/' });
    if(!accessToken){//get token from cookie
        if(cookieValue){
            accessToken = cookieValue;
            setAccessToken(cookieValue);
            //clear
            cookies.remove(TOKEN_COOKIE);
        }
    }
    */
    config.headers = {};
    config.headers['X-Requested-With'] = 'XMLHttpRequest'; 
    config.headers['Access-Control-Allow-Origin'] = '*';    
    config.headers['Access-Control-Allow-Headers'] = 'X-Requested-With';   
    //console.log("@@@@@@@ Authorization is:", accessToken);
    if (accessToken) {
        config.headers['Authorization'] = accessToken;
    }
    const instance = axios.create(config);

    //setAccessTokenTime();//reset time

    return instance;
}

var _clientTimer = null;
export function checkClientTimeout(thisObj) {//maybe header page or parent page
    console.error("## checkClientTimeout 222...");
    if(!thisObj){
        console.error("## checkClientTimeout page object is null.");
        return;
    }
    var pageObj = thisObj;
    if(thisObj.props.parentObj){
        pageObj = thisObj.props.parentObj;
    }
    var timerFun = setInterval(()=>isClientTimeout(pageObj), 60 * 1000);
    if(!_clientTimer){//create new timer
        _clientTimer = timerFun;
    }else{//update timer
        clearInterval(_clientTimer);//clear
        _clientTimer = timerFun;
    }
}

function isClientTimeout(pageObj){
	
    var isExpired = isClientTokenExpired();
    console.error("## isClientTimeout.", isExpired);
    if(isExpired == true){
        clearInterval(_clientTimer);
        timeoutChkDataDiff(pageObj);
    }
}

function checkTokenTimeout(pageObj) {//maybe header page or parent page
    //console.error("## checkTokenTimeout 222...");
    if(!pageObj){
        console.error("## checkTokenTimeout page object is null.");
        return;
    }
    //check token if expired
    var isExpired = isTokenExpired();
    if(isExpired == true){
    	timeoutLogout(pageObj);
    }
}



function unauthorized(pageObj) {
    if(LOGIN_PAGE_URL.disable == false){
        /* go login page */
        //check if ignore url 
        var isIgnoreUrl = false;
        var currUrlPath = "";
        if(pageObj && pageObj.props && pageObj.props.location){
            currUrlPath = pageObj.props.location.pathname;
            if(!currUrlPath){
                currUrlPath = "";
            }
            if(currUrlPath.indexOf(LOGIN_PAGE_URL.loginPageUrl) > -1){
                isIgnoreUrl = true;
            }
            if(!isIgnoreUrl){
                for(var a1 = 0; a1 < LOGIN_PAGE_URL.ignorePageUrls; a1++){
                    if(currUrlPath.indexOf(LOGIN_PAGE_URL.ignorePageUrls[a1]) > -1){
                        isIgnoreUrl = true;
                        break;
                    }
                }
            }
        }
        console.log('$ currUrlPath/isIgnoreUrl: ', currUrlPath, isIgnoreUrl);
        //alert('$ currUrlPath/isIgnoreUrl: '+ currUrlPath+ isIgnoreUrl);
        if(!isIgnoreUrl){
            /* clear token */
            if(pageObj && pageObj.props.setLoginUser){
                pageObj.props.setLoginUser(null);
            }

            if(LOGIN_PAGE_URL.routeUrl == true){
                dataPutAndGo(pageObj, LOGIN_PAGE_URL.loginPageUrl);
            }else{
            	window.location = LOGIN_PAGE_URL.logoutPageUrl;
            }
        }
    }
}
function updateURLParameter(url, param, paramVal){
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (var i=0; i<tempArray.length; i++){
            if(tempArray[i].split('=')[0] != param){
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
         }
    }
    var rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
}
function encodeURLParameter(url, newUrlParamData){
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
		var paramArray;
        for (var i=0; i<tempArray.length; i++){
			paramArray=tempArray[i].split('=');
			if (paramArray[1]) {
				 newAdditionalURL += temp + paramArray[0] + '=' + encodeURIComponent(paramArray[1]);
				  temp = "&";
            }
            else{
                newAdditionalURL += temp + paramArray[0] + '=' + '';
                temp = "&";
            }
         }
    }
	if (newUrlParamData) {
		var newParamStr=objectToParamString(newUrlParamData);
		if (newParamStr!="") {
			newAdditionalURL+=temp+newParamStr
		}

	} 
    return baseURL + "?" + newAdditionalURL;
}

export function getWithoutLoading(url, pageObj,urlParamData) {//get
    
    
    return new Promise((resolve, reject) => {
        const instance = axiosInstance();
        var targetUrl = encodeURLParameter(url,urlParamData);//urlAddRandom(url);
//        console.log('$ get url: ', targetUrl);
        //alert('$ get url: '+ targetUrl);
        instance.get(targetUrl).then(response => {
            console.log("$ get HTTP status/url: ", response.status, targetUrl);

            checkTokenTimeout(pageObj);

            if(response.status == HTTP_UNAUTHORIZED 
                || response.status == HTTP_UNAUTHORIZED+""){//Not login
                //goto login page
                unauthorized(pageObj);
            }            
            
            resolve(response.data);
        }).catch(error => {
            // console.error(error);
            

            if(error.response && (error.response.status == HTTP_UNAUTHORIZED 
                || error.response.status == HTTP_UNAUTHORIZED+"")){//Not login
                //goto login page
                unauthorized(pageObj);
            }

            try {
                reject(error.response.data);
            } catch (e) {
                reject(error.toString());
            }
        });
    });
}
export function get(url, pageObj,urlParamData) {//get
    if(pageObj){
        if(pageObj.showLoading){
            pageObj.showLoading(true);
        }else{
            //console.log("##get-begin pageObj.loadingCount: ", pageObj.loadingCount);
            if(!pageObj.loadingCount){
                pageObj.loadingCount = 0;
            }
            pageObj.loadingCount++;
        }
    }

    //check time out
//    checkClientTimeout(pageObj);
    
    return new Promise((resolve, reject) => {
        const instance = axiosInstance();
        var targetUrl = encodeURLParameter(url,urlParamData);//urlAddRandom(url);
//        console.log('$ get url: ', targetUrl);
        //alert('$ get url: '+ targetUrl);
        instance.get(targetUrl).then(response => {
            console.log("$ get HTTP status/url: ", response.status, targetUrl);

            checkTokenTimeout(pageObj);

            if(response.status == HTTP_UNAUTHORIZED 
                || response.status == HTTP_UNAUTHORIZED+""){//Not login
                //goto login page
                unauthorized(pageObj);
            }            
            if(pageObj){
                if(pageObj.showLoading){
                    pageObj.showLoading(false);
                }else{
                    //console.log("##get-end pageObj.loadingCount: ", pageObj.loadingCount);
                    pageObj.loadingCount--;
                }
            }
            resolve(response.data);
        }).catch(error => {
            // console.error(error);
            if(pageObj){
                if(pageObj.showLoading){
                    pageObj.showLoading(false);
                }else{
                    //console.log("##get-end pageObj.loadingCount: ", pageObj.loadingCount);
                    pageObj.loadingCount--;
                }
            }

            if(error.response && (error.response.status == HTTP_UNAUTHORIZED 
                || error.response.status == HTTP_UNAUTHORIZED+"")){//Not login
                //goto login page
                unauthorized(pageObj);
            }

            try {
                reject(error.response.data);
            } catch (e) {
                reject(error.toString());
            }
        });
    });
}

export function post(url, data, pageObj,urlParamData) {//post
    if(pageObj){
        if(pageObj.showLoading){
            pageObj.showLoading(true);
        }else{
            //console.log("##post-begin pageObj.loadingCount: ", pageObj.loadingCount);
            if(!pageObj.loadingCount){
                pageObj.loadingCount = 0;
            }
            pageObj.loadingCount++;
        }
    }

    //check time out
//    checkClientTimeout(pageObj);

    return new Promise((resolve, reject) => {
        const instance = axiosInstance();
        //url = urlAddRandom(url);
		url=encodeURLParameter(url,urlParamData);
        console.log('$ post url/data: ', url, data);
        instance.post(url, data).then(response => {
            console.log("$ post HTTP status/url: ", response.status, url);

            checkTokenTimeout(pageObj);

            if(response.status == HTTP_UNAUTHORIZED 
                || response.status == HTTP_UNAUTHORIZED+""){//Not login
                //goto login page
                unauthorized(pageObj);
            }
            //console.log(response);
            if(pageObj){
                if(pageObj.showLoading){
                    pageObj.showLoading(false);
                }else{
                    //console.log("##post-end pageObj.loadingCount: ", pageObj.loadingCount);
                    pageObj.loadingCount--;
                }
            }
            resolve(response.data);
        }).catch(error => {
            //console.error(error);
            if(pageObj){
                if(pageObj.showLoading){
                    pageObj.showLoading(false);
                }else{
                    //console.log("##post-end pageObj.loadingCount: ", pageObj.loadingCount);
                    pageObj.loadingCount--;
                }
            }

            if(error.response && (error.response.status == HTTP_UNAUTHORIZED 
                || error.response.status == HTTP_UNAUTHORIZED+"")){//Not login
                //goto login page
                unauthorized(pageObj);
            }

            try {
                if (error.response.data.errors) {
                    reject(error.response.data.errors[0].msg);
                } else if (error.response.data.message) {
                    reject(error.response.data.message);
                } else if (error.response.data) {
                    reject(error.response.data);
                }
            } catch (e) {
                reject(error.toString());
            }
        });
    });
}

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}
export function sleep(milliseconds) {
	  const date = Date.now();
	  let currentDate = null;
	  do {
	    currentDate = Date.now();
	  } while (currentDate - date < milliseconds);
	}

export function clearAccessToken() {
	localStorage.setItem(ACCESS_TOKEN_KEY, undefined);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY_TIMEOUT);
    localStorage.removeItem(TARGET_URL);
    localStorage.clear();
    window.localStorage.clear();
    window.sessionStorage.clear();
    sleep(1000);
}

// Get and store access_token in local storage
export function setAccessToken(accessToken) {
//    console.log("AuthService: setAccessToken() " + accessToken);
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(ACCESS_TOKEN_KEY_TIMEOUT, (new Date()).getTime());
}

export function setTargetUrl(url) {
    //console.log("AuthService: setTargetUrl() " + url);
    localStorage.setItem(TARGET_URL, url);
}

export function clearTargetUrl() {
    localStorage.removeItem(TARGET_URL);
    //console.log("AuthService: clearTargetUrl() ");
}

export function getTargetUrl() {
    var targetUrl = localStorage.getItem(TARGET_URL);
    //console.log("AuthService: getTargetUrl() " + targetUrl);
    return targetUrl;
}

export function refreshAccessTokenTime() {
	console.log("AuthService: refreshAccessTokenTime() " );
	setAccessTokenTime();
}

function setAccessTokenTime() {
    var isExpired = isTokenExpired();
    if(isExpired == false){
        localStorage.setItem(ACCESS_TOKEN_KEY_TIMEOUT, (new Date()).getTime());
    }else{
        console.error("@@@ had timeout, Cant not setAccessTokenTime .......");
    }
}


export function isClientTokenExpired() {
    console.log("@@@ isClientTokenExpired ...");
    var token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if(!token){
        console.log("@@@ isClientTokenExpired token is null");
        return true;
    }
    var lastAccessTime = localStorage.getItem(ACCESS_TOKEN_KEY_TIMEOUT);
    if(!lastAccessTime){
        console.log("@@@ isClientTokenExpired lastAccessTime is null");
        return true;
    }
    lastAccessTime = Number(lastAccessTime);
    lastAccessTime = lastAccessTime + CLIENT_TIMEOUT_SET * 60 * 1000;
    var now = (new Date()).getTime();
    console.log("@@@ isClientTokenExpired now/timeout: ", now, lastAccessTime);
    return now > lastAccessTime;
}

export function isTokenExpired() {
    //console.log("@@@ isTokenExpired ...");
    var token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if(!token){
        //console.log("@@@ isTokenExpired token is null");
        return false;
    }
    var lastAccessTime = localStorage.getItem(ACCESS_TOKEN_KEY_TIMEOUT);
    //console.log("@@@ lastAccessTime 1: ", lastAccessTime);
    if(!lastAccessTime){
        //console.log("@@@ isTokenExpired lastAccessTime is null");
        return false;
    }
    lastAccessTime = Number(lastAccessTime);
    lastAccessTime = lastAccessTime + CLIENT_TIMEOUT_SET * 60 * 1000;
    //console.log("@@@ lastAccessTime 2: ", lastAccessTime);
    var now = (new Date()).getTime();
    //console.log("@@@ lastAccessTime now/timeout: ", now, lastAccessTime);
    return now > lastAccessTime;
}

/*
export function isLoggedIn() {
    const accessToken = getAccessToken();
    return !!accessToken && !isTokenExpired(accessToken);
}


export function getTokenExpirationInMillisecond() {
    const token = getAccessToken();
    const expirationDate = getTokenExpirationDate(token);
    const now = new Date();
    if (!expirationDate) { return 0; }
    var ms = expirationDate.getTime() - now.getTime();
    return ms;
}

function getTokenExpirationDate(encodedToken) {
    const date = new Date(0);
    try {
        if (encodedToken) {
            const token = decode(encodedToken);
            if (!token) { return null; }
            if (!token.exp) { return null; }
            date.setUTCSeconds(token.exp);
        }
    } catch (e) {
        //console.log(e);
        return null;
    }
    return date;
}

function isTokenExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}

export function getLoginUserId() {
    const accessToken = getAccessToken();
    if (accessToken) {
        const token = decode(accessToken);
        if (!token.id) { return null; }
        return token.id;
    }
    return null;
}
*/
