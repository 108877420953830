import React, { Component } from 'react';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser, setUserPermission, setRoleCode } from '../../redux/actions';
import '../../index.css';
import HeaderPage from '.././HeaderPage';
import { sortingLabel, bind, dataPutAndGo, STATUS_, Separator, getDateStr, DATA_SAVE_MSG, getLabelFromList, removeRepeat, getDepartmentName, getPermission, removeFooter, isEmpty } from '../../Utils';
import { get, post, getAccessToken, sleep } from "../../services/AuthService";
import { refreshAccessTokenTime } from "../../services/AuthService";
import { Radio } from 'antd';
import MySelect from '../component/MySelect';
import {
	Form, Input, Row, Col, Button, Upload, Icon, message,
	Modal, Switch, List, ConfigProvider, Popconfirm, Tooltip
} from 'antd';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/table.css';
import BraftEditor from 'braft-editor';
import './css/mfb.css';
import { successStory_UI_CONFIG, DATA_UI_CONFIG, LEVEL_UI_CONFIG } from '../../../src/DataConfig'
import './css/SuccessStoryLeads.css';
import 'braft-extensions/dist/table.css';
import lodash from 'lodash';
import locale from 'antd/es/date-picker/locale/en_US';
import History from './History_new';
import MaxLength from 'braft-extensions/dist/max-length'
import MyDatePicker from '../component/MyDatePicker'
import { handlerMyDatePickerChange, } from '../component/EventUtil';
import { checkDataDiff } from '../../UtilDataDiff';
import logoBackW from './img/btnBack_w.png';
import logoBackG from './img/btnBack_g.png';
import logoCancelW from './img/btnCancel_w.png';
import logoCancelG from './img/btnCancel_g.png';
import logoSaveW from './img/btnSave_w.png';
import logoSaveG from './img/btnSave_g.png';
import logoCrossW from './img/btnCross_w.png';
import logoCrossG from './img/btnCross_g.png';
import logoPlusG from './img/plusG.png';
import logoPlusW from './img/plusW.png';
import logoMinusG from './img/minusG.png';
import logoMinusW from './img/minusW.png';
import logoRemoveG from './img/removeG.png';
import logoRemoveW from './img/removeW.png';
import smoothscroll from 'smoothscroll-polyfill';
import { Base64 } from 'js-base64';
smoothscroll.polyfill();

//

const { TextArea } = Input;
moment.locale('en');
const mapStateToProps = (state) => {
	return {
		userRoleCode: state.userRoleCode,
		currentLang: state.currentLang,
		loginUser: state.loginUser,
		userPermission: state.userPermission
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		setCurrentLang: (info) => {
			dispatch(setCurrentLang(info));
		},
		setLoginUser: (info) => {
			dispatch(setLoginUser(info));
		},
		setUserPermission: (info) => {
			dispatch(setUserPermission(info));
		},
		setRoleCode: (info) => {
			dispatch(setRoleCode(info));
		}
	};
}

const BraftEditor_MAX_INPUT_COUNT = 5000;
const scrollTimeout = 100;

class WriteSuccessStory extends Component {

	constructor(props) {
		super(props);

		this.control = {
			language: false,
			discloseToPublic: false,
			storyTier: false,
			typeOfSuccessCase: false,
			leadNatureGuiCase: false,
			kpiOversea: false,
			leadInfoDisc: false,
			devStatus: false,
			kpi: false,
			othertypeOfSuccessCase: false,
			pleaseDescribeTheLeadOrCase: false,
			locationInvolved: false,
			industry: false,
			topicTheme: false,
			attachments: false,
			url: false,
			companyBrandNames: false,
			headquartersLocation: false,
			isHongKongInd: false,
			isThereTdcInd: false,
			allEventControl: false,
			channel: false, usageDeleteButton: false, usageAttachment: false, usageUrl: false, description: false,
			emailReminder: false,
			emailReceiver: false,
			reminder: false,
			remarks: false,
		};


		this.state = {
			sessionOpenBasicInfo: true,
			sessionOpenCompanyInfo: false,
			sessionOpenHKTDCInvo: false,
			sessionOpenStoryDesc: false,
			sessionOpenStoryPublic: false,
			sessionOpenOtherInfo: false,
			editCharsLength: BraftEditor_MAX_INPUT_COUNT,
			gui: { approveTier1: false, approveTier2: false, approveTier3: false, radio1: true, radio2: false, radio3: false, radio4: false, radio5: false, radio01: true, radio02: false, radio03: false, reject01: true, reject02: false, mandatoryKpi: false, mandatoryKpiOversea: false },
			control1: true,
			titleMessage: "",
			editorState: BraftEditor.createEditorState(null),
			emailInputValidateStatus: "success",
			websiteInputValidateStatus: "success",
			kpiTopicControlList:[],
			isTooltipOpen:false,
			//check1 : false,check2 : false,check3 : false,check4 : false,check5 : false,check6 : false, check7 : false, check8 : false
			data: {
				//				attPrefixCode : new Date().getTime()+'_'+this.props.loginUser.loginId.replace('\\', '_'),
				usedLeadExternally: false,
				isThereTdcInd: true,
				sendSubRem: true,
				expfurdev: true,
				kpi: '',
				infoDisc:'',
				devStatus: '',
				emailReminder: 'NO',
				isHongKongInd: true,
				urlList: [],  /// new 
				serviceList: [{ eventType: null, eventName: null, eventId: null, eventDate: null }],
				companyInfoList: [{ companyName: null, hCountryName: null, hCountryId: null, cmpUrl: null, cmpIndusty: [] }],
				usageList: [{ Channel: null, otherChannel: null, AttachmentList: [], UrlList: [null], description: null }],
			},
			PageModel: 'write',
			showUsage: false,
			changesFlagValue: null,
			otherTypeOfSuccessCaseVisable: false,
			leadInforDiscVisable: false,
			
			isFirstTimeApproval: false

		};



		this.accessToken = getAccessToken();//for upload file

		this.DATA_DIFF = { thisKey: 'backupData', stateKey: 'data', compareSpecials: [] };
		this.CURRENT_PAGE_CODE = 'WriteSuccessStory';
		this.backupData = { usedLeadExternally: false, isThereTdcInd: true, isHongKongInd: true, otherTypeOfSuccessCase: null };
		bind(this, ['handlerInputChange2', 'searchRefData', 'handlerDepartmentSelectChange', 'saveHandler', 'handlerStatusSelectChange']);
		removeFooter();
	}

	componentDidMount() {
		var storyDescription = this.state.data.storyDescription;
		if (storyDescription) {
			this.setState({
				editorState: BraftEditor.createEditorState(storyDescription),
			})
		}

		window.scrollTo(0, 0);

	}

	componentWillUnmount() {
		if (this.state.timer != null) {
			clearInterval(this.state.timer);
		}
	}
	messageinfo(msg) {
		Modal.info({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}
	messagesuccess(msg) {
		Modal.success({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}
	messageerror(msg) {
		Modal.error({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}


	/* Save you data when logout */
	saveHandler = (callBackHandler, isTimeout) => {
		/* Save you data when logout */

		this.saveData(callBackHandler, isTimeout);

	}

	handleAddSubmit = () => {
		var value = this.state.data;
		//		if (this.state.emailInputValidateStatus != "success") {
		//			this.messageinfo("Please check email format.");
		//			
		//			return;
		//		}
		if (this.state.websiteInputValidateStatus != "success") {
			this.messageinfo("Please check website format.");

			return;
		}
		post('/sucessStroy/edit', value, this).then(data => {
			dataPutAndGo(this, '/successStoryLeads', {}, {}, false);
		});
	};

	goBack = () => {
		var thisObj = this;
		var backupData = this.backupData;
		var newData = this.state.data;
		
		if(this.state.isComingFromLeadStatusReport!=undefined){
			newData.isComingFromLeadStatusReport="Y";
		}
						
						
		var isUpdated = checkDataDiff(backupData, newData, []);
		if (isUpdated) {
			Modal.confirm({
				title: 'Confirm',
				content: (<span className="dataDiffMessage">{DATA_SAVE_MSG}</span>),
				okText: 'OK',
				cancelText: 'Cancel',
				onOk() {
					thisObj.saveData();
				},
				onCancel() {

					if (newData.id && newData.id > 0) {  
						dataPutAndGo(thisObj, '/successStoryLeads/storyDetail', newData, null, false);
					} else {
						dataPutAndGo(thisObj, '/successStoryLeads', null, null, false);
					}
				},
			});
		} else {
			if (newData.id && newData.id > 0) {
				dataPutAndGo(this, '/successStoryLeads/storyDetail', newData, null, false);
			} else {
				dataPutAndGo(this, '/successStoryLeads', null, null, false);
			}
		}
	}

	goCurSave = () => {
		
		var thisObj = this;

		thisObj.saveData();

	}

	handleEditorChange = (editorState) => {

		refreshAccessTokenTime();

		var data = this.state.data;
		const textString = editorState.toText();
		console.log("handleEditorChange", textString);
		//var noTagValue = getTextFromHtml(textString);
		//var lengthc = this.state.editCharsLength;
		var lengthc = BraftEditor_MAX_INPUT_COUNT;
		if (textString) {
			lengthc = BraftEditor_MAX_INPUT_COUNT - textString.length;
		}
		//BraftEditor.createEditorState(storyDescription)
		if (lengthc < 0) {
			//			message.config({ top: '10%' });
			this.setState({ titleMessage: 'Up to ' + BraftEditor_MAX_INPUT_COUNT + ' characters can be entered.' });
			setTimeout(() => {
				this.setState({ titleMessage: '' })
			}, 10000);
			//			this.messageerror("Up to "+BraftEditor_MAX_INPUT_COUNT+" characters can be entered.");
			this.setState({
				editorState: BraftEditor.createEditorState(data.storyDescription.substring(0, BraftEditor_MAX_INPUT_COUNT - 1)),
			})
			return;
		}
		data.storyDescription = editorState.toHTML();
		var storyDescriptionText = editorState.toText();
		this.setState({
			editCharsLength: lengthc,
			editorState,
			data: data,
			storyDescriptionText: storyDescriptionText
		})
	}

	splitUrlList = (str) => {
		if (!str) {
			str = "";
		}
		var urlList = str.split(',');
		var newList = [];
		for (var i = 0; i < urlList.length; i++) {
			newList.push(urlList[i]);
		}
		return newList;
	}

	splitUrlList2 = (str) => {
		if (!str) {
			str = "";
		}
		var urlList = str.split(',');
		if (urlList && urlList.length == 1) {
			return [];
		}
		var newList = [];
		for (var i = 0; i < urlList.length; i++) {
			if (i != 0) {
				newList.push({ value: urlList[i] });
			}
		}
		return newList;
	}

	getListUrl0 = (str) => {
		if (!str) {
			str = "";
		}
		var urlList = str.split(',');
		if (urlList && urlList.length >= 1) {
			return urlList[0];
		} else {
			return null;
		}
	}


	disableControlBystatus = (item) => {
		var status = item.status;
		var control = this.control;
		if (status != STATUS_.New && status != STATUS_.Reopened && status != undefined) {
			control.reminder = true;
			this.control = control;
		}
	}


	disableControl = (item) => {

		var loginUser = this.props.loginUser;
		if (!loginUser) {
			loginUser = {};
		}
		var roleCode = loginUser.roleId;

		if (roleCode != 1 && roleCode != 2) {
			let control = {
				storyTier: true
			}
			this.control = control;
		}

		var dataType1 = item.dataType1;
		if (dataType1 && dataType1 == "updateUsageOnly") {
			let control = {
				language: true,
				discloseToPublic: true,
				storyTier: true,
				typeOfSuccessCase: true,
				leadNatureGuiCase: true,
				kpiOversea: true,
				leadInfoDisc: true,
				devStatus: true,
				kpi: true,
				othertypeOfSuccessCase: true,
				pleaseDescribeTheLeadOrCase: true,
				locationInvolved: true,
				industry: true,
				topicTheme: true,
				attachments: true,
				url: true,
				companyBrandNames: true,
				headquartersLocation: true,
				isHongKongInd: true,
				isThereTdcInd: true,
				allEventControl: true,
				emailReminder: true,
				emailReceiver: true,
				reminder: true,
				remarks: true,
			}
			this.control = control;
		}

	}



	componentWillMount() {

		//dataGet(this, this.getStateData);

		var dataObj = this.props.location.query;
		if (!dataObj) {
			dataObj = {};
		}
		var stateData = dataObj.stateData;
		if (!stateData) {
			stateData = {};
			stateData.item = {};
		}
		
		
		if(stateData.item.isComingFromLeadStatusReport!=undefined){
			
			this.state.isComingFromLeadStatusReport=stateData.item.isComingFromLeadStatusReport;
		
		
		}
		
		get(LEVEL_UI_CONFIG.url + '/ssls/getKpiTopicControl', this).then(data => {

			if (data) {				

				this.state.kpiTopicControlList = data;
			}

		}).catch((e) => {
			console.log(e);
		});


		get(LEVEL_UI_CONFIG.url + '/searchTagging/SearchAllEventType', this).then(data => {


			if (data && data.returnType == "SUCESS") {

				var HKTDCCoulumList = [];


				HKTDCCoulumList = data.types.map((item) => {

					item.label = item.eventTypeEn;
					item.value = item.eventTypeEn;
					item.labelTC = item.eventTypeTc;
					item.labelSC = item.eventTypeSc;
					item.labelEN = item.eventTypeEn;
					return item;
				});

				var othersItem = undefined;

				sortingLabel(HKTDCCoulumList);

				HKTDCCoulumList = HKTDCCoulumList.filter(function (item) {
					if (item.label == "Others") {
						othersItem = item;
					}

					return (item.status == 'Active' && item.label != "Others");
				});
				if (othersItem != undefined) {
					HKTDCCoulumList.push(othersItem);
				}




				this.state.HKTDCList = HKTDCCoulumList;

			}

		}).catch((e) => {
			console.log(e);
		});




		get(LEVEL_UI_CONFIG.url + '/searchTagging/SearchAllChannels', this).then(data => {


			if (data && data.returnType == "SUCESS") {

				var tempList = [];
				var othersItem = undefined;
				
				var anotherItem = undefined;

				tempList = data.channels.map((item) => {



					item.label = item.channelNameEn;
					item.value = item.channelNameEn;
					return item;
				});

				tempList = tempList.filter(function (item) {
					if (item.label == "Others") {
						othersItem = item;
					}
					if (item.label == "Another HKTDC Platform") {
						anotherItem = item;
					}

					return (item.status == 'Active' && item.label != "Others" && item.label != "Another HKTDC Platform");
				});
				sortingLabel(tempList);
				
				
				
				var tempList2 = [];
				
				
				tempList.map((item) => {

					tempList2.push(	item);
					if (item.label == "HKTDC Website/App (other than HKMB)") {
						tempList2.push(	anotherItem);
					}
					
					return item;
				});


				if (othersItem != undefined) {
					tempList2.push(othersItem);
				}
				this.state.ChannelList = tempList2;


			}

		}).catch((e) => {
			console.log(e);
		});









		const options = {
			defaultValue: BraftEditor_MAX_INPUT_COUNT, // 
			height: 200,
			includeEditors: ['editor-id-1'], // 
		}
		BraftEditor.use(MaxLength(options));



		var data1 = stateData.item;
		var returnMsg = data1.returnMsg;
		this.disableControl(data1);

		var PageModel = this.state.PageModel;
		var usageList = this.state.data.usageList;
		//var departmentName = null;
		var curId = data1.id;
		var loginUser = this.props.loginUser;
		var parName = loginUser.userName;
		if (curId) {




			this.state.timer = setInterval(() => {


				var showLoadingBkVal = this.showLoading;
				this.showLoading = false;
				get(successStory_UI_CONFIG.url + '/ssls/searchByInterval?id=' + curId + '&&name=' + parName + '&&status=1', this).then(result => {
				});
				this.showLoading = showLoadingBkVal;

			}, 60000)
		}

		//		let departmentName = getDepartmentName(this.props);
		if (data1.id != undefined && data1.id != null && data1.id != "") {
			PageModel = "edit";
			get(successStory_UI_CONFIG.url + '/ssls/searchByidAndEditMode?id=' + data1.id + '&&name=' + parName + '&&status=1', this).then(result => {

				if (result.returnType != "SUCESS") {
					return;
				}

				let data = result.entity;
				

				console.log("data ", data)
				this.isFirstTimeApproval = result.firstTimeApproval;

				let changesFlagValue = null;
				if (data1.dataType1 === "Copy") {
					PageModel = 'write';
					data.id = null;
					data.leadId = null;
					data.createdDateTime = null; data.createdBy = null; data.department = null;
					data.lastModifiedBy = null; data.lastModifiedDateTime = null;
					data.publishedDate = null;
					data.reportPublishedDate = null;
					data.status = null;
					data.contentApprover = null;
					data.changesFlag = null;
					data.submittedBy = null;
					data.submittedDateTime = null;
					data.submittedByDept = null;
					data.updateByDept = null;
					data.rejectReopenRemake = null;
					data.editModeDate = null;
					data.editMode = null;
					data.versionStamp = null;
					data.createdByUserEntity = null;
					data.attPrefixCode = null;
					this.isFirstTimeApproval = false;
					if (data.attPrefixCode == undefined || data.attPrefixCode == '') {
						data.attPrefixCode = new Date().getTime() + '_' + this.props.loginUser.loginId.replace('\\', '_');
					}

					if (data.usageContentUpdated === true && (data.channels1 || data.usageAttachment1 ||
						data.usageUrl1 || data.channelDesc1)) {
						data.usageUpdatedInd = 'Y';
					}
				} else {
					changesFlagValue = data.leadId
				}


				var typeOfSuccessVal = [];
				var locationVal = [];
				var industryVal = [];
				var topiclVal = [];
				var leadNatureVal = [];


				if (data.typeOfSuccessCaseId != null) {
					typeOfSuccessVal.push(data.typeOfSuccessCaseId);	//typeOfSuccessCaseId
				}
				if (data.typeOfSuccessCaseId1 != null) {
					typeOfSuccessVal.push(data.typeOfSuccessCaseId1); //typeOfSuccessCaseId1
				}
				if (data.typeOfSuccessCaseId2 != null) {
					typeOfSuccessVal.push(data.typeOfSuccessCaseId2); //typeOfSuccessCaseId2
				}

				if (data.typeOfSuccessCaseId3 != null) {
					typeOfSuccessVal.push(data.typeOfSuccessCaseId3); //typeOfSuccessCaseId3
				}

				if (data.typeOfSuccessCaseId4 != null) {
					typeOfSuccessVal.push(data.typeOfSuccessCaseId4); //typeOfSuccessCaseId4
				}


				if (data.location1 != null) {
					locationVal.push(data.locationId1);	//industry1
				}
				if (data.location2 != null) {
					locationVal.push(data.locationId2); //industry2
				}
				if (data.location3 != null) {
					locationVal.push(data.locationId3); //industry3
				}

				if (data.location4 != null) {
					locationVal.push(data.locationId4); //industry4
				}

				if (data.location5 != null) {
					locationVal.push(data.locationId5); //industry5
				}

				if (data.industry1 != null) {
					industryVal.push(data.industryId1); //industry1
				}
				if (data.industry2 != null) {
					industryVal.push(data.industryId2); //industry2
				}
				if (data.industry3 != null) {
					industryVal.push(data.industryId3); //industry3
				}
				if (data.industry4 != null) {
					industryVal.push(data.industryId4); //industry4
				}
				if (data.industry5 != null) {
					industryVal.push(data.industryId5); //industry5
				}

				if (data.topicTheme1 != null) {
					topiclVal.push(data.topicTheme1);
				}
				if (data.topicTheme2 != null) {
					topiclVal.push(data.topicTheme2);
				}
				if (data.topicTheme3 != null) {
					topiclVal.push(data.topicTheme3);
				}
				if (data.topicTheme4 != null) {
					topiclVal.push(data.topicTheme4);
				}
				if (data.topicTheme5 != null) {
					topiclVal.push(data.topicTheme5);
				}
				if (data.leadNatureCase != null) {
					leadNatureVal.push(parseInt(data.leadNatureCase));	//leadNatureCase
				}
				if (data.leadNatureCase1 != null) {
					leadNatureVal.push(parseInt(data.leadNatureCase1)); //leadNatureCase1
				}
				if (data.leadNatureCase2 != null) {
					leadNatureVal.push(parseInt(data.leadNatureCase2)); //leadNatureCase2
				}

				data.location = locationVal;
				data.industry = industryVal;
				data.topicl = topiclVal;
				
				let hasFocus3Deals = false;				
					
				data.topicl.map((item) => {			

					if (item &&
						//(item == "Focus 3 Deals")
						(this.state.kpiTopicControlList.includes(item))
						) {
							hasFocus3Deals = true;					
						
					}
				
				});				

				data.typeOfSuccesses = typeOfSuccessVal;
				data.leadNature = leadNatureVal
				//    var typeOfSuccessCaseId = data.typeOfSuccessCaseId;			
				//    data.typeOfSuccessCase = typeOfSuccessCaseId;

				var editorState = BraftEditor.createEditorState(data.storyDescription != undefined ? data.storyDescription : "");

				//    this.setState({
				// 	editorState: BraftEditor.createEditorState(storyDescription),
				// 	})

				if (data.isHongKongInd != undefined && (data.isHongKongInd == false || data.isHongKongInd == "false")) {
					data.isHongKongInd = false;
				}
				if (data.isThereTdcInd != undefined && (data.isThereTdcInd == false || data.isThereTdcInd == "false")) {
					data.isThereTdcInd = false;
				}
				var usedLeadExternally = data.usedLeadExternally;
				if (usedLeadExternally != undefined && (usedLeadExternally == false || usedLeadExternally == "false")) {
					data.usedLeadExternally = false;
				}
				var usageContentUpdated = data.usageContentUpdated;
				if (usageContentUpdated != undefined && (usageContentUpdated == false || usageContentUpdated == "false")) {
					data.usageContentUpdated = false;
				} else {
					data.usageContentUpdated = true;
				}

				var expfurdev = data.expfurdev;
				if (expfurdev != undefined && (expfurdev == false || expfurdev == "false")) {
					data.expfurdev = false;
				} else {
					data.expfurdev = true;
				}

				var sendSubRem = data.sendSubRem;
				
				var reminderEmailSch = data.reminderEmailSch;
				var reminderEmailAtt = data.reminderEmailAtt;
				
				switch (reminderEmailSch){
				  case "MONTHLY":
					
					if (reminderEmailAtt == 'Y'){
						data.emailReminder='MNATT';
					}else{
						data.emailReminder='MN';
					}
					
					break;
				  case "WEEKLY":
					if (reminderEmailAtt == 'Y'){
						data.emailReminder='WKATT';
					}else{
						data.emailReminder='WK';
					}
					
					break;
				  default:
					data.emailReminder='NO';
				}
				
				
				
				


				usedLeadExternally = data.usedLeadExternally;
				if (usedLeadExternally) {
					var newusageList = [];
					for (var i = 0; i < 10; i++) {
						var channels = "channels";
						var otherChannel = "otherChannel";
						var usageAttachment = "usageAttachment";
						var usageUrl = "usageUrl";
						var channelDesc = "channelDesc";

						otherChannel += (i + 1);
						channels += (i + 1);
						usageAttachment += (i + 1);
						usageUrl += (i + 1);
						channelDesc += (i + 1);

						if (!this.isEmpty(data[channels]) || !this.isEmpty(data[usageAttachment]) || !this.isEmpty(data[usageUrl]) || !this.isEmpty(data[channelDesc])) {
							newusageList.push({
								Channel: data[channels],
								otherChannel: data[otherChannel],
								AttachmentList: data1.dataType1 != "Copy" ? this.genFileList(data.attPrefixCode, data[usageAttachment], (i + 1)) : null,
								UrlList: this.splitUrlList(data[usageUrl]),
								description: data[channelDesc]
							}
							);
						}
					}
					if (newusageList.length > 0) {
						usageList = newusageList;
					}
				}

				if (data1.dataType1 != "Copy") {
					data.fileList = this.genFileList(data.attPrefixCode, data.attachment, "file");
				}



				//Company Info set
				var loadCompanyInfoList = [];

				//First Company Info
				if (data.companyName != null && data.companyName != '') {
					var companyInfoVar = {
						companyName: data.companyName, hCountryName: data.headquartersCountry,
						hCountryId: data.headquartersCountryId, cmpUrl: data.url, cmpIndusty: []
					};
					if (data.cmpInd01Id != undefined & data.cmpInd01Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd01Id);
					}
					if (data.cmpInd02Id != undefined & data.cmpInd02Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd02Id);
					}
					if (data.cmpInd03Id != undefined & data.cmpInd03Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd03Id);
					}
					if (data.cmpInd04Id != undefined & data.cmpInd04Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd04Id);
					}
					if (data.cmpInd05Id != undefined & data.cmpInd05Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd05Id);
					}
					loadCompanyInfoList.push(companyInfoVar);
				}

				//Second Company Info
				if (data.companyName1 != null && data.companyName1 != '') {
					var companyInfoVar = {
						companyName: data.companyName1, hCountryName1: data.headquartersCountry1,
						hCountryId: data.headquartersCountryId1, cmpUrl: data.url1, cmpIndusty: []
					};
					if (data.cmpInd11Id != undefined & data.cmpInd11Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd11Id);
					}
					if (data.cmpInd12Id != undefined & data.cmpInd12Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd12Id);
					}
					if (data.cmpInd13Id != undefined & data.cmpInd13Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd13Id);
					}
					if (data.cmpInd14Id != undefined & data.cmpInd14Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd14Id);
					}
					if (data.cmpInd15Id != undefined & data.cmpInd15Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd15Id);
					}
					loadCompanyInfoList.push(companyInfoVar);
				}

				//Third Company Info
				if (data.companyName2 != null && data.companyName2 != '') {
					var companyInfoVar = {
						companyName: data.companyName2, hCountryName2: data.headquartersCountry2,
						hCountryId: data.headquartersCountryId2, cmpUrl: data.url2, cmpIndusty: []
					};
					if (data.cmpInd21Id != undefined & data.cmpInd21Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd21Id);
					}
					if (data.cmpInd22Id != undefined & data.cmpInd22Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd22Id);
					}
					if (data.cmpInd23Id != undefined & data.cmpInd23Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd23Id);
					}
					if (data.cmpInd24Id != undefined & data.cmpInd24Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd14Id);
					}
					if (data.cmpInd25Id != undefined & data.cmpInd25Id != '') {
						companyInfoVar.cmpIndusty.push(data.cmpInd25Id);
					}
					loadCompanyInfoList.push(companyInfoVar);
				}




				data.companyInfoList = loadCompanyInfoList;

				var urlList = this.splitUrlList2(data.url);


				var serviceList = this.state.data.serviceList;
				var eventDate1 = data.eventDate1;
				var eventName1 = data.eventName1;
				var eventId1 = data.eventId1;
				var eventType1 = data.eventType1;
				if (eventDate1 != null || eventName1 != null || eventType1 != null || eventId1 != null) {
					serviceList = [];
					serviceList.push({ eventType: eventType1, eventName: eventName1, eventId: eventId1, eventDate: eventDate1 });
				}

				var eventDate2 = data.eventDate2;
				var eventName2 = data.eventName2;
				var eventId2 = data.eventId2;
				var eventType2 = data.eventType2;
				if (eventDate2 != null || eventName2 != null || eventType2 != null || eventId2 != null) {
					serviceList.push({ eventType: eventType2, eventName: eventName2, eventId: eventId2, eventDate: eventDate2 });
				}

				var eventDate3 = data.eventDate3;
				var eventName3 = data.eventName3;
				var eventId3 = data.eventId3;
				var eventType3 = data.eventType3;
				if (eventDate3 != null || eventName3 != null || eventType3 != null || eventId3 != null) {
					serviceList.push({ eventType: eventType3, eventName: eventName3, eventId: eventId3, eventDate: eventDate3 });
				}

				var eventDate4 = data.eventDate4;
				var eventName4 = data.eventName4;
				var eventId4 = data.eventId4;
				var eventType4 = data.eventType4;
				if (eventDate4 != null || eventName4 != null || eventType4 != null || eventId4 != null) {
					serviceList.push({ eventType: eventType4, eventName: eventName4, eventId: eventId4, eventDate: eventDate4 });
				}

				var eventDate5 = data.eventDate5;
				var eventName5 = data.eventName5;
				var eventId5 = data.eventId5;
				var eventType5 = data.eventType5;
				if (eventDate5 != null || eventName5 != null || eventType5 != null || eventId5 != null) {
					serviceList.push({ eventType: eventType5, eventName: eventName5, eventId: eventId5, eventDate: eventDate5 });
				}

				// var att1 = [];
				// var usageAttachment1 = data.usageAttachment1;
				// if(usageAttachment1 ){
				// 	var attList = usageAttachment1.split(Separator);
				// 	var fileList = []; 
				// 	attList.map((item,index)=>{
				// 		fileList.push({
				// 			uid: index,
				// 			 name: item,
				// 			 status: 'done',
				// 			 url: BLOB_UI_CONFIG.url+ item,
				// 		});
				// 	});
				// 	att1 = fileList;
				// }
				// this.setState({
				// 	att1 : att1
				// });

				let language = data.language;
				let discloseToPublic = data.discloseToPublic;
				
				let gui = this.state.gui;
				console.log("Check KPI: ", data.kpi, data.kpiOversea);
				
				gui.mandatoryKpi=false;
				if(hasFocus3Deals){
					gui.mandatoryKpiOversea=true;
					
					this.control.kpi = true;	
					data.kpi='';
				}
				
				

				gui.radio01 = false; gui.radio02 = false; gui.radio03 = false;
				gui.radio1 = false; gui.radio2 = false; gui.radio3 = false;
				gui.radioKpiYes= false; gui.radioKpiNo = false;
				

				if (language == "EN") {
					gui.radio01 = true;
				} else if (language == "TC") {
					gui.radio02 = true;
				} else if (language == "SC") {
					gui.radio03 = true;
				}

				var defaultLanguage = "EN";
				language = data.language;
				if (language != undefined) {
					defaultLanguage = data.language;
				}

				if (discloseToPublic == "Disclosable") {
					gui.radio1 = true;
				} else if (discloseToPublic == "Cannot be Disclosed") {
					gui.radio2 = true;
				} else if (discloseToPublic == "Partially disclosable") {
					gui.radio3 = true;
				}
				

				//var gui = this.state.gui;
				if (data.leadId == "1") {
					gui.approveTier1 = true;
				} else if (data.leadId == "2") {
					gui.approveTier2 = true;
				} else if (data.leadId == "3") {
					gui.approveTier3 = true;
				}

				var emailReceiver = data.emailReceiver;
				var emailReceiverList = [];
				if (emailReceiver != undefined && emailReceiver.length > 0) {
					emailReceiverList = emailReceiver.split(Separator);
				}
				
				
				var infoDiscList = [];
				if (data.infoDisc != undefined && data.infoDisc.length > 0) {
					data.infoDisc.split("/").map((item)=>{
						if(item!=undefined && item.length>0){
							infoDiscList.push(parseInt(item));
						}
					});
				}

				this.initComboBox(defaultLanguage); // defaut use English


				var showUsage = false;
				var showSendSubRem = false;
				var userRoleCode = this.props.userRoleCode;
				if (userRoleCode === "1" || userRoleCode === "2") {
					showUsage = true;
					
				}
				showSendSubRem = true;

				//data2.otherTypeOfSuccessCase = null;
				var status = data.status;
				var titleMessage = "";
				var selfTitle = "Success story lead ID #" + data.id;
				if (status == STATUS_.Reviewing) {
					titleMessage = "Success story lead ID #" + data.id + " being reviewed by "+data.lastModifiedBy.split('(')[0]+".";
				} else if (status === STATUS_.Submitted) {
					titleMessage = "Success story lead ID #" + data.id + " is submitted.";
				}
				else if (status == STATUS_.Reopened) {
					if (returnMsg) {
						titleMessage = returnMsg;
					} else {
						titleMessage = "Success story lead ID #" + data.id + " is reopened.";
					}

				}
				else if (status == STATUS_.Approved) {
					titleMessage = "Success story lead ID #" + data.id + " is approved.";
				}
				else if (status == STATUS_.Published) {
					titleMessage = "Success story lead ID #" + data.id + " is published.";
				}
				else if (status == STATUS_.Archived) {
					titleMessage = "Success story lead ID #" + data.id + " is archived.";
				} else if (status == STATUS_.Closed) {
					titleMessage = "Success story lead ID #" + data.id + " is closed.";
				} else if (!data.id) {
					selfTitle = "Create story lead";
				}


				data.urlList = urlList;
				data.serviceList = serviceList;
				data.usageList = usageList;
				data.emailReceiverList = emailReceiverList;
				data.infoDiscList = infoDiscList;


				if (data.attPrefixCode == '' || typeof data.attPrefixCode == 'undefined') {
					this.state.data.attPrefixCode = new Date().getTime() + '_' + this.props.loginUser.loginId.replace('\\', '_');
				}

				this.disableControlBystatus(data);

				this.originalStatus = data.status;
				this.backupData = this.copyJson(data);

				this.setState({
					changesFlagValue: changesFlagValue,
					editorState: editorState,
					data: data,
					gui: gui,
					PageModel: PageModel,
					showUsage: showUsage,
					showSendSubRem: showSendSubRem,
					titleMessage: titleMessage,
					selfTitle: selfTitle,
				});
				setTimeout(() => {
					this.setState({ titleMessage: '' })
				}, 10000);
			});

		} else {
			this.initComboBox("EN"); // defaut use English

			let showUsage = false;
			let showSendSubRem = false;
			let userRoleCode = this.props.userRoleCode;
			if (userRoleCode === "1" || userRoleCode === "2") {
				showUsage = true;
				
			}
			showSendSubRem = true;


			this.setState({
				showUsage: showUsage,
				showSendSubRem: showSendSubRem
			});



		}
		if (this.state.data.attPrefixCode == '' || typeof this.state.data.attPrefixCode == 'undefined') {
			this.state.data.attPrefixCode = new Date().getTime() + '_' + this.props.loginUser.loginId.replace('\\', '_');
		}


		var datacp = this.state.data;
		this.backupData = this.copyJson(datacp);
	}

	copyJson = (baseData) => {
		var datasStr = JSON.stringify(baseData);
		if (datasStr) {
			return JSON.parse(datasStr);
		} else {
			return null;
		}
	}

	genFileList = (dirStr, fileListStr, index) => {
		var attachments = fileListStr;
		if (attachments) {
			var attachmentList = attachments.split(Separator);
			var fileList = [];
			attachmentList.map((item, subIndex) => {
				fileList.push({
					uid: subIndex,
					name: item,
					status: 'done',
					url: successStory_UI_CONFIG.url + '/file/down?dirStr=' + dirStr + '&name=' + item,
					header: { 'X-Requested-With': 'XMLHttpRequest', 'Authorization': this.accessToken },
				});
			});
			// if(index == "file" ){
			// 	var data = this.state.data;
			// 	data.fileList = fileList;
			// 	this.setState({
			// 		data : data
			// 	});
			// 	return null;
			// }else{

			// }

			return fileList;
		}
	}

	initComboBox = (lantype) => {

		get(LEVEL_UI_CONFIG.url + '/searchAllreferenceDataByCreate', this).then(data => {
			this.comboxData = data;
			this.changeLanguageType(lantype);
		});

		get(DATA_UI_CONFIG.url + '/searchAllDepartment', this).then(data => {
			if (data.returnType == "SUCCESS") {
				var departmentList = data.users.map((item) => {
					item.value = item.name;
					item.label = item.name;
					return item;
				});
				this.setState({ departmentList: departmentList });
			}
		});

		// get(  DATA_UI_CONFIG.url + '/userServer/searchAllEmail?email=' , this).then(data => {
		// 	if(data.users){
		// 	   var emailList = data.users.map((item) => {
		// 		   item.label = item.email;
		// 		   item.value = item.email;
		// 		  return item;
		// 	  });
		// 	  this.setState({  ccList: removeRepeat(emailList)	});
		// 	}
		//  });


	}


	changeLanguageType = (lantype) => {
		var typeOfSuccessCaseList = [];
		var typeOfLeadDiscList = [];
		var devStatusList = [{value:'Developed', label:'Developed'},{value:'Developing', label:'Developing'}];
		var IndustryList = [];
		var leadNatureList = [];
		var locationList = [];
		var TopicList = [];
		var eventList = [];
		var eventTypeList = [];

		var data = this.comboxData;


		if (!data) {
			data = [];
		}
		if (data.message == "new error") {
			return;
		}


		// var tempTopicList = [];
		// if(data.datas5){
		// 	data.datas5.map((item) => {
		// 		if(item.status == "Active" ){
		// 			if(lantype == item.language){
		// 				tempTopicList.push(item);
		// 			}
		// 		}
		// 	})
		// }



		if (lantype == 'EN') {
			typeOfLeadDiscList = data.leadInfoDiscList.map((item) => {
				item.value = item.id;
				item.label = item.nameEn;
				return item;
			});
			
			leadNatureList = data.leadNatureList.map((item) => {
				item.value = item.id;
				item.label = item.nameEn;
				return item;
			});
			
			typeOfSuccessCaseList = data.typeOfSuccessCaseList.map((item) => {
				item.value = item.id;
				item.label = item.nameEn;
				return item;
			});

			IndustryList = data.industryList.map((item) => {
				item.value = item.id;
				item.label = item.nameEn;
				return item;
			});

			IndustryList = IndustryList.filter(function (item) {
				return item.label != '' && item.label != 'undefined' && item.label;
			});

			if (data.topicThemeList != undefined) {
				TopicList = data.topicThemeList.map((item) => {
					item.value = item.topicTheme;
					item.label = item.topicTheme;
					item.similar = item.similarWords;
					return item;
				});


				TopicList = TopicList.filter(function (item) {
					return item.label != '' && item.label != 'undefined' && item.label && item.language == 'EN';
				});
				TopicList = removeRepeat(TopicList);
			}

			locationList = data.locationList.map((item) => {
				item.value = item.id;
				item.label = item.nameEn;
				item.similar =  (item.nameEn.toLowerCase().indexOf("mainland china")==0?"china":item.nameEn);
				return item;
			});

			locationList = locationList.filter(function (item) {
				return item.label != '' && item.label != 'undefined' && item.label;
			});



			// TopicList = tempTopicList.map((item) => {
			// 		item.value = item.topicTheme;
			// 		item.label = item.topicTheme;
			// 		return item;
			// });
			eventList = data.eventList.map((item) => {
				item.value = item.id;
				item.label = item.eventNameEn;
				return item;
			});


			eventList = eventList.filter(function (item) {


				return item.label != '' && item.label != 'undefined' && item.label;
			});






		} else if (lantype == 'TC') {
			typeOfLeadDiscList = data.typeOfLeadDiscList.map((item) => {
				item.value = item.id;
				item.label = item.nameTc;
				return item;
			});
			
			leadNatureList = data.leadNatureList.map((item) => {
				item.value = item.id;
				item.label = item.nameTc;
				return item;
			});
			
			typeOfSuccessCaseList = data.typeOfSuccessCaseList.map((item) => {
				item.value = item.id;
				item.label = item.nameTc;
				return item;
			});

			IndustryList = data.industryList.map((item) => {
				item.value = item.id;
				item.label = item.nameTc;
				return item;
			});
			locationList = data.locationList.map((item) => {
				item.value = item.id;
				item.label = item.nameTc;
				return item;
			});
			if (!isEmpty(data.tcTopicThemeList)) {
				TopicList = data.tcTopicThemeList.map((item) => {
					item.value = item.topicTheme;
					item.label = item.topicTheme;
					item.similar = item.similarWords;
					return item;
				});
				TopicList = TopicList.filter(function (item) {
					return item.label != '' && item.label != 'undefined' && item.label && item.label && item.language == 'TC';
				});
			}

			// TopicList = tempTopicList.map((item) => {
			// 		item.value = item.topicTheme;
			// 		item.label = item.topicTheme;
			// 		return item;
			// });

			eventList = data.eventList.map((item) => {
				item.value = item.id;
				item.label = item.eventNameTc;
				return item;
			});
			eventList = eventList.filter(function (item) {


				return item.label != '' && item.label != 'undefined' && item.label;
			});


		} else if (lantype == 'SC') {
			typeOfLeadDiscList = data.typeOfLeadDiscList.map((item) => {
				item.value = item.id;
				item.label = item.nameSc;
				return item;
			});
			
			
			
			leadNatureList = data.leadNatureDiscList.map((item) => {
				item.value = item.id;
				item.label = item.nameSc;
				return item;
			});

			IndustryList = data.industryList.map((item) => {
				item.value = item.id;
				item.label = item.nameSc;
				return item;
			});
			locationList = data.locationList.map((item) => {
				item.value = item.id;
				item.label = item.nameSc;
				return item;
			});
			if (!isEmpty(data.scTopicThemeList)) {
				TopicList = data.scTopicThemeList.map((item) => {
					item.value = item.topicTheme;
					item.label = item.topicTheme;
					item.similar = item.similarWords;
					return item;
				});
				TopicList = TopicList.filter(function (item) {
					return item.label != '' && item.label != 'undefined' && item.label && item.label && item.language == 'SC';
				});
			}
			// TopicList = tempTopicList.map((item) => {
			// 		item.value = item.topicTheme;
			// 		item.label = item.topicTheme;
			// 		return item;
			// });
			eventList = data.eventList.map((item) => {
				item.value = item.id;
				item.label = item.eventNameSc;
				return item;

			});

			eventList = eventList.filter(function (item) {


				return item.label != '' && item.label != 'undefined' && item.label;
			});


		}




		// get(  LEVEL_UI_CONFIG.url + '/searchEvent?name='+ "", this).then(data => {
		// 	var newList = [];
		// 	if(data.returnType == "SUCCESS" ){
		// 		data.users.map((item) => {
		// 			 newList.push({label:item.eventNameEn,value:item.eventNameEn,event:item.eventType});
		// 			});
		// 	}
		//     this.setState({
		//         searchList: newList
		//     });
		//  });

		if (IndustryList) sortingLabel(IndustryList);
		if (locationList) sortingLabel(locationList);
		if (TopicList) sortingLabel(TopicList);
		if (typeOfSuccessCaseList) sortingLabel(typeOfSuccessCaseList);
		if (typeOfLeadDiscList) sortingLabel(typeOfLeadDiscList);
		if (leadNatureList) sortingLabel(leadNatureList);

		var othersItem = undefined
		var othersItemInfoDisc = undefined;

		typeOfSuccessCaseList = typeOfSuccessCaseList.filter(function (item) {
			if (item.label == "Others") {
				othersItem = item;
			}

			return (item.status == 'Active' && item.label != "Others");
		});
		
		if (othersItem != undefined) {
			typeOfSuccessCaseList.push(othersItem);
		}
		
		
		leadNatureList = leadNatureList.filter(function (item) {
			if (item.label == "Other HK success cases") {
				othersItemInfoDisc = item;
			}

			return (item.status == 'Active' && item.label != "Other HK success cases");
		});
		console.log("leadNatureList", leadNatureList, othersItemInfoDisc);
		if (othersItemInfoDisc != undefined) {
			leadNatureList.push(othersItemInfoDisc);
		}
		

		var kpiList = [
				{ id: 1, value: "YES", label: "Yes" },
				{ id: 2, value: "NO", label: "No" },
				
			];
			
		var reminderOptionList = [
			
			{ id: 1, value: "NO", label: "No" },
			{ id: 2, value: "WK", label: "Weekly" },
			{ id: 3, value: "MN", label: "Monthly" },
			{ id: 4, value: "WKATT", label: "Weekly with attached Excel" },
			{ id: 5, value: "MNATT", label: "Monthly with attached Excel" },
			
		];
		this.setState({
			devStatusList : devStatusList,
			typeOfLeadDiscList: typeOfLeadDiscList,
			leadNatureList: leadNatureList,
			typeOfSuccessCaseList: typeOfSuccessCaseList,
			IndustryList: IndustryList,
			locationList: locationList,
			TopicList: TopicList,
			eventList: eventList,
			kpiList: kpiList,
			reminderOptionList: reminderOptionList
		});

	}


	handlerInputChange2 = (event) => {
		refreshAccessTokenTime();

		var stateData = this.state;
		var target = event.currentTarget;
		var fieldName = target.name;
		var value = target.value;
		lodash.set(stateData, fieldName, value);
		this.setState({ ...stateData });
		refreshAccessTokenTime();
	}


	beforeUpload = (file) => {
		const isLt100M = file.size / 1024 / 1024 < 100;
		return isLt100M;
	};

	handleCancel = (item) => {
		this.setState({
			showEditDialog: false,
		});
	}

	gotoStoryPage = (item) => {
		this.setState({
			showEditDialog: true,
		});
	}

	changeRadioKpi = (str, e) => {
		let gui = this.state.gui;
		let data = this.state.data;
		gui.radioKpiYes = false;
		gui.radioKpiNo = false;
		if ("YES" == str) {
			gui.radioKpiYes = true;
			gui.radioKpiNo = false;
		} else if ("NO" == str) {
			gui.radioKpiYes = false;
			gui.radioKpiNo = true;
		} 
		data.kpi = str;
		this.setState({ data: data, gui: gui })
	}


	changeRadio = (str, e) => {
		let gui = this.state.gui;
		let data = this.state.data;
		gui.radio1 = false;
		gui.radio2 = false;
		gui.radio3 = false;
		if ("Disclosable" == str) {
			gui.radio1 = true;
			data.infoDiscList=[];
		} else if ("Cannot be Disclosed" == str) {
			gui.approveTier3 = false;
			gui.radio2 = true;
			data.infoDiscList=[];
		} else if ("Partially disclosable" == str) {
			gui.approveTier3 = false;
			gui.radio3 = true;
		}
		data.discloseToPublic = str;
		this.setState({ data: data, gui: gui })
	}

	handleRemove = (file) => {
		let uri = successStory_UI_CONFIG.url + '/file/remove?dirStr=' + this.state.data.attPrefixCode + '&name=' + file.name;
		get(uri, this).then(data => {

			console.log("handleRemove", data);
			if (data.returnType === "SUCCESS") {
				message.config({ top: '10%', });
				this.messagesuccess(file.name + ' removed successfully.');
			} else {
				this.messageerror("Attachment failed to be removed. Please contact system admin.");

			}
		}).catch((e) => {
			this.messageerror("Attachment failed to be removed. Please contact system admin.");
		});
	};

	changeRadio2 = (str, e) => {
		let gui = this.state.gui;
		let data = this.state.data;
		gui.radio4 = false;
		gui.radio5 = false;
		if ("Yes" == str) {
			gui.radio4 = true;
		} else if ("No" == str) {
			gui.radio5 = true;
		}
		data.radioStr2 = str;
		this.setState({ data: data, gui: gui })
	}

	changeRadio3 = (str, e) => {
		let gui = this.state.gui;
		let data = this.state.data;
		var languages = "";
		if (gui.radio01) {
			languages = "EN"
		}
		if (gui.radio02) {
			languages = "TC"
		}
		if (gui.radio03) {
			languages = "SC"
		}
		if (languages != str) {
			data.topicl = undefined;
		}
		gui.radio01 = false;
		gui.radio02 = false;
		gui.radio03 = false;
		if ("EN" == str) {
			this.changeLanguageType("EN");
			gui.radio01 = true;
		} else if ("TC" == str) {
			this.changeLanguageType("TC");
			gui.radio02 = true;
		} else if ("SC" == str) {
			this.changeLanguageType("SC");
			gui.radio03 = true;
		}

		data.language = str;
		this.setState({ data: data, gui: gui })
	}

	changeRadio4(str, e) {
		let gui = this.state.gui;
		gui.reject01 = false;
		gui.reject02 = false;
		if (str == "01") {
			gui.reject01 = true;
		} else {
			gui.reject02 = true;
		}
		this.setState({ gui: gui });
	}

	changeRadioApprove(str, e) {
		let data = this.state.data;
				
		this.state.data.devStatus=str;
		
		this.setState({ data: data });
	}



	gotoHistory = (item) => {
		window.open("/History?id=" + item.id);
		//dataPutAndGo(this, '/writeStory/History', { item: item } ); 
	}

	changeSwitch(e, str) {
		var data = this.state.data;
		if ("isHongKongInd" == str) {
			data.isHongKongInd = e;
		} else if ("isThereTdcInd" == str) {
			data.isThereTdcInd = e;
			this.setState({
				eventNameStr1: "", eventTypeStr1: "", eventDateStr1: "", eventNameStr2: "",
				eventTypeStr2: "", eventDateStr2: "", eventTypeStr3: "", eventDateStr3: "", eventNameStr3: ""
			});
			data.serviceList = [{ eventType: null, eventName: null, eventId: null, eventDate: null }];

		} else if ("usedLeadExternally" == str) {
			this.setState({
				usageChannel0: "", usageDescriptionInd0: "", usageChannel1: "",
				usageDescriptionInd1: "", usageChannel2: "", usageDescriptionInd2: "", usageChannel3: "", usageDescriptionInd3: "",
				usageChannel4: "", usageDescriptionInd4: "", usageChannel5: "", usageDescriptionInd5: "", usageChannel6: "",
				usageDescriptionInd6: "", usageChannel7: "", usageDescriptionInd7: "", usageChannel8: "",
				usageDescriptionInd8: "", usageChannel9: "", usageDescriptionInd9: ""
			});

			data.usedLeadExternally = e;
			data.usageList = [{ Channel: null, AttachmentList: [], UrlList: [null], description: null }];

		} else if ("usageContentUpdated" == str) {
			data.usageContentUpdated = e;


		} else if ("expfurdev" == str) {
			data.expfurdev = e;


		}
		else if ("sendSubRem" == str) {
			data.sendSubRem = e;


		}
		this.setState({
			data: data
		});
	}

	changeCheckBox(e, str) {
		var ee = e.target.checked;
		var data = this.state.data;
		if (ee == undefined) {
			ee = false;
		}

		this.setState({ data: data });
	}

	




	dialogClose5 = () => {
		this.setState({
			control5: false
		})
		// var data = this.state.data;
		// dataPutAndGo(this, '/successStoryLeads' , data  );
	}



	closeApproveDialog = () => {
		let gui = this.state.gui;
		gui.approveTier1 = false;
		gui.approveTier2 = false;
		gui.approveTier3 = false;
		this.setState({
			controlApproveButton: false,
			gui: gui,	// Tier button of Approve
		});
	}
	changeSessionBasicInfo = () => {

		this.setState({ sessionOpenBasicInfo: !this.state.sessionOpenBasicInfo });

	}
	changeSessionCompanyInfo = () => {

		this.setState({ sessionOpenCompanyInfo: !this.state.sessionOpenCompanyInfo });

	}
	changeSessionHKTDCInvo = () => {

		this.setState({ sessionOpenHKTDCInvo: !this.state.sessionOpenHKTDCInvo });

	}
	changeSessionStoryDesc = () => {

		this.setState({ sessionOpenStoryDesc: !this.state.sessionOpenStoryDesc });

	}
	changeSessionStoryPublic = () => {

		this.setState({ sessionOpenStoryPublic: !this.state.sessionOpenStoryPublic });

	}
	changeSessionOtherInfo = () => {

		this.setState({ sessionOpenOtherInfo: !this.state.sessionOpenOtherInfo });

	}
	closeAllDialog = () => {
		this.setState({
			control1: false,
			control2: false,
			control3: false,
			control4: false,
			control5: false,
			control6: false,
			control7: false,
			//control8: false,
			control9: false,
			control10: false,
			control11: false,
			control12: false,
			controlApproveButton: false,
		})
	}

	showdialogByPram = (statusStr) => {
		if (statusStr === STATUS_.Approved) {
			// let gui = this.state.gui;
			// let approveTier1 = gui.approveTier1;
			// let approveTier2 = gui.approveTier2;
			// let approveTier3 = gui.approveTier3;
			// if (!(approveTier1 == false && approveTier2 == false && approveTier3 == false)) {
			// 	this.updateStatus(STATUS_.Archived);
			// 	return;
			// } else {
			// 	gui.approveTier1 = true;
			// 	this.setState({ controlApproveButton: true, gui: gui })
			// }
			this.setState({ controlApproveButton: true })
		} else if (statusStr === STATUS_.Published) {
			this.setState({ control9: true });
		} else if (statusStr === STATUS_.Reopened) {
			this.setState({ control10: true });
		} else if (statusStr === STATUS_.Closed) {

			let checkGui = this.state.gui;
			let approveTier2Ck = checkGui.approveTier2;
			let approveTier3Ck = checkGui.approveTier3;
			



			this.setState({ control11: true });

			var thispobj = this;
			Modal.confirm({
				title: 'Confirm',
				content: (<span className="dataDiffMessage">Do you want to close this lead {"<" + this.state.data.id + ">"}?</span>),

				onOk() { thispobj.updateStatus(STATUS_.Closed, "") },
				closable: false,
				onCancel() { },
				okText: 'OK',
				cancelText: "Cancel",
				width: "500px",
				//footer={null}

				maskClosable: false
			});



		} else if (statusStr === "Reject") {
			this.setState({ control5: true });
		} else if (statusStr === "save") {
			this.setState({ control7: true });
		}
	}




	checkBeoforeCancelGoback = () => {
		var thisObj = this;
		var backupData = this.backupData;
		var newData = this.state.data;
		var isUpdated = checkDataDiff(backupData, newData, []);
		if (isUpdated && getPermission(this.props, "saveLeadsInd")) {
			Modal.confirm({
				title: 'Confirm',
				content: (<span className="dataDiffMessage">{DATA_SAVE_MSG}</span>),
				okText: 'OK',
				cancelText: 'Cancel',
				onOk() {
					thisObj.saveData();
				},
				onCancel() {
					//dataPutAndGo(thisObj, '/successStoryLeads', null, null , false);
				},
			});
		}
	}

	/*
	cancelGoback = () => {
		// var backupData = this.state.backupData;
		var data = this.state.data;
		if(this.state.PageModel == "write" ){
			dataPutAndGo(this, '/successStoryLeads', null, null , false);
		}else{
			dataPutAndGo(this, '/successStoryLeads/storyDetail' , data , null , false );
		}
	}
	*/

	dialogClose12 = () => {
		this.setState({
			control12: false
		})
		this.saveData();
	}


	handlePreview = (info) => {
		console.log("handlePreview " + info.name);

		//console.log(" BLOB_UI_CONFIG.url + url >>>>> " , BLOB_UI_CONFIG.url + url );
		var downloadUrl = successStory_UI_CONFIG.url + '/file/down?dirStr=' + this.state.data.attPrefixCode + '&name=' + info.name;
		//window.location.href = downloadUrl;
		window.open(downloadUrl, "_blank");







	}

	handleChange = (info, str) => {
		let fileList = [...info.fileList];

		//		fileList = fileList.slice(-10);
		console.log("fileList size", fileList.length);


		fileList = fileList.map(file => {
			file.url = successStory_UI_CONFIG.url + '/file/down?dirStr=' + this.state.data.attPrefixCode + '&name=' + file.name;
			//file.url = BLOB_UI_CONFIG.url + file.name;   //BLOB_UI_CONFIG.url + file.name
			console.log("this.state.data. file >>>>>>>>>>>>>>>>>>> ", file);
			// if (file.response) { 
			//   file.url = file.response.url; 
			// }
			return file;
		});


		var type = "";
		if (info.file != undefined) {
			var fileName = info.file.name;
			var index = fileName.lastIndexOf('.');
			type = info.file.name.slice((index + 1), fileName.length);
		}
		var match = (new RegExp('[~#%\&{}+\|]|\\.\\.|^\\.|\\.$')).test(info.file.name);
		if (match) {
			this.messageerror("A file name can't contain any of the following characters: ~ # % & * { } \ : < > ? / + | \"");
			return false;
		}
		else if (type.toLowerCase() != "jpg" && type.toLowerCase() != "jpeg" && type.toLowerCase() != "png"
			&& type.toLowerCase() != "doc" && type.toLowerCase() != "docx" && type.toLowerCase() != "pdf") {
			message.config({ top: '10%', });
			this.messageerror("Format: jpg, jpeg, png, doc, docx, pdf. Maximum file size 10MB.");
			return false;
		} else if (info.file.size > 10485760) {	// 10M 
			message.config({ top: '10%', });
			this.messageerror(info.file.name + ' upload failure as file size exceeds 10MB.');
			return false;
		} else if (info.file.response && info.file.response != 'success') {
			message.config({ top: '10%', });
			var data = this.state.data;
			const matchKey = info.file.uid !== undefined ? 'uid' : 'name';
			console.log("matchKey", matchKey, info.file[matchKey]);
			const removed = fileList.filter(item => item[matchKey] != info.file[matchKey]);


			if (str == "file") {
				data.fileList = removed;
			} else {

				data.usageList[str].AttachmentList = removed;

			}



			console.log("matchKey", data.fileList);
			this.setState({ data: data });
			if (info.file.response != undefined && info.file.response != null && info.file.response != "fail" && info.file.response != "") {
				this.messageerror(info.file.response);

			} else {
				this.messageerror("Attachment failed to be uploaded. Please contact system admin.");
			}
			this.updateAttachmentList();
			return false;

		} else {
			if (info.file.status === "done") {
				message.config({ top: '10%', });
				this.messagesuccess(info.file.name + ' uploaded successfully.');

			}
			if (str == "file") {
				var dataFile = this.state.data;
				dataFile.fileList = fileList;
				this.setState({ data: dataFile });
			} else {
				var data2 = this.state.data;
				var usageList = data2.usageList;
				usageList[str].AttachmentList = fileList;
				data2.usageList = usageList;
				this.setState({ data: data2 });
			}
			this.updateAttachmentList();
		}

		console.log("this.state.data.fileList >>>>>>>>>>>>>>>>>>> ", this.state.data.fileList);
	};



	checkRemark = (code) => {
		if (code == "reject") {
			var remark = document.getElementById("rejectRemark").value;
			if ((remark == null || remark == "")
				|| (remark.length > 300)) {
				this.messageinfo("Remark required.");


				return;

			} else {
				var data = this.state.data;
				data.rejectReopenRemake = remark;
				this.setState({
					data: data
				});
			}
			this.updateStatus(this.state.gui.reject01 == true ? STATUS_.Reopened : STATUS_.Closed, this.state.gui.reject01 == true ? "type_4" : "type_3");
		} else if (code == "reopen") {
			var reopenRemark = document.getElementById("reopenRemark").value;
			if ((reopenRemark == null || reopenRemark == "") || (reopenRemark.length > 300)) {
				this.messageinfo("Remark required.");

				return;

			} else {
				data = this.state.data;
				data.rejectReopenRemake = reopenRemark;
				this.setState({
					data: data
				});
			}
			this.updateStatus(STATUS_.Reopened, "type_4")
		}
	}


	checkTierBeforeApprove = () => {
		this.updateStatus(STATUS_.Archived);
	}


	updateStatusRelease = (emailStatus) => {
		var thisObj = this;
		emailStatus = null;
		var backupData = this.backupData;
		var newData = this.state.data;
		var isUpdated = checkDataDiff(backupData, newData, []);
		if (isUpdated) {
			Modal.confirm({
				title: 'Confirm',
				content: (<span className="dataDiffMessage">{DATA_SAVE_MSG}</span>),
				okText: 'OK',
				cancelText: 'Cancel',
				onOk() {
					thisObj.updateStatus(STATUS_.Release, emailStatus);
				},
				onCancel() {
					thisObj.closeAllDialog();
				},
			});
		} else {
			thisObj.updateStatus(STATUS_.Release, emailStatus);
		}
	}



	getAttathments = (attList) => {
		var attStr = "";
		if (attList) {
			var fileList = attList;
			for (var i = 0; i < fileList.length; i++) {
				if (i == 0) {
					attStr = fileList[i].name;
				} else {
					attStr = attStr + Separator + fileList[i].name;
				}
			}
		}
		return attStr;
	}

	getChannelUrls = (urlList) => {
		var urlStr = "";
		if (urlList) {
			for (var i = 0; i < urlList.length; i++) {
				if (i == 0) {
					urlStr = urlList[i];
				} else {
					urlStr = urlStr + ',' + urlList[i];
				}
			}
		}
		return urlStr;
	}



	checkUrlList = (urlList) => {
		if (urlList && urlList.length == 1 && (this.isEmpty(urlList[0]))) {
			return true;
		} else {
			for (var i = 0; i < urlList.length; i++) {
				var url = urlList[i];
				if (this.isEmpty(url)) {
					return true;
				}
			}
			return false;
		}
	}
	isEmpty = (str) => {
		return (str == undefined || str == "" || str.length == 0) ? true : false
	}





	saveData = (callBackHandler, isTimeout) => {
		this.closeAllDialog();


		var dataEntity = this.copyJson(this.state.data);

		var status = dataEntity.status;

		

		dataEntity = this.dataValidationAndFormat(dataEntity);

		console.log("saveData dataEntity", dataEntity);


		if (dataEntity == null) {
			if (isTimeout == true && callBackHandler) {
				callBackHandler();
			}
			console.log("Fail Validation");
			return;
		}

		var datev = getDateStr();
		var userName = this.props.loginUser.userName;	// default
		var roleCode = this.props.loginUser.roleId;
		if (!userName) {
			userName = "admin";
		}
		let departmentName = getDepartmentName(this.props);


		// contentApprover info
		if (roleCode == 1 || roleCode == 2) {
			console.log("status ", status, roleCode, status == STATUS_.Archived || status == STATUS_.Reopened || status == STATUS_.Closed || status == STATUS_.Reviewing || status == 'Reviewing');

			if (status == STATUS_.Archived || status == STATUS_.Reopened || status == STATUS_.Closed || status == STATUS_.Reviewing || status == 'Reviewing') {

				console.log("status Updated ", status);
				//var dept = departmentName == undefined ? "" : "("+ departmentName + ")";
				dataEntity.contentApprover = userName;		// +  dept 
			}
		}
		// contentApprover info


		if (!dataEntity.createdBy) {

			if (dataEntity.department == undefined || dataEntity.department == null || dataEntity.department == "") {
				dataEntity.department = departmentName == undefined ? null : departmentName;
			}
			dataEntity.createdBy = userName;
		}
		if (!dataEntity.createdDateTime) {
			dataEntity.createdDateTime = datev;
		}
		if (!dataEntity.isStatusUpdated) {
			dataEntity.isStatusUpdated = 'N';
		}

		let gui = this.state.gui;
		let approveTier1 = gui.approveTier1;
		let approveTier2 = gui.approveTier2;
		let approveTier3 = gui.approveTier3;
		if (approveTier1) {
			dataEntity.leadId = 1;
		} else if (approveTier2) {
			dataEntity.leadId = 2;
		} else if (approveTier3) {
			dataEntity.leadId = 3;
		} else {
			dataEntity.leadId = null;
		}
		dataEntity.lastModifiedBy = userName;
		dataEntity.updateByDept = departmentName;
		dataEntity.lastModifiedDateTime = datev;
		
		if (dataEntity.infoDiscList!=undefined && dataEntity.infoDiscList.length>0){
			
			var infoDiscValues = "";
			dataEntity.infoDiscList.map((item) => {
				
					
					infoDiscValues+=(item+"/");
				}
			);
			dataEntity.infoDisc= infoDiscValues;
			
		}



		// history info
		dataEntity.actionHistoryBean = null;
		var logEntity = this.insertActiveLogBySave(dataEntity.status);
		dataEntity.actionHistoryBean = logEntity;
		if (dataEntity.status == STATUS_.Archived) {
			var datee = getDateStr();
			dataEntity.publishedDate = datee;
			dataEntity.reportPublishedDate = datee;

		}
		var leadId = null;
		if (dataEntity.leadId != null) {
			leadId = dataEntity.leadId.toString();
		}

		if (leadId !== this.state.changesFlagValue && this.state.changesFlagValue != null && dataEntity.status == 'Archived') {
			dataEntity.isStatusUpdated = 'Y';
			dataEntity.emailStatus = "type_1";
		}
		var backupRefdata = this.backupData;
		backupRefdata.storyDescription = Base64.encode (backupRefdata.storyDescription);
		if(backupRefdata.url!=undefined){
			backupRefdata.url = Base64.encode (backupRefdata.url);
		}
		if(backupRefdata.url1!=undefined){
		backupRefdata.url1 = Base64.encode (backupRefdata.url1);
		}
		if(backupRefdata.url2!=undefined){
		backupRefdata.url2 = Base64.encode (backupRefdata.url2);
		}
		if(backupRefdata.usageUrl1!=undefined){
		backupRefdata.usageUrl1 = Base64.encode (backupRefdata.usageUrl1);
		}
		if(backupRefdata.usageUrl2!=undefined){
		backupRefdata.usageUrl2 = Base64.encode (backupRefdata.usageUrl2);
		}
		if(backupRefdata.usageUrl3!=undefined){
		backupRefdata.usageUrl3 = Base64.encode (backupRefdata.usageUrl3);
		}
		if(backupRefdata.usageUrl4!=undefined){
		backupRefdata.usageUrl4 = Base64.encode (backupRefdata.usageUrl4);
		}
		if(backupRefdata.usageUrl5!=undefined){
		backupRefdata.usageUrl5 = Base64.encode (backupRefdata.usageUrl5);
		}
		if(backupRefdata.usageUrl6!=undefined){
		backupRefdata.usageUrl6 = Base64.encode (backupRefdata.usageUrl6);
		}
		if(backupRefdata.usageUrl7!=undefined){
		backupRefdata.usageUrl7 = Base64.encode (backupRefdata.usageUrl7);
		}
		if(backupRefdata.usageUrl8!=undefined){
		backupRefdata.usageUrl8 = Base64.encode (backupRefdata.usageUrl8);
		}
		if(backupRefdata.usageUrl9!=undefined){
		backupRefdata.usageUrl9 = Base64.encode (backupRefdata.usageUrl9);
		}
		if(backupRefdata.usageUrl10!=undefined){
		backupRefdata.usageUrl10 = Base64.encode (backupRefdata.usageUrl10);
		}
		if(backupRefdata.channelDesc1!=undefined){
		backupRefdata.channelDesc1 = Base64.encode (backupRefdata.channelDesc1);
		}
		if(backupRefdata.channelDesc2!=undefined){
		backupRefdata.channelDesc2 = Base64.encode (backupRefdata.channelDesc2);
		}
		if(backupRefdata.channelDesc3!=undefined){
		backupRefdata.channelDesc3 = Base64.encode (backupRefdata.channelDesc3);
		}
		if(backupRefdata.channelDesc4!=undefined){
		backupRefdata.channelDesc4 = Base64.encode (backupRefdata.channelDesc4);
		}
		if(backupRefdata.channelDesc5!=undefined){
		backupRefdata.channelDesc5 = Base64.encode (backupRefdata.channelDesc5);
		}
		if(backupRefdata.channelDesc6!=undefined){
		backupRefdata.channelDesc6 = Base64.encode (backupRefdata.channelDesc6);
		}
		if(backupRefdata.channelDesc7!=undefined){
		backupRefdata.channelDesc7 = Base64.encode (backupRefdata.channelDesc7);
		}
		if(backupRefdata.channelDesc8!=undefined){
		backupRefdata.channelDesc8 = Base64.encode (backupRefdata.channelDesc8);
		}
		if(backupRefdata.channelDesc9!=undefined){
		backupRefdata.channelDesc9 = Base64.encode (backupRefdata.channelDesc9);
		}
		if(backupRefdata.channelDesc10!=undefined){
		backupRefdata.channelDesc10 = Base64.encode (backupRefdata.channelDesc10);
		}
		if(backupRefdata.remarks!=undefined){
		backupRefdata.remarks = Base64.encode (backupRefdata.remarks);
		}
		if(backupRefdata.rejectReopenRemake!=undefined){
		backupRefdata.rejectReopenRemake = Base64.encode (backupRefdata.rejectReopenRemake);
		}
		
		if(backupRefdata.emailReceiver!=undefined){
			backupRefdata.emailReceiver = Base64.encode (backupRefdata.emailReceiver);
		}
		
		
		backupRefdata.companyInfoList = [];
		backupRefdata.usageList = [];
		
		
		dataEntity.backupRef = backupRefdata;
		
		//SSS-335 For Handling blockage of WAF 
        dataEntity.storyDescription = Base64.encode (dataEntity.storyDescription);
		if(dataEntity.url!=undefined){
			dataEntity.url = Base64.encode (dataEntity.url);
		}
		if(dataEntity.url1!=undefined){
		dataEntity.url1 = Base64.encode (dataEntity.url1);
		}
		if(dataEntity.url2!=undefined){
		dataEntity.url2 = Base64.encode (dataEntity.url2);
		}
		if(dataEntity.usageUrl1!=undefined){
		dataEntity.usageUrl1 = Base64.encode (dataEntity.usageUrl1);
		}
		if(dataEntity.usageUrl2!=undefined){
		dataEntity.usageUrl2 = Base64.encode (dataEntity.usageUrl2);
		}
		if(dataEntity.usageUrl3!=undefined){
		dataEntity.usageUrl3 = Base64.encode (dataEntity.usageUrl3);
		}
		if(dataEntity.usageUrl4!=undefined){
		dataEntity.usageUrl4 = Base64.encode (dataEntity.usageUrl4);
		}
		if(dataEntity.usageUrl5!=undefined){
		dataEntity.usageUrl5 = Base64.encode (dataEntity.usageUrl5);
		}
		if(dataEntity.usageUrl6!=undefined){
		dataEntity.usageUrl6 = Base64.encode (dataEntity.usageUrl6);
		}
		if(dataEntity.usageUrl7!=undefined){
		dataEntity.usageUrl7 = Base64.encode (dataEntity.usageUrl7);
		}
		if(dataEntity.usageUrl8!=undefined){
		dataEntity.usageUrl8 = Base64.encode (dataEntity.usageUrl8);
		}
		if(dataEntity.usageUrl9!=undefined){
		dataEntity.usageUrl9 = Base64.encode (dataEntity.usageUrl9);
		}
		if(dataEntity.usageUrl10!=undefined){
		dataEntity.usageUrl10 = Base64.encode (dataEntity.usageUrl10);
		}
		if(dataEntity.channelDesc1!=undefined){
		dataEntity.channelDesc1 = Base64.encode (dataEntity.channelDesc1);
		}
		if(dataEntity.channelDesc2!=undefined){
		dataEntity.channelDesc2 = Base64.encode (dataEntity.channelDesc2);
		}
		if(dataEntity.channelDesc3!=undefined){
		dataEntity.channelDesc3 = Base64.encode (dataEntity.channelDesc3);
		}
		if(dataEntity.channelDesc4!=undefined){
		dataEntity.channelDesc4 = Base64.encode (dataEntity.channelDesc4);
		}
		if(dataEntity.channelDesc5!=undefined){
		dataEntity.channelDesc5 = Base64.encode (dataEntity.channelDesc5);
		}
		if(dataEntity.channelDesc6!=undefined){
		dataEntity.channelDesc6 = Base64.encode (dataEntity.channelDesc6);
		}
		if(dataEntity.channelDesc7!=undefined){
		dataEntity.channelDesc7 = Base64.encode (dataEntity.channelDesc7);
		}
		if(dataEntity.channelDesc8!=undefined){
		dataEntity.channelDesc8 = Base64.encode (dataEntity.channelDesc8);
		}
		if(dataEntity.channelDesc9!=undefined){
		dataEntity.channelDesc9 = Base64.encode (dataEntity.channelDesc9);
		}
		if(dataEntity.channelDesc10!=undefined){
		dataEntity.channelDesc10 = Base64.encode (dataEntity.channelDesc10);
		}
		if(dataEntity.remarks!=undefined){
		dataEntity.remarks = Base64.encode (dataEntity.remarks);
		}
		if(dataEntity.rejectReopenRemake!=undefined){
		dataEntity.rejectReopenRemake = Base64.encode (dataEntity.rejectReopenRemake);
		}
		if(dataEntity.emailReceiver!=undefined){
			dataEntity.emailReceiver = Base64.encode (dataEntity.emailReceiver);
		}
	
		dataEntity.companyInfoList = [];
		dataEntity.usageList = [];
		dataEntity.fileList=[];
		
		console.log("/ssls/saveData", dataEntity);
		post(successStory_UI_CONFIG.url + '/ssls/saveData', dataEntity, this).then((data) => {


			if (data && data.returnType == "SAVE SUCESS") {
				var message1 = "Success story lead ID #" + data.entity.id + " is saved successfully.";
				this.setState({
					id: data.entity.id,
					titleMessage: message1
				});

				this.setState({ id: data.entity.id });
				this.setState({ titleMessage: "Success story lead ID #" + data.entity.id + " is saved successfully." });
				setTimeout(() => {
					this.setState({ titleMessage: '' })
				}, 10000);

				if (callBackHandler) {
					callBackHandler();
				} else {
					data.entity.returnMsg = message1;
					
					if(this.state.isComingFromLeadStatusReport!=undefined){
						data.entity.isComingFromLeadStatusReport="Y";
					}
					dataPutAndGo(this, '/successStoryLeads/storyDetail/?leadId=' + data.entity.id, data.entity, null, false);
					//this.GoDetailAfterSave(data.entity);
				}
			} else if (data && data.returnType == "LOCK") {
				this.setState({ titleMessage: data.message });
				setTimeout(() => {
					this.setState({ titleMessage: '' })
				}, 10000);
			} else {
				this.setState({ titleMessage: "Failed to process your request. Please contact system admin for assistance." });
				setTimeout(() => {
					this.setState({ titleMessage: '' })
				}, 10000);
				if (isTimeout == true && callBackHandler) {
					callBackHandler();
				}
			}
		}).catch((e) => {
			this.setState({ titleMessage: "Failed to process your request. Please contact system admin for assistance." });
			setTimeout(() => {
				this.setState({ titleMessage: '' })
			}, 10000);
			if (isTimeout == true && callBackHandler) {
				callBackHandler();
			}
		});
	}

	GoDetailAfterSave = (entity) => {
		var id = entity.id;
		if (!id) {
			id = 0;
		}
		window.location.href = '/successStoryLeads/storyDetail?leadId=' + id;

		//dataPutAndGo(this, '/successStoryLeads/storyDetail?leadId=' + id, entity , null , false ); 
	}


	insertActiveLogBySave = (currentStatus) => {
		var now = getDateStr();	// now
		var name = this.props.loginUser.userName; // login user name
		//var roleCode = this.props.loginUser.successStoryRole.roleCode;    // role code  
		//let departmentName = getDepartmentName(this.props);



		var logEntity = {};
		var actionMessage = "";
		var oriStatus = this.originalStatus;	// ?��??�椤?�潰?�跺?��?殑�??�€?
		var activity = "";

		if (currentStatus == undefined) {
			currentStatus = STATUS_.New;
		}

		if ((oriStatus == undefined && currentStatus == STATUS_.New)) {  //|| ( oriStatus === currentStatus )
			activity = "Create Story Lead";
			actionMessage = "The lead is saved";
		} else {	//Save story by Content Approver
			activity = "Update Story";
			actionMessage = "The lead is updated";
		}


		logEntity.activity = activity;
		logEntity.actionResult = Base64.encode (actionMessage);

		if (logEntity.activity != "") {

			// var str = "";
			// if(departmentName != null ){
			// 	str = "("+ departmentName +")";
			// }

			logEntity.actionBy = name;		// + str 
			logEntity.actionDate = now;

			return logEntity;
		}
		return null;
	}

	backToTop = () => {

		window.scrollTo(0, 0);
	}

	updateAttachmentList = () => {

		console.log("Updated Attachment List ");
		var data = {id: this.state.data.id};//this.copyJson(this.state.data);
		var fileList;
		var dep = "";
		var i;
		if (this.state.data.fileList) {
			fileList = this.state.data.fileList;
			for (i = 0; i < fileList.length; i++) {
				if (i == 0) { dep = fileList[i].name; }
				else { dep = dep + Separator + fileList[i].name; }
			}
		}
		data.attachment = dep;

		// usedLeadExternally 
		var usedLeadExternally = data.usedLeadExternally;
		if (usedLeadExternally == undefined) {
			data.usedLeadExternally = false;
		}

		for (i = 0; i < 10; i++) {		// clear External Usage of Story Lead

			var usageAttachment = "usageAttachment";

			usageAttachment += (i + 1);

			data[usageAttachment] = null;
		}

		if (usedLeadExternally) {
			var usageList = this.state.data.usageList;
			for (i = 0; i < usageList.length; i++) {

				usageAttachment = "usageAttachment";

				usageAttachment += (i + 1);



				data[usageAttachment] = this.getAttathments(usageList[i].AttachmentList);
			}
		}





		console.log("Updated Attachment List ", data);
		if (data == null || data.id == null || data.id == undefined || data.id == "") {
			return;
		}
		post(successStory_UI_CONFIG.url + '/ssls/saveFileList', data, this).then(data2 => {

			console.log("Updated Attachment List");


		}).catch((e) => {
			this.setState({ titleMessage: "Failed to process your request. Please contact system admin for assistance." });
			console.log("Updated Attachment List", e);
			setTimeout(() => {
				this.setState({ titleMessage: '' })
			}, 10000);
		});


	}
	updateStatus = (statusStr, emailStatus) => {
		this.closeAllDialog();







		var data = this.copyJson(this.state.data);
		data = this.dataValidationAndFormat(data);
		if (data == null) {
			return;
		}

		var datev = getDateStr();
		var id = this.state.id;
		if (id != undefined && id != "") {
			data.id = id;
		}


		var userName = this.props.loginUser.userName;
		let departmentName = getDepartmentName(this.props);



		let tempStatus = "";
		data.emailStatus = emailStatus;

		if (statusStr == "SUBMITTED_NON_DATA" || statusStr == STATUS_.Submitted) {
			statusStr = STATUS_.Submitted;
			if (!data.createdBy) {
				data.department = departmentName == undefined ? null : departmentName;
				data.createdBy = userName;
			}
			if (!data.createdDateTime) {
				data.createdDateTime = datev;
			}
			if (!data.submittedBy || data.submittedBy == "" || data.submittedBy == undefined || data.submittedBy == null) {
				data.submittedBy = userName;
				data.submittedDateTime = datev;
				data.submittedByDept = departmentName;
			}
			data.usageUpdatedInd = "Y";
		} else if (statusStr == STATUS_.Release) {
			tempStatus = STATUS_.Release;
			statusStr = STATUS_.Submitted;
		} else if (statusStr == STATUS_.Archived || statusStr == STATUS_.Reopened || statusStr == STATUS_.Closed || statusStr == STATUS_.Reviewing) {
			//var dept = departmentName == undefined ? "" : "("+ departmentName + ")";
			data.contentApprover = userName;			// +  dept 
		}



		let Tier = null;
		let gui = this.state.gui;
		let approveTier1 = gui.approveTier1;
		let approveTier2 = gui.approveTier2;
		let approveTier3 = gui.approveTier3;
		if (approveTier1) {
			Tier = 1;
		} else if (approveTier2) {
			Tier = 2;
		} else if (approveTier3) {
			Tier = 3;
		}


		data.leadId = Tier;

		//		if( statusStr == STATUS_.Closed )
		//		{data.leadId  =undefined;}

		
		if (data.infoDiscList!=undefined && data.infoDiscList.length>0){
			var infoDiscValues = "";
			data.infoDiscList.map((item) => {
					
				infoDiscValues = infoDiscValues+(item+"/");
				}
			);
			data.infoDisc= infoDiscValues;
		}
		
		
		data.status = statusStr;
		data.lastModifiedBy = userName;
		data.updateByDept = departmentName;
		data.lastModifiedDateTime = datev;

		// history data
		var logEntity = this.insertActiveLog(data.status, data.emailStatus, Tier);
		data.isStatusUpdated = 'Y';
		data.actionHistoryBean = logEntity;
		// history data
		if (data.status == STATUS_.Closed && data.emailStatus != "type_3") {
			data.emailStatus = "type_1";
		}
		if (data.status == STATUS_.Archived) {
			var datew = getDateStr();
			data.publishedDate = datew;
			data.reportPublishedDate = datew;
			data.emailStatus = "type_1";
		}


		var backupRefdata = this.backupData;
		backupRefdata.storyDescription = Base64.encode (backupRefdata.storyDescription);
		if(backupRefdata.url!=undefined){
			backupRefdata.url = Base64.encode (backupRefdata.url);
		}
		if(backupRefdata.url1!=undefined){
		backupRefdata.url1 = Base64.encode (backupRefdata.url1);
		}
		if(backupRefdata.url2!=undefined){
		backupRefdata.url2 = Base64.encode (backupRefdata.url2);
		}
		if(backupRefdata.usageUrl1!=undefined){
		backupRefdata.usageUrl1 = Base64.encode (backupRefdata.usageUrl1);
		}
		if(backupRefdata.usageUrl2!=undefined){
		backupRefdata.usageUrl2 = Base64.encode (backupRefdata.usageUrl2);
		}
		if(backupRefdata.usageUrl3!=undefined){
		backupRefdata.usageUrl3 = Base64.encode (backupRefdata.usageUrl3);
		}
		if(backupRefdata.usageUrl4!=undefined){
		backupRefdata.usageUrl4 = Base64.encode (backupRefdata.usageUrl4);
		}
		if(backupRefdata.usageUrl5!=undefined){
		backupRefdata.usageUrl5 = Base64.encode (backupRefdata.usageUrl5);
		}
		if(backupRefdata.usageUrl6!=undefined){
		backupRefdata.usageUrl6 = Base64.encode (backupRefdata.usageUrl6);
		}
		if(backupRefdata.usageUrl7!=undefined){
		backupRefdata.usageUrl7 = Base64.encode (backupRefdata.usageUrl7);
		}
		if(backupRefdata.usageUrl8!=undefined){
		backupRefdata.usageUrl8 = Base64.encode (backupRefdata.usageUrl8);
		}
		if(backupRefdata.usageUrl9!=undefined){
		backupRefdata.usageUrl9 = Base64.encode (backupRefdata.usageUrl9);
		}
		if(backupRefdata.usageUrl10!=undefined){
		backupRefdata.usageUrl10 = Base64.encode (backupRefdata.usageUrl10);
		}
		if(backupRefdata.channelDesc1!=undefined){
		backupRefdata.channelDesc1 = Base64.encode (backupRefdata.channelDesc1);
		}
		if(backupRefdata.channelDesc2!=undefined){
		backupRefdata.channelDesc2 = Base64.encode (backupRefdata.channelDesc2);
		}
		if(backupRefdata.channelDesc3!=undefined){
		backupRefdata.channelDesc3 = Base64.encode (backupRefdata.channelDesc3);
		}
		if(backupRefdata.channelDesc4!=undefined){
		backupRefdata.channelDesc4 = Base64.encode (backupRefdata.channelDesc4);
		}
		if(backupRefdata.channelDesc5!=undefined){
		backupRefdata.channelDesc5 = Base64.encode (backupRefdata.channelDesc5);
		}
		if(backupRefdata.channelDesc6!=undefined){
		backupRefdata.channelDesc6 = Base64.encode (backupRefdata.channelDesc6);
		}
		if(backupRefdata.channelDesc7!=undefined){
		backupRefdata.channelDesc7 = Base64.encode (backupRefdata.channelDesc7);
		}
		if(backupRefdata.channelDesc8!=undefined){
		backupRefdata.channelDesc8 = Base64.encode (backupRefdata.channelDesc8);
		}
		if(backupRefdata.channelDesc9!=undefined){
		backupRefdata.channelDesc9 = Base64.encode (backupRefdata.channelDesc9);
		}
		if(backupRefdata.channelDesc10!=undefined){
		backupRefdata.channelDesc10 = Base64.encode (backupRefdata.channelDesc10);
		}
		if(backupRefdata.remarks!=undefined){
		backupRefdata.remarks = Base64.encode (backupRefdata.remarks);
		}
		if(backupRefdata.rejectReopenRemake!=undefined){
		backupRefdata.rejectReopenRemake = Base64.encode (backupRefdata.rejectReopenRemake);
		}
		
		if(backupRefdata.emailReceiver!=undefined){
			backupRefdata.emailReceiver = Base64.encode (backupRefdata.emailReceiver);
		}
		
		
		backupRefdata.companyInfoList = [];
		backupRefdata.usageList = [];
		
		
		data.backupRef = backupRefdata;


		console.log("backupRefdata" + backupRefdata);
		
		//SSS-335 For Handling blockage of WAF 
        data.storyDescription = Base64.encode (data.storyDescription);
		if(data.url!=undefined){
			data.url = Base64.encode (data.url);
		}
		if(data.url1!=undefined){
		data.url1 = Base64.encode (data.url1);
		}
		if(data.url2!=undefined){
		data.url2 = Base64.encode (data.url2);
		}
		if(data.usageUrl1!=undefined){
		data.usageUrl1 = Base64.encode (data.usageUrl1);
		}
		if(data.usageUrl2!=undefined){
		data.usageUrl2 = Base64.encode (data.usageUrl2);
		}
		if(data.usageUrl3!=undefined){
		data.usageUrl3 = Base64.encode (data.usageUrl3);
		}
		if(data.usageUrl4!=undefined){
		data.usageUrl4 = Base64.encode (data.usageUrl4);
		}
		if(data.usageUrl5!=undefined){
		data.usageUrl5 = Base64.encode (data.usageUrl5);
		}
		if(data.usageUrl6!=undefined){
		data.usageUrl6 = Base64.encode (data.usageUrl6);
		}
		if(data.usageUrl7!=undefined){
		data.usageUrl7 = Base64.encode (data.usageUrl7);
		}
		if(data.usageUrl8!=undefined){
		data.usageUrl8 = Base64.encode (data.usageUrl8);
		}
		if(data.usageUrl9!=undefined){
		data.usageUrl9 = Base64.encode (data.usageUrl9);
		}
		if(data.usageUrl10!=undefined){
		data.usageUrl10 = Base64.encode (data.usageUrl10);
		}
		if(data.channelDesc1!=undefined){
		data.channelDesc1 = Base64.encode (data.channelDesc1);
		}
		if(data.channelDesc2!=undefined){
		data.channelDesc2 = Base64.encode (data.channelDesc2);
		}
		if(data.channelDesc3!=undefined){
		data.channelDesc3 = Base64.encode (data.channelDesc3);
		}
		if(data.channelDesc4!=undefined){
		data.channelDesc4 = Base64.encode (data.channelDesc4);
		}
		if(data.channelDesc5!=undefined){
		data.channelDesc5 = Base64.encode (data.channelDesc5);
		}
		if(data.channelDesc6!=undefined){
		data.channelDesc6 = Base64.encode (data.channelDesc6);
		}
		if(data.channelDesc7!=undefined){
		data.channelDesc7 = Base64.encode (data.channelDesc7);
		}
		if(data.channelDesc8!=undefined){
		data.channelDesc8 = Base64.encode (data.channelDesc8);
		}
		if(data.channelDesc9!=undefined){
		data.channelDesc9 = Base64.encode (data.channelDesc9);
		}
		if(data.channelDesc10!=undefined){
		data.channelDesc10 = Base64.encode (data.channelDesc10);
		}
		if(data.remarks!=undefined){
		data.remarks = Base64.encode (data.remarks);
		}
		if(data.rejectReopenRemake!=undefined){
		data.rejectReopenRemake = Base64.encode (data.rejectReopenRemake);
		}
		
		if(data.emailReceiver!=undefined){
			data.emailReceiver = Base64.encode (data.emailReceiver);
		}
		
		data.companyInfoList = [];
		data.usageList = [];
		data.fileList=[];
		console.log("saveData",data );
		if(this.state.isComingFromLeadStatusReport!=undefined){
			data.isComingFromLeadStatusReport="Y";
		}
		post(successStory_UI_CONFIG.url + '/ssls/saveData', data, this).then(data2 => {
			if (data2 && data2.returnType == "SAVE SUCESS") {
				if (statusStr == STATUS_.Submitted) {
					data.id = data2.entity.id;
				}

				if (statusStr == "SUBMITTED_NON_DATA") {
					var message1 = "Success story lead ID #" + data2.entity.id + " is saved successfully.";
					this.setState({
						id: data2.entity.id,
						titleMessage: message1
					});
					setTimeout(() => {
						this.setState({ titleMessage: '' })
					}, 10000);
					dataPutAndGo(this, '/successStoryLeads/storyDetail', data, null, false);
				}
				if (statusStr == STATUS_.Submitted) {
					if (tempStatus == STATUS_.Release) {
						this.setState({ titleMessage: "The lead is released." });
						setTimeout(() => {
							this.setState({ titleMessage: '' })
						}, 10000);
						data.returnMsg = 'The lead is released.';   
						
						if(this.state.isComingFromLeadStatusReport!=undefined && this.state.isComingFromLeadStatusReport=="Y" ){
							dataPutAndGo(this, '/leadStatusReport', data, {}, false);
						}else{
							
							dataPutAndGo(this, '/successStoryLeads', data, {}, false);
						}
					} else {
						this.setState({ titleMessage: "The lead is submitted." });
						setTimeout(() => {
							this.setState({ titleMessage: '' })
						}, 10000);
						data.returnMsg = "The lead is submitted.";
						dataPutAndGo(this, '/successStoryLeads/storyDetail', data, null, false);
					}
				} else if (statusStr == STATUS_.Reopened) {
					this.setState({ titleMessage: "The lead is reopened." });
					setTimeout(() => {
						this.setState({ titleMessage: '' })
					}, 10000);
					data.returnMsg = "The lead is reopened.";
					dataPutAndGo(this, '/successStoryLeads/storyDetail', data, null, false);
				}
				// else if(statusStr == STATUS_.Approved ){
				// 	this.setState({ titleMessage : "The lead is approved." });
				// 	dataPutAndGo(this, '/successStoryLeads/storyDetail' , data  , null , false);
				// }
				else if (statusStr == STATUS_.Archived) {
					this.setState({ titleMessage: "The lead is approved. " });
					setTimeout(() => {
						this.setState({ titleMessage: '' })
					}, 10000);
					data.returnMsg = "The lead is approved.";
					dataPutAndGo(this, '/successStoryLeads/storyDetail', data, null, false);
				}
				// else if(statusStr == STATUS_.Published ){	
				// 	this.setState({ titleMessage : "The lead is published." });
				// 	dataPutAndGo(this, '/successStoryLeads/storyDetail' , data  , null , false);
				// }
				else if (statusStr == STATUS_.Closed) {
					dataPutAndGo(this, '/successStoryLeads/storyDetail', data, null, false);
				}
				// else if(statusStr == STATUS_.Release ){
				// 	this.setState({ titleMessage : "The lead is released." });
				// 	dataPutAndGo(this, '/successStoryLeads', {}, {} , false );
				// }
				else {
					this.setState({ titleMessage: "The lead is " + statusStr });
					setTimeout(() => {
						this.setState({ titleMessage: '' })
					}, 10000);
				}

				this.setState({
					backupData: data,
					data: data
				});

			} else if (data2 && data2.returnType == "LOCK") {
				this.setState({ titleMessage: data2.message });
				setTimeout(() => {
					this.setState({ titleMessage: '' })
				}, 10000);
			} else {
				this.setState({ titleMessage: "Failed to process your request. Please contact system admin for assistance." });
				setTimeout(() => {
					this.setState({ titleMessage: '' })
				}, 10000);
			}



		}).catch((e) => {
			this.setState({ titleMessage: "Failed to process your request. Please contact system admin for assistance." });
			setTimeout(() => {
				this.setState({ titleMessage: '' })
			}, 10000);
		});
	}

	disabledRemindDate = itemDate => {
		var nowDate = new Date();
		if (itemDate <= nowDate) {
			return true;
		}
		return false;
	};


	insertActiveLog(currentStatus, emailStatus, Tier) {

		var now = getDateStr();	// now
		var name = this.props.loginUser.userName; // login user name
		//		let departmentName = getDepartmentName(this.props);

		var actionMessage = "";
		var oriStatus = this.originalStatus;	// ?��??�椤?�潰?�跺?��?殑�??�€?
		var activity = "";

		var logEntity = {};

		//.log("oriStatus     : " , oriStatus );

		// if( ( oriStatus == undefined && currentStatus == STATUS_.New ) ){  //|| ( oriStatus === currentStatus )
		// 	activity = "Create Story Lead";
		// 	actionMessage = "The lead is saved";
		// }else

		// var leadId = Tier;
		// var tierDesc = "";
		// if(leadId === "1" || leadId === 1 ){
		// 	tierDesc = "(Tier 1 - Internal Reference)";
		// }else if(leadId === "2" || leadId === 2 ){
		// 	tierDesc = "(Tier 2 - Pending)";
		// }else if(leadId === "3" || leadId === 3 ){
		// 	tierDesc = "(Tier 3 ??Mature Lead)";
		// }

		if (oriStatus == undefined && currentStatus == STATUS_.Submitted) {	// Submit new story
			activity = "Submit Story";
			actionMessage = "Story lead status change to \"Submitted\"";
		} else if (oriStatus != undefined && currentStatus == STATUS_.Submitted) {	//  Submit New/Submitted story
			if (oriStatus == STATUS_.New) {
				activity = "Submit Story";
				actionMessage = "Story lead status changes from \"New\" to \"Submitted\"";
			} else if (oriStatus == STATUS_.Reviewing && currentStatus == STATUS_.Submitted) {
				activity = "Release Story";
				actionMessage = "The lead is released.";
				actionMessage += "</br>";
				actionMessage += "Story lead status changes from \"Reviewing\" to \"Submitted\"";

			} else if (oriStatus == STATUS_.Submitted) {
				activity = "Update Story";
				actionMessage = "The lead is re-submitted.";
			} else if (oriStatus == STATUS_.Reopened) {		//Submit Reopened story
				activity = "Submit Story";
				actionMessage = "Story lead status changes from \"Reopened\" to \"Submitted\"";
			}
		}

		else if (oriStatus == STATUS_.Reviewing && currentStatus == STATUS_.Archived) {	//  Approve Reviewing story
			activity = "Approve Story";		//Archive Story
			actionMessage = "Story lead status change from \"Reviewing\" to \"Archived\"";
		} else if (oriStatus == STATUS_.Reviewing && currentStatus == STATUS_.Reopened) {	// Reject Reviewing story
			activity = "Return Story";
			actionMessage = "Story lead status change from \"Reviewing\" to \"Reopened\"";
		}
		else if (currentStatus == STATUS_.Closed) {		// oriStatus == STATUS_.Archived &&
			if (oriStatus == STATUS_.Archived) {
				activity = "Close Story";
				actionMessage = "The lead is closed";
				actionMessage += "</br>";
				actionMessage += "Story lead status changes from \"Archived\" to \"Closed\"";
			} else if (oriStatus == STATUS_.Reviewing) {
				activity = "Return Story";
				actionMessage = "Story lead status changes from \"" + oriStatus + "\" to \"Closed\"";
			}


			// actionMessage += "</br>";
			// 
		}
		else if (currentStatus == STATUS_.Reopened) {    // Reopen story by Lead Submitted Dept's user
			activity = "Reopen Story";
			actionMessage = "The lead is reopened";
			actionMessage += "</br>";
			actionMessage += "Story lead status changes from \"" + oriStatus + "\" to \"Reopened\"";

		}
		//		else if( currentStatus == STATUS_.Reopened ){    // Reopen story by Content Approver
		//			activity = "Reopen Story";	
		//			actionMessage = "The lead is reopened";
		//			actionMessage += "</br>";
		//			actionMessage += "Story lead status changes from \"" + oriStatus + "\" to \"Reopened\"";
		//
		//		}

		logEntity.emailStatus = emailStatus;
		logEntity.activity = activity;
		logEntity.actionResult = Base64.encode (actionMessage);

		if (logEntity.activity != "") {

			// var str = "";
			// if(departmentName != null ){
			// 	str = "("+ departmentName +")";
			// }

			logEntity.actionBy = name;		// + str 
			logEntity.actionDate = now;
			return logEntity;
		}

		return null;
	}



	genButtonByStatus = (status) => {
		var submitLeadsInd = getPermission(this.props, "submitLeadsInd");
		var approveArchiveLeadsInd = getPermission(this.props, "approveArchiveLeadsInd");
		var rejectLeadsInd = getPermission(this.props, "rejectLeadsInd");
		var releaseLeadsInd = getPermission(this.props, "releaseLeadsInd");
		var reopenLeadsInd = getPermission(this.props, "reopenLeadsInd");
		var closeLeadsInd = getPermission(this.props, "closeLeadsInd");



		let roleCode = this.props.userRoleCode;
		//		let gui = this.state.gui;
		//var approveTier1Ind = gui.approveTier1;

		let approveTier1Ind = this.state.data.leadId == 1;
		if (!closeLeadsInd) {
			approveTier1Ind = false;
		}


		if ((roleCode == "1" || roleCode == 1) || (roleCode == "2" || roleCode == 2)) {
			if (status == "" || status == undefined) {
				return (
					<div>
						<div className="backToTopEdit">
							<a onClick={() => this.backToTop()}>Back to Top</a>
						</div>
						<div className="writeSSButtons">
							<div style={{ paddingTop: '20px' }}  >
								<Button onClick={() => this.updateStatus("SUBMITTED_NON_DATA", "type_1")} className="oneButton" type="primary" style={{ display: submitLeadsInd ? '' : 'none', color: '#FB4F03', marginLeft: '10px' }} >Submit</Button>
							</div>
						</div>
					</div>
				)
			} else if (status == STATUS_.New || status == STATUS_.Reopened) {
				return (
					<div>
						<div className="backToTopEdit">
							<a onClick={() => this.backToTop()}>Back to Top</a>
						</div>
						<div className="writeSSButtons">
							<div style={{ paddingTop: '20px' }}>
								<Button onClick={() => this.updateStatus(STATUS_.Submitted, "type_1")} className="oneButton" type="primary" style={{ display: submitLeadsInd ? '' : 'none', color: '#FB4F03', marginLeft: '10px' }} >Submit</Button>
							</div>
						</div>
					</div>
				)
			} else if (status == STATUS_.Reviewing) {
				return (
					<div>
						<div className="backToTopEdit">
							<a onClick={() => this.backToTop()}>Back to Top</a>
						</div>
						<div className="writeSSButtons">
							<div style={{ paddingTop: '20px' }}>
								<Button onClick={() => this.showdialogByPram(STATUS_.Approved)} className="oneButton" type="primary" style={{ display: approveArchiveLeadsInd ? '' : 'none', color: '#FB4F03' }} >Approve</Button>
								<Button onClick={() => this.showdialogByPram("Reject")} className="oneButton" type="primary" style={{ display: rejectLeadsInd ? '' : 'none', color: '#FB4F03', marginLeft: '10px' }} >Return</Button>
								<Button onClick={() => this.updateStatusRelease("type_1")} className="oneButton" type="primary" style={{ display: releaseLeadsInd ? '' : 'none', color: '#FB4F03', marginLeft: '10px' }} >Release</Button>
							</div>
						</div>
					</div>
				)
			}
			else if (status == STATUS_.Archived) {
				return (
					<div>
						<div className="backToTopEdit">
							<a onClick={() => this.backToTop()}>Back to Top</a>
						</div>
						<div className="writeSSButtons">
							<div style={{ paddingTop: '20px' }}>
								<Button onClick={() => this.showdialogByPram(STATUS_.Reopened)} className="oneButton" type="primary" style={{ display: reopenLeadsInd ? '' : 'none', color: '#FB4F03', marginLeft: '10px' }} >Reopen</Button>
								<Button onClick={() => this.showdialogByPram(STATUS_.Closed)} className="oneButton" type="primary" style={{  color: '#FB4F03', marginLeft: '10px' }} >Close</Button>
							</div>
						</div>
					</div>
				)
			}


		} else if ((roleCode == "3" || roleCode == 3) || (roleCode == "4" || roleCode == 4)) {
			if (status == "" || status == undefined) {
				return (
					<div>
						<div className="backToTopEdit">
							<a onClick={() => this.backToTop()}>Back to Top</a>
						</div>
						<div className="writeSSButtons">
							<div style={{ paddingTop: '20px' }}>
								<Button onClick={() => this.updateStatus("SUBMITTED_NON_DATA", "type_1")} className="oneButton" type="primary" style={{ display: submitLeadsInd ? '' : 'none', color: '#FB4F03', marginLeft: '10px' }} >Submit</Button>
							</div>
						</div>
					</div>
				)
			} else if (status == STATUS_.New || status == STATUS_.Reopened || status == STATUS_.Submitted) {
				return (
					<div>
						<div className="backToTopEdit">
							<a onClick={() => this.backToTop()}>Back to Top</a>
						</div>
						<div className="writeSSButtons">
							<div style={{ paddingTop: '20px' }}>
								<Button onClick={() => this.updateStatus(STATUS_.Submitted, "type_1")} className="oneButton" type="primary" style={{ display: submitLeadsInd ? '' : 'none', color: '#FB4F03', marginLeft: '10px' }} >Submit</Button>
							</div>
						</div>
					</div>
				)
			}

		}




	}

	handlerSelectChange2 = (name, value) => {
		
		var stateData = this.state;
		
		
		if (name === "data.emailReceiver") {
			if (value.length > 1000) { return; }
		}

		

		
		if (name === "data.typeOfSuccesses") {

			console.log("handlerSelectChange2", name, value);
			value.map((item) => {
				var selectedTypeOfSuccessCase = getLabelFromList(this.state.typeOfSuccessCaseList, item);
				console.log("selectedTypeOfSuccessCase", name, value);

				if (selectedTypeOfSuccessCase &&
					(selectedTypeOfSuccessCase === "Others")) {
					value.length = 0;
					value.push(item);
				}
			});


		}
		if (name === "data.leadNature") {

			console.log("handlerSelectChange2", name, value);
			value.map((item) => {
				var selectedLeadNature = getLabelFromList(this.state.leadNatureList, item);
				console.log("selectedLeadNature", name, value);

				if (selectedLeadNature &&
					(selectedLeadNature === "Other HK success cases")) {
					value.length = 0;
					value.push(item);
				}
			});


		}
		
		lodash.set(stateData, name, value);
		
		
		
		
		
		let hasFocus3Deals = false;
		console.log("handlerSelectChange2", this.state.data.topicl);
		if(this.state.data.topicl){
			
			this.state.data.topicl.map((item) => {			

				if (item &&
					//(item == "Focus 3 Deals")
					(this.state.kpiTopicControlList.includes(item))
					) {
						hasFocus3Deals = true;					
					
				}
			
			});

		}
		stateData.gui.mandatoryKpi=false;
		
		if(hasFocus3Deals){
			stateData.gui.mandatoryKpiOversea=true;
			
			
			this.control.kpi = true;	
			stateData.data.kpi='';
		}else{
			stateData.gui.mandatoryKpiOversea=false;
			
		}
		
		
		
		
		
		
		
		this.setState({ ...stateData });
		
		
		

		refreshAccessTokenTime();
	}
	

	handlerSelectEvent = (name, value) => {
		var stateData = this.state;
		lodash.set(stateData, name, value);
		this.setState({ ...stateData });
		var data = this.state.data;
		if (name) {
			var num = Number(name.slice(17, 18));
			data.serviceList[num].eventId = "";
			data.serviceList[num].eventName = "";

			this.setState({
				data: data
			});
		}
		refreshAccessTokenTime();
	}

	formatDate = (dateStr) => {
		if (!dateStr) {
			dateStr = null;
		} else {
			var temp = moment(dateStr, 'YYYY-MM-DD HH:mm:ss');
			dateStr = moment(temp).format('D-MMM-YYYY HH:mm');
		}
		return dateStr;
	}

	formatDate2 = (dateStr) => {
		if (!dateStr) {
			dateStr = null;
		} else {
			var temp = moment(dateStr, 'YYYY-MM-DD HH:mm:ss');
			dateStr = moment(temp).format('D-MMM-YYYY');
		}
		return dateStr;
	}


	tooltipInfo = () => {
		this.setState({isTooltipOpen:true})
	};
	handleTooltipInfoCancel = () =>{
		this.setState({isTooltipOpen:false})
	}
	render() {

		console.log("data.serviceList 1", this.state.data.serviceList);
		const { editorState } = this.state;
		const excludeControls = ['emoji', 'superscript', 'subscript', 'code', 'headings', 'line-height', 'letter-spacing', 'text-indent'];

		if (!this.state.data) {
			this.state.data = {};
		}

		var titleMessage = this.state.titleMessage;
		var status = this.state.data.status;
		var id = this.state.data.id;
		var tempId = this.state.id;
		var guiId = "";


		var showStoryStatus = false;
		var showLeadId = false;

		if (status != undefined && status != "") {
			showStoryStatus = true;
		}
		if (id != undefined && id != "") {
			showLeadId = true;
			guiId = id;
		} else if (tempId != undefined && tempId != "") {
			showLeadId = true;
			guiId = tempId;
		}


		this.leadInforDiscVisable = false;
		var discloseToPublicText = this.state.data.discloseToPublic ;
		console.log("discloseToPublicText", discloseToPublicText);
		if (discloseToPublicText && (discloseToPublicText === "Partially disclosable")) {
			this.leadInforDiscVisable = true;
		}


		this.otherTypeOfSuccessCaseVisable = false;
		var typeOfSuccessCaseText = (this.state.data.typeOfSuccesses && this.state.data.typeOfSuccesses.length > 0) ?
			getLabelFromList(this.state.typeOfSuccessCaseList, this.state.data.typeOfSuccesses[0]) : undefined;
		console.log("typeOfSuccessCaseText", typeOfSuccessCaseText);
		if (typeOfSuccessCaseText && (typeOfSuccessCaseText === "Others")) {
			this.otherTypeOfSuccessCaseVisable = true;
		}


		var approveTier3Disabled = true;
		if (!this.control.storyTier) {
			approveTier3Disabled = !this.state.gui.radio1;
		}


		var submittedDateTime = this.formatDate(this.state.data.submittedDateTime);
		var lastModifiedDateTime = this.formatDate(this.state.data.lastModifiedDateTime);
		var reminderGui = this.formatDate2(this.state.data.reminder);

		const fileprops = {
			action: successStory_UI_CONFIG.url + '/file/upload?dirStr=' + this.state.data.attPrefixCode,
			headers: { 'X-Requested-With': 'XMLHttpRequest', 'Authorization': this.accessToken },
			onChange: e => this.handleChange(e, "file"),
			onPreview: e => this.handlePreview(e),
			multiple: false,

			defaultFileList: this.state.data.fileList,
			onRemove: e => this.handleRemove(e)
		};

		//let saveLeadsInd = getPermission(this.props,"saveLeadsInd");
		const data = [
			{
			  title: 'Collaboration',
			  text: 'MoUs, cooperation and partnership agreements, business matching, deal-making'
			},
			{
			  title: 'Market expansion',
			  text: 'Support businesses to launch products, services or open offices in other markets'
			},
			{
			  title: 'Transaction',
			  text: 'Exhibitor-buyer transactions, sales at fairs'
			},
			{
			  title: 'Other HK success cases',
			  text: 'Hong Kong success stories that did not involve HKTDC'
			},
		  ];
		  let textArr = ["Collaboration - MoUs, cooperation and partnership agreements, business matching, deal-making;", 
			"Market expansion - Support businesses to launch products, services or open offices in other markets;", 
			"Transaction - Exhibitor-buyer transactions, sales at fairs;",
			"Other HK success cases - Hong Kong success stories that did not involve HKTDC;"];
		return (
			<div className="pageMain">
				<HeaderPage parentObj={this} showLoading={true} telescopic={false} />
				<Row type="flex">
					<Col className="pageLift" style={this.state.pageLeftScreenWidthStyle} >{this.genMenu2 && this.genMenu2()}</Col>
					<Col className="pageRight" style={this.state.pageRightScreenWidthStyle}>
						{this.genPathDiv && this.genPathDiv()}

						<ConfigProvider locale={locale}>

							<Modal
								title="message"
								visible={this.state.control12}
								onOk={() => this.closeAllDialog()}
								closable={false}
								onCancel={this.handleAddSubmit}
								okText='OK'
								cancelText="Cancel"
								width="500px"
								//footer={null}
								title={null}
								maskClosable={false}
							>
								<p style={{ textAlign: 'center' }}>Do you want to save the changes?</p>
							</Modal>


							<div style={{ textAlign: 'left', borderColor: '1px solid #DCDCDC' }} >


								<div style={{ backgroundColor: '#9EC7F5', color: '#fff', padding: '5px', display: this.state.titleMessage == undefined || this.state.titleMessage == '' ? 'none' : '' }} >

									<Icon type="info-circle" style={{ fontSize: '18px' }} /> {titleMessage}
								</div>
								<div className="leadDtlTopBtnsbar">
									<Row>
										<Col className="leadDtlTopBtnsbar1" xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}  >

											<img className="leadDtlTopBack" onClick={this.goBack}
												src={logoBackW}
												onMouseOver={
													e => (e.currentTarget.src = logoBackG)
												}
												onMouseOut={
													e => (e.currentTarget.src = logoBackW)
												}
											/>
										</Col>


										<Col className="leadDtlTopBtnsbar4" xs={14} sm={14} md={14} lg={14} xl={14} xxl={14}  >
											<div className="leadDtlTopBtnsbar4Img" >
												<img className="leadDtlTopImg" onClick={() => this.goCurSave()} style={{ display: getPermission(this.props, "saveLeadsInd") ? '' : 'none' }}
													src={logoSaveW}
													onMouseOver={
														e => (e.currentTarget.src = logoSaveG)
													}
													onMouseOut={
														e => (e.currentTarget.src = logoSaveW)
													}
												/>


												<img className="leadDtlTopImg" onClick={() => this.checkBeoforeCancelGoback()}
													src={logoCancelW}
													onMouseOver={
														e => (e.currentTarget.src = logoCancelG)
													}
													onMouseOut={
														e => (e.currentTarget.src = logoCancelW)
													}
												/>

											</div>
										</Col>

									</Row>
								</div>



								<Modal
									title="Reject Detail"
									visible={this.state.control7}
									onOk={() => this.saveData()}
									closable={false}
									onCancel={() => this.closeAllDialog()}
									okText='Save'
									cancelText="Cancel"
									width="400px"
									// footer={null}
									title={null}
									maskClosable={false}
								>
									<p style={{ textAlign: 'center' }}>Do you want to save the changes?</p>
								</Modal>



								<div style={{ border: '1px solid rgb(220, 220, 220)', fontStyle: 'normal' }}>

									<div  >
										<div className="leadDtlHeadingOrangPinBox">
											<div className="leadDtlHeadingOrangPin" id="item_1" onClick={this.changeSessionBasicInfo}>
												<h4 className="leadDtlHeadingOrangPinText">{(this.state.sessionOpenBasicInfo) ? <Icon type="minus" /> : <Icon type="plus" />} Basic Information</h4>
											</div>
										</div>

										<div className="leadDtlNormal" style={{ display: this.state.sessionOpenBasicInfo ? '' : 'none' }} >
											<Row className="editPanel_create" align="middle" style={{ display: 'none' }}>
												<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ><Tooltip placement="right" title="Please select your input language.">Language<span style={{ color: 'red', display: true }}>*</span></Tooltip></Col> {/*Is this disclosable to public? : */}

												<Col className="editLeadfield" xs={24} sm={5} md={4} lg={4} xl={4} xxl={4}  >
													<Radio disabled={this.control.language} name="group1" checked={this.state.gui.radio01} onClick={() => this.changeRadio3("EN")} defaultChecked>English</Radio>
												</Col>

											</Row>

											<Row className="editPanel_create" style={{ display: showLeadId ? '' : 'none' }} >
												<Col className="editLeadPanel_label" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}  >Lead ID</Col>
												<Col className="editLeadfield" xs={10} sm={10} md={10} lg={15} xl={15} xxl={15}  >{guiId}</Col>
											</Row>



											<Row className="editPanel_create" style={{ display: showStoryStatus ? '' : 'none' }} >
												<Col className="editLeadPanel_label" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}  >Lead Status</Col>
												<Col className="editLeadfield" xs={10} sm={10} md={10} lg={15} xl={15} xxl={15}  >{this.state.data.status} </Col>
											</Row>


											
											<div id="ts_a">
												<Row className="editPanel_create">
													<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >
														<Tooltip placement="right" title="Can this lead be shared publicly? Please choose an option.">Disclose to Public<span style={{ color: 'red' }}>*</span></Tooltip></Col> {/*Is this disclosable to public? : */}

													<Col className="editLeadfield" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >
														<Radio disabled={this.control.discloseToPublic} name="group1" checked={this.state.gui.radio1} onClick={this.changeRadio.bind(this, "Disclosable")} defaultChecked >Disclosable</Radio>
													</Col>
													<Col className="editLeadfield" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >
														<Radio disabled={this.control.discloseToPublic} name="group1" checked={this.state.gui.radio2} onClick={this.changeRadio.bind(this, "Cannot be Disclosed")} >Cannot be Disclosed</Radio>
													</Col>
													<Col className="editLeadfield" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}  >
														<Radio disabled={this.control.discloseToPublic} name="group1" checked={this.state.gui.radio3} onClick={this.changeRadio.bind(this, "Partially disclosable")} >Partially disclosable</Radio>
													</Col>
												</Row>
											</div>
											
											<Row hidden={!this.state.check1} className="editPanel_create"  >
												<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
													<p style={{ color: 'red' }}  >Disclose to Public can't be null </p>
												</Col>
											</Row>
											<div style={{ display: this.leadInforDiscVisable ? '' : 'none' }} >
												<Row className="editPanel_create" id="type_leaddisc">

													<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} >Please select the information are disclosable<span style={{ color: 'red' }}>*</span></Col>
													<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}>
														<MySelect
															disabled={this.control.leadInfoDisc}
															name="data.infoDiscList"
															mode="multiple"
															maxSelectCount="10"
															topMode={{ topCount: 5, initAll: true }}
															style={{ width: '100%' }}
															placeholder="Select one or more options"
															options={this.state.typeOfLeadDiscList}
															value={this.state.data.infoDiscList}
															onChange={this.handlerSelectChange2}

														/>
													</Col>

												</Row>

												<Row hidden={!this.state.check21} className="editPanel_create"  >
													<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
													<Col className="editLeadfield" xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}  >
														<p style={{ color: 'red' }}  >The information for disclosable is required.</p>
													</Col>
												</Row>
											</div>
											
											<div>
												<Row className="editPanel_create" id="type_devStatus">

													<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} >Development Status<span style={{ color: 'red' }}>*</span></Col>
													<Col className="editLeadfield" xs={20} sm={20} md={20} lg={5} xl={5} xxl={5}>
														<MySelect
															disabled={this.control.devStatus}
															name="data.devStatus"
															mode="single"
															maxSelectCount="1"
															topMode={{ topCount: 5, initAll: true }}
															style={{ width: '100%' }}
															placeholder="Select one options"
															options={this.state.devStatusList}
															value={this.state.data.devStatus}
															onChange={this.handlerSelectChange2}

														/>
													</Col>

												</Row>

												<Row hidden={!this.state.check22} className="editPanel_create"  >
													<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
													<Col className="editLeadfield" xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}  >
														<p style={{ color: 'red' }}  >Development status is required.</p>
													</Col>
												</Row>
											</div>
											
											<Row className="editPanel_create" id="type_leadNature">

												<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} >Nature of Story Lead<span style={{ color: 'red' }}>*</span>
												&nbsp;&nbsp;
												{/* <Icon onClick={()=>{this.tooltipInfo()}} type="info-circle" style={{ fontSize: '18px' }} /> */}
												 <Icon type="info-circle" style={{ fontSize: '18px' }} onClick={()=>{this.tooltipInfo()}}/>
												</Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}>
													<MySelect
														disabled={this.control.leadNatureGuiCase}
														name="data.leadNature"
														mode="multiple"
														maxSelectCount="3"
														topMode={{ topCount: 3, initAll: true }}
														style={{ width: '100%' }}
														placeholder="Select one or more options"
														options={this.state.leadNatureList}
														value={this.state.data.leadNature}
														onChange={this.handlerSelectChange2}

													/>
												</Col>

											</Row>

											<Row hidden={!this.state.check23} className="editPanel_create"  >
												<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}  >
													<p style={{ color: 'red' }}  >Nature of Story Lead is required.</p>
												</Col>
											</Row>

											<Row className="editPanel_create" id="type_success">

												<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} >Types of Story Lead<span style={{ color: 'red' }}>*</span></Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}>
													<MySelect
														disabled={this.control.typeOfSuccessCase}
														name="data.typeOfSuccesses"
														mode="multiple"
														maxSelectCount="3"
														topMode={{ topCount: 5, initAll: true }}
														style={{ width: '100%' }}
														placeholder="Select one or more options"
														options={this.state.typeOfSuccessCaseList}
														value={this.state.data.typeOfSuccesses}
														onChange={this.handlerSelectChange2}

													/>
												</Col>

											</Row>

											<Row hidden={!this.state.check10} className="editPanel_create"  >
												<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}  >
													<p style={{ color: 'red' }}  >Types of Story Lead is required.</p>
												</Col>
											</Row>

											<div style={{ display: this.otherTypeOfSuccessCaseVisable ? '' : 'none' }} >
												<Row className="editPanel_create"  >
													<div id="title_a">
														<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} >Other Type of Story Lead<span style={{ color: 'red' }}>*</span> </Col>
														<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
															<Input disabled={this.control.othertypeOfSuccessCase} name="data.otherTypeOfSuccessCase" maxLength={100} onChange={this.handlerInputChange2} value={this.state.data.otherTypeOfSuccessCase} />
														</Col>
													</div>
												</Row>

												<Row hidden={!this.state.check9} className="editPanel_create"  >
													<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
													<Col className="editLeadfield" xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}  >
														<p style={{ color: 'red' }}  >Description required.</p>
													</Col>
												</Row>

											</div>
											<Row className="editPanel_create" id="topictheme_a">
												<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >Topics/Themes<span style={{ color: 'red' }}>*</span></Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
													<MySelect
														disabled={this.control.topicTheme}
														name="data.topicl"
														mode="multiple"
														maxSelectCount="5"
														topMode={{ topCount: 10, initAll: true }}
														//searchHandlerPromise={this.searchHandlerPromiseTopic}
														style={{ width: '100%' }}
														options={this.state.TopicList}
														value={this.state.data.topicl}
														onChange={this.handlerSelectChange2}
														placeholder="Select one or more options"
													//filterNum="10"
													/></Col>
											</Row>
											<Row hidden={!this.state.checkTopicTheme} className="editPanel_create"   >
												<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
													<p style={{ color: 'red' }}  >Topics/Themes is required.</p>
												</Col>
											</Row>







											<div>
												<Row className="editPanel_create" id="location_a">
													<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >
														<Tooltip placement="right" title="Please select up to 5 locations involved in this case, including the markets and origins of the companies involved.">Locations Involved</Tooltip><span style={{ color: 'red' }}>*</span></Col>
													<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
														<MySelect
															disabled={this.control.locationInvolved}
															id="location"
															name="data.location"
															mode="multiple"
															maxSelectCount="5"
															topMode={{ topCount: 5, initAll: true }}
															style={{ width: '100%' }}
															options={this.state.locationList}
															value={this.state.data.location}
															onChange={this.handlerSelectChange2}
															placeholder="E.g. markets being developed, origins of the organisations involved, etc"
															filterNum="5"
														/>
													</Col>
												</Row>
											</div>
											<Row hidden={!this.state.check3} className="editPanel_create"  >
												<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}  >
													<p style={{ color: 'red' }}  >Locations Involved is required.</p>
												</Col>
											</Row>
											<div>
												<Row className="editPanel_create" id="industry_a">

													<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >Industries Involved<span style={{ color: 'red' }}>*</span>  </Col>
													<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
														<MySelect
															disabled={this.control.industry}
															name="data.industry"
															mode="multiple"
															maxSelectCount="5"
															topMode={{ topCount: 5, initAll: true }}
															style={{ width: '100%' }}
															options={this.state.IndustryList}
															value={this.state.data.industry}
															onChange={this.handlerSelectChange2}
															placeholder="E.g. the industries of the companies involved and/or industries that they are interested in exploring"
															filterNum="5"
														/></Col>
												</Row>
											</div>
											<Row hidden={!this.state.check7} className="editPanel_create"   >
												<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
													<p style={{ color: 'red' }}  >Industries involved is required.</p>
												</Col>
											</Row>
											
											<div id="ts_kpi">
												<Row className="editPanel_create" hidden={true}>
													<Col className="editLeadPanel_label"  xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >
														Success Case Endorsed by Department Heads as KPI<br></br>(For <span className="editLeadPanel_Orangelabel" ><u>HO</u></span> colleagues Only) <span hidden={!this.state.gui.mandatoryKpi} style={{ color: 'red' }}>*</span></Col> 

													<Col className="editLeadfield" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >
														<MySelect
															disabled={this.control.kpi}
															name="data.kpi"
															mode="single"
															style={{ width: '100%' }}
															options={this.state.kpiList}
															value={this.state.data.kpi}
															onChange={this.handlerSelectChange2}
															
														/>
														
													</Col>
													<Col className="editLeadfield" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >
														&nbsp;
													</Col>
													<Col className="editLeadfield" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >
														&nbsp;
													</Col>
													<Col className="editLeadfield" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}  >
														&nbsp;
													</Col>
												</Row>
												<Row hidden={true} className="editPanel_create"  >
													<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
													<Col className="editLeadfield" xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}  >
														<p style={{ color: 'red' }}  >Success Case Endorsed by Department Heads as KPI (For HO colleagues Only) is required.</p>
													</Col>
												</Row>
												<Row hidden={!this.state.checkKpiEither} className="editPanel_create"  >
													<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
													<Col className="editLeadfield" xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}  >
														<p style={{ color: 'red' }}  >Either Success Case Endorsed by Department Heads as KPI (For HO colleagues Only) or “Focus 3 Deals” KPI Endorsed by DED/M(For Overseas Offices Only) is required.</p>
													</Col>
												</Row>
											</div>
											
											<div id="ts_kpi_oversea">
												<Row className="editPanel_create">
													<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >
														“Focus 3 Deals” KPI Endorsed by DED/M<br></br>(For <span className="editLeadPanel_Orangelabel" ><u>Overseas</u></span> Offices Only)<span hidden={!this.state.gui.mandatoryKpiOversea} style={{ color: 'red' }}>*</span></Col> 

													<Col className="editLeadfield" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >
														<MySelect
															disabled={this.control.kpiOversea }
															name="data.kpiOversea"
															mode="single"
															style={{ width: '100%' }}
															options={this.state.kpiList}
															value={this.state.data.kpiOversea}
															onChange={this.handlerSelectChange2}
															
														/>
														
													</Col>
													<Col className="editLeadfield" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >
														&nbsp;
													</Col>
													<Col className="editLeadfield" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  >
														&nbsp;
													</Col>
													<Col className="editLeadfield" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}  >
														&nbsp;
													</Col>
												</Row>
												<Row hidden={!this.state.checkKpiOversea} className="editPanel_create"  >
													<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
													<Col className="editLeadfield" xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}  >
														<p style={{ color: 'red' }}  >“Focus 3 Deals” KPI Endorsed by DED/M (For Overseas Offices Only) is required.</p>
													</Col>
												</Row>
												<Row hidden={!this.state.checkKpiEither} className="editPanel_create"  >
													<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
													<Col className="editLeadfield" xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}  >
														<p style={{ color: 'red' }}  >Either Success Case Endorsed by Department Heads as KPI (For HO colleagues Only) or “Focus 3 Deals” KPI Endorsed by DED/M (For Overseas Offices Only) is required.</p>
													</Col>
												</Row>
											</div>







										</div>
									</div>
									<div className="leadDtlHeadingOrangPinBox">
										<div className="leadDtlHeadingOrangPin" id="item_1" >
											<h4 className="leadDtlHeadingOrangPinText">
											<span onClick={this.changeSessionCompanyInfo}>
											{(this.state.sessionOpenCompanyInfo) ? <Icon type="minus" /> : <Icon type="plus" />} Company Information
											</span>
											
											<span className="sectionSubRef" style={{ display: this.state.sessionOpenCompanyInfo && this.state.data.companyInfoList.length < 3? '' : 'none' }} > 
											(Click 
											&nbsp;<Button className="oneButtonText" ><Icon type="plus" style={{}} />Add</Button>&nbsp;
											 if more than one company)
											
											</span></h4>
										</div>
									</div>

									<div style={{ display: this.state.sessionOpenCompanyInfo ? '' : 'none' }} >


										{this.genCompanyInfoList(this.state.data.companyInfoList)}

										<Button onClick={() => this.companyInfoAdd()} className="oneButton" style={{ height: '25px', marginLeft: '20px', marginTop: '10px', display: (this.state.data.companyInfoList.length == 3) ? 'none' : '' }} ><Icon type="plus" style={{}} />&nbsp;Add</Button>




									</div>

									<div className="leadDtlHeadingOrangPinBox">
										<div className="leadDtlHeadingOrangPin" id="item_1" >
											<h4 className="leadDtlHeadingOrangPinText">
											<span onClick={this.changeSessionHKTDCInvo}>
											{(this.state.sessionOpenHKTDCInvo) ? <Icon type="minus" /> : <Icon type="plus" />} HKTDC Involvement
											</span>
											<span className="sectionSubRef" style={{ display: this.state.sessionOpenHKTDCInvo && this.state.data.serviceList.length < 3 && this.state.data.isThereTdcInd ? '' : 'none' }} > 
											(Click 
											&nbsp;<Button disabled={this.control.isThereTdcInd}  className="oneButtonText"  ><Icon type="plus" style={{}} />Add</Button>&nbsp;
											 if more than one service/event)
											
											</span>
											
											</h4>
										</div>
									</div>

									<div className="leadDtlNormal" style={{ display: this.state.sessionOpenHKTDCInvo ? '' : 'none' }} >
										<Row className="editPanel_create" style={{ height: '65px' }}>
											<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >Is there an HKTDC service or event involved?<span style={{ color: 'red' }}>*</span></Col>
											<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
												<Switch disabled={this.control.isThereTdcInd} name="data.isThereTdcInd" checked={this.state.data.isThereTdcInd} onChange={(e) => { this.changeSwitch(e, "isThereTdcInd") }} checkedChildren="Y" unCheckedChildren="N" />
											</Col>
										</Row>
									</div>

									<div style={{ display: this.state.sessionOpenHKTDCInvo ? '' : 'none' }} >
										{this.genServiceList(this.state.data.serviceList)}

										<Button disabled={this.control.isThereTdcInd} onClick={() => this.serviceAdd()} className="oneButton" style={{ height: '25px', marginLeft: '20px', marginTop: '10px', display: (this.state.data.serviceList.length == 3 || !this.state.data.isThereTdcInd) ? 'none' : '' }} ><Icon type="plus" style={{}} />&nbsp;Add</Button>
									</div>


									<div className="leadDtlHeadingOrangPinBox">
										<div className="leadDtlHeadingOrangPin" id="item_1" onClick={this.changeSessionStoryDesc}>
											<h4 className="leadDtlHeadingOrangPinText">{(this.state.sessionOpenStoryDesc) ? <Icon type="minus" /> : <Icon type="plus" />} Story Description</h4>
										</div>
									</div>

									<div className="leadDtlNormal" style={{ display: this.state.sessionOpenStoryDesc ? '' : 'none' }} >
										<Row className="editPanel_create" id="descri_a">
											<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}   >
												Please describe the lead or case<span style={{ color: 'red' }}>*</span>
												<br /><br />
												<span className="sectionSubRef">Provide details of the story behind this lead, such as when it occurred, what products or services were involved, who was involved and in what capacity, whether any deals were involved, how much they were worth, etc.</span>
											</Col>
											<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
												<BraftEditor
													disabled={this.control.pleaseDescribeTheLeadOrCase}
													className="myBraftEditor"
													contentStyle={{
														height: '150px'
													}}

													height={150}
													lineHeight={150}
													id={"editor-id-1"}
													value={editorState}
													language="en"
													onChange={this.handleEditorChange}
													excludeControls={excludeControls}
													maxLength={BraftEditor_MAX_INPUT_COUNT}

													media={{
														allowPasteImage: true,

														accepts: {
															image: true,
															video: false,
															audio: false,
														},
														externals: {
															image: true,
															video: false,
															audio: false,
															embed: false,
														}

													}}
												/>
												<p style={{ float: 'right' }}>{this.state.editCharsLength} chars</p>
											</Col>
										</Row>
										<Row className="editPanel_create" >



											<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
											<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17} hidden={!this.state.check2}  >
												<p style={{ color: 'red' }}  >Please describe the lead or case is required.</p>
											</Col>
										</Row>

									</div>






									<div className="leadDtlHeadingOrangPinBox">
										<div className="leadDtlHeadingOrangPin" id="item_1" >
											<h4 className="leadDtlHeadingOrangPinText">
											<span onClick={this.changeSessionStoryPublic}>
											{(this.state.sessionOpenStoryPublic) ? <Icon type="minus" /> : <Icon type="plus" />} Story Publicity
											</span>
											<span className="sectionSubRef" style={{ display: this.state.sessionOpenStoryPublic && this.state.data.usedLeadExternally && this.state.data.usageList.length < 10 ? '' : 'none' }} > 
											
											(Click 
											&nbsp;<Button  className="oneButtonText"  ><Icon type="plus" style={{}} />Add</Button>&nbsp;
											 if more than one channel)
											
											</span>
											</h4>
										</div>
									</div>

									<div className="leadDtlNormal" style={{ display: this.state.sessionOpenStoryPublic ? '' : 'none' }} >
										<Row className="editPanel_create">
											<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >Has this lead been promoted externally?<span style={{ color: 'red' }}>*</span></Col>
											<Col className="editLeadfield" xs={20} sm={20} md={20} lg={5} xl={5} xxl={5}  >
												<Switch name="data.usedLeadExternally" checked={this.state.data.usedLeadExternally} onChange={(e) => { this.changeSwitch(e, "usedLeadExternally") }} checkedChildren="Y" unCheckedChildren="N" />
											</Col>
											<Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}  ></Col>

											<Col className="editLeadPanel_label" xs={20} sm={20} md={20} lg={5} xl={5} xxl={5} style={{ display: this.state.showUsage ? '' : 'none' }}>Get publicity updates? <Tooltip placement="right" title={(<span>Should the Content Approver be notified when Publicity information is updated?</span>)}    > <Icon type="info-circle" /></Tooltip></Col>
											<Col className="editLeadfield" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{ display: this.state.showUsage ? '' : 'none' }} >
												<Switch name="data.usageContentUpdated" checked={this.state.data.usageContentUpdated} onChange={(e) => { this.changeSwitch(e, "usageContentUpdated") }} checkedChildren="Y" unCheckedChildren="N" />

											</Col>
										</Row>
									</div>
									<div style={{ display: this.state.sessionOpenStoryPublic ? '' : 'none' }} >
										{this.genUsageList(this.state.data.usageList)}

										<Button onClick={() => this.usageAdd()} className="oneButton" style={{ height: '25px', marginLeft: '20px', marginTop: '10px', display: (this.state.data.usageList.length == 10 || !this.state.data.usedLeadExternally) ? 'none' : '' }} ><Icon type="plus" style={{}} />&nbsp;Add</Button>
									</div>


									<div className="leadDtlHeadingOrangPinBox">
										<div className="leadDtlHeadingOrangPin" id="item_1" onClick={this.changeSessionOtherInfo}>
											<h4 className="leadDtlHeadingOrangPinText">{(this.state.sessionOpenOtherInfo) ? <Icon type="minus" /> : <Icon type="plus" />} Other Information</h4>
										</div>
									</div>

									<div id="item_3" >
										<div className="leadDtlNormal" style={{ display: this.state.sessionOpenOtherInfo ? '' : 'none' }} >

											<Row className="editPanel_create">
												<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}> <Tooltip placement="right" title="Format: jpg, jpeg, png, doc, docx, pdf. Maximum file size 10MB.">Is there any other reference information that would be useful to understanding this lead?</Tooltip></Col>
												<Col className="editLeadfield" xs={10} sm={10} md={5} lg={5} xl={5} xxl={5} style={{ float: 'left', color: '#000' }} >

													{/* onChange={(e) => {this.afterUpload(e)}} */}
													<Upload disabled={this.control.attachments}
														{...fileprops}
														fileList={this.state.data.fileList}
													>
														<button className="dftant-btn" disabled={this.control.attachments} >
															<Icon type="upload" /> Click to Upload
														</button>
													</Upload>


												</Col>
											</Row>

											<Row className="editPanel_create" id="email_a">
												
												
												
												
												<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{ display: this.state.showSendSubRem ? '' : 'none' }}>Send update reminders?</Col>
												<Col className="editLeadfield"  xs={20} sm={20} md={20} lg={5} xl={5} xxl={5}  style={{ display: this.state.showSendSubRem ? '' : 'none' }} >
													
													
													<MySelect
															disabled={this.control.emailReminder}
															name="data.emailReminder"
															mode="single"
															style={{ width: '100%' }}
															options={this.state.reminderOptionList}
															value={this.state.data.emailReminder}
															onChange={this.handlerSelectChange2}
															
														/>
												</Col>
												
												

											</Row>
											<Row className="editPanel_create" id="email_a">
												<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >Who should be copied on follow-up emails?<span style={{ color: 'red' }}>*</span></Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
													{/* <Input name="data.companyName" placeholder="Company Name" onChange={this.handlerInputChange2} value={this.state.data.companyName} /> */}

													<MySelect
														disabled={this.control.emailReceiver}
														name="data.emailReceiverList"
														mode="multiple"
														maxSelectCount="10"
														//topMode={{topCount:5, initAll: true}}
														searchHandlerPromise={this.searchHandlerPromiseEmail}
														style={{ width: '100%' }}
														placeholder="Email Receiver"
														options={this.state.ccList}
														value={this.state.data.emailReceiverList}
														onChange={this.handlerSelectChange2}
													/>

												</Col>
											</Row>

											<Row hidden={!this.state.checkEmailRec} className="editPanel_error"  >
												<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}  >
													<p style={{ color: 'red' }}  >Email Receiver is required.</p>
												</Col>
											</Row>

											<Row className="editPanel_create" style={{ display: 'none' }}>
												<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ><Tooltip placement="right" title="When do you need to be reminded to update this lead?">Reminder</Tooltip></Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={6} xl={7} xxl={7}  >
													<MyDatePicker
														disabledDate={this.disabledRemindDate}
														disabled={this.control.reminder}
														pageObj={this}
														locale={locale}
														name={"data.reminder"}
														value={reminderGui}
														format="D-MMM-YYYY"
														onChange={handlerMyDatePickerChange}
														style={{ width: '100%' }}
													/>
												</Col>
											</Row>

											<Row className="editPanel_create">
												<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}   >
													Remarks (if any)
												</Col>
												<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
													<Input disabled={this.control.remarks} name="data.remarks" maxLength={500} placeholder="" onChange={this.handlerInputChange2} value={this.state.data.remarks} />
												</Col>
											</Row>
										</div>


										<div className="leadDtlHeadingOrangPinBox" id="item_1" style={{ display: this.state.data.createdBy == undefined || this.state.data.createdBy == '' ? 'none' : '' }}>
											<div className="leadDtlHeadingOrangPin">
												<h4 className="leadDtlHeadingOrangPinText">Work Log</h4>
											</div>
										</div>

										<ConfigProvider locale={locale}>
											<div className="workLog" style={{ display: this.state.data.createdBy == undefined || this.state.data.createdBy == '' ? 'none' : '' }}>
												<table style={{ width: '100%' }}>
													<tr style={{ backgroundColor: '#DDDDDD' }}  >
														<td style={{ padding: '10px', width: '20%' }} className="leadDtlLabel"  >Submitted by</td>
														<td style={{ padding: '10px', width: '20%' }} className="leadDtlLabel"  >Submission Date</td>
														<td style={{ padding: '10px', width: '20%' }} className="leadDtlLabel"  >Last Modified By</td>
														<td style={{ padding: '10px', width: '20%' }} className="leadDtlLabel"  >Last Modification Date</td>
														<td style={{ padding: '10px', width: '20%' }} className="leadDtlLabel"  >Content Approver</td>
													</tr>
													<tr style={{ borderTop: '1px solid #fff', backgroundColor: '#F2F2F2' }}>
														<td style={{ padding: '10px', width: '20%' }}  >  {this.state.data.submittedBy}  </td>			{/*{this.state.data.department == undefined ? "" : "(" + this.state.data.department + ")"}*/}
														<td style={{ padding: '10px', width: '20%' }}  >  {submittedDateTime}  </td>
														<td style={{ padding: '10px', width: '20%' }}  >  {this.state.data.lastModifiedBy}   </td>		{/*{this.state.data.updateByDept == undefined ? "" : "(" + this.state.data.updateByDept+ ")" }*/}
														<td style={{ padding: '10px', width: '20%' }}  >  {lastModifiedDateTime}  </td>
														<td style={{ padding: '10px', width: '20%' }}  >  {this.state.data.contentApprover}  </td>
													</tr>
												</table>
											</div>
										</ConfigProvider>


										<div className="leadDtlHeadingOrangPinBox" style={{ display: this.state.PageModel != "write" ? '' : 'none' }} id="item_1">
											<div className="leadDtlHeadingOrangPin" id="item_1">
												<h4 className="leadDtlHeadingOrangPinText">Audit</h4>
											</div>
										</div>

										<div className="audit_View" style={{ marginBottom: '70px', display: this.state.PageModel != "write" ? '' : 'none' }}>
											<table style={{ width: '100%' }}>
												<tr style={{ backgroundColor: '#DDDDDD', height: '45px' }} >
													<td style={{ fontSize: '16px' }} ><span className="leadDtlLabelAudit">Click &#x22;</span><a onClick={(e) => this.gotoHistory(this.state.data)} disabled={this.state.PageModel != "write" ? false : true} style={{ fontweight: '400 !important' }} >Audit Trail History Page</a><span className="leadDtlLabelAuditNoPad">&#x22; to check</span></td>
												</tr>
											</table>
										</div>



										{this.genButtonByStatus(this.state.data.status)}


									</div>

								</div>

								<div className="backToTopEditFix" style={{
									display:
										(this.genButtonByStatus(this.state.data.status) != undefined && this.genButtonByStatus(this.state.data.status) != null && this.genButtonByStatus(this.state.data.status) != "") ? "none" : ""
								}} >

									<a onClick={() => this.backToTop()} >Back to Top</a>
								</div>
								<div class="normalFooter">Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>
								<br /><br /><br />
								{/* </Card> */}
								<Modal
									visible={this.state.control8}
									onOk={() => this.updateStatus(STATUS_.Approved, "type_1")}
									closable={false}
									onCancel={() => this.closeAllDialog()}
									okText='OK'
									cancelText="Cancel"
									width="500px"
									//footer={null}
									title={null}
									maskClosable={false}
								>
									Do you want to change status to "APPROVED"?
				</Modal>

								<Modal
									title="Reject Detail"
									visible={this.state.control9}
									onOk={() => this.updateStatus(STATUS_.Published, "type_1")}
									closable={false}
									onCancel={() => this.closeAllDialog()}
									okText='OK'
									cancelText="Cancel"
									width="500px"
									//footer={null}
									title={null}
									maskClosable={false}
								>
									Do you want to change status to "PUBLISHED"?
				</Modal>

								<Modal
									title="Reject Detail"
									visible={this.state.control10}  //
									onOk={() => this.updateStatus(STATUS_.Reopened, "")}
									closable={false}
									onCancel={() => this.closeAllDialog()}
									okText='Send'
									cancelText=""
									width="500px"
									footer={null}
									title={null}
									maskClosable={false}
								>
									<a onClick={() => this.closeAllDialog()} style={{ align: 'left' }} >
										<img style={{ height: '30px' }}
											src={logoCrossW}

											onMouseOver={
												e => (e.currentTarget.src = logoCrossG)
											}
											onMouseOut={
												e => (e.currentTarget.src = logoCrossW)
											}

										/>
									</a>
									<Row className="editPanel">
										<div id="title_a">

											<Col className="EditLeadAppDialabel" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} ><p style={{ fontSize: '16px' }}>Please let us know why you are reopening this<br />success story lead:</p></Col>
										</div>
									</Row>

									<Row className="editPanel">
										<div id="title_a">

											<Col className="EditLeadAppDiafield" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}  >
												<TextArea id="reopenRemark" rows={5} placeholder="Add remark here..." style={{ border: '2px solid #B8B8B8' }} maxLength={300} />
											</Col>
										</div>
										<Button onClick={() => this.checkRemark("reopen")} style={{ float: 'right', marginTop: '20px' }} className="oneButton" >Send</Button>
									</Row>
								</Modal>



								<Modal
									title="Reject Detail"
									visible={this.state.control5} //
									onOk={this.handleSave}
									closable={false}
									onCancel={this.dialogClose5}
									okText='Send'
									cancelText="Cancel"
									width="500px"
									footer={null}
									title={null}
									maskClosable={false}
								>
									<a onClick={() => this.dialogClose5()} style={{ align: 'left' }} >
										<img style={{ height: '30px' }}
											src={logoCrossW}

											onMouseOver={
												e => (e.currentTarget.src = logoCrossG)
											}
											onMouseOut={
												e => (e.currentTarget.src = logoCrossW)
											}

										/>

									</a>

									<div className="EditLeadAppDia">


										<Col className="field" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}  >
											<Row className="editPanel">
												<div id="title_a">
													<Col className="EditLeadAppDialabel" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >Please choose your notification and add remarks:</Col>

												</div>
											</Row>

											<Row className="editPanel">
												<div id="title_a">
													<Col className="EditLeadAppDiafield" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
														<Radio checked={this.state.gui.reject01} onClick={() => this.changeRadio4("01")}><strong>Request more information</strong></Radio>
													</Col>
												</div>
											</Row>

											

											<Row className="editPanel">
												<div id="title_a">
													<Col className="EditLeadAppDiafield" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}  >
														<TextArea id="rejectRemark" rows={2} placeholder="Add remark here..." style={{ border: '2px solid #B8B8B8' }} maxLength={300} />
													</Col>
												</div>
											</Row>
											<Button onClick={() => this.checkRemark("reject")} style={{ float: 'right', marginTop: '20px' }} className="oneButton" >Send</Button>
										</Col>
										<div> &nbsp;</div>
									</div>
								</Modal>





								<Modal
									title="Approve"
									visible={this.state.controlApproveButton} //
									onOk={this.handleSave}
									closable={false}
									onCancel={() => this.closeApproveDialog()}
									okText='Send'
									cancelText="Cancel"
									width="500px"
									footer={null}
									title={null}
									maskClosable={false}
								>
									<a onClick={() => this.closeApproveDialog()} style={{ align: 'left' }} >
										<img style={{ height: '30px' }}
											src={logoCrossW}

											onMouseOver={
												e => (e.currentTarget.src = logoCrossG)
											}
											onMouseOut={
												e => (e.currentTarget.src = logoCrossW)
											}

										/>
									</a>

									<div className="EditLeadAppDia">

										<Col className="field" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}  >
											<Row className="editPanel">
												<div id="title_a">
													<Col className="EditLeadAppDialabel" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >Please confirm the Development Status:</Col>

												</div>
											</Row>

											<Row className="editPanel">
												<div id="title_a">
													<Col className="EditLeadAppDiafield" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ align: 'left' }}>
														<Radio checked={this.state.data.devStatus=='Developed'} onClick={() => this.changeRadioApprove("Developed")}><strong>Developed</strong></Radio>
													</Col>
												</div>
											</Row>

											<Row className="editPanel">
												<div id="title_a">
													<Col className="EditLeadAppDiafield" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}  >
														<Radio checked={this.state.data.devStatus=='Developing'} onClick={() => this.changeRadioApprove("Developing")} ><strong>Developing</strong></Radio>
													</Col>
												</div>
											</Row>

											


											<Button onClick={() => this.checkTierBeforeApprove()} style={{ float: 'right', marginTop: '20px' }} className="oneButton" >Send</Button>
										</Col>
										<div> &nbsp;</div>
									</div>
								</Modal>

								<Modal title="" visible={this.state.isTooltipOpen} 
								onCancel={this.handleTooltipInfoCancel}
								footer={''}
								>
									<List
										itemLayout="horizontal"
										dataSource={data}
										renderItem={(item, index) => (
										<List.Item>
											<List.Item.Meta
											title={item.title}
											description={item.text}
											/>
										</List.Item>
										)}
									/>
								</Modal>



								{/* <Modal
								title="Story Lead Audit Trial History Page"
								visible={this.state.control4}
								onOk={this.handleSave}
								closable={false}
								onCancel={this.dialogClose}
								okText='Save'
								cancelText="Cancel" 
								width="1000px"
								maskClosable={false}
							>
								<History />
							</Modal> */}



								{/* ---end--- */}
							</div>
						</ConfigProvider>


					</Col>
				</Row>
			</div>

		);
	}

	doNothingAndGoBack = () => {
		var backData = this.backupData;
		if(this.state.isComingFromLeadStatusReport!=undefined){
						backData.isComingFromLeadStatusReport="Y";
		}
		dataPutAndGo(this, '/successStoryLeads/storyDetail', backData);
	}




	addToUrlList = (str) => {
		if (this.control.url) {
			return;
		}

		var data = this.state.data;
		var urlList = data.urlList;
		if (urlList.length < 10) {
			var newUrl = { value: "" };
			urlList.push(newUrl);
			data.urlList = urlList;
			this.setState({ data: data });
		}
	}
	removeUrl = (url) => {
		var data = this.state.data;
		var urlList = data.urlList;
		var newList = [];
		urlList.map((item, index) => {
			if (item != url) {
				newList.push(item);
			}
		});
		data.urlList = newList;
		this.setState({ data: data })
	}
	genUrlList = (urlList) => {
		if (urlList == undefined) {
			urlList = [];
		}
		return urlList.map((item, index) => {
			return (

				<Row className="editPanel_create">
					<div id="title_a">
						<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}></Col>
						<Col className="editLeadfield" xs={16} sm={22} md={22} lg={17} xl={17} xxl={17}  >
							<Input disabled={this.control.url} name={"data.urlList[" + index + "].value"} maxLength={100} onChange={this.handlerInputChange2} value={this.state.data.urlList[index].value} />
						</Col>
						<Col className="fieldButtonPLus" xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} >



							<img className="fieldButtonPLusIcon" hidden={this.control.url} onClick={() => this.removeUrl(item)}
								src={logoMinusW}
								onMouseOver={
									e => (e.currentTarget.src = logoMinusG)
								}
								onMouseOut={
									e => (e.currentTarget.src = logoMinusW)
								}
							/>

						</Col>
					</div>
				</Row>
			)
		})
	}

	companyInfoAdd = () => {
		var data = this.state.data;
		var companyInfoList = data.companyInfoList;
		if (companyInfoList && companyInfoList.length < 3) {
			var companyInfo = { companyName: null, hCountryName: null, hCountryId: null, cmpUrl: null, cmpIndusty: [] };
			companyInfoList.push(companyInfo);
		}
		data.companyInfoList = companyInfoList;
		this.setState({ data: data })
	}
	companyInfoDelete = (companyInfoItem) => {
		var data = this.state.data;
		var companyInfoList = data.companyInfoList;
		if (companyInfoList && companyInfoList.length > 1) {
			var newList = [];
			companyInfoList.map((item, index) => {
				if (item != companyInfoItem) {
					newList.push(item);
				}
			})
			data.companyInfoList = newList;
			this.setState({ data: data })
		}
	}
	genCompanyInfoList = (companyInfoList) => {

		if (companyInfoList && companyInfoList.length > 0) {
			return companyInfoList.map((item, index) => {
				var companyInfoId = 'companyInfo_' + index;
				var companyInfoName = 'data.companyInfoList[' + index + '].companyName';
				var companyInfoCountryName = 'data.companyInfoList[' + index + '].hCountryId';
				var companyInfoUrl = 'data.companyInfoList[' + index + '].cmpUrl';
				var companyInfoIndusty = 'data.companyInfoList[' + index + '].cmpIndusty';

				var checkingCmpInfoVar = this.state.checkCompanyName0;
				if (index == 1) {
					checkingCmpInfoVar = this.state.checkCompanyName1;
				}
				if (index == 2) {
					checkingCmpInfoVar = this.state.checkCompanyName2;
				}

				var checkingVar = this.state.checkHLoc0;
				if (index == 1) {
					checkingVar = this.state.checkHLoc1;
				}
				if (index == 2) {
					checkingVar = this.state.checkHLoc2;
				}
				
				var checkingCmpInd = this.state.checkCmpInd0;
				if (index == 1) {
					checkingCmpInd = this.state.checkCmpInd1;
				}
				if (index == 2) {
					checkingCmpInd = this.state.checkCmpInd2;
				}
				console.log("this.state.data.companyInfoList[index].hCountryId " , this.state.data.companyInfoList[index].hCountryId);

				return (
					<div className="companyInfoAble" id={companyInfoId}>
						<Row className="editPanel_create" id={'companyBrand_' + index}>
							<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >Company/Brand Name<span style={{ color: 'red' }}>*</span></Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
								<Input disabled={this.control.companyBrandNames} name={companyInfoName} maxLength={100}
									placeholder="Company/Brand Name" onChange={this.handlerInputChange2} value={this.state.data.companyInfoList[index].companyName} />
							</Col>
							<Col style={{ display: companyInfoList.length == 1 ? 'none' : '' }} xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} className="fieldButtonPLus">
								<Popconfirm

									title="Do you want to remove this section?"
									okText="Yes" cancelText="No"
									onConfirm={() => this.companyInfoDelete(item)}
									icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>



									<img className="fieldButtonPLusIcon"
										src={logoRemoveW}
										onMouseOver={
											e => (e.currentTarget.src = logoRemoveG)
										}
										onMouseOut={
											e => (e.currentTarget.src = logoRemoveW)
										}
									/>

								</Popconfirm>
							</Col>
						</Row>

						<Row hidden={!checkingCmpInfoVar} className="editPanel_create"   >
							<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
								<p style={{ color: 'red' }}  >Company/ Brand Name is required.</p>
							</Col>
						</Row>

						<Row className="editPanel_create" id={'headquarters_' + index}>
							<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >Headquarters Location<span style={{ color: 'red' }}>*</span></Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >

								<MySelect
									disabled={this.control.headquartersLocation}
									id="location"
									name={companyInfoCountryName}
									mode="single"
									topMode={{ topCount: 5, initAll: true }}
									allowClear="true"
									style={{ width: '100%' }}
									placeholder='Try to select a specific country or city rather than a region or "worldwide"'
									options={this.state.locationList}
									value={this.state.data.companyInfoList[index].hCountryId==undefined? [] : this.state.data.companyInfoList[index].hCountryId}
									//  locationVal
									onChange={this.handlerSelectChange2}
								/>

							</Col>
						</Row>

						<Row hidden={!checkingVar} className="editPanel_create"   >
							<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
								<p style={{ color: 'red' }}  >Headquarters Location is required.</p>
							</Col>
						</Row>


						<Row className="editPanel_create" id={'cmpIndustry_' + index}>

							<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >Industry<span style={{ color: 'red' }}>*</span></Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
								<MySelect
									disabled={this.control.industry}
									name={companyInfoIndusty}
									mode="multiple"
									maxSelectCount="5"
									topMode={{ topCount: 5, initAll: true }}
									style={{ width: '100%' }}
									options={this.state.IndustryList}
									value={this.state.data.companyInfoList[index].cmpIndusty}
									onChange={this.handlerSelectChange2}
									placeholder=""
									filterNum="5"
								/></Col>
						</Row>
						<Row hidden={!checkingCmpInd} className="editPanel_create"   >
							<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
								<p style={{ color: 'red' }}  >Industry is required.</p>
							</Col>
						</Row>

						<Row className="editPanel_create">
							<div id="title_a">
								<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
									<Tooltip placement="right" title="Please put audio or video URL here.">URL</Tooltip></Col>
								<Col className="editLeadfield" xs={16} sm={22} md={22} lg={17} xl={17} xxl={17}  >
									<Input disabled={this.control.url} name={companyInfoUrl} onChange={this.handlerInputChange2}
										value={this.state.data.companyInfoList[index].cmpUrl} placeholder="Website/webpage with the company's information, etc" />
								</Col>
								<Col className="fieldButtonPLus" xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} >&nbsp;</Col>
							</div>
						</Row>
					</div>

				)
			})
		}
	}
	serviceAdd = () => {
		var data = this.state.data;
		var serviceList = data.serviceList;
		if (serviceList && serviceList.length < 3) {
			var service = { eventType: null, eventName: null, eventId: null, eventDate: null };
			serviceList.push(service);
		}
		data.serviceList = serviceList;
		this.setState({ data: data })
	}
	serviceDelete = (serviceItem) => {
		var data = this.state.data;
		var serviceList = data.serviceList;
		if (serviceList && serviceList.length > 1) {
			var newList = [];
			serviceList.map((item, index) => {
				if (item != serviceItem) {
					newList.push(item);
				}
			})
			data.serviceList = newList;
			this.setState({ data: data })
		}
	}

	genServiceList = (serviceList) => {

		if (serviceList && serviceList.length > 0) {
			return serviceList.map((item, index) => {

				var eventIsDisabled = true;
				if (!this.control.allEventControl) {
					eventIsDisabled = !this.state.data.isThereTdcInd;
				}


				var eventDate = this.formatDate2(this.state.data.serviceList[index].eventDate);
				var eventList = this.state.eventList;
				var showTextBox1 = true;
				var eventType = item.eventType;

				let typeLists = (this.state.HKTDCList ? this.state.HKTDCList : []);

				var currentEventType;

				typeLists.map((itemEventType) => {
					if (itemEventType.labelEN == eventType || itemEventType.labelTC == eventType || itemEventType.labelSC == eventType) {


						currentEventType = itemEventType;

					}
				});
				var eventInd = "N";




				if (currentEventType && currentEventType.textInputInd == "Y") {
					if (eventList != undefined) {
						var newSecList = [];
						eventList.map((subItem) => {

							if (subItem.eventType == currentEventType.eventTypeEn ||
								subItem.eventType == currentEventType.eventTypeSc ||
								subItem.eventType == currentEventType.eventTypeTc) {
								newSecList.push(subItem);
							}
							if (item.eventId == subItem.id) {
								eventInd = "Y";
							}
						});

						eventList = newSecList;
						sortingLabel(eventList);



					}
					showTextBox1 = false;

				} else {
					showTextBox1 = true;
				}


				var eventTypeStr = "";
				var eventDateStr = "";
				var eventNameStr = "";
				var serverInd = false;
				var serverId = "";
				if (index == 0) {
					eventTypeStr = this.state.eventTypeStr1;
					eventDateStr = this.state.eventDateStr1;
					eventNameStr = this.state.eventNameStr1;
					serverInd = this.state.first_server;
					serverId = 'service_a';
				}
				if (index == 1) {
					eventTypeStr = this.state.eventTypeStr2;
					eventDateStr = this.state.eventDateStr2;
					eventNameStr = this.state.eventNameStr2;
					serverInd = this.state.mid_server;
					serverId = 'service_b';
				}
				if (index == 2) {
					eventTypeStr = this.state.eventTypeStr3;
					eventDateStr = this.state.eventDateStr3;
					eventNameStr = this.state.eventNameStr3;
					serverInd = this.state.last_server;
					serverId = 'service_c';
				}
				if (index == 3) {
					eventTypeStr = this.state.eventTypeStr4;
					eventDateStr = this.state.eventDateStr4;
					eventNameStr = this.state.eventNameStr4;
					serverInd = this.state.last_server;
					serverId = 'service_d';
				}
				if (index == 4) {
					eventTypeStr = this.state.eventTypeStr5;
					eventDateStr = this.state.eventDateStr5;
					eventNameStr = this.state.eventNameStr5;
					serverInd = this.state.last_server;
					serverId = 'service_e';
				}



				return (
					<div className={this.state.data.isThereTdcInd ? 'connectionHKTDC' : 'connectionHKTDCDisable'} id={serverId}>
						<Row className="editPanel">
							<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} >Service/Event Type</Col>
							<Col className="editLeadfield" xs={23} sm={23} md={23} lg={5} xl={5} xxl={5}  >
								<MySelect
									disabled={eventIsDisabled}
									name={"data.serviceList[" + index + "].eventType"}

									style={{ width: '100%' }}
									options={this.state.HKTDCList}
									value={(this.state.data.serviceList[index].eventType == null ||
										this.state.data.serviceList[index].eventType == "") ? undefined : this.state.data.serviceList[index].eventType}
									onChange={this.handlerSelectEvent}
								/>
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}  ></Col>
							<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}   >Service/Event Date</Col>
							<Col className="editLeadfield" xs={23} sm={23} md={23} lg={6} xl={6} xxl={6}  >
								<MyDatePicker
									disabled={eventIsDisabled}
									pageObj={this}
									locale={locale}
									name={"data.serviceList[" + index + "].eventDate"}
									value={eventDate}
									format="D-MMM-YYYY"
									onChange={handlerMyDatePickerChange}
									style={{ width: '100%', left: '0px', marginLeft: '0px', right: '0px', marginRight: '0px' }}
								/>
							</Col>
							<Col style={{ display: serviceList.length == 1 ? 'none' : '' }} xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} className="fieldButtonPLus">
								<Popconfirm
									disabled={eventIsDisabled}
									title="Do you want to remove this section?"
									okText="Yes" cancelText="No"
									onConfirm={() => this.serviceDelete(item)}
									icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>



									<img className="fieldButtonPLusIcon" hidden={eventIsDisabled}
										src={logoRemoveW}
										onMouseOver={
											e => (e.currentTarget.src = logoRemoveG)
										}
										onMouseOut={
											e => (e.currentTarget.src = logoRemoveW)
										}
									/>

								</Popconfirm>
							</Col>
						</Row>
						<Row className="editPanel_error" hidden={!serverInd} >
							<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} ></Col>
							<Col className="editLeadfield" xs={23} sm={23} md={23} lg={5} xl={5} xxl={5} >
								<p style={{ color: 'red' }}  >{eventTypeStr}</p>
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}  ></Col>
							<Col className="label" xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}  ></Col>
							<Col className="editLeadfield" xs={23} sm={23} md={23} lg={6} xl={6} xxl={6}  >
								<p style={{ color: 'red' }}  >{eventDateStr}</p>
							</Col>


						</Row>
						<Row className="editPanel" style={{ display: showTextBox1 ? 'none' : '' }} >
							<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >Service/Event Name</Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
								<MySelect
									disabled={eventIsDisabled}
									name={"data.serviceList[" + index + "].eventId"}
									mode="single"
									style={{ width: '100%' }}
									topMode={{ topCount: 5, initAll: true }}
									options={eventList}
									value={eventInd == "N" ? undefined : this.state.data.serviceList[index].eventId}
									onChange={this.handlerSelectChange2}
								/>	</Col>
						</Row>
						<Row className="editPanel" style={{ display: showTextBox1 ? '' : 'none' }} >
							<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  >Service/Event Name</Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
								<Input disabled={eventIsDisabled} name={"data.serviceList[" + index + "].eventName"} maxLength={100} placeholder="" onChange={this.handlerInputChange2} value={this.state.data.serviceList[index].eventName} />
							</Col>
						</Row>
						<Row className="editPanel_error" hidden={!serverInd} >
							<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
								<p style={{ color: 'red' }}  >{eventNameStr}</p>
							</Col>

						</Row>
					</div>

				)
			})
		}
	}


	usageDelete = (usage) => {
		var data = this.state.data;
		var usageList = data.usageList;
		var newList = [];
		if (usageList && usageList.length > 1) {
			usageList.map((item, index) => {
				if (item != usage) {
					newList.push(item);
				}
			});
			data.usageList = newList;
			this.setState({ data: data })
		}
	}

	genUsageList = (usageList) => {
		if (!usageList) {
			usageList = [];
		}
		return usageList.map((item, index) => {

			var serverInd = false;
			var descInd = false;
			var channelStr = "";
			var usageDescriptionStr = "";
			var usageOtherDescriptionStr = "";
			var otherChannelId = "";
			var usageId = "";
			var descriptionId = "";

			//var states =this.state;
			if (index == 0) {
				channelStr = this.state.usageChannel0;
				usageDescriptionStr = this.state.usageDescriptionInd0;
				usageOtherDescriptionStr = this.state.usageOtherDescriptionInd0;
				usageId = "usage_0";
				descriptionId = "description_0";
				otherChannelId = "otherChannel_0";
			}
			if (index == 1) {
				channelStr = this.state.usageChannel1;
				usageDescriptionStr = this.state.usageDescriptionInd1;
				usageOtherDescriptionStr = this.state.usageOtherDescriptionInd1;
				usageId = "usage_1";
				descriptionId = "description_1";
				otherChannelId = "otherChannel_1";
			}
			if (index == 2) {
				channelStr = this.state.usageChannel2;
				usageDescriptionStr = this.state.usageDescriptionInd2;
				usageOtherDescriptionStr = this.state.usageOtherDescriptionInd2;
				usageId = "usage_2";
				descriptionId = "description_2";
				otherChannelId = "otherChannel_2";
			}
			if (index == 3) {
				channelStr = this.state.usageChannel3;
				usageDescriptionStr = this.state.usageDescriptionInd3;
				usageOtherDescriptionStr = this.state.usageOtherDescriptionInd3;
				usageId = "usage_3";
				descriptionId = "description_3";
				otherChannelId = "otherChannel_3";
			}
			if (index == 4) {
				channelStr = this.state.usageChannel4;
				usageDescriptionStr = this.state.usageDescriptionInd4;
				usageOtherDescriptionStr = this.state.usageOtherDescriptionInd4;
				usageId = "usage_4";
				descriptionId = "description_4";
				otherChannelId = "otherChannel_4";
			}
			if (index == 5) {
				channelStr = this.state.usageChannel5;
				usageDescriptionStr = this.state.usageDescriptionInd5;
				usageOtherDescriptionStr = this.state.usageOtherDescriptionInd5;
				usageId = "usage_5";
				descriptionId = "description_5";
				otherChannelId = "otherChannel_5";
			}
			if (index == 6) {
				channelStr = this.state.usageChannel6;
				usageDescriptionStr = this.state.usageDescriptionInd6;
				usageOtherDescriptionStr = this.state.usageOtherDescriptionInd6;
				usageId = "usage_6";
				descriptionId = "description_6";
				otherChannelId = "otherChannel_6";
			}
			if (index == 7) {
				channelStr = this.state.usageChannel7;
				usageDescriptionStr = this.state.usageDescriptionInd7;
				usageOtherDescriptionStr = this.state.usageOtherDescriptionInd7;
				usageId = "usage_7";
				descriptionId = "description_7";
				otherChannelId = "otherChannel_7";
			}
			if (index == 8) {
				channelStr = this.state.usageChannel8;
				usageDescriptionStr = this.state.usageDescriptionInd8;
				usageOtherDescriptionStr = this.state.usageOtherDescriptionInd8;
				usageId = "usage_8";
				descriptionId = "description_8";
				otherChannelId = "otherChannel_8";
			}
			if (index == 9) {
				channelStr = this.state.usageChannel9;
				usageDescriptionStr = this.state.usageDescriptionInd9;
				usageOtherDescriptionStr = this.state.usageOtherDescriptionInd9;
				usageId = "usage_9";
				descriptionId = "description_9";
				otherChannelId = "otherChannel_9";
			}
			if (channelStr != "" && channelStr != undefined) {
				serverInd = true;
			}
			if (usageDescriptionStr != "" && usageDescriptionStr != undefined) {
				descInd = true;
			}

			const props1 = {
				action: successStory_UI_CONFIG.url + '/file/upload?dirStr=' + this.state.data.attPrefixCode,
				headers: { 'X-Requested-With': 'XMLHttpRequest', 'Authorization': this.accessToken },
				onChange: e => this.handleChange(e, index),
				onPreview: e => this.handlePreview(e),
				onRemove: e => this.handleRemove(e),
				multiple: false
			};

			return (
				<div className={this.state.data.usedLeadExternally ? "externalUsageAble" : "externalUsageDisable"}>
					<Row className="editPanel" id={usageId}>
						<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ><Tooltip placement="right" title="Please select the external channels in which you have already used this lead.">Channel</Tooltip></Col>
						<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17} >
							<MySelect
								disabled={!this.control.channel && !this.state.data.usedLeadExternally}
								name={"data.usageList[" + index + "].Channel"}
								style={{ width: '100%' }}
								options={this.state.ChannelList}
								value={this.state.data.usageList[index].Channel == "" || this.state.data.usageList[index].Channel == null ?
									undefined : this.state.data.usageList[index].Channel}
								onChange={this.handlerSelectChange2}
							/>
						</Col>

						<Col hidden={usageList.length == 1} className="fieldButtonPLus" xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}   >
							<Popconfirm
								disabled={!this.control.usageDeleteButton && !this.state.data.usedLeadExternally}
								title="Do you want to remove this section?"
								okText="Yes" cancelText="No"
								onConfirm={() => this.usageDelete(item)}
								icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>


								<img className="fieldButtonPLusIcon" hidden={!this.control.channel && !this.state.data.usedLeadExternally}
									src={logoRemoveW}
									onMouseOver={
										e => (e.currentTarget.src = logoRemoveG)
									}
									onMouseOut={
										e => (e.currentTarget.src = logoRemoveW)
									}
								/>
							</Popconfirm>
						</Col>
					</Row>


					<Row className="editPanel" id={otherChannelId} style={{ display: this.state.data.usageList[index].Channel == "Others" ? '' : 'none' }}>
						<div id="title_a">
							<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} >Other Channel</Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
								<Input maxLength={100} name={"data.usageList[" + index + "].otherChannel"} placeholder="" onChange={this.handlerInputChange2} value={this.state.data.usageList[index].otherChannel} />
							</Col>
						</div>
					</Row>
					<Row className="editPanel_error" hidden={!serverInd}>
						<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ></Col>
						<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17} >
							<p style={{ color: 'red' }}  >{channelStr}</p>
						</Col>
						<Col hidden={usageList.length == 1} xs={20} sm={20} md={20} lg={1} xl={1} xxl={1}   >
						</Col>
					</Row>
					<Row className="editPanel">
						<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}  ><Tooltip placement="right" title="Format: jpg, jpeg, png, doc, docx, pdf. Maximum file size 10MB.">Attachment<span style={{ color: 'red' }}> </span></Tooltip></Col>
						<Col className="editLeadfield" xs={10} sm={10} md={5} lg={5} xl={5} xxl={5} style={{ float: 'left', color: '#000' }} >
							<Upload {...props1} fileList={this.state.data.usageList[index].AttachmentList} disabled={!this.control.usageAttachment && !this.state.data.usedLeadExternally}  >
								<button className="dftant-btn" disabled={!this.control.usageAttachment && !this.state.data.usedLeadExternally}  >
									<Icon type="upload" onClick={this.upLoadImg} /> Click to Upload
													</button>
							</Upload>    </Col>
					</Row>
					{this.genChannelUrlList(index)}
					<Row className="editPanel" id={descriptionId}>
						<div id="title_a">
							<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} >Description</Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
								<TextArea style={{ height: '100px' }} rows={10} name={"data.usageList[" + index + "].description"} maxLength={3000} disabled={!this.control.description && !this.state.data.usedLeadExternally} placeholder="" onChange={this.handlerInputChange2} value={this.state.data.usageList[index].description} />
							</Col>
						</div>
					</Row>
					<Row className="editPanel" hidden={!descInd}>
						<div id="title_a">
							<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} ></Col>
							<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
								<p style={{ color: 'red' }}  >{usageDescriptionStr}</p>
							</Col>
						</div>
					</Row>
				</div>
			)
		})
	}

	genChannelUrlList = (parantIndex) => {
		var urlList = this.state.data.usageList[parantIndex].UrlList;
		if (urlList == undefined) {
			urlList = [];
		}
		return urlList.map((item, index) => {
			if (index == 0) {
				return (


					<Row className="editPanel">
						<Col className="editLeadPanel_label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} ><Tooltip placement="right" title="">URL</Tooltip></Col>
						<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
							<Input name={"data.usageList[" + parantIndex + "].UrlList[" + index + "]"} maxLength={100} disabled={!this.control.usageUrl && !this.state.data.usedLeadExternally} placeholder="" onChange={this.handlerInputChange2} value={this.state.data.usageList[parantIndex].UrlList[index]} />
						</Col>
						<Col hidden={!this.control.usageUrl && !this.state.data.usedLeadExternally} className="fieldButtonPLus" xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} >



							<img className="fieldButtonPLusIcon" onClick={() => this.addToChannelUrlList(parantIndex)}
								src={logoPlusW}
								onMouseOver={
									e => (e.currentTarget.src = logoPlusG)
								}
								onMouseOut={
									e => (e.currentTarget.src = logoPlusW)
								}
							/>


						</Col>

					</Row>
				)
			} else {
				return (
					<Row className="editPanel">
						<Col className="label" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} ></Col>
						<Col className="editLeadfield" xs={20} sm={20} md={20} lg={17} xl={17} xxl={17}  >
							<Input name={"data.usageList[" + parantIndex + "].UrlList[" + index + "]"} maxLength={100} disabled={!this.control.usageUrl && !this.state.data.usedLeadExternally} placeholder="" onChange={this.handlerInputChange2} value={this.state.data.usageList[parantIndex].UrlList[index]} />
						</Col>
						<Col className="fieldButtonPLus" xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} >

							<img className="fieldButtonPLusIcon" onClick={() => this.removeChannelUrl(parantIndex, index)}
								disabled={!this.control.usageUrl && !this.state.data.usedLeadExternally}
								src={logoMinusW}
								onMouseOver={
									e => (e.currentTarget.src = logoMinusG)
								}
								onMouseOut={
									e => (e.currentTarget.src = logoMinusW)
								}
							/>

						</Col>
					</Row>
				)
			}
		})
	}

	usageAdd = () => {
		var data = this.state.data;
		var usageList = this.state.data.usageList;
		if (usageList.length < 10) {
			usageList.push({ Channel: null, AttachmentList: [], UrlList: [null,], description: null });
			data.usageList = usageList;
			this.setState({ data: data });
		}
	}

	addToChannelUrlList = (index) => {
		var data = this.state.data;
		var usageList = data.usageList;
		if (usageList[index].UrlList.length < 10) {
			var newUrl = "";
			usageList[index].UrlList.push(newUrl);
			data.usageList = usageList;
			this.setState({ data: data });
		}
	}

	removeChannelUrl = (parantIndex, index) => {
		var data = this.state.data;
		var usageList = data.usageList;
		//var newList = [];
		usageList[parantIndex].UrlList.splice(index, 1);
		data.usageList = usageList;
		this.setState({ data: data });
	}




	dataValidationAndFormat = (dataEntity) => {

		console.log("dataValidationAndFormat", dataEntity);

		this.setState({
			check21: false, check22: false, check23: false,
			checkTopicTheme: false, check1: false, first_server: false, mid_server: false, last_server: false, check2: false, check3: false, check4: false,
			check5: false, check6: false, check7: false, check8: false, check9: false, check10: false, checkTier: false, checkEmailRec: false,
			checkCompanyName0: false, checkCompanyName1: false, checkCompanyName2: false, checkHLoc0: false, checkHLoc1: false, checkHLoc2: false,
			checkCmpInd0: false, checkCmpInd1: false, checkCmpInd2: false, checkKpi: false, checkKpiOversea: false, checkKpiEither: false  
		});
		var checkNumber = 0;



		var Locations = dataEntity.location;
		var industrys = dataEntity.industry;

		var LocationList = this.state.locationList;
		var IndustryList = this.state.IndustryList;


		var storyDescriptionStr = dataEntity.storyDescription;
		var storyDescriptionText = this.state.storyDescriptionText;
		var jumpingInd = "N";

		//			console.log("jumpingInd 1", jumpingInd);

		if (dataEntity.typeOfSuccesses == undefined || dataEntity.typeOfSuccesses.length == 0) {
			checkNumber++;

			this.setState({ check10: true, sessionOpenBasicInfo: true });

			let anchorElement = document.getElementById("type_success");
			if (anchorElement) {

				setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
				jumpingInd = "Y";
			}
		}
		if (dataEntity.leadNature == undefined || dataEntity.leadNature.length == 0) {
			checkNumber++;

			this.setState({ check23: true, sessionOpenBasicInfo: true });

			let anchorElement = document.getElementById("type_leadNature");
			if (anchorElement) {

				setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
				jumpingInd = "Y";
			}
		}
		console.log("Check KPI:", this.state.gui.mandatoryKpiOversea , 
		this.state.gui.mandatoryKpi, dataEntity.kpi, dataEntity.kpiOversea);
		if( this.state.gui.mandatoryKpiOversea && this.state.gui.mandatoryKpi
		&& (dataEntity.kpi == undefined || dataEntity.kpi == 0)
		&& (dataEntity.kpiOversea == undefined || dataEntity.kpiOversea == 0)){
			console.log("Check KPI:", 1);
			checkNumber++;
			this.setState({ checkKpiEither: true, sessionOpenBasicInfo: true });
			let anchorElement = document.getElementById("ts_kpi");
			if (anchorElement) {

				setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
				jumpingInd = "Y";
			}
		}else if (!this.state.gui.mandatoryKpiOversea && this.state.gui.mandatoryKpi 
		&& (dataEntity.kpi == undefined || dataEntity.kpi == 0)) {
			console.log("Check KPI:", 2);
			checkNumber++;

			this.setState({ checkKpi: true, sessionOpenBasicInfo: true });

			let anchorElement = document.getElementById("ts_kpi");
			if (anchorElement) {

				setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
				jumpingInd = "Y";
			}
		}else if (this.state.gui.mandatoryKpiOversea && !this.state.gui.mandatoryKpi
		&& (dataEntity.kpiOversea == undefined || dataEntity.kpiOversea == 0)) {
			console.log("Check KPI:", 3);
			checkNumber++;

			this.setState({ checkKpiOversea: true, sessionOpenBasicInfo: true });

			let anchorElement = document.getElementById("ts_kpi_Oversea");
			if (anchorElement) {

				setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
				jumpingInd = "Y";
			}
		}
		
		
		
		//			console.log("jumpingInd 2", jumpingInd);
		if (!storyDescriptionStr || storyDescriptionStr == "<p></p>" || storyDescriptionText == "" || storyDescriptionText.length == 0) {
			checkNumber++;
			this.setState({ check2: true, sessionOpenStoryDesc: true });
			if (jumpingInd == "N") {
				let anchorElement = document.getElementById("descri_a");
				if (anchorElement) {
					setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
					jumpingInd = "Y";
				}
			}


		}
		//			console.log("jumpingInd 3" , jumpingInd);

		if (Locations == undefined || Locations.length == 0) {
			checkNumber++;
			this.setState({ check3: true, sessionOpenBasicInfo: true });
			if (jumpingInd == "N") {
				let anchorElement = document.getElementById("location_a");
				if (anchorElement) {
					setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
					jumpingInd = "Y";
				}
			}

		}
		
		//leadInforDiscVisable
		if (this.leadInforDiscVisable) {

			var infoDiscList = dataEntity.infoDiscList;
			if (infoDiscList == undefined || infoDiscList.length == 0) {
				checkNumber++;
				this.setState({ check21: true, sessionOpenBasicInfo: true });
				let anchorElement = document.getElementById("type_leaddisc");
				if (anchorElement) {
					setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
					jumpingInd = "Y";
				}

			}

		}
		
		//devStatus
		

			var devStatus = dataEntity.devStatus;
			if (devStatus == undefined || devStatus.length == 0) {
				checkNumber++;
				this.setState({ check22: true, sessionOpenBasicInfo: true });
				let anchorElement = document.getElementById("type_devStatus");
				if (anchorElement) {
					setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
					jumpingInd = "Y";
				}

			}

		
		
		//leadNature
		

			// var leadNature = dataEntity.leadNature;
			// if (leadNature == undefined || leadNature.length == 0) {
			// 	checkNumber++;
			// 	this.setState({ check23: true, sessionOpenBasicInfo: true });
			// 	let anchorElement = document.getElementById("type_leadNature");
			// 	if (anchorElement) {
			// 		setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
			// 		jumpingInd = "Y";
			// 	}

			// }
		
		
		
		//			console.log("jumpingInd 4", jumpingInd);
		if (this.otherTypeOfSuccessCaseVisable) {

			var otherTypeOfSuccessCase = dataEntity.otherTypeOfSuccessCase;
			if (otherTypeOfSuccessCase == undefined || otherTypeOfSuccessCase.length == 0) {
				checkNumber++;
				this.setState({ check9: true, sessionOpenBasicInfo: true });
				let anchorElement = document.getElementById("type_success");
				if (anchorElement) {
					setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
					jumpingInd = "Y";
				}

			}

		}
		//			console.log("jumpingInd 5", jumpingInd);
		if (industrys == undefined || industrys.length == 0) {
			checkNumber++;
			this.setState({ check7: true, sessionOpenBasicInfo: true });
			if (jumpingInd == "N") {
				let anchorElement = document.getElementById("industry_a");
				if (anchorElement) {
					setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
					jumpingInd = "Y";
				}
			}

		}
		//			console.log("jumpingInd 6", jumpingInd);
		var topicls = dataEntity.topicl;
		
		 if (topicls == undefined || topicls.length == 0) {
		 	checkNumber++;
		 	this.setState({ checkTopicTheme: true, sessionOpenBasicInfo: true });
		 	
		 	if (jumpingInd == "N") {
		 		let anchorElement = document.getElementById("topictheme_a");
		 		
		 		if (anchorElement) {	 			

		 			setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
		 			jumpingInd = "Y";
		 		}
		 	}
		 	
		 }
					


		dataEntity.companyInfoList.map((item, index) => {
			console.log("Company Info", item, index);
			// Company/Brand Names
			if (item.companyName == undefined || item.companyName == ""  
				) {
				checkNumber++;
				if (index == 0) {
					this.setState({ checkCompanyName0: true, sessionOpenCompanyInfo: true });
				}
				if (index == 1) {
					this.setState({ checkCompanyName1: true, sessionOpenCompanyInfo: true });
				}
				if (index == 2) {
					this.setState({ checkCompanyName2: true, sessionOpenCompanyInfo: true });
				}

				if (jumpingInd == "N") {
					let anchorElement = document.getElementById('companyBrand_' + index);
					if (anchorElement) {
						setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
						jumpingInd = "Y";
					}
				}

			}

			// Headquarters Country


			if (item.hCountryId == undefined || item.hCountryId == "") {
				checkNumber++;
				if (index == 0) {
					this.setState({ checkHLoc0: true, sessionOpenCompanyInfo: true });
				}
				if (index == 1) {
					this.setState({ checkHLoc1: true, sessionOpenCompanyInfo: true });
				}
				if (index == 2) {
					this.setState({ checkHLoc2: true, sessionOpenCompanyInfo: true });
				}
				if (jumpingInd == "N") {
					let anchorElement = document.getElementById('headquarters_' + index);
					if (anchorElement) {
						setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
						jumpingInd = "Y";
					}
				}

			}

			

				if (index == 0) {
					//Clean before setup
					dataEntity.companyName = undefined;
					dataEntity.headquartersCountryId = undefined;
					dataEntity.headquartersCountry = undefined;
					dataEntity.url = undefined;
					dataEntity.cmpInd01Id = undefined;
					dataEntity.cmpInd02Id = undefined;
					dataEntity.cmpInd03Id = undefined;
					dataEntity.cmpInd04Id = undefined;
					dataEntity.cmpInd05Id = undefined;
					dataEntity.cmpInd01 = undefined;
					dataEntity.cmpInd02 = undefined;
					dataEntity.cmpInd03 = undefined;
					dataEntity.cmpInd04 = undefined;
					dataEntity.cmpInd05 = undefined;
					dataEntity.companyName1 = undefined;
					dataEntity.headquartersCountryId1 = undefined;
					dataEntity.headquartersCountry1 = undefined;
					dataEntity.url1 = undefined;
					dataEntity.cmpInd11Id = undefined;
					dataEntity.cmpInd12Id = undefined;
					dataEntity.cmpInd13Id = undefined;
					dataEntity.cmpInd14Id = undefined;
					dataEntity.cmpInd15Id = undefined;
					dataEntity.cmpInd11 = undefined;
					dataEntity.cmpInd12 = undefined;
					dataEntity.cmpInd13 = undefined;
					dataEntity.cmpInd14 = undefined;
					dataEntity.cmpInd15 = undefined;
					dataEntity.companyName2 = undefined;
					dataEntity.headquartersCountryId2 = undefined;
					dataEntity.headquartersCountry2 = undefined;
					dataEntity.url2 = undefined;
					dataEntity.cmpInd21Id = undefined;
					dataEntity.cmpInd22Id = undefined;
					dataEntity.cmpInd23Id = undefined;
					dataEntity.cmpInd24Id = undefined;
					dataEntity.cmpInd25Id = undefined;
					dataEntity.cmpInd21 = undefined;
					dataEntity.cmpInd22 = undefined;
					dataEntity.cmpInd23 = undefined;
					dataEntity.cmpInd24 = undefined;
					dataEntity.cmpInd25 = undefined;
					//


					dataEntity.companyName = item.companyName;
					dataEntity.headquartersCountryId = item.hCountryId;
					dataEntity.headquartersCountry = getLabelFromList(LocationList, item.hCountryId);
					dataEntity.url = item.cmpUrl;

					
					if (item.cmpIndusty != undefined) {
						item.cmpIndusty.map((cmpIndItem, cmpIndIndex) => {

							if (cmpIndIndex == 0) {
								dataEntity.cmpInd01Id = cmpIndItem;
								dataEntity.cmpInd01 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 1) {
								dataEntity.cmpInd02Id = cmpIndItem;
								dataEntity.cmpInd02 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 2) {
								dataEntity.cmpInd03Id = cmpIndItem;
								dataEntity.cmpInd03 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 3) {
								dataEntity.cmpInd04Id = cmpIndItem;
								dataEntity.cmpInd04 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 4) {
								dataEntity.cmpInd05Id = cmpIndItem;
								dataEntity.cmpInd05 = getLabelFromList(IndustryList, cmpIndItem);
							}


						});
					}
					console.log("dataEntity.cmpIndusty " + index, item.cmpIndusty, dataEntity.cmpInd01);
					if (item.cmpIndusty == undefined ||
							dataEntity.cmpInd01 == null || 
							dataEntity.cmpInd01 == undefined || dataEntity.cmpInd01 =='') {
						this.setState({ checkCmpInd0: true, sessionOpenCompanyInfo: true });
					
					
						if (jumpingInd == "N") {
							let anchorElement = document.getElementById('cmpIndustry_' + index);
							
							console.log("dataEntity.cmpIndusty " + index, anchorElement);
							if (anchorElement) {
								setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
								checkNumber++;
								jumpingInd = "Y";
							}
						}
						
					}
				}



				if (index == 1) {
					dataEntity.companyName1 = item.companyName;
					dataEntity.headquartersCountryId1 = item.hCountryId;
					dataEntity.headquartersCountry1 = getLabelFromList(LocationList, item.hCountryId);
					dataEntity.url1 = item.cmpUrl;





					if (item.cmpIndusty != undefined) {
						item.cmpIndusty.map((cmpIndItem, cmpIndIndex) => {

							if (cmpIndIndex == 0) {
								dataEntity.cmpInd11Id = cmpIndItem;
								dataEntity.cmpInd11 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 1) {
								dataEntity.cmpInd12Id = cmpIndItem;
								dataEntity.cmpInd12 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 2) {
								dataEntity.cmpInd13Id = cmpIndItem;
								dataEntity.cmpInd13 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 3) {
								dataEntity.cmpInd14Id = cmpIndItem;
								dataEntity.cmpInd14 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 4) {
								dataEntity.cmpInd15Id = cmpIndItem;
								dataEntity.cmpInd15 = getLabelFromList(IndustryList, cmpIndItem);
							}


						});
					}
					
					if (item.cmpIndusty == undefined ||
							dataEntity.cmpInd11 == null || 
							dataEntity.cmpInd11 == undefined || dataEntity.cmpInd11 =='') {
						this.setState({ checkCmpInd1: true, sessionOpenCompanyInfo: true });				
					
						if (jumpingInd == "N") {
							let anchorElement = document.getElementById('cmpIndustry_' + index);
							if (anchorElement) {
								setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
								checkNumber++;
								jumpingInd = "Y";
							}
						}
					}
				}
				if (index == 2) {
					dataEntity.companyName2 = item.companyName;
					dataEntity.headquartersCountryId2 = item.hCountryId;
					dataEntity.headquartersCountry2 = getLabelFromList(LocationList, item.hCountryId);
					dataEntity.url2 = item.cmpUrl;

					if (item.cmpIndusty != undefined) {
						item.cmpIndusty.map((cmpIndItem, cmpIndIndex) => {

							if (cmpIndIndex == 0) {
								dataEntity.cmpInd21Id = cmpIndItem;
								dataEntity.cmpInd21 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 1) {
								dataEntity.cmpInd22Id = cmpIndItem;
								dataEntity.cmpInd22 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 2) {
								dataEntity.cmpInd23Id = cmpIndItem;
								dataEntity.cmpInd23 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 3) {
								dataEntity.cmpInd24Id = cmpIndItem;
								dataEntity.cmpInd24 = getLabelFromList(IndustryList, cmpIndItem);
							}
							if (cmpIndIndex == 4) {
								dataEntity.cmpInd25Id = cmpIndItem;
								dataEntity.cmpInd25 = getLabelFromList(IndustryList, cmpIndItem);
							}


						});
					}
					if (item.cmpIndusty == undefined ||
							dataEntity.cmpInd21 == null || 
							dataEntity.cmpInd21 == undefined || dataEntity.cmpInd21 =='') {
						this.setState({ checkCmpInd2: true, sessionOpenCompanyInfo: true });
					
					
						if (jumpingInd == "N") {
							let anchorElement = document.getElementById('cmpIndustry_' + index);
							if (anchorElement) {
								setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
								checkNumber++;
								jumpingInd = "Y";
							}
						}
					}
					
				}
			

		});

		console.log("dataEntity save", dataEntity);






		//			console.log("jumpingInd 8", jumpingInd);
		var serviceList = this.state.data.serviceList;
		var serviceEvent1 = serviceList[0];
		var serviceEvent2 = serviceList[1] == undefined ? [] : serviceList[1];
		var serviceEvent3 = serviceList[2] == undefined ? [] : serviceList[2];
		var serviceEvent4 = serviceList[3] == undefined ? [] : serviceList[3];
		var serviceEvent5 = serviceList[4] == undefined ? [] : serviceList[4];
		var eventList = this.state.eventList;
		var event1Ind = "N";
		var event2Ind = "N";
		var event3Ind = "N";
		var event4Ind = "N";
		var event5Ind = "N";
		var event1Type = serviceEvent1.eventType;
		var event2Type = serviceEvent2.eventType;
		var event3Type = serviceEvent3.eventType;
		var event4Type = serviceEvent4.eventType;
		var event5Type = serviceEvent5.eventType;
		eventList.map((subItem, subIndex) => {
			if (event1Type && event1Type != "" && (event1Type == "Exhibition"  ||
				event1Type == "Conference & Forum" )) {
				if (serviceEvent1.eventId == subItem.id) {
					event1Ind = "Y";
				}
			} else {
				event1Ind = "Y";
			}
			if (event2Type && event2Type != "" && (event2Type == "Exhibition"  ||
				event2Type == "Conference & Forum" )) {
				if (serviceEvent2.eventId == subItem.id) {
					event2Ind = "Y";
				}
			} else {
				event2Ind = "Y";
			}
			if (event3Type && event3Type != "" && (event3Type == "Exhibition"  ||
				event3Type == "Conference & Forum" )) {
				if (serviceEvent3.eventId == subItem.id) {
					event3Ind = "Y";
				}
			} else {
				event3Ind = "Y";
			}
			if (event4Type && event4Type != "" && (event4Type == "Exhibition" ||
				event4Type == "Conference & Forum" )) {
				if (serviceEvent4.eventId == subItem.id) {
					event4Ind = "Y";
				}
			} else {
				event4Ind = "Y";
			}
			if (event5Type && event5Type != "" && (event5Type == "Exhibition"  ||
				event5Type == "Conference & Forum" )) {
				if (serviceEvent5.eventId == subItem.id) {
					event5Ind = "Y";
				}
			} else {
				event5Ind = "Y";
			}

		});
		//				console.log("jumpingInd 9", jumpingInd);
		var isThereTdcInd = dataEntity.isThereTdcInd;
		if (isThereTdcInd) {
			var eventNameStr1 = "";
			var eventTypeStr1 = "";
			var eventDateStr1 = "";
			var eventNameStr2 = "";
			var eventTypeStr2 = "";
			var eventDateStr2 = "";
			var eventNameStr3 = "";
			var eventTypeStr3 = "";
			var eventDateStr3 = "";
			var eventNameStr4 = "";
			var eventTypeStr4 = "";
			var eventDateStr4 = "";
			var eventNameStr5 = "";
			var eventTypeStr5 = "";
			var eventDateStr5 = "";
			if (serviceList.length >= 1) {
				if (((serviceEvent1.eventName == null || serviceEvent1.eventName == "") && (serviceEvent1.eventId == null || serviceEvent1.eventId == "")) || (serviceEvent1.eventType == null || serviceEvent1.eventDate == "") || (serviceEvent1.eventDate == null || serviceEvent1.eventDate == "")) {
					checkNumber++;
					if ((serviceEvent1.eventName == null || serviceEvent1.eventName == "") && (serviceEvent1.eventId == null || serviceEvent1.eventId == "")) {
						eventNameStr1 = "Service/Event Name is required.";
					}
					if (serviceEvent1.eventType == null || serviceEvent1.eventType == "") {
						eventTypeStr1 = "Service/Event Type is required.";
					}
					if (serviceEvent1.eventDate == null || serviceEvent1.eventDate == "") {
						eventDateStr1 = "Service/Event Date is required.";
					}
					this.setState({ first_server: true, sessionOpenHKTDCInvo: true });
					if (jumpingInd == "N") {
						let anchorElement = document.getElementById("service_a");
						if (anchorElement) {
							setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
							jumpingInd = "Y";
						}
					}

				}
				if (event1Ind == "N") {
					checkNumber++;
					eventNameStr1 = "Service/Event Name is required.";
					this.setState({ first_server: true, sessionOpenHKTDCInvo: true });
					if (jumpingInd == "N") {
						let anchorElement = document.getElementById("service_a");
						if (anchorElement) {
							setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
							jumpingInd = "Y";
						}
					}

				}
			}
			if (serviceList.length >= 2) {
				if (((serviceEvent2.eventName == null || serviceEvent2.eventName == "") && (serviceEvent2.eventId == null || serviceEvent2.eventId == "")) || (serviceEvent2.eventType == null || serviceEvent2.eventDate == "") || (serviceEvent2.eventDate == null || serviceEvent2.eventDate == "")) {
					checkNumber++;
					if ((serviceEvent2.eventName == null || serviceEvent2.eventName == "") && (serviceEvent2.eventId == null || serviceEvent2.eventId == "")) {
						eventNameStr2 = "Service/Event Name is required.";
					}
					if (serviceEvent2.eventType == null || serviceEvent2.eventType == "") {
						eventTypeStr2 = "Service/Event Type is required.";
					}
					if (serviceEvent2.eventDate == null || serviceEvent2.eventDate == "") {
						eventDateStr2 = "Service/Event Date is required.";
					}
					this.setState({ mid_server: true, sessionOpenHKTDCInvo: true });
					if (jumpingInd == "N") {
						let anchorElement = document.getElementById("service_b");
						if (anchorElement) {
							setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
							jumpingInd = "Y";
						}
					}

				}
				if (event2Ind == "N") {
					checkNumber++;
					eventNameStr2 = "Service/Event Name is required.";
					this.setState({ mid_server: true, sessionOpenHKTDCInvo: true });
					if (jumpingInd == "N") {
						let anchorElement = document.getElementById("service_b");
						if (anchorElement) {
							setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
							jumpingInd = "Y";
						}
					}

				}
			}
			if (serviceList.length >= 3) {
				if (((serviceEvent3.eventName == null || serviceEvent3.eventName == "") && (serviceEvent3.eventId == null || serviceEvent3.eventId == "")) || (serviceEvent3.eventType == null || serviceEvent3.eventDate == "") || (serviceEvent3.eventDate == null || serviceEvent3.eventDate == "")) {
					checkNumber++;
					if ((serviceEvent3.eventName == null || serviceEvent3.eventName == "") && (serviceEvent3.eventId == null || serviceEvent3.eventId == "")) {
						eventNameStr3 = "Service/Event Name is required.";
					}
					if (serviceEvent3.eventType == null || serviceEvent3.eventType == "") {
						eventTypeStr3 = "Service/Event Type is required.";
					}
					if (serviceEvent3.eventDate == null || serviceEvent3.eventDate == "") {
						eventDateStr3 = "Service/Event Date is required.";
					}
					this.setState({ last_server: true, sessionOpenHKTDCInvo: true });
					if (jumpingInd == "N") {
						let anchorElement = document.getElementById("service_c");
						if (anchorElement) {
							setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
							jumpingInd = "Y";
						}
					}

				}
				if (event3Ind == "N") {
					checkNumber++;
					eventNameStr3 = "Service/Event Name is required.";
					this.setState({ last_server: true, sessionOpenHKTDCInvo: true });
					if (jumpingInd == "N") {
						let anchorElement = document.getElementById("service_c");
						if (anchorElement) {
							setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
							jumpingInd = "Y";
						}
					}

				}
			}
			if (serviceList.length >= 4) {
				if (((serviceEvent4.eventName == null || serviceEvent4.eventName == "")
					&& (serviceEvent4.eventId == null || serviceEvent4.eventId == ""))
					|| (serviceEvent4.eventType == null || serviceEvent4.eventDate == "")
					|| (serviceEvent4.eventDate == null || serviceEvent4.eventDate == "")) {
					checkNumber++;
					if ((serviceEvent4.eventName == null || serviceEvent4.eventName == "")
						&& (serviceEvent4.eventId == null || serviceEvent4.eventId == "")) {
						eventNameStr4 = "Service/Event Name is required.";
					}
					if (serviceEvent4.eventType == null || serviceEvent4.eventType == "") {
						eventTypeStr4 = "Service/Event Type is required.";
					}
					if (serviceEvent4.eventDate == null || serviceEvent4.eventDate == "") {
						eventDateStr4 = "Service/Event Date is required.";
					}
					this.setState({ last_server: true, sessionOpenHKTDCInvo: true });
					if (jumpingInd == "N") {
						let anchorElement = document.getElementById("service_d");
						if (anchorElement) {
							setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
							jumpingInd = "Y";
						}
					}

				}
				if (event4Ind == "N") {
					checkNumber++;
					eventNameStr4 = "Service/Event Name is required.";
					this.setState({ last_server: true, sessionOpenHKTDCInvo: true });
					if (jumpingInd == "N") {
						let anchorElement = document.getElementById("service_d");
						if (anchorElement) {
							setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
							jumpingInd = "Y";
						}

					}

				}
			}
			//					console.log("jumpingInd 10", jumpingInd);
			if (serviceList.length >= 5) {
				if (((serviceEvent5.eventName == null || serviceEvent5.eventName == "")
					&& (serviceEvent5.eventId == null || serviceEvent5.eventId == ""))
					|| (serviceEvent5.eventType == null || serviceEvent5.eventDate == "")
					|| (serviceEvent5.eventDate == null || serviceEvent5.eventDate == "")) {
					checkNumber++;
					if ((serviceEvent5.eventName == null || serviceEvent5.eventName == "")
						&& (serviceEvent5.eventId == null || serviceEvent5.eventId == "")) {
						eventNameStr4 = "Service/Event Name is required.";
					}
					if (serviceEvent5.eventType == null || serviceEvent5.eventType == "") {
						eventTypeStr5 = "Service/Event Type is required.";
					}
					if (serviceEvent5.eventDate == null || serviceEvent5.eventDate == "") {
						eventDateStr5 = "Service/Event Date is required.";
					}
					this.setState({ last_server: true, sessionOpenHKTDCInvo: true });
					if (jumpingInd == "N") {
						let anchorElement = document.getElementById("service_e");
						if (anchorElement) {
							setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
							jumpingInd = "Y";
						}
					}

				}
				if (event5Ind == "N") {
					checkNumber++;
					eventNameStr5 = "Service/Event Name is required.";
					this.setState({ last_server: true, sessionOpenHKTDCInvo: true });
					if (jumpingInd == "N") {
						let anchorElement = document.getElementById("service_e");
						if (anchorElement) {
							setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
							jumpingInd = "Y";
						}
					}

				}
			}

			this.setState({ eventNameStr1: eventNameStr1 });
			this.setState({ eventTypeStr1: eventTypeStr1 });
			this.setState({ eventDateStr1: eventDateStr1 });
			this.setState({ eventNameStr2: eventNameStr2 });
			this.setState({ eventTypeStr2: eventTypeStr2 });
			this.setState({ eventDateStr2: eventDateStr2 });
			this.setState({ eventTypeStr3: eventTypeStr3 });
			this.setState({ eventDateStr3: eventDateStr3 });
			this.setState({ eventNameStr3: eventNameStr3 });
			this.setState({ eventTypeStr4: eventTypeStr4 });
			this.setState({ eventDateStr4: eventDateStr4 });
			this.setState({ eventNameStr4: eventNameStr4 });
			this.setState({ eventTypeStr5: eventTypeStr5 });
			this.setState({ eventDateStr5: eventDateStr5 });
			this.setState({ eventNameStr5: eventNameStr5 });
		}

		var usedLeadExternally = dataEntity.usedLeadExternally;

		if (usedLeadExternally) {
			var usageList = this.state.data.usageList;
			for (var i = 0; i < usageList.length; i++) {
				var channelStr = "";

				var descriptionStr = "";
				var usage = usageList[i];
				let anchorElement = "";
				console.log("usage", usage);
				if (this.isEmpty(usage.Channel) || this.isEmpty(usage.description)
					|| (usage.Channel == "Others" && this.isEmpty(usage.otherChannel))) {		// channel and desc is mandatory
					checkNumber++;
					if (this.isEmpty(usage.Channel)) {
						channelStr = "Usage channel is required.";
						anchorElement = document.getElementById("usage_" + i);
					}
					if (this.isEmpty(usage.description)) {
						descriptionStr = "Usage description is required.";
						anchorElement = document.getElementById("description_" + i);
					}

					if (usage.Channel == "Others" && this.isEmpty(usage.otherChannel)) {
						channelStr = "Description required.";

						anchorElement = document.getElementById("otherChannel_" + i);


					}




				}
				if (i == 0) {
					this.setState({ usageChannel0: channelStr, sessionOpenStoryPublic: true });
					this.setState({ usageDescriptionInd0: descriptionStr, sessionOpenStoryPublic: true });
					if (channelStr == "Others") {
						this.setState({ otherChannel0: usage.otherChannel, sessionOpenStoryPublic: true });
					} else {
						this.setState({ otherChannel0: "" });
					}

				}
				if (i == 1) {
					this.setState({ usageChannel1: channelStr, sessionOpenStoryPublic: true });
					this.setState({ usageDescriptionInd1: descriptionStr, sessionOpenStoryPublic: true });
					if (channelStr == "Others") {
						this.setState({ otherChannel1: usage.otherChannel, sessionOpenStoryPublic: true });
					} else {
						this.setState({ otherChannel1: "" });
					}
				}
				if (i == 2) {
					this.setState({ usageChannel2: channelStr, sessionOpenStoryPublic: true });
					this.setState({ usageDescriptionInd2: descriptionStr, sessionOpenStoryPublic: true });
					if (channelStr == "Others") {
						this.setState({ otherChannel2: usage.otherChannel, sessionOpenStoryPublic: true });
					} else {
						this.setState({ otherChannel2: "" });
					}
				}
				if (i == 3) {
					this.setState({ usageChannel3: channelStr, sessionOpenStoryPublic: true });
					this.setState({ usageDescriptionInd3: descriptionStr, sessionOpenStoryPublic: true });
					if (channelStr == "Others") {
						this.setState({ otherChannel3: usage.otherChannel, sessionOpenStoryPublic: true });
					} else {
						this.setState({ otherChannel3: "" });
					}
				}
				if (i == 4) {
					this.setState({ usageChannel4: channelStr, sessionOpenStoryPublic: true });
					this.setState({ usageDescriptionInd4: descriptionStr, sessionOpenStoryPublic: true });
					if (channelStr == "Others") {
						this.setState({ otherChannel4: usage.otherChannel, sessionOpenStoryPublic: true });
					} else {
						this.setState({ otherChannel4: "" });
					}
				}
				if (i == 5) {
					this.setState({ usageChannel5: channelStr, sessionOpenStoryPublic: true });
					this.setState({ usageDescriptionInd5: descriptionStr, sessionOpenStoryPublic: true });
					if (channelStr == "Others") {
						this.setState({ otherChannel5: usage.otherChannel, sessionOpenStoryPublic: true });
					} else {
						this.setState({ otherChannel5: "" });
					}
				}
				if (i == 6) {
					this.setState({ usageChannel6: channelStr, sessionOpenStoryPublic: true });
					this.setState({ usageDescriptionInd6: descriptionStr, sessionOpenStoryPublic: true });
					if (channelStr == "Others") {
						this.setState({ otherChannel6: usage.otherChannel, sessionOpenStoryPublic: true });
					} else {
						this.setState({ otherChannel6: "" });
					}
				}
				if (i == 7) {
					this.setState({ usageChannel7: channelStr, sessionOpenStoryPublic: true });
					this.setState({ usageDescriptionInd7: descriptionStr, sessionOpenStoryPublic: true });
					if (channelStr == "Others") {
						this.setState({ otherChannel7: usage.otherChannel, sessionOpenStoryPublic: true });
					} else {
						this.setState({ otherChannel7: "" });
					}
				}
				if (i == 8) {
					this.setState({ usageChannel8: channelStr, sessionOpenStoryPublic: true });
					this.setState({ usageDescriptionInd8: descriptionStr, sessionOpenStoryPublic: true });
					if (channelStr == "Others") {
						this.setState({ otherChannel8: usage.otherChannel, sessionOpenStoryPublic: true });
					} else {
						this.setState({ otherChannel8: "" });
					}
				}
				if (i == 9) {
					this.setState({ usageChannel9: channelStr, sessionOpenStoryPublic: true });
					this.setState({ usageDescriptionInd9: descriptionStr, sessionOpenStoryPublic: true });
					if (channelStr == "Others") {
						this.setState({ otherChannel9: usage.otherChannel, sessionOpenStoryPublic: true });
					} else {
						this.setState({ otherChannel9: "" });
					}
				}
				console.log("jumpingInd 11", jumpingInd, (jumpingInd == "N"), anchorElement);
				if (jumpingInd == "N") {
					if (anchorElement) {

						setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
						jumpingInd = "Y";
					}

				}


			}
		}

		if (!this.control.emailReceiver && (this.state.data.emailReceiverList == undefined || this.state.data.emailReceiverList == ""
			|| this.state.data.emailReceiverList.length == 0)) {

			checkNumber++;
			this.setState({ checkEmailRec: true, sessionOpenOtherInfo: true });
			if (jumpingInd == "N") {
				let anchorElement = document.getElementById("email_a");
				if (anchorElement) {
					setTimeout(() => { anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, scrollTimeout);
					jumpingInd = "Y";
				}
			}

		}





		if (checkNumber > 0) {
			this.state.titleMessage = "Required information is missing!"
			setTimeout(() => {
				this.setState({ titleMessage: '' })
			}, 10000);

			return null;
		}



		var id = this.state.id;
		if (id != undefined && id != "") {
			dataEntity.id = id;
		}

		var fileList;
		var dep = "";
		if (this.state.data.fileList) {
			fileList = this.state.data.fileList;
			for (i = 0; i < fileList.length; i++) {
				if (i == 0) { dep = fileList[i].name; }
				else { dep = dep + Separator + fileList[i].name; }
			}
		}
		dataEntity.attachment = dep;


		let gui = this.state.gui;

		if (gui.radio01 == true || gui.radio01 == "true") {
			dataEntity.language = "EN";
		} else if (gui.radio02 == true || gui.radio02 == "true") {
			dataEntity.language = "TC";
		} else if (gui.radio03 == true || gui.radio03 == "true") {
			dataEntity.language = "SC";
		}

		if (gui.radio1 == true || gui.radio1 == "true") {
			dataEntity.discloseToPublic = "Disclosable";
		} else if (gui.radio2 == true || gui.radio2 == "true") {
			dataEntity.discloseToPublic = "Cannot be Disclosed";
		} else if (gui.radio3 == true || gui.radio3 == "true") {
			dataEntity.discloseToPublic = "Partially disclosable";
		}
console.log("devStatus F",dataEntity.devStatus);

		// usedLeadExternally 
		usedLeadExternally = dataEntity.usedLeadExternally;
		if (usedLeadExternally == undefined) {
			dataEntity.usedLeadExternally = false;
		}

		for (i = 0; i < 10; i++) {		// clear External Usage of Story Lead
			var channels = "channels";
			var usageAttachment = "usageAttachment";
			var usageUrl = "usageUrl";
			var channelDesc = "channelDesc";
			var otherChannel = "otherChannel";
			channels += (i + 1);
			usageAttachment += (i + 1);
			usageUrl += (i + 1);
			channelDesc += (i + 1);
			otherChannel += (i + 1);
			dataEntity[channels] = null;
			dataEntity[usageAttachment] = null;
			dataEntity[usageUrl] = null;
			dataEntity[channelDesc] = null;
			dataEntity[otherChannel] = null;
		}

		if (usedLeadExternally) {
			usageList = this.state.data.usageList;
			for (i = 0; i < usageList.length; i++) {
				channels = "channels";
				usageAttachment = "usageAttachment";
				usageUrl = "usageUrl";
				channelDesc = "channelDesc";
				otherChannel = "otherChannel";
				channels += (i + 1);
				usageAttachment += (i + 1);
				usageUrl += (i + 1);
				channelDesc += (i + 1);
				otherChannel += (i + 1);

				usage = usageList[i];
				dataEntity[channels] = usage.Channel;
				dataEntity[usageAttachment] = this.getAttathments(usage.AttachmentList);
				dataEntity[usageUrl] = this.getChannelUrls(usage.UrlList);
				dataEntity[channelDesc] = usage.description;
				dataEntity[otherChannel] = usage.otherChannel;
			}
		}


		Locations = dataEntity.location;
		//var industrys = dataEntity.industry



		dataEntity.locationId1 = null;
		dataEntity.location1 = null;
		dataEntity.locationId2 = null;
		dataEntity.location2 = null;
		dataEntity.locationId3 = null;
		dataEntity.location3 = null;
		dataEntity.locationId4 = null;
		dataEntity.location4 = null;
		dataEntity.locationId5 = null;
		dataEntity.location5 = null;

		if (Locations.length >= 1) {
			dataEntity.locationId1 = Locations[0];

			dataEntity.location1 = getLabelFromList(LocationList, Locations[0]);
		}
		if (Locations.length >= 2) {
			dataEntity.locationId2 = Locations[1];

			dataEntity.location2 = getLabelFromList(LocationList, Locations[1]);
		}
		if (Locations.length >= 3) {
			dataEntity.locationId3 = Locations[2];

			dataEntity.location3 = getLabelFromList(LocationList, Locations[2]);
		}
		if (Locations.length >= 4) {
			dataEntity.locationId4 = Locations[3];

			dataEntity.location4 = getLabelFromList(LocationList, Locations[3]);
		}
		if (Locations.length >= 5) {
			dataEntity.locationId5 = Locations[4];

			dataEntity.location5 = getLabelFromList(LocationList, Locations[4]);
		}




		dataEntity.industryId1 = null;
		dataEntity.industryId2 = null;
		dataEntity.industryId3 = null;
		dataEntity.industryId4 = null;
		dataEntity.industryId5 = null;

		dataEntity.industry1 = null;
		dataEntity.industry2 = null;
		dataEntity.industry3 = null;
		dataEntity.industry4 = null;
		dataEntity.industry5 = null;


		if (industrys.length >= 1) {
			dataEntity.industryId1 = industrys[0];
			dataEntity.industry1 = getLabelFromList(IndustryList, industrys[0]);
		}
		if (industrys.length >= 2) {
			dataEntity.industryId2 = industrys[1];
			dataEntity.industry2 = getLabelFromList(IndustryList, industrys[1]);
		}
		if (industrys.length >= 3) {
			dataEntity.industryId3 = industrys[2];
			dataEntity.industry3 = getLabelFromList(IndustryList, industrys[2]);
		}
		if (industrys.length >= 4) {
			dataEntity.industryId4 = industrys[3];
			dataEntity.industry4 = getLabelFromList(IndustryList, industrys[3]);
		}
		if (industrys.length >= 5) {
			dataEntity.industryId5 = industrys[4];
			dataEntity.industry5 = getLabelFromList(IndustryList, industrys[4]);
		}


		//topic
		dataEntity.topicTheme1 = null;
		dataEntity.topicTheme2 = null;
		dataEntity.topicTheme3 = null;
		dataEntity.topicTheme4 = null;
		dataEntity.topicTheme5 = null;
		if (topicls != undefined) {
			if (topicls.length >= 1) {
				dataEntity.topicTheme1 = topicls[0];
			}
			if (topicls.length >= 2) {
				dataEntity.topicTheme2 = topicls[1];
			}
			if (topicls.length >= 3) {
				dataEntity.topicTheme3 = topicls[2];
			}
			if (topicls.length >= 4) {
				dataEntity.topicTheme4 = topicls[3];
			}
			if (topicls.length >= 5) {
				dataEntity.topicTheme5 = topicls[4];
			}

		}



//		var urlList = this.state.data.urlList;
//		var urlstr = this.state.data.url;
//
//		if (urlList != undefined && urlList.length > 0) {
//			urlstr = urlstr + ",";
//			urlList.map((item, index) => {
//				if (item.value != "" && item.value != null) {
//					urlstr = urlstr + item.value;
//					if (index != urlList.length - 1) {
//						urlstr = urlstr + ",";
//					}
//				}
//			})
//		}
//		dataEntity.url = urlstr;


		//urlsd 
		var emailReceiver = this.state.data.emailReceiverList;
		var newEmailReceiver = "";

		if (emailReceiver != undefined && emailReceiver != "" && emailReceiver.length != 0) {
			emailReceiver.map((item, index) => {
				newEmailReceiver = newEmailReceiver + item
				if (index != emailReceiver.length - 1) {
					newEmailReceiver = newEmailReceiver + Separator;
				}
			})
		}
		dataEntity.emailReceiver = newEmailReceiver;


		// Is Hong Kong its Asia / Asia-Pacific headquarters ?
		if (dataEntity.isHongKongInd == undefined) {
			dataEntity.isHongKongInd = false;
		}
		// Is there a TDC event / or service involved?
		if (dataEntity.isThereTdcInd == undefined) {
			dataEntity.isThereTdcInd = false;
		}

		for (i = 0; i < 3; i++) {	// clear Connection with HKTDC
			var eventType = "eventType" + (i + 1);
			var eventName = "eventName" + (i + 1);
			var eventId = "eventId" + (i + 1);
			var eventDate = "eventDate" + (i + 1);
			dataEntity[eventType] = null;
			dataEntity[eventName] = null;
			dataEntity[eventId] = null;
			dataEntity[eventDate] = null;
		}

		if (dataEntity.isThereTdcInd) {	// if selected  , check Event
			serviceList = this.state.data.serviceList;
			var eventSecList = this.state.eventList;
			for (i = 0; i < serviceList.length; i++) {
				var eventEntity = serviceList[i] == undefined ? [] : serviceList[i];

				eventType = "eventType" + (i + 1);
				eventName = "eventName" + (i + 1);
				eventId = "eventId" + (i + 1);
				eventDate = "eventDate" + (i + 1);

				var isCombox = false;
				if (eventEntity.eventType == "Conference & Forum" || eventEntity.eventType == "Exhibition") {
					isCombox = true;
				}

				dataEntity[eventType] = eventEntity.eventType;
				dataEntity[eventId] = eventEntity.eventId;
				dataEntity[eventName] = isCombox ? getLabelFromList(eventSecList, eventEntity.eventId) : eventEntity.eventName;
				var tempDate = moment(eventEntity.eventDate, 'YYYY-MM-DD HH:mm:ss').local('en');
				let tempDate4 = moment(tempDate).format('YYYY-MM-DD HH:mm:ss') == "Invalid date" ? null : moment(tempDate).format('YYYY-MM-DD HH:mm:ss');
				dataEntity[eventDate] = tempDate4;
			}
		}

		var reminder = dataEntity.reminder;
		if (reminder) {
			// var tempDate2 = moment(dataEntity.reminder,'YYYY-MM-DD HH:mm:ss').local('en');
			// var tempDate3 =  moment(tempDate2).format('YYYY-MM-DD HH:mm:ss')== "Invalid date" ? null : moment(tempDate2).format('YYYY-MM-DD HH:mm:ss');
			// dataEntity.reminder = tempDate3;
			dataEntity.reminder = reminder;
			dataEntity.reminderInd = 'Y';
		}
		
			
			
			switch(dataEntity.emailReminder) {
				  case "NO":
					dataEntity.reminderEmailSch='NO';
					break;
				  case "WK":
					dataEntity.reminderEmailSch='WEEKLY';
					dataEntity.reminderEmailAtt='N';
					break;
				  case "MN":
					dataEntity.reminderEmailSch='MONTHLY';
					dataEntity.reminderEmailAtt='N';
					break;
				  case "WKATT":
					dataEntity.reminderEmailSch='WEEKLY';
					dataEntity.reminderEmailAtt='Y';
					break;
			      case "MNATT":
					dataEntity.reminderEmailSch='MONTHLY';
					dataEntity.reminderEmailAtt='Y';
					break;
				  default:
					dataEntity.reminderEmailSch='NO';
				}
		


		//var headquartersCountryId = dataEntity.headquartersCountry;
		//dataEntity.headquartersCountryId = headquartersCountryId; 

		dataEntity.headquartersCountry = getLabelFromList(LocationList, dataEntity.headquartersCountryId);

		dataEntity.typeOfSuccessCase = null;
		dataEntity.typeOfSuccessCaseId = null;
		dataEntity.typeOfSuccessCase1 = null;
		dataEntity.typeOfSuccessCaseId1 = null;
		dataEntity.typeOfSuccessCase2 = null;
		dataEntity.typeOfSuccessCaseId2 = null;
		dataEntity.typeOfSuccessCase3 = null;
		dataEntity.typeOfSuccessCaseId3 = null;
		dataEntity.typeOfSuccessCase4 = null;
		dataEntity.typeOfSuccessCaseId4 = null;


		if (dataEntity.typeOfSuccesses.length >= 1) {
			dataEntity.typeOfSuccessCase = getLabelFromList(this.state.typeOfSuccessCaseList, dataEntity.typeOfSuccesses[0]);
			dataEntity.typeOfSuccessCaseId = dataEntity.typeOfSuccesses[0];
		}
		if (dataEntity.typeOfSuccesses.length >= 2) {
			dataEntity.typeOfSuccessCase1 = getLabelFromList(this.state.typeOfSuccessCaseList, dataEntity.typeOfSuccesses[1]);
			dataEntity.typeOfSuccessCaseId1 = dataEntity.typeOfSuccesses[1];
		}
		if (dataEntity.typeOfSuccesses.length >= 3) {
			dataEntity.typeOfSuccessCase2 = getLabelFromList(this.state.typeOfSuccessCaseList, dataEntity.typeOfSuccesses[2]);
			dataEntity.typeOfSuccessCaseId2 = dataEntity.typeOfSuccesses[2];
		}
		if (dataEntity.typeOfSuccesses.length >= 4) {
			dataEntity.typeOfSuccessCase3 = getLabelFromList(this.state.typeOfSuccessCaseList, dataEntity.typeOfSuccesses[3]);
			dataEntity.typeOfSuccessCaseId3 = dataEntity.typeOfSuccesses[3];
		}
		if (dataEntity.typeOfSuccesses.length >= 5) {
			dataEntity.typeOfSuccessCase4 = getLabelFromList(this.state.typeOfSuccessCaseList, dataEntity.typeOfSuccesses[4]);
			dataEntity.typeOfSuccessCaseId4 = dataEntity.typeOfSuccesses[4];
		}



		dataEntity.otherTypeOfSuccessCase = this.otherTypeOfSuccessCaseVisable ? dataEntity.otherTypeOfSuccessCase : null;


		dataEntity.infoDisc = this.leadInforDiscVisable ?  (this.state.typeOfLeadDiscList, dataEntity.infoDisc): null;
		// dataEntity.leadNature = (this.state.leadNatureList, dataEntity.leadNature);

		dataEntity.leadNatureCase = null;
		dataEntity.leadNatureCase1 = null;
		dataEntity.leadNatureCase2 = null;
		dataEntity.leadNatureCaseDesc = null;
		dataEntity.leadNatureCaseDesc1 = null;
		dataEntity.leadNatureCaseDesc2 = null;
		if (dataEntity.leadNature.length >= 1) {
			dataEntity.leadNatureCaseDesc = getLabelFromList(this.state.leadNatureList, dataEntity.leadNature[0]);
			dataEntity.leadNatureCase = dataEntity.leadNature[0];
		}
		if (dataEntity.leadNature.length >= 2) {
			dataEntity.leadNatureCaseDesc1 = getLabelFromList(this.state.leadNatureList, dataEntity.leadNature[1]);
			dataEntity.leadNatureCase1 = dataEntity.leadNature[1];
		}
		if (dataEntity.leadNature.length >= 3) {
			dataEntity.leadNatureCaseDesc2 = getLabelFromList(this.state.leadNatureList, dataEntity.leadNature[2]);
			dataEntity.leadNatureCase2 = dataEntity.leadNature[2];
		}
		
		//Get publicity updates?
		if (dataEntity.usageContentUpdated == undefined) {
			dataEntity.usageContentUpdated = false;
		}



		if (!dataEntity.status) {
			dataEntity.status = STATUS_.New;
		}
		
		
	console.log("devStatus F",dataEntity.devStatus);	

		return dataEntity;
	}

	searchHandlerPromiseEmail = (key) => {
		return new Promise(resolve => {
			get(DATA_UI_CONFIG.url + '/userServer/searchAllUserName?userName=' + key, null).then(data => {
			console.log("searchAllUserName", key, data);
				if (data.returnType === "SUCCESS") {
					var options = [];
					options = data.emailList.map((item, index) => {
						return { value: item, label: item }
					})
					resolve(options);
				}
			}).catch((e) => {
				resolve([]);
			});
		});
	}
	searchHandlerPromiseTopic = (key) => {
		return new Promise(resolve => {
			var lanCode = this.state.data.language == undefined ? "EN" : this.state.data.language;
			get(DATA_UI_CONFIG.url + '/searchtopicByLabel?lanCode=' + lanCode + '&label=' + key, null).then(data => {
				if (data.returnType === "SUCCESS") {
					var options = [];
					data.topicThemeList.map((item, index) => {
						options.push({ value: item.topicTheme, label: item.topicTheme, similar: item.similarWords });
					})
					resolve(removeRepeat(options));
				}
			}).catch((e) => {
				resolve([]);
			});
		});
	}



}

const ConnectedWriteSuccessStory = connect(mapStateToProps, mapDispatchToProps)(WriteSuccessStory);

export default withRouter(ConnectedWriteSuccessStory);