import React, { Component } from 'react';
import { connect } from "react-redux";
//import { getLabel } from '../Lang';
import { withRouter } from 'react-router';
//import '../mock/UserApi.js';
import { get, post, clearAccessToken, getAccessToken, isTokenExpired, setTargetUrl, getTargetUrl, clearTargetUrl, refreshAccessTokenTime, checkClientTimeout } from "../services/AuthService";
import { setCurrentLang, setLoginUser , setUserPermission ,setRoleCode, setSysVersion} from '../redux/actions';
import { Row, Col, Icon,  Menu,  Modal, Input } from 'antd';
import { menuArray, bind, bindParent, dataPutAndGo, DATA_SAVE_MSG, beforeunload, beforeunloadInvisable, removeEventBeforeunloadUtil, addEventBeforeunloadUtil  } from '../Utils';
import './headpage.css';
import {SECURITY_UI_CONFIG} from '../../src/DataConfig';
import hideIcon from './img/hide_pass.ico';
import {successStory_UI_CONFIG,DATA_UI_CONFIG} from '../../src/DataConfig'
import { checkDataDiff } from '../UtilDataDiff';
import lodash from 'lodash';
import { LOGIN_PAGE_URL  } from '../Config';
//import Cookies from 'universal-cookie';
import decode from 'jwt-decode';
import logoImage from './img/2__TDC_LOGO_158x22.png';

//const cookies = new Cookies();
const LEAD_SEARCH_HIST = 'LeadSearchHist';
const mapStateToProps = (state) => {
    return {
        userRoleCode: state.userRoleCode,
        currentLang: state.currentLang,
        loginUser: state.loginUser,
        userPermission : state.userPermission,
        sysVersion: state.sysVersion,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLang: (info) => {
            dispatch(setCurrentLang(info));
        },
        setLoginUser: (info) => {
            dispatch(setLoginUser(info));
        },
        setUserPermission:(info) =>{
            dispatch(setUserPermission(info));
        },
        setRoleCode:(info) =>{
            dispatch(setRoleCode(info));
        },
        setSysVersion:(info) =>{
            dispatch(setSysVersion(info));
        }
    };
}

const MENU_WIDTH = 300;



class HeaderPage extends Component {

    constructor(props) {
        super(props);

        var telescopic = this.props.telescopic;     // this.props.telescopic;  点击之后 默认关闭菜单栏

       // console.log("constructor >>> " );
        this.state = {
            passType : "password",
            showLoading: this.props.showLoading==true?true:false,
            login :{id: 1 ,userName:"",password:""},
            showLoginModal: false,
            telescopic: telescopic , //open
            data: { account: '', pwd: '' }
        };
        bind(this, [ 'logoutChkDataDiff', 'reallyLogout', 'refersMenu', 'showLoading', 'handlerTelescopic', 'handlerSelfInputChange', 'genMenu', 'genMobileMenu', 'showLogin', 'loginCancel', 'loginOk']);
        bindParent(this, this.props.parentObj, ['removeEventBeforeunload', 'showLoading', 'getWith', 'genPathDiv', 'handlerInputChange', 'handlerSwitchChange', 'handlerSelectChange', 'genMenu', 'genMenu2']);

        checkClientTimeout(this);
    }

   

    showLoading(show){
        var pageObj = this.props.parentObj;
        if(!pageObj){
            pageObj = this;
        }
        //console.log("## pageObj.loadingCount: ", pageObj.loadingCount, show);
        if(!pageObj.loadingCount){
            pageObj.loadingCount = 0;
        }
        if(show == true){
            pageObj.loadingCount++;
        }else{
            pageObj.loadingCount--;
        }
        //console.log("@@ pageObj.loadingCount: ", pageObj.loadingCount);
        if(pageObj.loadingCount < 1){
            if(this.state.showLoading == true){
                this.setState({showLoading: false});
            }
        }else{
            if(this.state.showLoading == false){
                this.setState({showLoading: true});
            }
        }
    }

    /*
    showLoading_(show){
        // this.setState({showLoading: show});
        // console.log("## this.loadingCount: ", this.loadingCount, show);
        if(!this.loadingCount || this.loadingCount < 1){
            this.loadingCount = 0;
            if(this.state.showLoading == false){
                this.setState({showLoading: true});
            }
        }
        if(show == true){
            this.loadingCount++;
        }else{
            this.loadingCount--;
        }        
        if(this.loadingCount < 1){
            this.setState({showLoading: false});
        }     
    }
    */

    componentDidMount() {
        window.addEventListener('resize', this.handleResize.bind(this));
        window.addEventListener('click', this.handleClickKeydown.bind(this));
        window.addEventListener('keydown', this.handleClickKeydown.bind(this));
        // window.addEventListener('scroll', this.handleScroll.bind(this))
        // this.seeScreenSize();

        // document.oncontextmenu = function(){
        //     return false;
        // }
//        console.log("Did Mount");
//        window.addEventListener('beforeunload', beforeunload);
        this.handlePageWidth();
      
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.handleResize.bind(this));
        //window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillMount() {
        if(LOGIN_PAGE_URL.disable == false){
            var loginUser = this.props.loginUser;
            var currUrlPath = "/home";
            if(this.props.location){
                //console.log("this.props.location === ", this.props.location);
                currUrlPath = this.props.location.pathname + this.props.location.search;
                if(!currUrlPath){
                    currUrlPath = "";
                }
            }
            var thisObj = this;
            var token = getAccessToken();
            var isExpired = isTokenExpired();
            var targetUrl = getTargetUrl();            
            //console.log("# isExpired/loginUser >>>>>  " , isExpired, loginUser );
            //console.log("# targetUrl/currUrlPath >>>>>  " , targetUrl, currUrlPath );
            //alert(token);
            if(!token || ( isExpired == true ) ){//No login or Timeout
                this.props.setLoginUser(null);
                //loginUser=null;

                setTargetUrl(currUrlPath); //set want go to target url

                if(LOGIN_PAGE_URL.routeUrl == true){
                    dataPutAndGo(thisObj, LOGIN_PAGE_URL.loginPageUrl);
                }else{
                    window.location = LOGIN_PAGE_URL.loginPageUrl;
                }
                return;
            }            
            if(!loginUser){//get logined user info                
                var userInfo = decode(token); //the token must is not empty
                var userName = userInfo.sub;
                get(LOGIN_PAGE_URL.loginAppUrl+"?userName="+userName, thisObj).then(data =>{
                    if(data.returnType == "SUCCESS"){
                        thisObj.props.setLoginUser(data.entity);
                        //console.log("### data.entity: ", data.entity);
                        if(data.entity){
                            thisObj.initPerMission(data.entity.userName);
                        }
                        //alert("targetUrl="+targetUrl);
                        if(targetUrl){
                            clearTargetUrl();
                            dataPutAndGo(thisObj, targetUrl);
                        }
                    }
                })
            }
        }        

        if(!this.props.sysVersion){
            var parentObj = this.props.parentObj;
            get(successStory_UI_CONFIG.url + '/getTDCVersion', this).then(data =>{
                //console.log("# getTDCVersion >>>>>  " , data );
                this.sysVersion = data;
                this.props.setSysVersion(this.sysVersion);
                parentObj.setState({...parentObj.state});
            })
        }else{
            this.sysVersion = this.props.sysVersion;
        }
        console.log("beforeunload",this.props.parentObj, this.props.parentObj.DATA_DIFF );
        if(this.props.parentObj && this.props.parentObj.DATA_DIFF){
        	console.log("Add beforeunload");
        	addEventBeforeunloadUtil();
        }else{
        	console.log("remove beforeunload");
        	removeEventBeforeunloadUtil();
        }
        //
        this.setState({ logined: "admin" });
    }

    clearPerMission = () =>{
        // var createLeadsInd =false;
        // var submitLeadsInd = false;
        // var editLeadsInd = false;
        // var viewLeadsInd = false;
        // var searchLeadsInd = false;
        // var reviewLeadsInd = false;
        // var approveArchiveLeadsInd=false;
        // var releaseLeadsInd = false;
        // var publishLeadsInd = false;
        // var rejectLeadsInd = false;
        // var reopenLeadsInd = false;
        // var exportLeadsInd = false;
        // var reportGenerationInd = false;
        // var userRoleMaintenanceInd = false;
        // var userMaintenanceInd = false;
        // var eventTopicThemeTagManInd = false;
        // var permission = {
        //     createLeadsInd : createLeadsInd,
        //     submitLeadsInd : submitLeadsInd,
        //     editLeadsInd : editLeadsInd,
        //     viewLeadsInd : viewLeadsInd,
        //     searchLeadsInd : searchLeadsInd,
        //     reviewLeadsInd : reviewLeadsInd,
        //     approveArchiveLeadsInd : approveArchiveLeadsInd,
        //     releaseLeadsInd : releaseLeadsInd,
        //     publishLeadsInd : publishLeadsInd,
        //     rejectLeadsInd : rejectLeadsInd,
        //     reopenLeadsInd : reopenLeadsInd,
        //     exportLeadsInd : exportLeadsInd,
        //     reportGenerationInd : reportGenerationInd ,
        //     userRoleMaintenanceInd : userRoleMaintenanceInd,
        //     userMaintenanceInd : userMaintenanceInd,
        //     eventTopicThemeTagManInd : eventTopicThemeTagManInd
        // }
        this.props.setUserPermission(null);
    }

    initPerMission = (userName) => {
         this.props.setUserPermission(null);
         this.props.setRoleCode(null);
        
		post(DATA_UI_CONFIG.url + '/userServer/searchPerByName?name='+userName , null, this).then(data=>{
            //console.log(" searchPerByName  >> " , data );
            if(!data.perMission){ data.perMission = [] }
            var permissionEntity = {};
			for(var i = 0 ; i < data.perMission.length ; i ++ ){
                var da = data.perMission[i];
                
                if(da == "saveLeads"){
                    permissionEntity.saveLeadsInd = true;
                }
                else if(da == "createLeads" ){
					permissionEntity.createLeadsInd = true;
                }
                else if(da == "bulkUploadLeads" ){
					permissionEntity.bulkUploadLeadsInd = true;
                }
                else if(da == "submitLeads" ){
					permissionEntity.submitLeadsInd = true;
                }
                else if(da == "editLeads" ){
                    permissionEntity.editLeadsInd = true;
                }
                else if(da == "reviewLeads" ){
                    permissionEntity.reviewLeadsInd = true;
                }
                else if(da == "approveArchiveLeads" ){
                    permissionEntity.approveArchiveLeadsInd = true;
                }
                else if(da == "rejectLeads" ){
                    permissionEntity.rejectLeadsInd = true;
                }
                else if(da == "releaseLeads" ){
                    permissionEntity.releaseLeadsInd = true;
                }
                else if(da == "reopenLeads" ){
                    permissionEntity.reopenLeadsInd = true;
                }
                else if(da == "closeLeads" ){
                    permissionEntity.closeLeadsInd = true;
                }
                else if(da == "copyLeads" ){
                    permissionEntity.copyLeadsInd = true;
                }
                else if(da == "searchLeads" ){
                    permissionEntity.searchLeadsInd = true;
                }
                else if(da == "viewLeads" ){
                    permissionEntity.viewLeadsInd = true;
                }
                else if(da == "exportLeads" ){
                    permissionEntity.exportLeadsInd = true;
                }
                else if(da == "userRoleMaintenanceRead" ){
                    permissionEntity.userRoleMaintenanceReadInd = true;
                }
                else if(da == "userRoleMaintenanceWrite" ){
                    permissionEntity.userRoleMaintenanceWriteInd = true;
                }
                else if(da == "userMaintenanceRead" ){
                    permissionEntity.userMaintenanceReadInd = true;
                }
                else if(da == "userMaintenanceWrite" ){
                    permissionEntity.userMaintenanceWriteInd = true;
                }
                else if(da == "eventMaintenanceRead" ){
                    permissionEntity.eventMaintenanceReadInd = true;
                }
                else if(da == "eventMaintenanceWrite" ){
                    permissionEntity.eventMaintenanceWriteInd = true;
                }
                else if(da == "TopicThemeMaintenanceRead" ){
                    permissionEntity.TopicThemeMaintenanceReadInd = true;
                }
                else if(da == "TopicThemeMaintenanceWrite" ){
                    permissionEntity.TopicThemeMaintenanceWriteInd = true;
                }
                else if(da == "adminMaintenanceReportRead" ){
                    permissionEntity.adminMaintenanceReportReadInd = true;
                }
                else if(da == "adminMaintenanceReportWrite" ){
                    permissionEntity.adminMaintenanceReportWriteInd = true;
                }else if(da == "searchLeadsAllDepartment" ){
                    permissionEntity.searchLeadsAllDepartment = true;
                }
                else if(da == "eventTypeMaintenanceRead" ){
                    permissionEntity.eventTypeMaintenanceReadInd = true;
                }
                else if(da == "eventTypeMaintenanceWrite" ){
                    permissionEntity.eventTypeMaintenanceWriteInd = true;
                }
                else if(da == "channelMaintenanceRead" ){
                    permissionEntity.channelMaintenanceReadInd = true;
                }
                else if(da == "channelMaintenanceWrite" ){
                    permissionEntity.channelMaintenanceWriteInd = true;
                }
                else if(da == "storyCaseTypeMaintenanceRead" ){
                    permissionEntity.storyCaseTypeMaintenanceReadInd = true;
                }
                else if(da == "storyCaseTypeMaintenanceWrite" ){
                    permissionEntity.storyCaseTypeMaintenanceWriteInd = true;
                }
                else if(da == "annoBoxMaintenanceRead" ){
                    permissionEntity.annoBoxMaintenanceRead = true;
                }
                else if(da == "annoBoxMaintenanceWrite" ){
                    permissionEntity.annoBoxMaintenanceWrite = true;
                }
                else if(da == "trainingMaintenanceRead" ){
                    permissionEntity.trainingMaintenanceRead = true;
                }
                else if(da == "trainingMaintenanceWrite" ){
                    permissionEntity.trainingMaintenanceWrite = true;
                }
                else if(da == "trainingMaterialRead" ){
                    permissionEntity.trainingMaterialRead = true;
                }else if(da == "leadInfoDiscMaintenanceRead" ){
                    permissionEntity.leadInfoDiscMaintenanceReadInd = true;
                }
                else if(da == "leadInfoDiscMaintenanceWrite" ){
                    permissionEntity.leadInfoDiscMaintenanceWriteInd = true;
                }                
                else if(da == "leadNatureMaintenanceRead" ){
                    permissionEntity.leadNatureMaintenanceReadInd = true;
                }
                else if(da == "leadNatureMaintenanceWrite" ){
                    permissionEntity.leadNatureMaintenanceWriteInd = true;
                }
                else if(da == "leadStatusReportRead" ){
                    permissionEntity.leadStatusReportReadInd = true;
                }
                else if(da == "leadStatusReportWrite" ){
                    permissionEntity.leadStatusReportWriteInd = true;
                }
                else if(da == "exportSubmittedLeads" ){
                    permissionEntity.exportSubmittedLeadsInd = true;
                }
                else if(da == "exportPendingLeads" ){
                    permissionEntity.exportPendingLeadsInd = true;
                }
                else if(da == "viewSubmittedLeads" ){
                    permissionEntity.viewSubmittedLeadsInd = true;
                }
                else if(da == "viewPendingLeads" ){
                    permissionEntity.viewPendingLeadsInd = true;
                }
                else if(da == "reviewLeadStatusReport" ){
                    permissionEntity.reviewLeadStatusReportInd = true;
                }
                


            }
     
            this.props.setUserPermission(permissionEntity);
            this.props.setRoleCode(data.roleCode?data.roleCode:null);
        });
    }

    handlerSelfInputChange = (event) => {
        var stateData = this.state;
        var target = event.currentTarget;
        var fieldName = target.name;
        var value = target.value;
        lodash.set(stateData, fieldName, value);
        this.setState({ ...stateData });
    }

    /* For parentObj */
    handlerSelectChange = (name, value) => {
        console.log("# handlerSelectChange: ", name, value);
        var parentObj = this.props.parentObj;
        var stateData = parentObj.state;
        lodash.set(stateData, name, value);
        parentObj.setState({ ...stateData });
    }

    /* For parentObj */
    handlerInputChange = (event) => {
      //  console.log("# handlerInputChange: ", event);
        var parentObj = this.props.parentObj;
        var stateData = parentObj.state;
        var target = event.currentTarget;
        var inputName = target.name;
        var fieldName = inputName;
        var actions = "";
        var value = target.value;
        if (inputName.indexOf('|') > -1) {//exist Actions
            var index = inputName.indexOf('|');
            actions = inputName.substring(index + 1);
            fieldName = inputName.substring(0, index);
            if (actions.indexOf('U') > -1 || actions.indexOf('u') > -1) { //toUpperCase
                value = value.toUpperCase();
            }
        }
        // console.log("# handlerInputChange, inputName = ", inputName);
        // console.log("# handlerInputChange, actions = ", actions);
        // console.log("# handlerInputChange, fieldName = ", fieldName);
        // console.log("# handlerInputChange, value = ", value);
        lodash.set(stateData, fieldName, value);
        parentObj.setState({ ...stateData });
    }

    /* For parentObj */
    handlerSwitchChange = (val, event) => {
        //console.log("# handlerSwitchChange: ", val, event);
        var parentObj = this.props.parentObj;
        var stateData = parentObj.state;
        //console.log("# handlerSwitchChange 1: ", JSON.stringify(stateData));
        var target = event.currentTarget;
        var fieldName = target.name;
        //console.log("# handlerSwitchChange fieldName: ", fieldName);
        lodash.set(stateData, fieldName, val);
        //console.log("# handlerSwitchChange 2: ", JSON.stringify(stateData));
        parentObj.setState({ ...stateData });
    }


    showLogin = () => {
        this.setState({ showLoginModal: true });
    }

    loginCancel = () => {
        this.setState({ showLoginModal: false });
    }

    loginOk = () => {
        this.setState({ showLoginModal: false });
    }

    genMobileMenu = (menuArrayPar) => {
        
        var newMenuArray = [];

        var createLeadsInd = false;
        var eventTopicThemeTagManInd = false;
        if(this.props.userPermission){
            createLeadsInd = this.props.userPermission.createLeadsInd;
            eventTopicThemeTagManInd = this.props.userPermission.eventTopicThemeTagManInd;
        }
        
        menuArrayPar.map((item, index) => {
            if(item.id == 2){
                if(createLeadsInd){
                    newMenuArray.push(item);
                }
            }else if(item.id == 4){
                if(eventTopicThemeTagManInd){
                    newMenuArray.push(item);
                }
            }else{
                newMenuArray.push(item);
            }
			
        });

        //console.log("newMenuArray >>>>> " , newMenuArray );
        var menuItems = newMenuArray.map((item, index) => {
            return (
                <Menu.Item key={index} onClick={() => this.gotoPage(item)}>
                    <Icon type={item.icon} /> {item.title}
                </Menu.Item>
            );
        });
        return (
            <Menu>
                {menuItems}
            </Menu>
        );
    }

    // genMenu__ = () => {
    //     this.menuSelect = { title: '' };
    //     var path = this.props.location.pathname;
    //     if (!path) {
    //         path = "";
    //     }
    //     return menuArray.map((item, index) => {
    //         var menuSelect = "";
    //         if (path.indexOf(item.url) > -1) {
    //             menuSelect = "menuSelect";
    //             this.menuSelect = item;
    //         }
    //         return (
    //             <Col key={index} xs={0} sm={0} md={item.width + 4} lg={item.width + 2} xl={item.width}>
    //                 <div onClick={() => this.gotoPage(item)} className={"menu " + menuSelect}>{item.title}</div>
    //             </Col>
    //         );
    //     });
    // }

    genPathDiv = () => {
        var parentObj = this.props.parentObj;
        var menuSelect = parentObj.menuSelect;
        console.log("menuSelect", menuSelect);
        var title = "";
        if(menuSelect){
            title = menuSelect.title;
            if(menuSelect.asTitle){
                title = menuSelect.asTitle;
            }
        }
        if(parentObj.state.selfTitle){
            title = parentObj.state.selfTitle;
        }
        
        
        
        return (
            <Row className="headerTop3">
                <Col className="path">
                    <span> {title}</span>            
                </Col>
                {/* <Col className="path" xs={4} sm={4} md={4} style={{ textAlign: 'right' }}>
                    <a><span style={{ color: '#009FCC' }}>繁</span></a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a><span style={{ color: '#009FCC' }}>简</span></a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </Col> */}
            </Row>
        );
    }

    refersMenu(){
        //console.log("###################### refersMenu.......");
        var parentObj = this.props.parentObj;
        if(parentObj){
            parentObj.setState({refersMenu: true});
        }
    }

    genMenu2 = () => {
        var parentObj = this.props.parentObj;
        //console.log("###################### parentObj:", parentObj);
        parentObj.menuSelect = { title: '', index: '0' };
        var path = this.props.location.pathname;
        if (!path) {
            path = "";
        }

        //var saveLeads = false;          // wirte page
        var createLeadsInd = false;     // wirte page

        var eventMaintenanceReadInd = false;
        var eventMaintenanceWriteInd = false;

        var TopicThemeMaintenanceReadInd = false;
        var TopicThemeMaintenanceWriteInd = false;

        var searchLeadsInd = false;

        var leadStatusReportReadInd = false;
        var leadStatusReportWriteInd = false;

        var adminMaintenanceReportReadInd = false;
        var adminMaintenanceReportWriteInd = false;
        
        var userRoleMaintenanceReadInd = false;
        var userRoleMaintenanceWriteInd = false;

        var userMaintenanceReadInd = false;
        var userMaintenanceWriteInd = false;
        
        var annoBoxMaintenanceReadInd = false;
        var annoBoxMaintenanceWriteInd = false;
        
        var trainingMaintenanceReadInd = false;
        var trainingMaintenanceWriteInd = false;
        
        var trainingMaterialReadInd = false;
        

        console.log("this.props.userPermission >>>>>>> " , this.props.userPermission );
        if(this.props.userPermission){

            // Search Story Lead
            searchLeadsInd = this.props.userPermission.searchLeadsInd || this.props.userPermission.searchLeadsAllDepartment; 

            leadStatusReportReadInd = this.props.userPermission.leadStatusReportReadInd
            leadStatusReportWriteInd = this.props.userPermission.leadStatusReportWriteInd
            // Create Story Lead
            //saveLeads = this.props.userPermission.saveLeadsInd;
            createLeadsInd = this.props.userPermission.createLeadsInd;
            
            //Admin Management Report Control
            adminMaintenanceReportReadInd = this.props.userPermission.adminMaintenanceReportReadInd;
            adminMaintenanceReportWriteInd = this.props.userPermission.adminMaintenanceReportWriteInd;


            // Tagging Maintenance Control
            eventMaintenanceReadInd = this.props.userPermission.eventMaintenanceReadInd;
            eventMaintenanceWriteInd = this.props.userPermission.eventMaintenanceWriteInd;
            TopicThemeMaintenanceReadInd = this.props.userPermission.TopicThemeMaintenanceReadInd;
            TopicThemeMaintenanceWriteInd = this.props.userPermission.TopicThemeMaintenanceWriteInd;

            // User Role Management Control 
            userRoleMaintenanceReadInd = this.props.userPermission.userRoleMaintenanceReadInd;
            userRoleMaintenanceWriteInd = this.props.userPermission.userRoleMaintenanceWriteInd;
            userMaintenanceReadInd = this.props.userPermission.userMaintenanceReadInd;
            userMaintenanceWriteInd = this.props.userPermission.userMaintenanceWriteInd;
            // User Role Management Control 

            //Announcement Maintence Control
            annoBoxMaintenanceReadInd = this.props.userPermission.annoBoxMaintenanceRead;
            annoBoxMaintenanceWriteInd = this.props.userPermission.annoBoxMaintenanceWrite;
            //--------------
            
			//Training Maintenance Control
            trainingMaintenanceReadInd = this.props.userPermission.trainingMaintenanceRead;
            trainingMaintenanceWriteInd = this.props.userPermission.trainingMaintenanceWrite;
            //--------------
            
            //Training Material Control
            trainingMaterialReadInd = this.props.userPermission.trainingMaterialRead;
            
            //--------------
        }

        var newMenuArray = [];
        menuArray.map((item, index) => {

            //newMenuArray.push(item);

            if(item.id === 1){
                if( searchLeadsInd ){
                    newMenuArray.push(item);
                }
            }else if(item.id === 2){
                if( createLeadsInd  ){
                    newMenuArray.push(item);
                }
            }
            else if(item.id === 3){
                if( adminMaintenanceReportReadInd || adminMaintenanceReportWriteInd ){
                    newMenuArray.push(item);
                }
            }
            else if(item.id === 4){
                if(eventMaintenanceReadInd || eventMaintenanceWriteInd || TopicThemeMaintenanceReadInd || TopicThemeMaintenanceWriteInd ){
                    newMenuArray.push(item);
                }
            }
			else if(item.id === 5){
                if(userRoleMaintenanceReadInd || userRoleMaintenanceWriteInd || userMaintenanceReadInd || userMaintenanceWriteInd ){
                    newMenuArray.push(item);
                }
            }else if(item.id === 6){
                if(annoBoxMaintenanceReadInd || annoBoxMaintenanceWriteInd){
                    newMenuArray.push(item);
                }
            }else if(item.id === 7){
                if(trainingMaterialReadInd ){
                    newMenuArray.push(item);
                }
            }else if(item.id === 8){
                if(trainingMaintenanceReadInd || trainingMaintenanceWriteInd){
                    newMenuArray.push(item);
                }
            }else if(item.id === 9){
                if(leadStatusReportReadInd || leadStatusReportWriteInd){
                    newMenuArray.push(item);
                }
            }else{
                newMenuArray.push(item);
            }

        })

        var menus = newMenuArray.map((item, index) => {
            if (path.indexOf(item.url) > -1) {
                item.index = index + "";
                parentObj.menuSelect = item;
            }
            return (
                <Menu.Item key={index+""} onClick={() => this.gotoPage(item)} style={{ fontSize: '20px', height:'52px', paddingleft:'0px', margin:'0px !important'}} >
                   <span> <Icon type={item.icon} style={{ fontSize: '17px', paddingleft:'0px'}} /></span><span className="menutitle">{item.title}</span>
                </Menu.Item>
            );
        });
        //console.log("########### menuSelect=", parentObj.menuSelect);


        var height = document.body.scrollHeight;
        // console.log("########### document.body.scrollHeight=", document.body.scrollHeight);
        // console.log("########### document.body.clientHeight=", document.body.clientHeight);
        if (document.body.clientHeight > height) {
            height = document.body.clientHeight;
        }

        let height2 = this.state.scrollingElementScrollHeight;
        if (height2) {
            if (height2 > height) {
                height = height2;
            }
        }

        var sysVersion = this.sysVersion;
        
        if(!sysVersion){
            sysVersion = '';
        }
        return (
            <Row className="leftMainMenu"  >
                <Col>
                    <Menu
                        style={{ position:'fixed', zIndex: 101, width: 300 , height: height - 30 ,size:'30px' }}     //MENU_WIDTH
                        selectedKeys={[parentObj.menuSelect.index]}
                        mode="inline"
                    //inlineCollapsed={true}
                    >
                    {menus}
                    </Menu>

                    <div className="versionDiv" style={{width:MENU_WIDTH-1}}>
                        <table style={{height:'100%', width:'100%'}}>
                            <tbody><tr><td style={{verticalAlign: 'middle'}}>{sysVersion}</td></tr></tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        );
    }

    handlerTelescopic = () => {
        this.setState({ telescopic: !this.state.telescopic }, () => {
            this.handlePageWidth();
        });
    }

    handlePageWidth = () => {
        // if(parentObj){
        //     var left = 4;
        //     var right = 20;
        //     if(this.state.telescopic){
        //         left = 4;
        //         right = 20;
        //     }else{
        //         left = 0;
        //         right = 24;
        //     }
        //     parentObj.setState({left:left, right:right});
        //   }
        var parentObj = this.props.parentObj;
        if (parentObj) {
            // if (this.state.telescopic) {
            //     var pageLeft_md = 8;
            //     var pageLeft_lg = 6;
            //     var pageLeft_xl = 6;
            //     var pageLeft_xxl = 5;

            //     var pageRight_md = 24 - pageLeft_md;
            //     var pageRight_lg = 24 - pageLeft_lg;
            //     var pageRight_xl = 24 - pageLeft_xl;
            //     var pageRight_xxl = 24 - pageLeft_xxl;
            // } else {
            //     var pageLeft_md = 0;
            //     var pageLeft_lg = 0;
            //     var pageLeft_xl = 0;
            //     var pageLeft_xxl = 0;
            //     var pageRight_md = 24;
            //     var pageRight_lg = 24;
            //     var pageRight_xl = 24;
            //     var pageRight_xxl = 24;
            // }
            // parentObj.setState({
            //     pageLeft_md: pageLeft_md,
            //     pageLeft_lg: pageLeft_lg,
            //     pageLeft_xl: pageLeft_xl,
            //     pageLeft_xxl: pageLeft_xxl,
            //     pageRight_md: pageRight_md,
            //     pageRight_lg: pageRight_lg,
            //     pageRight_xl: pageRight_xl,
            //     pageRight_xxl: pageRight_xxl
            // });            

            var nowScreenWidth = document.body.clientWidth;
//            console.log("window.screen", window.screen.width);
//            console.log("clientWidth", nowScreenWidth);
            this.handleScreenWidth(this.state.telescopic, nowScreenWidth);
        }
    }

    gotoPage = (item) => {
    	console.log("Menu ", item);
    	if (item.url=='/successStoryLeads'){
    		localStorage.removeItem(LEAD_SEARCH_HIST);
    	}
        dataPutAndGo(this, item.url, item.stateData, item.thisData);
    }
    handleClickKeydown = e => {
    	refreshAccessTokenTime();
    }
    handleResize = e => {
    	this.setState({ telescopic: false }, () => {
            this.handlePageWidth();
        });
        console.log('e.target.innerWidth', e.target.innerWidth)
        var nowScreenWidth = e.target.innerWidth;
        this.handleScreenWidth(this.state.telescopic, nowScreenWidth);
    }

    handleScroll = e => {
        var parentObj = this.props.parentObj;
        // console.log( '浏览器滚动事件',  e.srcElement.scrollingElement.scrollTop, e.srcElement.scrollingElement.scrollHeight)
        let h = e.srcElement.scrollingElement.scrollHeight;
        if (h) {
            parentObj.setState({ scrollingElementScrollHeight: h });
        }
    }

    seeScreenSize(){
        // console.log('i am seeScreenSize');
        // console.log(" 網頁可見區域寬：" + document.body.clientWidth);
        // console.log(" 網頁可見區域高：" + document.body.clientHeight);
        // console.log(" 網頁可見區域寬：" + document.body.offsetWidth + " (包括邊線和捲軸的寬)");
        // console.log(" 網頁可見區域高：" + document.body.offsetHeight + " (包括邊線的寬)");
        // console.log(" 網頁正文全文寬：" + document.body.scrollWidth);
        // console.log(" 網頁正文全文高：" + document.body.scrollHeight);
        // console.log(" 網頁被卷去的高(ff)：" + document.body.scrollTop);
        // console.log(" 網頁被卷去的高(ie)：" + document.documentElement.scrollTop);
        // console.log(" 網頁被卷去的左：" + document.body.scrollLeft);
        // console.log(" 網頁正文部分上：" + window.screenTop);
        // console.log(" 網頁正文部分左：" + window.screenLeft);
        // console.log(" 螢幕解析度的高：" + window.screen.height);
        // console.log(" 螢幕解析度的寬：" + window.screen.width);
        // console.log(" 螢幕可用工作區高度：" + window.screen.availHeight);
        // console.log(" 螢幕可用工作區寬度：" + window.screen.availWidth);
        // console.log(" 你的螢幕設置是 " + window.screen.colorDepth + " 位彩色");
        // console.log(" 你的螢幕設置 " + window.screen.deviceXDPI + " 像素/英寸");
    }


    handleScreenWidth = (telescopic, nowScreenWidth) => {

        // var ffgg = document.getElementsByClassName('pageMain')[0];
//        console.log('nowScreenWidth' , nowScreenWidth);

        var left = MENU_WIDTH;
        var leftStyle ;
        var right;
        var rightStyle;
        console.log('telescopic' , telescopic);
        
        
        if (telescopic) {
            leftStyle = { width: left };
            right = nowScreenWidth - left;
            rightStyle = { width: right };
            console.log('left 1' , left);
            console.log('right 1'  , right);
        } else {
            leftStyle = { display: 'none' };
            right = nowScreenWidth;
            rightStyle = { width: right };
            console.log('left 2' , left);
            console.log('right 2' , right);
        }
      
        // if (nowScreenWidth <= 767) {
        //     leftStyle = { display: 'none' };
        //     rightStyle = { width: nowScreenWidth };
        // }

        var parentObj = this.props.parentObj;
        parentObj.setState({
            pageLeftScreenWidthStyle: leftStyle,
            pageRightScreenWidthStyle: rightStyle
        })
    }

    controlTopMenu = (entity) => {
        if(!entity){
            entity = {};
        }
    var userName = entity.userName;
        if( !userName){
            return(
                <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
                    {/* <div className="user">
                        <span onClick={this.showLogin}>Login</span> 
                    </div> */}
                </Col>
            )
        }else{
            return(
                <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10} >
                    <div className="loginInfo" >
                    <Icon type="user"  /><span  className="userName" >{userName}</span>|<span onClick={()=>this.loginOut()} className="logoutTitle">Logout</span> 
                        {/* |<span onClick={() => this.callserver() }>call Server</span>   */}
                        {/* <span>Change Password</span> |*/}
                        {/* |<span>繁 简</span>  */}
                        {/* | <span onClick={this.showLogin}>Login</span>  */}
                    </div>
                </Col>
            )
        }
    }


    handlerInputChange2 = (event) => {
        ///////////////////////////////////////////////////////Reference by HeaderPage.js
        // console.log("# handlerInputChange: ", event);
        var stateData = this.state;
        var target = event.currentTarget;
        var fieldName = target.name;
        var value = target.value;
        // console.log("# handlerInputChange, fieldName = ", fieldName);
        // console.log("# handlerInputChange, value = ", value);
        lodash.set(stateData, fieldName, value);
        this.setState({ ...stateData });
    }
    
    loginTo(userEntity){
        var userName = (userEntity.loginId==undefined) ? userEntity.userName : userEntity.loginId ;
		
        var thisObj = this;
        // this.props.setLoginUser("admin");  // for developer
        // this.initPerMission(); // for developer

        post(SECURITY_UI_CONFIG.url + '/login?username='+userName, null, this).then(data => {
            //console.log(" data >>> " , data );
          if(data.status =='success'){
                

                get(DATA_UI_CONFIG.url + '/userServer/getLoginUserEntity?userName=' + userName, thisObj).then(data2 =>{
                   // console.log(" getLoginUserEntity >>>>>  " , data2 );
                    if(data2.returnType == "SUCCESS"){
                        this.props.setLoginUser(data2.entity);
                    }
                })

                this.initPerMission(userName);
          		this.setState({
          		     logined : userEntity,
          		     showLoginModal: false,
          		     login:{userName:"",password:""}
                })
                    dataPutAndGo(this, '/home', {item:{}});
          }else{
          		this.setState({
          		     logined : "",
          		     showLoginModal: true,
          		     login:{userName:"",password:""}
          		})
          }
          
        });
    }

    logoutChkDataDiff(okAction, cancelAction){
        var isGoNow = true;
        var pageObj = this.props.parentObj;
        var dataDiff = pageObj.DATA_DIFF;
        //console.log("# checkDataDiff -> dataDiff = ", dataDiff);
        if(dataDiff){
            var thisValue = undefined;
            var stateValue = undefined;
            var dataHandler = dataDiff.dataHandler;
            var diffHandler = dataDiff.diffHandler;

            if(dataHandler){//have top priority
                var bothData = dataHandler();
                if(!bothData){
                    bothData = {};
                }
                thisValue = bothData.thisData;
                stateValue = bothData.stateData;
            }else{
                var thisKey = dataDiff.thisKey;
                var stateKey = dataDiff.stateKey;
                //console.log("# checkDataDiff -> thisKey/stateKey = ", thisKey, stateKey);
                thisValue = thisKey ? pageObj[thisKey] : undefined;
                stateValue = (stateKey && stateKey != '') ? pageObj.state[stateKey] : pageObj.state;
            }
            // var thisKey = dataDiff.thisKey;
            // var stateKey = dataDiff.stateKey;
            // console.log("# checkDataDiff -> thisKey/stateKey = ", thisKey, stateKey);
            // var thisValue = thisKey ? pageObj[thisKey] : undefined;
            // var stateValue = (stateKey && stateKey != '') ? pageObj.state[stateKey] : pageObj.state;
            
            //console.log("# checkDataDiff -> thisValue/stateValue = ", thisValue, stateValue);

            var dataUpdated = false;
            if(diffHandler){//自定义对比方法
                //console.log("# checkDataDiff -> user selft diff method.");
                dataUpdated = diffHandler(thisValue, stateValue, dataDiff.compareSpecials);
            }else{
                dataUpdated = checkDataDiff(thisValue, stateValue, dataDiff.compareSpecials);
            }
            //var dataUpdated = checkDataDiff(thisValue, stateValue, dataDiff.compareSpecials);
            //console.log("# checkDataDiff -> dataUpdated = ", dataUpdated);
            if(dataUpdated){
                isGoNow = false;
                //confirm
                Modal.confirm({
                    title: 'Confirm',
                    content: (<span className="dataDiffMessage">{DATA_SAVE_MSG}</span>),
                    okText: 'OK',//save & logout
                    cancelText: 'Cancel',//no save & logout
                    onOk() {
						if(pageObj && pageObj.CURRENT_PAGE_CODE=="WriteSuccessStory" &&pageObj.backupData){
						    get(successStory_UI_CONFIG.url + '/ssls/searchByidAndEditMode?id='+pageObj.backupData.id+'&&name=admin&&status=2', pageObj).then(data=>{});
						}
                        if(okAction){
                            okAction(cancelAction);//callback mehotd
                        }
                    },
                    onCancel() {
						if(pageObj && pageObj.CURRENT_PAGE_CODE=="WriteSuccessStory" &&pageObj.backupData){
						    get(successStory_UI_CONFIG.url + '/ssls/searchByidAndEditMode?id='+pageObj.backupData.id+'&&name=admin&&status=2', pageObj).then(data=>{});
						}
                        if(cancelAction){
                            cancelAction();
                        }
                    },
                });
            }
        }

        if(isGoNow && cancelAction){
			if(pageObj && pageObj.CURRENT_PAGE_CODE=="WriteSuccessStory" &&pageObj.backupData){
			    get(successStory_UI_CONFIG.url + '/ssls/searchByidAndEditMode?id='+pageObj.backupData.id+'&&name=admin&&status=2', pageObj).then(data=>{});
			}
            cancelAction();
        }
    }

    reallyLogout(){        
        //console.log(" into login out function >>>>>  : "  );
        var userEntity = {id: 0, userName:"",password:""}
        // post('/user/login', userEntity, this).then(data => {
        //     console.log("login : " , data );
        // });

        this.props.setLoginUser(null);
        //cookies.remove(TOKEN_COOKIE);
        clearAccessToken();

        this.setState({
            logined : userEntity
        })
        
        this.clearPerMission();

        if(LOGIN_PAGE_URL.disable == false){
            if(LOGIN_PAGE_URL.routeUrl == true){
                dataPutAndGo(this, LOGIN_PAGE_URL.logoutPageUrl, null, null, false);
            }else{
                window.removeEventListener('beforeunload', beforeunload);
                window.location = LOGIN_PAGE_URL.logoutPageUrl;
            }            
        }else{
            dataPutAndGo(this, '/blank', {item:{}}, {}, false);
        }        
    }

    loginOut = () => {
        this.logoutChkDataDiff(this.props.parentObj.saveHandler, this.reallyLogout);        
    }

    changePassType(){
        var passType = this.state.passType;

        if(passType === "text"){
            passType = "password";
        }else {
            passType = "text";
        }
        this.setState({
            passType : passType
        })

        
    }

    goHome = () => {
    	localStorage.removeItem(LEAD_SEARCH_HIST);
        dataPutAndGo(this, '/home', null, null);
    }

    render() {

       // console.log("this.state.perMission " , this.state.permission );



       // var menu = this.genMobileMenu(menuArray);
        return (
            <div className="pageMain"   >
            <div className="headerBlank"></div>
            <div className="pageMain headerFix">   
                <div style={{backgroundColor:'red'}} >
                <Row  className="headerTop1"   >
                    <Col xs={23} sm={23} md={14} lg={14} xl={14} xxl={14}>

                        <div onClick={this.handlerTelescopic} className="menuTelescopic">
                             <Icon type={"menu" }  />    
                        </div>
                        <div className="logo" >
                            
                               
                                <a id="gohome" href="/"  onClick={this.goHome}  ><img src={logoImage} alt="SUCCESS STORY SYSTEM " style={{  height: '22px', width:'158px'     }}/> </a>
                                </div> <div className="logoTitle">
                                
                                SUCCESS STORY SYSTEM 
                           
                        </div>
                    </Col>


                    { this.controlTopMenu(this.props.loginUser) }

{/* 
                    <Col xs={3} sm={3} md={0} lg={0} xl={0} xxl={0}>
                        <div className="iconMenu">
                            <Dropdown overlay={menu}>
                                <Icon type="appstore" />
                            </Dropdown>
                        </div>
                    </Col>
                    <Col xs={18} sm={18} md={0} lg={0} xl={0} xxl={0}>
                        <div className="iconMenuUser" style={{ position:'fixed' }} >
                            <span>Van Liao</span>
                        </div>
                    </Col>
                    <Col xs={3} sm={3} md={0} lg={0} xl={0} xxl={0}>
                        <div className="iconMenu" style={{ position:'fixed' }} >
                            <Icon type="user" />
                        </div>
                    </Col> */}

                </Row>
                </div>

                <Modal
                    title=" "
                    centered
                    visible={this.state.showLoginModal}
                    onOk={this.loginOk}
                    onCancel={this.loginCancel}
                    okText="Login"
                    // cancelText="Cancel"
                    footer={null}
                    maskClosable={false}
                >
   

                        <h1 class="text-center" id="registerTitle" style={{textAlign: 'center' , margin: '20px 0 0' , fontSize: '30px' }}><span style={{color:'#000000'}}>Log in</span></h1>

                        <div class="main_box"  style={{marginBottom:'0px',maxWidth: '615px', backgroundColor: '#ececec' , borderRadius: '8px' , padding: '7px' , margin: '20px auto' }}>
							<div class="inner">	
							    <div class="content form-container" style={{paddingTop:'20px',paddingBottom:'0px'}}>
									<div class="form-group reg"  style={{ marginLeft:'70px' , width:'350px'  }}>	

											<div class="input-group" style={{width:'320px'}}  >
													<span class="input-group-addon" id="reg-email"><span class="icon icon-reg"></span></span>
													<Input placeholder="Enter login ID or email address." name="login.userName" value={this.state.login.userName} onChange={this.handlerInputChange2} style={{  borderRadius: '8px' , borderTopLeftRadius: '0px' , borderBottomLeftRadius: '0px' , border: '2px solid #b2b2b2',height: '40px',borderLeft: '0px',  display: 'block',padding: '8px 12px',fontSize: '16px',lineHeight: '1.42857'  }} />	
													<span class="error" id="emailidSpan" style={{color:'red',display:'block'}} ></span>	
                                            </div>

                                            
 
                                            <div class="input-group" style={{marginTop:'10px',width:'320px'  }}>
													<span class="input-group-addon" id="reg-email"><span class="icon2 icon-reg2"></span></span>
													<Input placeholder="Enter your password." name="login.password" type={this.state.passType}  value={this.state.login.password} onChange={this.handlerInputChange2} style={{   borderRadius: '8px' , borderTopLeftRadius: '0px' , borderBottomLeftRadius: '0px' , border: '2px solid #b2b2b2',height: '40px',borderLeft: '0px',  display: 'block',padding: '8px 12px',fontSize: '16px',lineHeight: '1.42857'  }} />

                                                    <span class="error" id="emailidSpan" style={{color:'red',display:'block'}} ></span>
                                            </div>

                                            <img alt="hide" onClick={()=> this.changePassType()} src={hideIcon} style={{ position :'relative' , left:'320px',top:'-34px' }} /> 

                                            <div style={{clear:"both"}}></div>

										 <div class="text-center" style={{ textAlign:'center' , margin:'0px', padding:'0px' }}  >
											<button style={{ textAlign:'center', outline:'none'}} id="register-next" class="btn btn-primary round" onClick={ () => this.loginTo(this.state.login)} >Log in</button>
										</div>

										{/*
										<div class="hr" style={{marginBottom:'5px',marginTop:'25px'}}  ><span>OR</span></div>
 	          						        
									
									<div class="text-center"></div> */}
						
					                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                
            </div>
            <Modal maskClosable={false} centered visible={this.state.showLoading} footer={null} width={140} closable={false} >
                <span className="loading"><Icon type="sync" spin /> Loading...</span>
            </Modal>
        </div>
        );
    }
}

const ConnectedHeaderPage = connect(mapStateToProps, mapDispatchToProps)(HeaderPage);

export default withRouter(ConnectedHeaderPage);
