import React, { Component } from 'react';
import { connect } from "react-redux";
//import { getLabel } from '../Lang';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser ,setUserPermission,setRoleCode}from '../redux/actions';
import {DATA_UI_CONFIG} from '../../src/DataConfig'
import { Row, Col, Button, Checkbox , message, Modal } from 'antd';
//import HeaderPage from './HeaderPage';
import { bind, dataGet ,getPermission } from '../Utils';
import { get, post } from "../services/AuthService";

// import "../mock/RoleApi";
const listType1 ='Success Story Lead Maintenance';
const listType3 ='User Role Management';
const listType2 ='Tagging Management';
const listType4 ='Report Management';

const mapStateToProps = (state) => {
    return {
        userRoleCode: state.userRoleCode,
        currentLang: state.currentLang,
        loginUser: state.loginUser,
        userPermission : state.userPermission
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLang: (info) => {
            dispatch(setCurrentLang(info));
        },
        setLoginUser: (info) => {
            dispatch(setLoginUser(info));
        },
        setUserPermission:(info) =>{
            dispatch(setUserPermission(info));
        },
        setRoleCode:(info) =>{
            dispatch(setRoleCode(info));
        }
    };
}
class RolePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userPermissionList: [],
           
			permissionTitleList1: [],
			permissionTitleList2: [],
			permissionTitleList3: [],
			permissionTitleList4: [],
            // ['Create leads','Submit Leads','Edit leads','View leads','Search leads','Review leads',
            // 'Approve/Archive Leads','Release Leads','Publish leads','Reject leads','Reopen leads','Export leads',
            // 'Report Generation','User Role Management','User Maintenance','Event/ Topic Theme Tagging Management']
        };
        bind(this, ['clickRow', 'handleNew', 'genMenu', 'saveHandler', 'dataDiffHandler']);
        this.props.parentObj.RolePage = this;
    }

    componentWillMount() {
        /* call dataGet in componentWillMount */
        dataGet(this);
        this.initData();
    }

    dataDiffHandler = () =>{
        var bothData = {thisData: this.backDate, stateData: this.state.userPermissionList };
        console.log("########## RolePage dataDiffHandler is: ", bothData);
        return bothData;
    }

    /* Save you data when logout */
    saveHandler = (callBackHandler, isTimeout) => {
        /* Save you data when logout */
        var parameter = this.state.userPermissionList;
        var userName =this.props.loginUser.userName;
        
        console.log("saveHandler ", userName);
        parameter = parameter.map((item) => {
			item.updatedBy= userName;
			return item;
		});
        post( DATA_UI_CONFIG.url + '/saveAllData?allData=', parameter , this.props.parentObj).then(data => {
			message.config({ top: '10%' });
//            message.info('User Role permisison is updated successfully.');
            Modal.success({
			    title: 'User role permisison is updated successfully.',
			    content: (
			      <div>
			        
			      </div>
			    ),
			    onOk() {},
			  });
            
            if(callBackHandler){
                callBackHandler();
            }
        }).catch(error => {
            if(isTimeout == true && callBackHandler){
                callBackHandler();
            }
        });
    }

    copyJson = (baseData) =>{
        var datasStr = JSON.stringify(baseData);
        if (datasStr) {
              return JSON.parse(datasStr);
        }else{
            return null;
        }
    }

    initData =() => {
        get( DATA_UI_CONFIG.url + '/searchAll2', this.props.parentObj).then(data => {
            //console.log("searchAll2 >>> ", data.users  );
              this.setState({ userPermissionList: data.users });

              this.backDate = this.copyJson(data.users);
        });

        
		
		get( DATA_UI_CONFIG.url + '/searchAllByGroupName?groupName='+listType1, this.props.parentObj).then(data3 => {
            console.log("searchAllByGroupName >>>",listType1, data3 );
			data3.users.sort((a, b) => {
				  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
				  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
				  if (nameA < nameB) {
					return -1;
				  }
				  if (nameA > nameB) {
					return 1;
				  }

				  // names must be equal
				  return 0;
				});
			console.log("searchAllByGroupName >>>",listType1, data3 );
              this.setState({ permissionTitleList1: data3.users });
        });
		
		get( DATA_UI_CONFIG.url + '/searchAllByGroupName?groupName='+listType2, this.props.parentObj).then(data3 => {
            console.log("searchAllByGroupName >>>",listType2, data3 );
			data3.users.sort((a, b) => {
				  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
				  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
				  if (nameA < nameB) {
					return -1;
				  }
				  if (nameA > nameB) {
					return 1;
				  }

				  // names must be equal
				  return 0;
				});
			console.log("searchAllByGroupName >>>",listType2, data3 );
              this.setState({ permissionTitleList2: data3.users });
        });
		
		get( DATA_UI_CONFIG.url + '/searchAllByGroupName?groupName='+listType3, this.props.parentObj).then(data3 => {
            console.log("searchAllByGroupName >>>",listType3, data3 );
			data3.users.sort((a, b) => {
				  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
				  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
				  if (nameA < nameB) {
					return -1;
				  }
				  if (nameA > nameB) {
					return 1;
				  }

				  // names must be equal
				  return 0;
				});
			console.log("searchAllByGroupName >>>",listType3, data3 );
              this.setState({ permissionTitleList3: data3.users });
        });
		
		get( DATA_UI_CONFIG.url + '/searchAllByGroupName?groupName='+listType4, this.props.parentObj).then(data3 => {
            console.log("searchAllByGroupName >>>",listType4, data3 );
			data3.users.sort((a, b) => {
				  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
				  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
				  if (nameA < nameB) {
					return -1;
				  }
				  if (nameA > nameB) {
					return 1;
				  }

				  // names must be equal
				  return 0;
				});
			console.log("searchAllByGroupName >>>",listType4, data3 );
              this.setState({ permissionTitleList4: data3.users });
        });
    }

    checkExistItemUnticked = (oldData, newData) => {
        if(!oldData){
            oldData = [];
        }
        if(!newData){
            newData = [];
        }
        var untickedRoles = null;
        var tmpRoleIds = "";
        for(var a1 = 0; a1 < oldData.length; a1++){
            for(var b1 = 0; b1 < newData.length; b1++){
                if(oldData[a1].status == 'Active' 
                    && oldData[a1].id == newData[b1].id 
                    && newData[b1].status == 'Inactive'){
                    var roleId = '_'+oldData[a1].successStoryRole.id+'_';
                    //console.log("# oldData[a1].successStoryRole = ", oldData[a1].successStoryRole);
                    if(tmpRoleIds.indexOf(roleId) < 0){
                        tmpRoleIds = tmpRoleIds + roleId;
                        var roleName = oldData[a1].successStoryRole.roleName;
                        if(!untickedRoles){
                            untickedRoles = roleName;
                        }else{
                            untickedRoles = untickedRoles + ", " + roleName;
                        }
                    }
                }
            }
        }
        return untickedRoles;
    }

    saveData = () =>{
        var parameter = this.state.userPermissionList;
        var userName =this.props.loginUser.userName;
        
        console.log("saveHandler ", userName);
        parameter = parameter.map((item) => {
			item.updatedBy= userName;
			return item;
		});
        //console.log("@@@@@@@@ this.backDate: ", this.backDate);
        //console.log("@@@@@@@@ parameter: ", parameter);
        var untickedRoles = this.checkExistItemUnticked(this.backDate, parameter);
        if(untickedRoles){
            var thisObj = this;
            Modal.confirm({
                title: 'Confirm',
                content: (<span className="dataDiffMessage">
                    Are you confirmed to change the permission(s) of the user role(s) [{ untickedRoles }]?
                </span>),
                okText: 'OK',
                cancelText: 'Cancel',
                onOk() {
                    post( DATA_UI_CONFIG.url + '/saveAllData', parameter , thisObj.props.parentObj).then(data => {
                        //message.config({ top: '10%' });
                        //message.info('User Role permisison is updated successfully.');
                    	Modal.success({
            			    title: 'User role permisison is updated successfully.',
            			    content: (
            			      <div>
            			        
            			      </div>
            			    ),
            			    onOk() {},
            			  });
                        thisObj.initData();//reSearch
                    });
                },
                onCancel() {
                    //do noting
                },
            });
        }else{
            post( DATA_UI_CONFIG.url + '/saveAllData', parameter , this.props.parentObj).then(data => {
                //message.config({ top: '10%' });
                //message.info('User Role permisison is updated successfully.');
                //this.backDate = parameter;
            	Modal.success({
    			    title: 'User role permisison is updated successfully.',
    			    content: (
    			      <div>
    			        
    			      </div>
    			    ),
    			    onOk() {},
    			  });
                this.initData();//reSearch
            });
        }
    }


 



    boxChange = (id) =>{
        var userList = this.state.userPermissionList;
        var newList = [];

        userList.map((user,index)=>{
            if(user.id == id){
                if(user.status == "Active"){
                    user.status = "Inactive";
                }else{
                    user.status = "Active"
                }
            }
            newList.push(user);
        }); 

         this.setState({
            userPermissionList : newList
         });
    }

    genCheckBox =(title,str) => {
        var userList = this.state.userPermissionList;
        //var titleList = this.state.permissionTitleList;

        var currentUserList = [];
		if(userList){
			userList.map((user,index) => {
			    if(user.successStoryRole.id == str){
			        currentUserList.push(user);
			    }
			});
		}
           return currentUserList.map((user,index)=>{
                if(user.successStoryPermission.code == title){
                    return(
                        <Checkbox checked={user.status == "Active" ? true : false } onClick={ ()=> this.boxChange(user.id)} ></Checkbox>
                    )
                }
            })

    }

    gemTitle = (str) =>{

        if(str == "All Department & Status Lead - Search & View"){
            return(
                <div className="editPanelRole" id="item_1">
						<h4 style={{color:'#F5791F',textAlign:'left' }}><strong>Success Story Lead Maintenance</strong></h4>
				</div>
            )
        }
        else if(str == "Admin Management Report - Read" ){
            return(
                <div className="editPanelRole" id="item_1">
						<h4 style={{color:'#F5791F',textAlign:'left' }}><strong>Report Management</strong></h4>
				</div>
            )
        }
        else if(str == "Event Name Maintenance - Read" ){
            return(
                <div className="editPanelRole" id="item_1">
						<h4 style={{color:'#F5791F',textAlign:'left' }}><strong>Tagging Management</strong></h4>
				</div>
            )
        }
        else if(str == "User Role Maintenance - Read" ){
            return(
                <div className="editPanelRole" id="item_1">
						<h4 style={{color:'#F5791F',textAlign:'left' }}><strong>User Role Management</strong></h4>
				</div>
            )
        }
    }
	
	gemTitleByType = (typeStr) =>{

        if(typeStr == listType1){
            return(
                <div className="editPanelRole" id="item_1">
						<h4 style={{color:'#F5791F',textAlign:'left' }}><strong>Success Story Lead Maintenance</strong></h4>
				</div>
            )
        }
        else if(typeStr == listType4 ){
            return(
                <div className="editPanelRole" id="item_1">
						<h4 style={{color:'#F5791F',textAlign:'left' }}><strong>Report Management</strong></h4>
				</div>
            )
        }
        else if(typeStr == listType2){
            return(
                <div className="editPanelRole" id="item_1">
						<h4 style={{color:'#F5791F',textAlign:'left' }}><strong>Tagging Management</strong></h4>
				</div>
            )
        }
        else if(typeStr == listType3 ){
            return(
                <div className="editPanelRole" id="item_1">
						<h4 style={{color:'#F5791F',textAlign:'left' }}><strong>User Role Management</strong></h4>
				</div>
            )
        }
    }

    genPermissionTitle = (titleList) =>{
       return titleList.map((title,index) => {
            // console.log(" title >>>>>>>>>>>>>>>>>>>> " , title );
            return(
                <Row className="editPanel" style={{padding:'0px',paddingLeft:'0px',paddingbottom:'5px'}} >
                	
                    <Col className="fieldRole1" xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} style={{paddingLeft:'25px'}} >
                        {title.name}
                    </Col>

                    <Col className="fieldRole" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} >
                        <center>
                            {this.genCheckBox(title.code,"1")}
                        </center>
                    </Col>

                    <Col className="fieldRole" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} >
                        <center>
                            {this.genCheckBox(title.code,"2")}
                        </center>
                    </Col>
                    <Col className="fieldRole" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} >
                        <center>
                            {this.genCheckBox(title.code,"3")}
                        </center>
                    </Col>
                    <Col className="fieldRole" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} >
                        <center>
                            {this.genCheckBox(title.code,"4")}
                        </center>
                    </Col>
                    <Col className="fieldRole" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} >
	                    <center>
	                        {this.genCheckBox(title.code,"5")}
	                    </center>
	                </Col>
                </Row>
            );
        })
    }
	
	

    render() {

        // let permissionTitleList = this.state.permissionTitleList;


        const columns = [
            { title: 'Role/Function' },
            { title: 'System Admin' },
            { title: 'Content Approver' },
            { title: 'Power User' },
            { title: 'General User' }, 
            { title: 'Special User' }
        ];

                return (
                    <div className="pageMain">
                        <Row className="editPanel">
                            {
                                columns.map((data, index) => {
                                    if (index == 0) {
                                        return (
                                            <Col className="fieldRoleTitle1" xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} st >
                                                <b>{data.title}</b>
                                            </Col>
                                        );
                                    } else {
                                        return (
                                            <Col className="fieldRole" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} >
                                                <center ><b>{data.title}</b></center>
                                            </Col>
                                        );
                                    }
                                })
                            }
                            
                        </Row>
                        <Row className="editPanel">
                        <hr />
                        {
                                    <div id="ooo" style={{border:'1px solid #CFCFCF'  }}>
                                            
											
											{this.gemTitleByType(listType1)}
											{this.genPermissionTitle(this.state.permissionTitleList1)}
											
											{this.gemTitleByType(listType2)}
											{this.genPermissionTitle(this.state.permissionTitleList2)}
											
											{this.gemTitleByType(listType3)}
											{this.genPermissionTitle(this.state.permissionTitleList3)}
											
											{this.gemTitleByType(listType4)}
											{this.genPermissionTitle(this.state.permissionTitleList4)}
											
											
                                    </div>
                        }
                        <div class="normalFooter">Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>
						
                        <br></br>
                        
                        
                        </Row>
                        <Row className="editPanel">
	                        <Col className="field" xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} >
		                        <b></b>
		                    </Col>
                        </Row>
                        <Row className="editPanel">
	                        <Col className="field" xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} >
		                        <b></b>
		                    </Col>
	                    </Row>
	                    <Row className="editPanel">
	                        <Col className="field" xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} >
		                        <b></b>
		                    </Col>
	                    </Row>
	                    
                        <Row className="editPanel">
                        <div className="writeSSButtons" style={{ display : getPermission(this.props,"userRoleMaintenanceWriteInd") ? '' : 'none' }} >
                            <div style={{  paddingTop:'15px' }}  >
                                <Button style={{ }} className="oneButton" type="primary" onClick={ ()=> this.saveData()}>Save</Button>
                            </div>
						</div>

						 </Row>
						 
                    </div>
                    
                );
        

        
    }
}
//==
const ConnectedRolePage = connect(mapStateToProps, mapDispatchToProps)(RolePage);

export default withRouter(ConnectedRolePage);
