import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import { withRouter } from 'react-router';
import { setCurrentLang, setLoginUser, setUserPermission } from '../../redux/actions';
import '../../index.css';
import '../component/MyComponent.css';
import { Input, Row, Col, Button, DatePicker, Icon, Modal, message, ConfigProvider, Switch, List } from 'antd';
import HeaderPage from '.././HeaderPage';
import { sortingValue, sortingLabel, dataPutAndGo, dataGet, STATUS_, sortDateStr, getDepartmentName, removeFooter, fireWinResizeEv, messageerror, messagesuccess } from '../../Utils';
import { get, post, getAccessToken } from "../../services/AuthService";

import ResponseTable from '../component/ResponseTableFixColWidth.js';
import { Tooltip } from 'antd';
import MySelect from '../component/MySelect';
import { successStory_UI_CONFIG, DATA_UI_CONFIG, LEVEL_UI_CONFIG, REPORT_UI_CONFIG } from '../../../src/DataConfig'
import lodash from 'lodash';
//import { setServers } from 'dns';
import locale from 'antd/es/date-picker/locale/en_US';
import BraftEditor from 'braft-editor';
import { GUI_DATE_FORMAT } from '../../Config'

moment.locale('en');

const LEAD_SEARCH_ANNO_CLOSE = 'LeadSearchAnnoCLose';

const mapStateToProps = (state) => {
	//console.log("mapStateToProps >>> " , state );
	return {
		currentLang: state.currentLang,
		loginUser: state.loginUser,
		userPermission: state.userPermission
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		setCurrentLang: (info) => {
			dispatch(setCurrentLang(info));
		},
		setLoginUser: (info) => {
			dispatch(setLoginUser(info));
		},
		setUserPermission: (info) => {
			dispatch(setUserPermission(info));
		}
	};
}

class LeadStatusReportPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showSubmittedAllCriteria: true,
			showPendingAllCriteria: true,
			//gui : {storyId : "", keywords : "" , userName :"" ,eventName:"",tier:"" ,status : "" ,}
			submittedGui: {isThereTdcInd: false},
			pendingGui: {isThereTdcInd: false},
			titleMessage: "",
			hideTable: false,
			isShowAnnouncement: true,
			annoBox: "",
			departmentList: [],
			submittedInfoList:[],
			pendingInfoList:[],
			isTooltipOpen:false,
			attprefix: "batchUploadLeads"
		};

		this.accessToken = getAccessToken();

	}


	searchHandlerPromiseName = (key) => {
		return new Promise(resolve => {
			get(DATA_UI_CONFIG.url + '/userServer/searchAllUserName?userName=' + key, null).then(data => {
				//console.log(" searchAllEmail >>>>> " , data );
				if (data.returnType === "SUCCESS") {
					var options = [];
					options = data.emailList.map((item, index) => {
						return { value: item.split('(')[0], label: item.split('(')[0] }
					})
					resolve(options);
				}
			}).catch((e) => {
				resolve([]);
			});
		});
	}
	getTableButtons(d, type) {
		var exportSubmittedLeadsInd = false;
		var exportPendingLeadsInd = false;
		if (this.props.userPermission) {
			exportSubmittedLeadsInd = this.props.userPermission.exportSubmittedLeadsInd;
			exportPendingLeadsInd = this.props.userPermission.exportPendingLeadsInd;
		}
		var buttonsList = [];
		if("PENDING"===type){
			if (exportPendingLeadsInd) {
				console.log("##### d:",d)
				if (d === undefined || d.length === 0) {
					buttonsList.push({ title: 'Export', handler: this.handleExport, style: { cursor: 'default', color: '#7F7F7F' }, icon: 'export' });
				} else {
					buttonsList.push({ title: 'Export', handler: ()=>{this.handleExport(d)}, style: { cursor: 'pointer' }, icon: 'export' });
				}
			}
		}else if("SUBMITTED"===type){
			if (exportSubmittedLeadsInd) {
				console.log("##### d:",d)
				if (d === undefined || d.length === 0) {
					buttonsList.push({ title: 'Export', handler: this.handleExport, style: { cursor: 'default', color: '#7F7F7F' }, icon: 'export' });
				} else {
					buttonsList.push({ title: 'Export', handler: ()=>{this.handleExport(d)}, style: { cursor: 'pointer' }, icon: 'export' });
				}
			}
		}
		
		return buttonsList;
	}
	handleChange = (info, str) => {
		let fileList = [...info.fileList];

		fileList = fileList.slice();
		console.log("fileList size", fileList.length);


		var type = "";
		if (info.file !== undefined) {
			var fileName = info.file.name;
			var index = fileName.lastIndexOf('.');
			type = info.file.name.slice((index + 1), fileName.length);
		}
		var match = (new RegExp('[~#%\&{}+\|]|\\.\\.|^\\.|\\.$')).test(info.file.name);
		if (match) {
			messageerror("A file name can't contain any of the following characters: ~ # % & * { } \ : < > ? / + | \"");
			return false;
		}
		else if (type.toLowerCase() !== "xlsm") {
			message.config({ top: '10%', });
			messageerror("Format: xlsm. Maximum file size 50MB.");
			return false;
		} else if (info.file.size > 52428800) {	// 50M 
			message.config({ top: '10%', });
			messageerror(info.file.name + ' upload failure as file size exceeds 50MB.');
			return false;
		} else if (info.file.response && info.file.response !== 'success') {
			message.config({ top: '10%', });
			var data = this.state.data;
			const matchKey = info.file.uid !== undefined ? 'uid' : 'name';
			console.log("matchKey", matchKey, info.file[matchKey]);
			const removed = fileList.filter(item => item[matchKey] !== info.file[matchKey]);


			if (str === "file") {
				data.fileList = removed;
			} 

			console.log("matchKey", data.fileList);
			this.setState({ data: data });
			if (info.file.response !== undefined && info.file.response !== null && info.file.response !== "fail" && info.file.response !== "") {
				messageerror(info.file.response);

			} else {
				messageerror("File failed to be uploaded. Please contact system admin.");
			}
			
			return false;

		} else {
			if (info.file.status === "done") {
				message.config({ top: '10%', });
				messagesuccess(info.file.name + ' uploaded successfully.');
			
			}
			
		}
		this.setState( {fileList});
		console.log("Batch Leads Upload this.state.data.fileList >>> ", info);
	};
	

	filterOptionTopic = (inputValue, option) => {

		return true;

	}
	searchHandlerPromiseTopic = (key) => {
		console.log(" searchAllTopic >>>>>", key);
		this.setState({ TopicList: [] });
		if (key === undefined || key == null || key.trim() === "") {
			console.log(" searchAllTopic >> retrun >>>", key);

			return;
		}



		return new Promise(resolve => {
			var lanCode = "EN";

			if (!key || key === undefined || key == null || key.trim() === "") {
				return;
			}

			get(DATA_UI_CONFIG.url + '/searchtopicByLabel?lanCode=' + lanCode + '&label=' + key, null).then(data => {
				console.log(" searchAllTopic >>>>> ", data);
				if (data.returnType === "SUCCESS") {
					var options = [];
					options = data.topicThemeList.map((item, index) => {
						return { value: item.topicTheme, label: item.topicTheme }
					})
					sortingValue(options);


					console.log(" searchAllTopic >>options>>> ", options);
					this.setState({ TopicList: options });

				}
			}).catch((e) => {
				resolve([]);
			});
		});
	}

	componentDidMount() {
		console.log("this.props.loginUser ", this.props.loginUser);
		if (this.props.loginUser !== null && this.props.loginUser !== undefined) {
			let str = this.props.loginUser.userName;  
			let submittedGui = this.state.submittedGui
			if(str!==undefined && str!==''){
				let index = str.indexOf("(");  
				let result = str.substring(0, index).trim(); 
				submittedGui.userName = result
				this.setState({submittedGui},()=>{
					this.handleSubmittedSearch();
					this.handlePendingSearch();
				})
			}
			
		}
		removeFooter();

		console.log(LEAD_SEARCH_ANNO_CLOSE, localStorage.getItem(LEAD_SEARCH_ANNO_CLOSE));
		if (localStorage.getItem(LEAD_SEARCH_ANNO_CLOSE) !== null ||
			localStorage.getItem(LEAD_SEARCH_ANNO_CLOSE) === '') {
			console.log(LEAD_SEARCH_ANNO_CLOSE, "Set false");

			this.setState({ isShowAnnouncement: false });
		}
	}
	componentWillMount() {
		if (this.props.loginUser !== null && this.props.loginUser !== undefined) {

			dataGet(this);
			//this.searchData();
			var dataObj = this.props.location.query;
			if (!dataObj) {
				dataObj = {};
			}
			var oridata = dataObj.stateData;
			if (!oridata) {
				oridata = {};
				oridata.item = {};
			}
			var titleMessage = "";
			var returnMsg = oridata.returnMsg;
			if (returnMsg) {
				titleMessage = returnMsg;
			}
			var departmentList = [];
			get(DATA_UI_CONFIG.url + '/searchAllDepartment', this).then(data => {
				//console.log(" department data ------------->>> " , data );
				if (data) {
					departmentList = data.users.map((item) => {
						item.value = item.name;
						item.label = item.name;
						
						if(item.similarname){
							item.similar=item.similarname;
						}
						
						return item;
					});
					
					departmentList = this.removeRepeat(departmentList);
					get(DATA_UI_CONFIG.url + '/ssls/getOffices', this).then(data1 => {
						
						
						if (data1) {
							
							data1.reverse();
							data1.map((item) => {
								
								departmentList.unshift({value:item, label:item});
							
								
								return ;
							});
							
							
						}
                        console.log("departmentList >>>>> " , departmentList );
                        this.setState({ departmentList: departmentList });
                        
					});
					
				}
			});

			
			
			

			//		get(successStory_UI_CONFIG.url + '/ssls/searchSslsData', this).then(data => {
			//			this.setState({ ssInfoList: data.users });
			//		});
			//Get Announcement
			get(DATA_UI_CONFIG.url + '/ssls/getAnnoBox', this).then(data => {

				if (data && data !== '') {

					this.setState({ annoBox: data.entity });
				}
			});


			// get( DATA_UI_CONFIG.url + '/userServer/searchByNameLike?name=' + "" + '&email=' + "" + '&role='  + "" + '&department=' + "", this).then(data => {

			// 	//console.log("searchByNameLike ------------->>> " , data );
			// 	if(data.returnType === "SUCCESS" ){
			// 		var newList = [];
			// 		data.users.map((item) => {
			// 			newList.push({ id: item.id , value : item.userName , label : item.userName });

			// 		})
			// 		this.setState({ userList : newList });
			// 	}

			// });

			get(LEVEL_UI_CONFIG.url + '/searchAllreferenceDataBySearch', this).then(data => {

				var typeOfSuccessCaseList = [];
				var IndustryList = [];
				var locationList = [];
				var TopicList = [];
				var leadNatureList = [];

				var eventList = [];
				//console.log("data22222 ------------->>> " , data );

				if (data.returnType === "SUCCESS") {
					typeOfSuccessCaseList = data.typeOfSuccessCaseList;
					IndustryList = data.industryList;
					leadNatureList = data.leadNatureList;

					locationList = data.locationList.map((item) => {
						item.value = item.id;
						item.label = item.nameEn;
						return item;
					});

					if (!data.eventList) {
						data.eventList = [];
					}
					eventList = data.eventList.map((item, index) => {
						item.value = item.id;
						item.label = item.eventNameEn;
						return item;
					});
					sortingLabel(eventList);


				}

				typeOfSuccessCaseList = this.splitByLanguage(typeOfSuccessCaseList, 'nameEn', 'nameTc', 'nameSc');
				leadNatureList = this.splitByLanguage(leadNatureList, 'nameEn', 'nameTc', 'nameSc');
				var othersItem = undefined;

				typeOfSuccessCaseList = typeOfSuccessCaseList.filter(function (item) {
					if (item.label === "Others") {
						othersItem = item;
					}

					return (item.label !== "Others");
				});

				if (othersItem !== undefined) {
					typeOfSuccessCaseList.push(othersItem);
				}


				if (leadNatureList) sortingLabel(leadNatureList);
				this.setState({
					typeOfSuccessCaseList: typeOfSuccessCaseList,
					leadNatureList: leadNatureList,
					IndustryList: this.splitByLanguage(IndustryList, 'nameEn', 'nameTc', 'nameSc'),
					locationList: this.splitByLanguage(locationList, 'nameEn', 'nameTc', 'nameSc'),
					TopicList: this.removeRepeat(TopicList),
					titleMessage: titleMessage,
					eventNameList: this.splitByLanguage(eventList, 'eventNameEn', 'eventNameTc', 'eventNameSc')
				});
				setTimeout(() => {
					this.setState({ titleMessage: '' })
				}, 10000);
			});
			var kpiList = [
				{ id: 1, value: "YES", label: "Yes" },
				{ id: 2, value: "NO", label: "No" },
				
			];
			var leadDiscPublicList = [
				{ id: 1, value: "Disclosable", label: "Disclosable" },
				{ id: 2, value: "Cannot be Disclosed", label: "Cannot be Disclosed" },
				{ id: 2, value: "Partially disclosable", label: "Partially disclosable" },
			];
			
			var devStatusList = [
				{ id: 1, value: "Developed", label: "Developed" },
				{ id: 2, value: "Developing", label: "Developing" },
			];
			
			var tierList = [{ id: 1, value: "1", label: "Tier 1 - Internal Reference" },
			{ id: 2, value: "2", label: "Tier 2 - Pending" },
			{ id: 3, value: "3", label: "Tier 3 - Mature Lead" }
			];


			var usageChannelList = [

			];
			var submittedStatus = [
				{ id: 2, value: "Submitted", label: "Submitted" },
				{ id: 3, value: "Reopened", label: "Reopened" },
				{ id: 7, value: "Archived", label: "Archived" },
				{ id: 8, value: "Closed", label: "Closed" }
			];
			var pendingStatus = [
				{ id: 2, value: "Submitted", label: "Submitted" },
				{ id: 3, value: "Reopened", label: "Reopened" },
				{ id: 7, value: "Archived", label: "Archived" }
			];
			this.setState({ usageChannelList: usageChannelList, submittedStatus: submittedStatus,pendingStatus:pendingStatus, tierList: tierList, kpiList:kpiList, leadDiscPublicList:leadDiscPublicList, devStatusList:devStatusList });

			get(LEVEL_UI_CONFIG.url + '/searchTagging/SearchAllChannels', this).then(data => {

				console.log("searchTagging 6", data);
				if (data && data.returnType === "SUCESS") {

					var tempList = [];
					var othersItem = undefined;
					var anotherItem = undefined;

					tempList = data.channels.map((item) => {



						item.label = item.channelNameEn;
						item.value = item.channelNameEn;
						return item;
					});

					sortingLabel(tempList);



					tempList = tempList.filter(function (item) {
						if (item.label === "Others") {
							othersItem = item;
						}
						if (item.label === "Another HKTDC Platform") {
							anotherItem = item;
						}

						return (item.status === 'Active' && item.label !== "Others" && item.label !== "Another HKTDC Platform");
					});
					
					var tempList2 = [];
				
				
					tempList.map((item) => {
	
						tempList2.push(	item);
						if (item.label === "HKTDC Website/App (other than HKMB)") {
							tempList2.push(	anotherItem);
						}
						
						return item;
					});

					if (othersItem !== undefined) {
						tempList2.push(othersItem);
					}

					// this.state.usageChannelList = tempList2;
					console.log("searchTagging 7", tempList2);
					this.setState({usageChannelList:tempList2})

				}

			}).catch((e) => {
				console.log(e);
			});

			var submittedGui = this.state.submittedGui;
			let str = this.props.loginUser.userName;  
			if(str!==undefined && str!==''){
				let index = str.indexOf("(");  
				let result = str.substring(0, index).trim(); 
				submittedGui.userName = result
				this.setState({submittedGui},()=>{
					this.handleSubmittedSearch()
				})
			}
			
			//		this.setState({  gui: JSON.parse(localStorage.getItem("LeadSearchGui")) });
		}
	}


	splitByLanguage(list, lanEnCode, lanTCcode, lanScCode) {
		var enList = []; var tcList = []; var scList = [];

		list.map((item, index) => {
			//console.log("splitByLanguage", item, item[lanEnCode]);
			if (item[lanEnCode] && item[lanEnCode] !== '') {
				enList.push({ value: item.id + '_en', label: item[lanEnCode], similar: (item[lanEnCode].toLowerCase().indexOf("mainland china")===0?"china":item[lanEnCode]) });
			}
			if (item[lanTCcode] && item[lanTCcode] !== '') {
				tcList.push({ value: item.id + '_tc', label: item[lanTCcode] });
			}
			if (item[lanScCode] && item[lanScCode] !== '') {
				scList.push({ value: item.id + '_sc', label: item[lanScCode] });
			}
		});

		enList.push.apply(enList, tcList);
		enList.push.apply(enList, scList);
		sortingLabel(enList);
		return enList;
	}


	messageinfo(msg) {
		Modal.info({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}
	messageerror(msg) {
		Modal.error({
			title: msg,
			content: (
				<div>

				</div>
			),
			onOk() { },
		});
	}

	clickRow2 = (header, rowData, parentIndex) => {
		//console.log(" rowData " , rowData );

		var gui = this.state.gui;
		var topiclList = gui.topicl;
		if (topiclList === undefined) {
			topiclList = [];
		}
		if (topiclList.length === 0) {
			topiclList.push(rowData.label);
		}

		var check = 0;
		if (topiclList.length < 3) {
			topiclList.map((item, index) => {
				if (item === rowData.label) {
					check = 1;
				}
				return null;
			});

			if (check === 0) {
				topiclList.push(rowData.label);
			}
		}



		gui.topicl = topiclList;

		if (rowData) {
			this.setState({
				gui: gui,
				visible: false,
			});
		}
	}



	clickId = (entity,type) => {
		//var list = this.state.ssInfoList;
		//var entity = {};
		// list.map((item,index)=>{
		// 	if(item.id === id){
		// 		entity = item;
		// 	}
		// });
		var viewSubmittedLeadsInd = false;
		var viewPendingLeadsInd = false;

		if (this.props.userPermission) {
			viewSubmittedLeadsInd = this.props.userPermission.viewSubmittedLeadsInd;
			viewPendingLeadsInd = this.props.userPermission.viewPendingLeadsInd;
		}
		var viewPermission = false
		if("PENDING"===type){
			viewPermission = viewPendingLeadsInd
		}else if("SUBMITTED"===type){
			viewPermission = viewSubmittedLeadsInd
		}
		if (viewPermission) {
			var pathname = this.props.location.pathname;
				entity.pageSource = pathname;
				entity.isComingFromLeadStatusReport = "Y";
				dataPutAndGo(this, '/successStoryLeads/storyDetail/?leadId=' + entity.id, entity);
				window.scrollTo(0, 0)

		}
	}

	spliteAndGetIdArr2 = (oriId) => {
		//console.log("oriId arr >>> "  ,oriId );
		var newArr = "";
		if (oriId !== undefined && oriId.length > 0) {
			oriId.map((item) => {
				var arr = item.split('_');
				newArr += arr[0] + '<|>';
			})
		}
		return newArr;
	}
	spliteAndGetIdArr = (oriId) => {
		//console.log("oriId arr >>> "  ,oriId );
		var newArr = "";
		if (oriId !== undefined && oriId.length > 0) {
			oriId.map((item) => {
				var arr = item.split('_');
				newArr += arr[0] + ',';
			})
		}
		return newArr;
	}

	spliteAndGetIdStr = (oriId) => {
		//console.log("oriId str >>> "  ,oriId );
		var arr = oriId.split('_');
		return arr[0];
	}

	splitByComma = (dataList) => {
		var str = "";
		dataList.map((item, index) => {
			str += item + ',';
		})
		return str;
	}


	copyJson = (baseData) => {
		var datasStr = JSON.stringify(baseData);
		if (datasStr) {
			return JSON.parse(datasStr);
		} else {
			return null;
		}
	}

	handleSubmittedSearch = () => {
		var gui = this.copyJson(this.state.submittedGui);
		console.log("submitted gui before >>> ", gui);
		var storyId = gui.storyId === undefined ? "" : Number(gui.storyId);
		let cc = [storyId]
		gui.storyId = cc;
		var keywords = gui.keywords === undefined ? "" : gui.keywords;
		var userName = gui.userName === undefined ? "" : gui.userName;
		var department = gui.department === undefined ? "" : this.spliteAndGetIdArr2(gui.department);
		gui.department = department;
		var typeOfSuccessCase = gui.typeOfSuccessCase === undefined ? "" : this.spliteAndGetIdArr(gui.typeOfSuccessCase);
		gui.typeOfSuccessCase = typeOfSuccessCase;
		var leadNature = gui.leadNature === undefined ? "" : this.spliteAndGetIdArr(gui.leadNature);
		gui.leadNature = leadNature;
		var topicl = gui.topicl === undefined ? "" : this.splitByComma(gui.topicl);
		gui.topicl = topicl;
		var industry = gui.industry === undefined ? "" : this.spliteAndGetIdArr(gui.industry);
		gui.industry = industry;
		var eventId = gui.eventId === undefined ? "" : this.spliteAndGetIdArr(gui.eventId);
		gui.eventId = eventId;
		var location = gui.location === undefined ? "" : this.spliteAndGetIdArr(gui.location);
		gui.location = location;
		var storyTier = gui.storyTier === undefined ? "" : gui.storyTier;
		var status = gui.status === undefined ? "" : gui.status;
		var kpi = gui.kpi === undefined ? "" : gui.kpi;
		var kpiOversea = gui.kpiOversea === undefined ? "" : gui.kpiOversea;
		var discloseToPublic = gui.discloseToPublic === undefined?"": gui.discloseToPublic;
		var devStatus = gui.devStatus === undefined?"": gui.devStatus;
		var usageChannel = gui.usageChannel === undefined ? "" : this.spliteAndGetIdArr(gui.usageChannel);

		gui.usageChannel = usageChannel;

		var submissionDateFrom = gui.submissionDateFrom === undefined ? "" : gui.submissionDateFrom;
		var submissionDateTo = gui.submissionDateTo === undefined ? "" : gui.submissionDateTo;
		var lastmodifiedFrom = gui.lastmodifiedFrom === undefined ? "" : gui.lastmodifiedFrom;
		var lastmodifiedTo = gui.lastmodifiedTo === undefined ? "" : gui.lastmodifiedTo;

		if ("{}" === JSON.stringify(gui)) {
			this.messageinfo("Please fill in at least one search field");
			return;
		}
		if (storyId === "" && keywords === "" && userName === "" && department === "" 
		&& typeOfSuccessCase === "" && topicl === "" && industry === "" && eventId === "" 
		&& location === "" && storyTier === "" && status === "" && usageChannel === "" 
		&& submissionDateFrom === "" && submissionDateTo === "" && lastmodifiedFrom === "" 
		&& lastmodifiedTo === "" && kpi === "" && kpiOversea === "" && discloseToPublic==="" && devStatus==="" && leadNature==="" ) {
			this.messageinfo("Please fill in at least one search field");
			return;
		}

		var loginUser = this.props.loginUser;
		var roleCode = loginUser.roleId;
		var departmentName = getDepartmentName(this.props);
		gui.currentDepartment = departmentName;
		gui.currentRole = roleCode;
		if (keywords !== "") {
			gui.keywords = keywords;
		}
		gui.userName = this.props.loginUser.userName;
		console.log("submitted gui >>> " , gui );
		post(successStory_UI_CONFIG.url + '/ssls/findByCriteriaAndSubmitterAndCCList', gui, this).then(data => {
			console.log("submitted gui data >>> " , data );
			// let submittedStatus = this.state.submittedStatus
			var submittedStatus = ["Submitted", "Reopened", "Closed", "Archived"];
			var ssInfoList = this.formatDataForGui(data.users);
			console.log(">>filter before submittedInfoList:",ssInfoList)
			let submittedInfoList =  ssInfoList.filter(item => submittedStatus.includes(item.status));
			console.log(">>filter after submittedInfoList:",submittedInfoList)
			this.setState({ submittedInfoList: submittedInfoList });
			fireWinResizeEv();
			console.log("dispatchEvent");
		});
		this.setState({
			hideTable: false
		})

	}
	handlePendingSearch = () => {
		var gui = this.copyJson(this.state.pendingGui);
		console.log("pending gui before >>> ", gui);
		
		var storyId = gui.storyId === undefined ? "" : Number(gui.storyId);
		let cc = [storyId]
		gui.storyId = cc;
		var keywords = gui.keywords === undefined ? "" : gui.keywords;
		// var userName = gui.userName === undefined ? "" : gui.userName;
		var department = gui.department === undefined ? "" : this.spliteAndGetIdArr2(gui.department);
		gui.department = department;
		var typeOfSuccessCase = gui.typeOfSuccessCase === undefined ? "" : this.spliteAndGetIdArr(gui.typeOfSuccessCase);
		gui.typeOfSuccessCase = typeOfSuccessCase;
		var leadNature = gui.leadNature === undefined ? "" : this.spliteAndGetIdArr(gui.leadNature);
		gui.leadNature = leadNature;
		var topicl = gui.topicl === undefined ? "" : this.splitByComma(gui.topicl);
		gui.topicl = topicl;
		var industry = gui.industry === undefined ? "" : this.spliteAndGetIdArr(gui.industry);
		gui.industry = industry;
		var eventId = gui.eventId === undefined ? "" : this.spliteAndGetIdArr(gui.eventId);
		gui.eventId = eventId;
		var location = gui.location === undefined ? "" : this.spliteAndGetIdArr(gui.location);
		gui.location = location;
		var usageChannel = gui.usageChannel === undefined ? "" : this.spliteAndGetIdArr(gui.usageChannel);

		gui.usageChannel = usageChannel;

		if ("{}" === JSON.stringify(gui)) {
			this.messageinfo("Please fill in at least one search field");
			return;
		}


		var loginUser = this.props.loginUser;
		var roleCode = loginUser.roleId;
		var departmentName = getDepartmentName(this.props);
		gui.currentDepartment = departmentName;
		gui.currentRole = roleCode;		
		
		console.log("pendingGui.userName", gui.userName);
		
		if (keywords !== "") {
			gui.keywords = keywords;
		}
		gui.emailReceiver = this.props.loginUser.userName
		console.log("pendingGui>>> " , gui );
		post(successStory_UI_CONFIG.url + '/ssls/findByCriteriaAndSubmitterAndCCList', gui, this).then(data => {
			console.log(" pending gui data >>> " , data );
			var pendingStatus = ["Submitted","Reopened", "Archived"]
			
			var ssInfoList = this.formatDataForGui(data.users);
			if(pendingStatus&&pendingStatus.length>0){
				ssInfoList = ssInfoList.filter(item => pendingStatus.includes(item.status));
			}
			var pendingInfoList =[...ssInfoList].filter((item, index, self) =>  
				index === self.findIndex((t) => (  
				  t.id === item.id  
				))  
			  );  
			this.setState({ pendingInfoList: pendingInfoList });
			fireWinResizeEv();
			console.log("dispatchEvent");
		});
		this.setState({
			hideTable: false
		})

	}
	formatDataForGui(data) {
		if (!data) {
			data = [];
		}
		var dataList = [];
		data.map((item, index) => {
			var location1 = item.location1;
			var location2 = item.location2;
			var location3 = item.location3;
			var location4 = item.location4;
			var location5 = item.location5;
			var location = "";
			if (location1) { location += location1; }
			if (location2) { location += ", " + location2; }
			if (location3) { location += ", " + location3; }
			if (location4) { location += ", " + location4; }
			if (location5) { location += ", " + location5; }
			item.location = location;

			var industry1 = item.industry1;
			var industry2 = item.industry2;
			var industry3 = item.industry3;
			var industry4 = item.industry4;
			var industry5 = item.industry5;

			var industry = "";
			if (industry1) { industry += industry1; }
			if (industry2) { industry += ", " + industry2; }
			if (industry3) { industry += ", " + industry3; }
			if (industry4) { industry += ", " + industry4; }
			if (industry5) { industry += ", " + industry5; }
			item.industry = industry;

			var topicTheme1 = item.topicTheme1;
			var topicTheme2 = item.topicTheme2;
			var topicTheme3 = item.topicTheme3;
			var topicTheme4 = item.topicTheme4;
			var topicTheme5 = item.topicTheme5;

			var topStr = "";
			if (topicTheme1) { topStr += topicTheme1; }
			if (topicTheme2) { topStr += ", " + topicTheme2; }
			if (topicTheme3) { topStr += ", " + topicTheme3; }
			if (topicTheme4) { topStr += ", " + topicTheme4; }
			if (topicTheme5) { topStr += ", " + topicTheme5; }
			item.topicTheme = topStr;

			dataList.push(item);
			return null;
		}
		);



		return dataList;
	}

	
	handleTemplate	= () => {

		let roleCode = this.props.loginUser.roleId;
		let ids = "";
		
		var	checkDownloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/checkGenByIdsReportXLSX";
			

		if (checkDownloadUrl !== "") {
			
			var postData = {ids: ids, rolecode: roleCode};
			post(checkDownloadUrl, postData, this).then(data => {
				if (data.result && data.result.notGenReport === true) {
					message.config({
						top: '10%',
					});
					this.messageinfo(data.message);
					return;
				}
				if (data.result === undefined) {
					message.config({
						top: '10%',
					});
					this.messageerror("The lead failed to export. Please try again. If the problem persists, please contact system support.");
					return;
				}
				var downloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/downloadLeadPageReportByPath/" + data.result.encodeFilePath + "/" + data.result.key + "/" + data.result.downloadFileName;
				window.open(downloadUrl);
			}).catch((e) => {

				this.messageerror("The lead failed to export. Please try again. If the problem persists, please contact system support.");
				return;
			});

		}
		
		
	}
	handleExport = (sslsList) => {
		var checkDownloadUrl = "";
		if (!sslsList || sslsList.length <= 0) {
			return;
		}
		// var departmentName = getDepartmentName(this.props);
		var isSameDepInd = 'Y';
		let roleCode = this.props.loginUser.roleId;
		// let sslsList = ssInfoList;
		let ids = "";
		for (let i = 0; i < sslsList.length; i++) {
			if (sslsList[i]._rt_checked) {
				ids += sslsList[i].id + ",";
			}
		}
		console.log("##### handleExport:",sslsList,ids)
		ids = ids.substring(0, ids.length - 1);
		if (isSameDepInd === 'N' && (roleCode === '3' || roleCode === '4')) {
			this.messageinfo("Export records must be the same department.");
		} else {
			if (ids !== "") {
				// checkDownloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/checkGenByIdsReportXLSX/" + ids + "/" + roleCode;
				checkDownloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/checkGenByIdsReportXLSX";
			} else {
				message.config({
					top: '10%',
				});
				this.messageinfo("No record is selected.");
			}
		}


		if (checkDownloadUrl !== "") {
			var postData = {ids: ids, rolecode: roleCode};
			post(checkDownloadUrl, postData, this).then(data => {
				if (data.result && data.result.notGenReport === true) {
					message.config({
						top: '10%',
					});
					this.messageinfo(data.message);
					return;
				}
				if (data.result === undefined) {
					message.config({
						top: '10%',
					});
					this.messageerror("The lead failed to export. Please try again. If the problem persists, please contact system support.");
					return;
				}
				var downloadUrl = REPORT_UI_CONFIG.url + "/SuccessStoryLeadReport/downloadLeadPageReportByPath/" + data.result.encodeFilePath + "/" + data.result.key + "/" + data.result.downloadFileName;
				window.open(downloadUrl);
			}).catch((e) => {

				this.messageerror("The lead failed to export. Please try again. If the problem persists, please contact system support.");
				return;
			});

		}
	}


	formatNorList = (vals) => {
		var items;
		if (!vals) {
			items = vals;
		} else {
			var val = vals.split(",");
			val.map((item, index) => {
				if (index === 0) {
					items = item + ' \r\n '
				} else {
					items = items + item + ' \r\n '
				}

			});
		}
		return items;
	}

	changeSwitch(e, str) {
		var submittedGui = this.state.submittedGui;
		var pendingGui = this.state.pendingGui;
		if ("submittedThereTdcInd" === str) {
			submittedGui.isThereTdcInd = e;
		} else if("pendingThereTdcInd" === str){
			pendingGui.isThereTdcInd = e;
		}
		this.setState({
			submittedGui: submittedGui,
			pendingGui: pendingGui
		});
	}

	formatIndustryList = (vals) => {
		var result = "";
		if (!vals) {
			vals = [];
		}
		var IndustryList = this.state.IndustryList;
		if (!IndustryList) {
			IndustryList = [];
		}
		var length = vals.length;
		for (var i = 0; i < length; i++) {
			var userId = vals[i];
			for (var j = 0; j < IndustryList.length; j++) {
				if (userId === IndustryList[j].id) {
					if (result === "") {
						result = IndustryList[j].label;
					} else {
						result = result + "; " + IndustryList[j].label;
					}
					break;
				}
			}
		}

		return result;
	}
	

	formatSingle = (vals) => {
		var result = "";
		if (!vals) {
			vals = [];
		}
		var stageList = [{ value: 1, label: "Stage 1" }, { value: 2, label: "Stage 2" }];

		var userId = vals;
		for (var j = 0; j < stageList.length; j++) {
			if (userId === stageList[j].value) {
				result = stageList[j].label;
				break;
			}
		}



		return result;
	}

	handleSubmittedClean = () => {
		//		var isShowAllCriteria = this.state.isShowAllCriteria;
		var gui = this.state.submittedGui;
		gui.storyId = "";
		gui.keywords = undefined;
		// gui.userName = "";
		gui.department = undefined;
		gui.typeOfSuccessCase = undefined;
		gui.leadNature = undefined;
		gui.topicl = undefined;
		gui.industry = undefined;
		gui.eventId = undefined;
		gui.location = undefined;
		gui.storyTier = "";
		gui.status = "";
		gui.usageChannel = undefined;
		gui.submissionDateFrom = "";
		gui.submissionDateTo = "";
		gui.lastmodifiedFrom = "";
		gui.lastmodifiedTo = "";
		gui.isThereTdcInd = false;
		gui.kpi = "";
		gui.kpiOversea = "";
		gui.discloseToPublic = "";
		gui.devStatus = "";
		this.setState({
			submittedGui: gui
		});
	}
	handlePendingClean = () => {
		//		var isShowAllCriteria = this.state.isShowAllCriteria;
		var gui = this.state.pendingGui;
		gui.storyId = "";
		gui.keywords = undefined;
		gui.userName = "";
		gui.department = undefined;
		gui.typeOfSuccessCase = undefined;
		gui.leadNature = undefined;
		gui.topicl = undefined;
		gui.industry = undefined;
		gui.eventId = undefined;
		gui.location = undefined;
		gui.storyTier = "";
		gui.status = "";
		gui.usageChannel = undefined;
		gui.submissionDateFrom = "";
		gui.submissionDateTo = "";
		gui.lastmodifiedFrom = "";
		gui.lastmodifiedTo = "";
		gui.isThereTdcInd = false;
		gui.kpi = "";
		gui.kpiOversea = "";
		gui.discloseToPublic = "";
		gui.devStatus = "";
		this.setState({
			pendingGui: gui
		});
	}

	datePickerOnChange = (value, mode) => {
		var gui = this.state.gui;
		gui.submissionDateFrom = mode
		this.setState({ gui: gui });
	}

	datePickerOnChange2 = (value, mode) => {
		var gui = this.state.gui;
		gui.submissionDateTo = mode
		this.setState({ gui: gui });
	}

	datePickerOnChange3 = (value, mode) => {
		var gui = this.state.gui;
		gui.lastmodifiedFrom = mode
		this.setState({ gui: gui });
	}

	datePickerOnChange4 = (value, mode) => {
		var gui = this.state.gui;
		gui.lastmodifiedTo = mode
		this.setState({ gui: gui });
	}

	displaySubmittedAllCriteria = () => {
		var isShowAllCriteria = this.state.showSubmittedAllCriteria;
		var gui = this.state.submittedGui;

		this.setState({
			showSubmittedAllCriteria: !isShowAllCriteria,
			submittedGui: gui,
		});
		fireWinResizeEv();
	}
	displayPendingAllCriteria = () => {
		var isShowAllCriteria = this.state.showPendingAllCriteria;
		var gui = this.state.pendingGui;

		this.setState({
			showPendingAllCriteria: !isShowAllCriteria,
			pendingGui: gui,
		});
		fireWinResizeEv();
	}

	handleCancel = e => {
		this.setState({
			visible: false,
		});
	};

	handlerInputChange2 = (event) => {
		var stateData = this.state;
		var target = event.currentTarget;
		var fieldName = target.name;
		var value = target.value;
		lodash.set(stateData, fieldName, value);
		this.setState({ ...stateData });
	};

	removeRepeatSingleStr = (itemList) => {
		var resultList = [];
		if (!itemList) {
			itemList = [];
		}
		itemList.map((item) => {
			var exist = false;
			for (var a1 = 0; a1 < resultList.length; a1++) {
				if (item === resultList[a1]) {
					exist = true;
					break;
				}
			}
			if (!exist) {
				resultList.push(item);
			}
			return null;
		});
		return resultList;
	}

	removeRepeat = (itemList) => {
		var resultList = [];
		if (!itemList) {
			itemList = [];
		}
		itemList.map((item) => {
			var exist = false;
			for (var a1 = 0; a1 < resultList.length; a1++) {
				if (item.value === resultList[a1].value) {
					exist = true;
					break;
				}
			}
			if (!exist) {
				resultList.push(item);
			}
			return null;
		});
		return resultList;
	}

	handleCloseAnno = () => {

		this.setState({ isShowAnnouncement: false });
		localStorage.setItem(LEAD_SEARCH_ANNO_CLOSE, "Closed");
	}
	formatDateStr = (date)=>{
		if (!date) {
			date = null;
		} else {
			date = moment(date, 'YYYY-MM-DD');
		}
		return date;
	}
	tooltipInfo = () => {
		this.setState({isTooltipOpen:true})
	};
	handleTooltipInfoCancel = () =>{
		this.setState({isTooltipOpen:false})
	}
	render() {
		var pendingInfoList = this.state.pendingInfoList;
		var submittedInfoList = this.state.submittedInfoList;

		var ssInfoHeader = {
			columns: [
				// {
				// 	field: 'id', width: '97px', title: 'Lead ID',
				// 	rowHandler: (col, rowData, tableObj) => {//the value also can get from rowData
				// 		var val = rowData.id;
				// 		return (<a title={val} onClick={() => this.clickId(rowData)}>{val}</a>);
				// 	},
				// },
				{
					field: 'companyName', width: '170px', title: 'Company Name',
					format: (val) => {
						return this.formatNorList(val);
					}
				},
				
				{
					field: 'location', width: '200px', title: 'Location',
					rowHandler: (col, rowData, tableObj) => {

						var noTagVal = rowData.location;
						//console.log("######## vvvvv val: ", val);
						var len = 50;
						var showVal = noTagVal;
						if (noTagVal && noTagVal.length > len) {
							showVal = noTagVal.substring(0, len) + "...";
						}

						return (<span title={noTagVal}>{showVal}</span>);
					},
				},
				{
					field: 'industry', width: '200px', title: 'Industry',
					rowHandler: (col, rowData, tableObj) => {

						var noTagVal = rowData.industry;
						//console.log("######## vvvvv val: ", val);
						var len = 50;
						var showVal = noTagVal;
						if (noTagVal && noTagVal.length > len) {
							showVal = noTagVal.substring(0, len) + "...";
						}

						return (<span title={noTagVal}>{showVal}</span>);
					},
				},
				{
					field: 'topicTheme', width: '210px', title: 'Topic / Theme',
					rowHandler: (col, rowData, tableObj) => {

						var noTagVal = rowData.topicTheme;
						//console.log("######## vvvvv val: ", val);
						var len = 50;
						var showVal = noTagVal;
						if (noTagVal && noTagVal.length > len) {
							showVal = noTagVal.substring(0, len) + "...";
						}

						return (<span title={noTagVal}>{showVal}</span>);
					},
				},


				{
					field: 'discloseToPublic', width: '210px', title: 'Disclose to Public',
					format: (val) => {
						if (val !== undefined && val !== "") {
							return val;
						} else {
							return null
						}
					}
				},
				{
					field: 'devStatus', width: '110px', title: 'Developing Status',
					format: (val) => {
						if (val !== undefined && val !== "") {
							return val;
						} else {
							return null
						}
					}
				},
				
				{
					field: 'status', width: '130px', title: 'Lead Status',
					rowHandler: (col, rowData, tableObj) => {		//the value also can get from rowData
						if(STATUS_.Reviewing===rowData.status ){
							return (
									<span>{rowData.status} by {rowData.lastModifiedBy.split('(')[0]}</span>
								);
						}else{						
						
							return (
								<span>{rowData.status}</span>
							);
						}


					}
				},
				{
					field: 'submittedByDept', width: '190px', title: 'Submission Department',
					// format: (val) => {
					// 	return this.formatDepartmentList(val);;
					// }
				},
				{
					field: 'submittedBy', width: '184px', title: 'Submitted By',
					rowHandler: (col, rowData, tableObj) => {
						

						var submittedBy = rowData.submittedBy === undefined ? "" : rowData.submittedBy;
						// var dept = rowData.submittedByDept === null ? null : rowData.submittedByDept;
						// var str = "";
						// if(dept != null ){
						// 	str = "("+ dept +")";
						// }
						// var value = "";
						// if( submittedBy != "" ){
						// 	value = submittedBy + str;
						// }


						return submittedBy.split('(')[0];
					}
				},
				{
					field: 'submittedDateTime', width: '193px', title: 'Submission Date',
					format: (val) => {
						if (!val) {
							val = null;
						} else {
							var temp = moment(val, 'YYYY-MM-DD HH:mm:ss');
							val = moment(temp).format(GUI_DATE_FORMAT);
						}
						return val;
					},
					sortHandler: (val) => {
						return sortDateStr(val);
					}
				},
				{
					field: 'lastModifiedBy', width: '184px', title: 'Last Modified By',
					rowHandler: (col, rowData, tableObj) => {
						var lastModifiedBy = rowData.lastModifiedBy === undefined ? "" : rowData.lastModifiedBy;
						// var dept = rowData.updateByDept == null ? null : rowData.updateByDept;
						// var str = "";
						// if(dept != null ){
						// 	str = "("+ dept +")";
						// }

						// var value = lastModifiedBy + str;
						return lastModifiedBy.split('(')[0];
					}
				},
				{
					field: 'lastModifiedDateTime', width: '193px', title: 'Last Modification Date',
					format: (val) => {
						if (!val) {
							val = null;
						} else {
							var temp = moment(val, 'YYYY-MM-DD HH:mm:ss');
							val = moment(temp).format(GUI_DATE_FORMAT);
						}
						return val;
					},
					sortHandler: (val) => {
						return sortDateStr(val);
					}
				},
				{
					field: 'storyDescription', width: '260px', title: 'Lead Description',
					rowHandler: (col, rowData, tableObj) => {//the value also can get from rowData
						//						var val = rowData.storyDescription;
						var noTagVal = BraftEditor.createEditorState(rowData.storyDescription).toText();// deleteHtmlElement(val);//getTextFromHtml(val);
						//console.log("######## vvvvv val: ", val);
						var len = 50;
						var showVal = noTagVal;
						if (noTagVal && noTagVal.length > len) {
							showVal = noTagVal.substring(0, len) + "...";
						}
						//console.log("######## vvvvv noTagVal/showVal: ", noTagVal,showVal);
						return (<span title={noTagVal}>{showVal}</span>);
					},
				},
				
				{
					field: 'kpiOversea', width: '470px', title: '“Focus 3 Deals” KPI Endorsed by DED/M (For Overseas Offices Only)',
					format: (val) => {
						if (val === 'YES' ) {
							return 'Yes';
						} else if (val === 'NO' ) {
							return 'No';
						} else {
							return null
						}
					}
				},
				// { field: 'submiteByAndDate', width: 2, title: 'Submission' },
				// { field: 'lastModifiedByAndDate', width: 3, title: 'Last Modification' },
			]
		};

		var submittedHeader = ssInfoHeader
		var pendingHeader = ssInfoHeader
		if(submittedHeader.columns && submittedHeader.columns[0].field !=="id"){
			submittedHeader.columns.unshift({
				field: 'id', width: '97px', title: 'Lead ID',
				rowHandler: (col, rowData, tableObj) => {//the value also can get from rowData
					var val = rowData.id;
					// eslint-disable-next-line jsx-a11y/anchor-is-valid
					return (<a title={val} onClick={() => this.clickId(rowData,"SUBMITTED")}>{val}</a>);
				},
			})
		}
		if(pendingHeader.columns && pendingHeader.columns[0].field !=="id"){
			pendingHeader.columns.unshift({
				field: 'id', width: '97px', title: 'Lead ID',
				rowHandler: (col, rowData, tableObj) => {//the value also can get from rowData
					var val = rowData.id;
					return (<a title={val} onClick={() => this.clickId(rowData,"PENDING")}>{val}</a>);
				},
			})
		}
		console.log("this.state.gui", this.state.gui);

		var titleMessage = this.state.titleMessage;
		console.log("annoBox.enable", (this.state.annoBox && this.state.annoBox.enable !== 'false'));
		let textArr = ["Collaboration - MoUs, cooperation and partnership agreements, business matching, deal-making;", 
			"Market expansion - Support businesses to launch products, services or open offices in other markets;", 
			"Transaction - Exhibitor-buyer transactions, sales at fairs;",
			"Other HK success cases - Hong Kong success stories that did not involve HKTDC;"];
		const data = [
			{
				title: 'Collaboration',
				text: 'MoUs, cooperation and partnership agreements, business matching, deal-making'
			},
			{
				title: 'Market expansion',
				text: 'Support businesses to launch products, services or open offices in other markets'
			},
			{
				title: 'Transaction',
				text: 'Exhibitor-buyer transactions, sales at fairs'
			},
			{
				title: 'Other HK success cases',
				text: 'Hong Kong success stories that did not involve HKTDC'
			},
			];
		return (
			<div className="pageMain">
				<HeaderPage parentObj={this} showLoading={true} telescopic={false} />
				<Row type="flex">
					<Col className="pageLift" style={this.state.pageLeftScreenWidthStyle} >{this.genMenu2 && this.genMenu2()}</Col>
					<Col className="pageRightLead" style={this.state.pageRightScreenWidthStyle}>
						<div className={ (this.state.isShowAnnouncement && this.state.annoBox.enable !== 'false') ?"announcementBackgroundBlock":"announcementBackgroundNormal"} style={this.state.pageRightScreenWidthStyle}>
							<div>
								<div style={{ textAlign: 'left' }} className="pageMainIn" >
									<Row className="headerTop3">
										<Col className="path">
											<span>My submitted Leads</span>            
										</Col>
									</Row>
									<div style={{ backgroundColor: '#9EC7F5', color: '#fff', padding: '5px', display: this.state.titleMessage === undefined || this.state.titleMessage === '' ? 'none' : '' }} >
										<Icon type="info-circle" style={{ fontSize: '18px' }} /> {titleMessage}
									</div>
									<div>
										<Row className="searchPanelLeadUp">
											<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Lead ID</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}><Input name="submittedGui.storyId" placeholder="Lead Reference No." onChange={this.handlerInputChange2} value={this.state.submittedGui.storyId} /></Col>
											<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
												<Tooltip placement="right" title="Keywords of story lead Description, Industry, Topic/Theme, Location, Event name, Company Name.">Keywords</Tooltip></Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect name="submittedGui.keywords"
													style={{ width: '100%' }}
													options={this.state.TopicList}
													allowClear="true"
													mode="tags"
													filterOption={this.filterOptionTopic}
													value={this.state.submittedGui.keywords}
													onChange={this.handlerSelectChange}
													searchHandlerPromise={this.searchHandlerPromiseTopic}
												/>
											</Col>
										</Row>
									</div>
									<div id="showAllCriteria" style={{ display: this.state.showSubmittedAllCriteria ? 'block' : 'none' }}>
										<Row className="editPanel">
											<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Submitter</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												{/* <Input name="gui.userName" placeholder="" onChange={this.handlerInputChange2} value={this.state.gui.userName} /> */}
												<MySelect
													name="submittedGui.userName"
													mode="single"
													allowClear="true"
													//topMode={{topCount:5, initAll: false}}
													searchHandlerPromise={this.searchHandlerPromiseName}
													style={{ width: '100%' }}
													options={this.state.userList}
													value={this.state.submittedGui.userName}
													onChange={this.handlerSelectChange}
													disabled={true}
												/>
											</Col>

											<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Source Department</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="submittedGui.department"
													mode="multiple"
													allowClear="true"
													topMode={{ topCount: 5, initAll: true }}
													style={{ width: '100%' }}
													options={this.state.departmentList}
													value={this.state.submittedGui.department}
													onChange={this.handlerSelectChange}
												/>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
												<span>Types of Story Lead</span>
											</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="submittedGui.typeOfSuccessCase"
													mode="multiple"
													allowClear="true"
													maxSelectCount="5"
													topMode={{ topCount: 5, initAll: true }}
													style={{ width: '100%' }}

													options={this.state.typeOfSuccessCaseList}
													value={this.state.submittedGui.typeOfSuccessCase}
													onChange={this.handlerSelectChange}
													placeholder="Select one or more options"
												/>
											</Col>

											<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Service/Event Name</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="submittedGui.eventId"
													mode="multiple"
													allowClear="true"
													maxSelectCount="5"
													topMode={{ topCount: 5, initAll: true }}
													style={{ width: '100%' }}
													options={this.state.eventNameList}
													value={this.state.submittedGui.eventId}
													onChange={this.handlerSelectChange}
													placeholder="Select one or more options"
												/>
											</Col>


										</Row>
										<Row className="editPanel">
											<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Industries</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="submittedGui.industry"
													mode="multiple"
													topMode={{ topCount: 5, initAll: true }}
													style={{ width: '100%' }}
													maxSelectCount="5"
													options={this.state.IndustryList}
													value={this.state.submittedGui.industry}
													onChange={this.handlerSelectChange}
													placeholder="Select one or more options"
												/>
											</Col>

											<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Story Publicity</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="submittedGui.usageChannel"
													mode="multiple"
													allowClear="true"
													maxSelectCount="10"
													style={{ width: '100%' }}
													options={this.state.usageChannelList}
													value={this.state.submittedGui.usageChannel}
													onChange={this.handlerSelectChange}
													placeholder="Select one or more options"
												/>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Locations</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="submittedGui.location"
													mode="multiple"
													topMode={{ topCount: 5, initAll: true }}
													maxSelectCount="5"
													style={{ width: '100%' }}
													options={this.state.locationList}
													value={this.state.submittedGui.location}
													onChange={this.handlerSelectChange}
													placeholder="Select one or more options"
												/>
											</Col>

											<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Lead Status</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}> <MySelect
													name="submittedGui.status"
													mode="single"
													allowClear="true"
													//maxSelectCount="3"
													style={{ width: '100%' }}
													options={this.state.submittedStatus}
													value={this.state.submittedGui.status}
													onChange={this.handlerSelectChange}
												/>
											</Col>
										</Row>

										<ConfigProvider locale={locale}>
											<Row className="editPanel">
												<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Submission Date From</Col>
												<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>
													<DatePicker
														className="_NMT_mydatepicker"
														disabledDate={(currentDate)=>{this.disabledSubmissionStartDate(currentDate,this.state.submittedGui.submissionDateFrom)}}
														name="submittedGui.submissionDateFrom"
														placeholder=""
														value={this.formatDateStr(this.state.submittedGui.submissionDateFrom)}
														format="D-MMM-YYYY"
														onChange={(date,dateString)=>{this.submissionDataFromChange(date,dateString,'SUBMITTED')}}
														style={{ width: '100%', cursor: 'pointer' }}
													/>
												</Col>
												<Col className="searchlabel1Center" xs={6} sm={6} md={6} lg={2} xl={2} xxl={2}>To</Col>
												<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>

													<DatePicker
														className="_NMT_mydatepicker"
														disabledDate={(currentDate)=>{this.disabledSubmissionEndDate(currentDate,this.state.submittedGui.submissionDateTo)}}
														name="submittedGui.submissionDateTo"
														value={this.formatDateStr(this.state.submittedGui.submissionDateTo)}
														placeholder=""
														format="D-MMM-YYYY"
														onChange={(date,dateString)=>{this.submissionDateToChange(date,dateString,'SUBMITTED')}}
													/>
												</Col>

												<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>HKTDC Involvement</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}> 
												<Switch  name="submittedGui.isThereTdcInd" checked={this.state.submittedGui.isThereTdcInd} onChange={(e) => { this.changeSwitch(e, "submittedThereTdcInd") }} checkedChildren="Y" unCheckedChildren="ALL" />
												</Col>	
											</Row>
											<Row className="editPanel">
												<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Last Modification Date From</Col>
												<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>
													<DatePicker
														className="_NMT_mydatepicker"
														disabledDate={(currentDate)=>{this.disabledLastModifiedDateFrom(currentDate,this.state.submittedGui.lastmodifiedFrom)}}
														name="submittedGui.lastmodifiedFrom"
														value={this.formatDateStr(this.state.submittedGui.lastmodifiedFrom)}
														placeholder=""
														format="D-MMM-YYYY"
														onChange={(date,dateString)=>{this.dateChange3(date,dateString,'SUBMITTED')}}
													/>
												</Col>
												<Col className="searchlabel1Center" xs={6} sm={6} md={6} lg={2} xl={2} xxl={2}>To</Col>
												<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>
													<DatePicker
														className="_NMT_mydatepicker"
														disabledDate={(currentDate)=>{this.disabledLastModifiedDateTo(currentDate,this.state.submittedGui.lastmodifiedFromTo)}}
														name="submittedGui.lastmodifiedFromTo"
														value={this.formatDateStr(this.state.submittedGui.lastmodifiedFromTo)}
														placeholder=""
														format="D-MMM-YYYY"
														onChange={(date,dateString)=>{this.dateChange4(date,dateString,'PENDING')}}
													/>
												</Col>
												<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4} >“Focus 3 Deals” KPI Endorsed by DED/M (For Overseas Offices Only)</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="submittedGui.kpiOversea"
													mode="single"
													allowClear="true"
													//maxSelectCount="3"
													style={{ width: '100%' }}
													options={this.state.kpiList}
													value={this.state.submittedGui.kpiOversea}
													onChange={this.handlerSelectChange}
												/>
												</Col>
											</Row>
											<Row className="editPanel">
												<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Disclose to Public</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="submittedGui.discloseToPublic"
													mode="single"
													allowClear="true"
													//maxSelectCount="3"
													style={{ width: '100%' }}
													options={this.state.leadDiscPublicList}
													value={this.state.submittedGui.discloseToPublic}
													onChange={this.handlerSelectChange}
												/>
												</Col>
												<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4} >Developing Status</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="submittedGui.devStatus"
													mode="single"
													allowClear="true"
													//maxSelectCount="3"
													style={{ width: '100%' }}
													options={this.state.devStatusList}
													value={this.state.submittedGui.devStatus}
													onChange={this.handlerSelectChange}
												/>
												</Col>
											</Row>
											<Row className="editPanel" >
												<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
													<span>Nature of story lead</span>&nbsp;&nbsp;
													<Tooltip placement="right" title={<List
														size="small"
														bordered={false}
														dataSource={textArr}
														renderItem={(item) => <List.Item>{item}</List.Item>}
														/>} 
													><Icon type="info-circle" style={{ fontSize: '18px' }} onClick={()=>{this.tooltipInfo()}} /></Tooltip>
												</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
													<MySelect
														name="submittedGui.leadNature"
														mode="multiple"
														allowClear="true"
														maxSelectCount="5"
														topMode={{ topCount: 5, initAll: true }}
														style={{ width: '100%' }}
														options={this.state.leadNatureList}
														value={this.state.submittedGui.leadNature}
														onChange={this.handlerSelectChange}
														placeholder="Select one or more options"
													/>
												</Col>
											</Row>	
										</ConfigProvider>
									</div>
									<Row className="searchButtonMoreLessPanel" >
										<div>
											{/* <Icon className="oneIcon" onClick={this.displayAllCriteria} type="up-circle" style={{ fontSize: '25px', color: '#FB4F03' }} rotate={this.state.isShowAllCriteria ? 0 : 180} /> */}
											<a onClick={this.displaySubmittedAllCriteria}  >{!this.state.showSubmittedAllCriteria ? "Show more" : "Show Less"} &nbsp;&nbsp;</a>
										</div>
									</Row>
									<Row className="searchButtonPanel" >
										<div>
											<Button onClick={this.handleSubmittedClean} className="oneButton" type="primary">Reset</Button>
											&nbsp;
											<Button onClick={this.handleSubmittedSearch} className="oneSearchButton" type="primary"><Icon type="search" className="oneSearchIcon" /><span className="oneSearchTitle">Search</span></Button>
										</div>
									</Row>
									<div className="submittedLeadResultTableList">
										<div style={{ display: !this.state.hideTable ? '' : 'none' }} >
											{/* <div style={{overflowY: 'auto'}}> */}
											<ResponseTable
												name="submittedLeadTable"
												pageObj={this}
												response={false}
												selectMode={3}
												onePageCount={10} //record count one page, default: 10
												selectPageIndex={1}
												pageMode={1}
												sort={{ index: 0, orderBy: false }}
												showPageSelect={false}
												width={'3040px'}
												isIconChangeLine={false}
												style={{ overflow: 'auto' }}
												buttons={this.getTableButtons(submittedInfoList,"SUBMITTED")}
												//handlerClick={this.clickRow}
												data={submittedInfoList}
												header={submittedHeader}
											/>
											{/* </div> */}
										</div>
									</div>
								</div>
								<div style={{ textAlign: 'left' }} className="pageMainIn">&nbsp;</div>
								<div style={{ textAlign: 'left' }} className="pageMainIn">&nbsp;</div>
								<div style={{ textAlign: 'left' }} className="pageMainIn">&nbsp;</div>
								<div style={{ textAlign: 'left' }} className="pageMainIn">
									<Row className="headerTop3">
										<Col className="path">
											<span>Pending leads to be followed up</span>            
										</Col>
									</Row>
									<div style={{ backgroundColor: '#9EC7F5', color: '#fff', padding: '5px', display: this.state.titleMessage === undefined || this.state.titleMessage === '' ? 'none' : '' }} >
										<Icon type="info-circle" style={{ fontSize: '18px' }} /> {titleMessage}
									</div>
									<div>
										<Row className="searchPanelLeadUp">
											<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Lead ID</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}><Input name="pendingGui.storyId" placeholder="Lead Reference No." onChange={this.handlerInputChange2} value={this.state.pendingGui.storyId} /></Col>

											<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
												<Tooltip placement="right" title="Keywords of story lead Description, Industry, Topic/Theme, Location, Event name, Company Name.">Keywords</Tooltip></Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect name="pendingGui.keywords"
													style={{ width: '100%' }}
													options={this.state.TopicList}
													allowClear="true"
													mode="tags"
													filterOption={this.filterOptionTopic}
													value={this.state.pendingGui.keywords}
													onChange={this.handlerSelectChange}
													searchHandlerPromise={this.searchHandlerPromiseTopic}
												/>
											</Col>
										</Row>
									</div>
									<div id="showAllCriteria" style={{ display: this.state.showPendingAllCriteria ? 'block' : 'none' }}>
										<Row className="editPanel">
											<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Submitter</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												{/* <Input name="gui.userName" placeholder="" onChange={this.handlerInputChange2} value={this.state.pendingGui.userName} /> */}
												<MySelect
													name="pendingGui.userName"
													mode="single"
													allowClear="true"
													//topMode={{topCount:5, initAll: false}}
													searchHandlerPromise={this.searchHandlerPromiseName}
													style={{ width: '100%' }}
													options={this.state.userList}
													value={this.state.pendingGui.userName}
													onChange={this.handlerSelectChange}
												/>
											</Col>

											<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Source Department</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="pendingGui.department"
													mode="multiple"
													allowClear="true"
													topMode={{ topCount: 5, initAll: true }}
													style={{ width: '100%' }}
													options={this.state.departmentList}
													value={this.state.pendingGui.department}
													onChange={this.handlerSelectChange}
												/>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
												<span>Types of Story Lead</span>
											</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="pendingGui.typeOfSuccessCase"
													mode="multiple"
													allowClear="true"
													maxSelectCount="5"
													topMode={{ topCount: 5, initAll: true }}
													style={{ width: '100%' }}

													options={this.state.typeOfSuccessCaseList}
													value={this.state.pendingGui.typeOfSuccessCase}
													onChange={this.handlerSelectChange}
													placeholder="Select one or more options"
												/>
											</Col>

											<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Service/Event Name</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="pendingGui.eventId"
													mode="multiple"
													allowClear="true"
													maxSelectCount="5"
													topMode={{ topCount: 5, initAll: true }}
													style={{ width: '100%' }}
													options={this.state.eventNameList}
													value={this.state.pendingGui.eventId}
													onChange={this.handlerSelectChange}
													placeholder="Select one or more options"
												/>
											</Col>


										</Row>
										<Row className="editPanel">
											<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Industries</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="pendingGui.industry"
													mode="multiple"
													topMode={{ topCount: 5, initAll: true }}
													style={{ width: '100%' }}
													maxSelectCount="5"
													options={this.state.IndustryList}
													value={this.state.pendingGui.industry}
													onChange={this.handlerSelectChange}
													placeholder="Select one or more options"
												/>
											</Col>

											<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Story Publicity</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="pendingGui.usageChannel"
													mode="multiple"
													allowClear="true"
													maxSelectCount="10"
													style={{ width: '100%' }}
													options={this.state.usageChannelList}
													value={this.state.pendingGui.usageChannel}
													onChange={this.handlerSelectChange}
													placeholder="Select one or more options"
												/>
											</Col>
										</Row>
										<Row className="editPanel">
											<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Locations</Col>
											<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="pendingGui.location"
													mode="multiple"
													topMode={{ topCount: 5, initAll: true }}
													maxSelectCount="5"
													style={{ width: '100%' }}
													options={this.state.locationList}
													value={this.state.pendingGui.location}
													onChange={this.handlerSelectChange}
													placeholder="Select one or more options"
												/>
											</Col>

											<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Lead Status</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}> <MySelect
													name="pendingGui.status"
													mode="single"
													allowClear="true"
													//maxSelectCount="3"
													style={{ width: '100%' }}
													options={this.state.pendingStatus}
													value={this.state.pendingGui.status}
													onChange={this.handlerSelectChange}
												/>
											</Col>

										</Row>

										<ConfigProvider locale={locale}>
											<Row className="editPanel">
												<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Submission Date From</Col>
												<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>
													<DatePicker
														className="_NMT_mydatepicker"
														disabledDate={(currentDate)=>{this.disabledSubmissionStartDate(currentDate,this.state.pendingGui.submissionDateFrom)}}
														name="pendingGui.submissionDateFrom"
														placeholder=""
														value={this.formatDateStr(this.state.pendingGui.submissionDateFrom)}
														format="D-MMM-YYYY"
														onChange={(date,dateString)=>{this.submissionDataFromChange(date,dateString,'PENDING')}}
														style={{ width: '100%', cursor: 'pointer' }}
													/>
												</Col>
												<Col className="searchlabel1Center" xs={6} sm={6} md={6} lg={2} xl={2} xxl={2}>To</Col>
												<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>

													<DatePicker
														className="_NMT_mydatepicker"
														disabledDate={(currentDate)=>{this.disabledSubmissionEndDate(currentDate,this.state.pendingGui.submissionDateTo)}}
														name="pendingGui.submissionDateTo"
														value={this.formatDateStr(this.state.pendingGui.submissionDateTo)}
														placeholder=""
														format="D-MMM-YYYY"
														onChange={(date,dateString)=>{this.submissionDateToChange(date,dateString,'PENDING')}}

													/>
												</Col>

												<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>HKTDC Involvement</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}> 
												<Switch  name="pendingGui.isThereTdcInd" checked={this.state.pendingGui.isThereTdcInd} onChange={(e) => { this.changeSwitch(e, "pendingThereTdcInd") }} checkedChildren="Y" unCheckedChildren="ALL" />
												</Col>	
											</Row>
											<Row className="editPanel">
												<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Last Modification Date From</Col>
												<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>
													<DatePicker
														className="_NMT_mydatepicker"
														disabledDate={(currentDate)=>{this.disabledLastModifiedDateFrom(currentDate,this.state.pendingGui.lastmodifiedFrom)}}
														name="pendingGui.lastmodifiedFrom"
														value={this.formatDateStr(this.state.pendingGui.lastmodifiedFrom)}
														placeholder=""
														format="D-MMM-YYYY"
														onChange={(date,dateString)=>{this.dateChange3(date,dateString,'PENDING')}}

													/>
												</Col>
												<Col className="searchlabel1Center" xs={6} sm={6} md={6} lg={2} xl={2} xxl={2}>To</Col>
												<Col className="field" xs={9} sm={9} md={9} lg={3} xl={3} xxl={3}>
													<DatePicker
														className="_NMT_mydatepicker"
														disabledDate={(currentDate)=>{this.disabledLastModifiedDateTo(currentDate,this.state.pendingGui.lastmodifiedFromTo)}}
														name="pendingGui.lastmodifiedFromTo"
														value={this.formatDateStr(this.state.pendingGui.lastmodifiedFromTo)}
														placeholder=""
														format="D-MMM-YYYY"
														onChange={(date,dateString)=>{this.dateChange4(date,dateString,'PENDING')}}

													/>
												</Col>
												<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4} >“Focus 3 Deals” KPI Endorsed by DED/M (For Overseas Offices Only)</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="pendingGui.kpiOversea"
													mode="single"
													allowClear="true"
													//maxSelectCount="3"
													style={{ width: '100%' }}
													options={this.state.kpiList}
													value={this.state.pendingGui.kpiOversea}
													onChange={this.handlerSelectChange}
												/>
												</Col>	

												
											</Row>
											<Row className="editPanel">
												<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>Disclose to Public</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="pendingGui.discloseToPublic"
													mode="single"
													allowClear="true"
													//maxSelectCount="3"
													style={{ width: '100%' }}
													options={this.state.leadDiscPublicList}
													value={this.state.pendingGui.discloseToPublic}
													onChange={this.handlerSelectChange}
												/>
												</Col>

												
												
												<Col className="searchlabel" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4} >Developing Status</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<MySelect
													name="pendingGui.devStatus"
													mode="single"
													allowClear="true"
													//maxSelectCount="3"
													style={{ width: '100%' }}
													options={this.state.devStatusList}
													value={this.state.pendingGui.devStatus}
													onChange={this.handlerSelectChange}
												/>
												</Col>
											</Row>
											<Row className="editPanel" >
												<Col className="searchlabel1" xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
													<span>Nature of story lead</span>&nbsp;&nbsp;
													<Tooltip placement="right" title={<List
														size="small"
														bordered={false}
														dataSource={textArr}
														renderItem={(item) => <List.Item>{item}</List.Item>}
														/>} 
													><Icon type="info-circle" style={{ fontSize: '18px' }} onClick={()=>{this.tooltipInfo()}}/>
													</Tooltip>
												</Col>
												<Col className="field" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
													<MySelect
														name="pendingGui.leadNature"
														mode="multiple"
														allowClear="true"
														maxSelectCount="5"
														topMode={{ topCount: 5, initAll: true }}
														style={{ width: '100%' }}
														options={this.state.leadNatureList}
														value={this.state.pendingGui.leadNature}
														onChange={this.handlerSelectChange}
														placeholder="Select one or more options"
													/>
												</Col>
											</Row>
										</ConfigProvider>
									</div>

									<Row className="searchButtonMoreLessPanel" >
										<div>
											{/* <Icon className="oneIcon" onClick={this.displayAllCriteria} type="up-circle" style={{ fontSize: '25px', color: '#FB4F03' }} rotate={this.state.isShowAllCriteria ? 0 : 180} /> */}
											<a onClick={this.displayPendingAllCriteria}  >{!this.state.showPendingAllCriteria ? "Show more" : "Show Less"} &nbsp;&nbsp;</a>
										</div>
									</Row>
									<Row className="searchButtonPanel" >
										<div>
											<Button onClick={this.handlePendingClean} className="oneButton" type="primary">Reset</Button>
											&nbsp;
											<Button onClick={this.handlePendingSearch} className="oneSearchButton" type="primary"><Icon type="search" className="oneSearchIcon" /><span className="oneSearchTitle">Search</span></Button>
										</div>
									</Row>

									<div className="leadResultTableList">
										<div style={{ display: !this.state.hideTable ? '' : 'none' }} >
											{/* <div style={{overflowY: 'auto'}}> */}
											<ResponseTable
												name="pendingLeadTable"
												pageObj={this}
												response={false}
												selectMode={3}
												onePageCount={10} //record count one page, default: 10
												selectPageIndex={1}
												pageMode={1}
												sort={{ index: 0, orderBy: false }}
												showPageSelect={false}
												width={'3040px'}
												isIconChangeLine={false}
												style={{ overflow: 'auto' }}
												buttons={this.getTableButtons(pendingInfoList,"PENDING")}
												//handlerClick={this.clickRow}
												data={pendingInfoList}
												header={pendingHeader}
											/>
											{/* </div> */}
										</div>
									</div>
								</div>
								<div class="normalFooter">Copyright &copy; {new Date().getFullYear()} Hong Kong Trade Development Council. All rights reserved.</div>
							</div>
						</div>
					</Col>
				</Row>
				<Modal title="" visible={this.state.isTooltipOpen} 
				onCancel={this.handleTooltipInfoCancel}
				footer={''}
				>
					<List
						itemLayout="horizontal"
						dataSource={data}
						renderItem={(item, index) => (
						<List.Item>
							<List.Item.Meta
							title={item.title}
							description={item.text}
							/>
						</List.Item>
						)}
					/>
				</Modal>
			</div>
		);
	}

	disabledSubmissionStartDate = (itemDate, value) => {
		var nowDate = new Date();
		if (itemDate > nowDate) {
			return true;
		}
		var endValue = value;

		itemDate = moment(itemDate).format('YYYY-MM-DD');
		itemDate = moment(itemDate, 'YYYY-MM-DD');

		if (endValue !== undefined) {
			endValue = moment(endValue).format('YYYY-MM-DD');
			endValue = moment(endValue, 'YYYY-MM-DD');
		}

		if (endValue && itemDate > endValue) {
			return true;
		}
		return false;
	};

	disabledSubmissionEndDate = (itemDate, value) => {
		var nowDate = new Date();
		if (itemDate > nowDate) {
			return true;
		}
		var startValue = value;

		itemDate = moment(itemDate).format('YYYY-MM-DD');
		itemDate = moment(itemDate, 'YYYY-MM-DD');

		if (startValue !== undefined) {
			startValue = moment(startValue).format('YYYY-MM-DD');
			startValue = moment(startValue, 'YYYY-MM-DD');
		}

		//console.log("itemDate >>> " , itemDate );
		//console.log("startValue >>> " , startValue );

		if (startValue && itemDate < startValue) {
			return true;
		}
		return false;
	};


	disabledLastModifiedDateFrom = (itemDate, value) => {
		var nowDate = new Date();
		if (itemDate > nowDate) {
			return true;
		}
		var endValue = value;

		itemDate = moment(itemDate).format('YYYY-MM-DD');
		itemDate = moment(itemDate, 'YYYY-MM-DD');

		if (endValue !== undefined) {
			endValue = moment(endValue).format('YYYY-MM-DD');
			endValue = moment(endValue, 'YYYY-MM-DD');
		}

		if (endValue && itemDate > endValue) {
			return true;
		}
		return false;
	};

	disabledLastModifiedDateTo = (itemDate, value) => {
		var nowDate = new Date();
		if (itemDate > nowDate) {
			return true;
		}
		var startValue = value;

		itemDate = moment(itemDate).format('YYYY-MM-DD');
		itemDate = moment(itemDate, 'YYYY-MM-DD');

		if (startValue !== undefined) {
			startValue = moment(startValue).format('YYYY-MM-DD');
			startValue = moment(startValue, 'YYYY-MM-DD');
		}

		if (startValue && itemDate < startValue) {
			return true;
		}
		return false;
	};




	submissionDataFromChange = (value, mode, type) => {
		//console.log("value >>> " , value );
		//console.log("mode >>> " , mode );
		var newDate = moment(mode, 'DD-MMM-YYYY').locale('en');
		var date = moment(newDate).format('YYYY-MM-DD');
		if(type==='PENDING'){
			var gui = this.state.pendingGui;
			gui.submissionDateFromMoment = value;
			gui.submissionDateFrom = date;
			if (date === "Invalid date") {
				gui.submissionDateFrom = null;
			}
			this.setState({ pendingGui: gui });
		}else if(type==='SUBMITTED'){
			var gui = this.state.submittedGui;
		
			gui.submissionDateFromMoment = value;
			gui.submissionDateFrom = date;
			if (date === "Invalid date") {
				gui.submissionDateFrom = null;
			}
			this.setState({ submittedGui: gui });
		}
	}

	submissionDateToChange = (value, mode, type) => {
		var newDate = moment(mode, 'DD-MMM-YYYY').locale('en');
		var date = moment(newDate).format('YYYY-MM-DD');
		if(type==='PENDING'){
			var gui = this.state.pendingGui;
			gui.submissionDateToMoment = value;
			gui.submissionDateTo = date;
			if (date === "Invalid date") {
				gui.submissionDateTo = null;
			}
			this.setState({ pendingGui: gui });
		}else if(type==='SUBMITTED'){
			var gui = this.state.submittedGui;
		
			gui.submissionDateToMoment = value;
			gui.submissionDateTo = date;
			if (date === "Invalid date") {
				gui.submissionDateTo = null;
			}
			this.setState({ submittedGui: gui });
		}
	}

	dateChange3 = (value, mode, type) => {
		var newDate = moment(mode, 'DD-MMM-YYYY').locale('en');
		var date = moment(newDate).format('YYYY-MM-DD');
		if(type==='PENDING'){
			var gui = this.state.pendingGui;
			gui.LastmodifiedFromMoment = value;
			gui.lastmodifiedFrom = date;
			if (date === "Invalid date") {
				gui.lastmodifiedFrom = null;
			}
			this.setState({ pendingGui: gui });
		}else if(type==='SUBMITTED'){
			var gui = this.state.submittedGui;
		
			gui.LastmodifiedFromMoment = value;
			gui.lastmodifiedFrom = date;
			if (date === "Invalid date") {
				gui.lastmodifiedFrom = null;
			}
			this.setState({ submittedGui: gui });
		}
	}
	dateChange4 = (value, mode, type) => {
		var newDate = moment(mode, 'DD-MMM-YYYY').locale('en');
		var date = moment(newDate).format('YYYY-MM-DD');
		if(type==='PENDING'){
			var gui = this.state.pendingGui;
			gui.LastmodifiedToMoment = value;
			gui.lastmodifiedTo = date;
			if (date === "Invalid date") {
				gui.lastmodifiedTo = null;
			}
			this.setState({ pendingGui: gui });
		}else if(type==='SUBMITTED'){
			var gui = this.state.submittedGui;
		
			gui.LastmodifiedToMoment = value;
			gui.lastmodifiedTo = date;
			if (date === "Invalid date") {
				gui.lastmodifiedTo = null;
			}
			this.setState({ submittedGui: gui });
		}
	}


}

const ConnectedLeadStatusReportPage = connect(mapStateToProps, mapDispatchToProps)(LeadStatusReportPage);

export default withRouter(ConnectedLeadStatusReportPage);
