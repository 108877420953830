import React, { Component } from 'react';
import lodash from 'lodash';
import { Row, Col, Icon, Checkbox, Input } from 'antd';
import MySelect from './MySelect';
import MyPagination from './MyPagination';
import './ResponseTable.css';


//
const pageCounts = [
    { value: 5, label: "show 5 records" },
    { value: 10, label: "show 10 records" },
    { value: 20, label: "show 20 records" },
    { value: 50, label: "show 50 records" },
    { value: 100, label: "show 100 records" }
];

const getWidth = () => {
    var bodyWidthCheck = document.body.clientWidth + 20;
    //console.log("================getWidth=========", bodyWidth);
    if (bodyWidthCheck >= 1600) {
        return "xxl";
    } else if (bodyWidthCheck >= 1200) {
        return "xl";
    } else if (bodyWidthCheck >= 992) {
        return "lg";
    } else if (bodyWidthCheck >= 768) {
        return "md";
    } else if (bodyWidthCheck >= 576) {
        return "sm";
    }
    return "xs";
}

const fixWidth = () => {
    var width = getWidth();
    if (width == "sm" || width == "xs") {
        return false;
    }
    return true;
}


class ResponseTableFixColWidth extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            selectPageIndex: this.props.selectPageIndex,
            onePageCount: this.props.onePageCount,
            checked: false, 
            
            
            sort: this.props.sort,
            selectedCount: 0,
            isEditMode: false,
            
            buttons: this.props.buttons ? this.props.buttons : [],
            fixWidth: fixWidth(),
            data: this.props.data,
            
			maxLength:this.props.maxLength,
			showPageCreate: this.props.showPageCreate,
			minLength:this.props.minLength,
			
        };
        this.genHeader = this.genHeader.bind(this);
        this.updateEditRow = this.updateEditRow.bind(this);
        
        this.genOneRow = this.genOneRow.bind(this);
        this.isNewRecord = this.isNewRecord.bind(this);
        this.genRows = this.genRows.bind(this);
        this.clearRowData = this.clearRowData.bind(this);
        this.handlerOptionChange = this.handlerOptionChange.bind(this);
        this.startEditRow = this.startEditRow.bind(this);
        this.handlerPageChange = this.handlerPageChange.bind(this);
        this.deleteOneRow = this.deleteOneRow.bind(this);
        this.genButtons = this.genButtons.bind(this);
        this.stopCancelEditRow = this.stopCancelEditRow.bind(this);
        this.handlerButtonClick = this.handlerButtonClick.bind(this);
        this.stopSaveEditRow = this.stopSaveEditRow.bind(this);
        this.handlerHeaderCheckbox = this.handlerHeaderCheckbox.bind(this);
        this.handlerRowCheckbox = this.handlerRowCheckbox.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
        this.genHeaderSort = this.genHeaderSort.bind(this);
        this.handlerSort = this.handlerSort.bind(this);
        this.copyData = this.copyData.bind(this);
        
        this.showButton = this.showButton.bind(this);
        this.onlyShowButton = this.onlyShowButton.bind(this);
        this.hideButton = this.hideButton.bind(this);
        
        
        
        this.createNewRecord = this.createNewRecord.bind(this);
        
        this.openEditModel = this.openEditModel.bind(this);
        this.setFieldValue = this.setFieldValue.bind(this);
        this.closeEditModel = this.closeEditModel.bind(this);
        this.genNoData = this.genNoData.bind(this);
       
        this.getSelectedRowIndexs = this.getSelectedRowIndexs.bind(this);
        this.handleResize = this.handleResize.bind(this);

        this.rowClickMethod = this.rowClickMethod.bind(this);
       
        this.stopSort  = this.stopSort.bind(this);
        this.sortDatas = this.sortDatas.bind(this);
        
        this.getSelectedRowDatas = this.getSelectedRowDatas.bind(this);
        
        
        
        this.handlerInputChange = this.handlerInputChange.bind(this);
        this.deleteSelectedRowData = this.deleteSelectedRowData.bind(this);
        this.getEditDataList = this.getEditDataList.bind(this);
        
        //console.log(" # ResponseTable | constructor ");

        var tableName = this.props.name;
        var pageObj = this.props.pageObj;
        if(pageObj && tableName){
            var tables = pageObj.tables;
            if(!tables){
                tables = {};
                pageObj.tables = tables;
            }
            pageObj.tables[tableName] = this;
        }

    }

    stopSort = () =>{
        console.log(" stop sort ");
       this.setState({ sort : undefined });
       console.log(" this.state. " , this.state );
    }

    handleResize = () => {
        this.setState({ fixWidth: fixWidth() });
    }
    handlerHeaderCheckbox = (event) => {
        //console.log("-------------all--------------", event);

        // if(this.state.isEditMode){
        //     return;
        // }
        var target = event.target;
        var isCheck = target.checked;

        var selectMode = this.props.selectMode;
        if (selectMode != 3 && selectMode != 4) {
            return;
        }

        //var allDatas = this.state.data;
        var allDatas = null;
        if (this.state.isEditMode) {
            allDatas = this.currentData;
        } else {
            allDatas = this.state.data;
        }

        var selectedCount = 0;
        if (selectMode == 3) {//have checkbox, select more than one record for all page; 
            allDatas.map((item, index) => {
                item._rt_checked = isCheck;
                if (item._rt_checked == true) {
                    selectedCount++;
                }
            });

        } else if (selectMode == 4) {//have checkbox, select more than one record for current page; 
            this.currentData.map((item, index) => {
                item._rt_checked = isCheck;
                if (item._rt_checked == true) {
                    selectedCount++;
                }
            });
        }

        this.setState({ checked: isCheck, selectedCount: selectedCount }, () => {
            if (this.props.headerCheckBoxChange) {
                this.props.headerCheckBoxChange(isCheck);
            }
            if (this.props.selectChange) {
                this.props.selectChange(selectedCount);
            }
        });
    }
    componentWillMount() {
        //console.log(" # ResponseTable | componentWillMount");
    }

    componentDidMount() {
        if (this.props.width) {
            window.addEventListener('resize', this.handleResize);
        }
    }

    componentWillReceiveProps(nextPropsFixColWidth) {
        //console.log(" # ResponseTable | componentWillReceiveProps");
        this.refreshFromOut = true;
        this.existRefreshReq = (nextPropsFixColWidth.refresh == true || nextPropsFixColWidth.refresh == false) ? true : false;
        this.refresh = nextPropsFixColWidth.refresh;
        //var data = nextPropsFixColWidth.data;//this.copyData(nextPropsFixColWidth.data);
        var datas = nextPropsFixColWidth.data;
        if (!datas) {
            datas = [];
        }
        if (!this.state.isEditMode) {
            if (this.refreshFromOut == true && this.existRefreshReq == true && this.refresh == true) {
                datas.map((item) => {
                    //item._rt_checked = false;
                    this.clearRowData(item);
                    return datas.map;
                });
                this.state.selectedCount = 0;
                this.state.checked = false;
                this.state.selectPageIndex = 1;
                
            }
            this.state.data = datas;
            this.state.checked = false;
        }

       
    }

    

    

    handlerRowCheckbox = (eventFixColWidth, rowDataFixColWidth) => {

        eventFixColWidth.stopPropagation();

        // if(this.state.isEditMode){
        //     return;
        // }
        var target = eventFixColWidth.target;
        var isCheck = target.checked;

        //console.log("------------handlerRowCheckbox--------isCheck-------", isCheck);

        var selectMode = this.props.selectMode;
        if (selectMode != 1 && selectMode != 2 && selectMode != 3 && selectMode != 4) {
            return;
        }

        var isAllRowSelected = true;
        var allDatas = null;
        if (this.state.isEditMode) {
            allDatas = this.currentData;
        } else {
            allDatas = this.state.data;
        }

        var selectedCount = 0;
        if (selectMode == 1) {//have checkbox, only select one record for all page; 
            allDatas.map((item, index) => {
                item._rt_checked = false;
            });
            rowDataFixColWidth._rt_checked = isCheck;

            for (var i = 0; i < allDatas.length; i++) {
                if (allDatas[i]._rt_checked == true) {
                    selectedCount++;
                }
            }

        } else if (selectMode == 2) {//have checkbox, only select one record for current page; 
            this.currentData.map((item, index) => {
                item._rt_checked = false;
            });
            rowDataFixColWidth._rt_checked = isCheck;

            for (var i5 = 0; i5 < this.currentData.length; i5++) {
                if (this.currentData[i5]._rt_checked == true) {
                    selectedCount++;
                }
            }

        } 
        if (selectMode == 3) {//have checkbox, select more than one record for all page; 
            rowDataFixColWidth._rt_checked = isCheck;
            for (var i1 = 0; i1 < allDatas.length; i1++) {
                if (!allDatas[i1]._rt_checked || allDatas[i1]._rt_checked == false) {
                    isAllRowSelected = false;
                    break;
                }
            }

            for (var i2 = 0; i2 < allDatas.length; i2++) {
                if (allDatas[i2]._rt_checked == true) {
                    selectedCount++;
                }
            }

        } else if (selectMode == 4) {//have checkbox, select more than one record for current page; 
            rowDataFixColWidth._rt_checked = isCheck;
            for (var i3 = 0; i3 < this.currentData.length; i3++) {
                if (!this.currentData[i3]._rt_checked || this.currentData[i3]._rt_checked == false) {
                    isAllRowSelected = false;
                    break;
                }
            }

            for (var i4 = 0; i4 < this.currentData.length; i4++) {
                if (this.currentData[i4]._rt_checked == true) {
                    selectedCount++;
                }
            }
        }

        this.setState({ checked: isAllRowSelected, selectedCount: selectedCount }, () => {
            if (this.props.selectChange) {
                this.props.selectChange(selectedCount);
            }
        });
    }
    genHeader = (header) => {
        var isResponse = this.props.response;
        if(isResponse == true){
            isResponse = true;
        }else{
            isResponse = false;
        }
        var selectColWith = 0;
        if(!isResponse){
            selectColWith = 1;
        }        

        var columns = header.columns;
        if (!columns) {
            columns = [];
        }
        

        var headerStyle = this.props.headerStyle;
        if (!headerStyle) {
            headerStyle = {};
        }

        var cols = columns.map((col, index) => {

            

            var headerHandler = col.headerHandler;
            if (headerHandler) {
                return (
                    <Col style={{width:col.width }} onClick={() => this.handlerSort(col, index)} key={'_hh' + index} className="_NMT_colHeader" >
                        {headerHandler(col)}
                        {this.genHeaderSort(col, index)}
                    </Col>
                );
            }
            //default handler
            return (
                <Col style={{ width:col.width}} onClick={() => this.handlerSort(col, index)} key={'_hd' + index} className="_NMT_colHeader" >
                    {col.showTips ? (<span style={headerStyle} title={col.title}>{col.title}</span>) : (<span style={headerStyle}>{col.title}</span>)}
                    {this.genHeaderSort(col, index)}
                </Col>
            );
        });

        //gen checkbox begin
        var selectMode = this.props.selectMode;
        if (selectMode != 1 && selectMode != 2 && selectMode != 3 && selectMode != 4) {
            selectMode = 0;
        }
        if (selectMode == 1 || selectMode == 2 || selectMode == 3 || selectMode == 4) {
            //!this.state.isEditMode &&
            var checkboxCol = (
                <Col key={'_hhc'} className="_NMT_colHeader_checkboxAll" xs={selectColWith} sm={selectColWith} md={1}>
                    {(selectMode == 3 || selectMode == 4) && (<Checkbox checked={this.state.checked} onChange={this.handlerHeaderCheckbox} style={{  left:'0px' , right:'0px'}}/>)}
                </Col>
            );
            cols.unshift(checkboxCol);
        }
        //gen checkbox end

        return (
            <Row className="_NMT_tableHeader">
                {cols}
            </Row>
        );
    }
    sortDatas = (datasFixColWidth, headerFixColWidth) => {
        var sort = this.state.sort;
        if (!headerFixColWidth || !headerFixColWidth.columns || !sort || !datasFixColWidth) {
            return datasFixColWidth;
        }
        var colIndex = sort.index;
        var by = sort.orderBy;

        if ((!colIndex && colIndex != 0) || colIndex < 0 || headerFixColWidth.columns.length < colIndex + 1) {
            return datasFixColWidth;
        }
        var col = headerFixColWidth.columns[colIndex];
        datasFixColWidth.sort(function (d1, d2) {
            var v1 = lodash.get(d1, col.field);
            var v2 = lodash.get(d2, col.field);
            if(col.sortHandler){
                //console.log("########### sortHandler...", v1, v2);
                v1 = col.sortHandler(v1);
                v2 = col.sortHandler(v2);
            }else if (col.format) {
                v1 = col.format(v1);
                v2 = col.format(v2);
            }
            if(v1 == undefined){
                v1 = null;
            }
            if(v2 == undefined){
                v2 = null;
            }
			
            if (by == false) {//desc
                if(v1==null){
                	if ( v2 != null){
                    	return 1;
                	} else {
                    	return 0;
                	}
                } else {
                	if( v2 == null){
                		return -1;	
                	} else {
                		return (v1 < v2 ? 1 : (v1 > v2 ? -1 : 0));
                	}
                }
            } else {//asc
                if(v1==null){
                	if (v2 != null){
                    	return -1;
                	} else {
                    	return 0;
                	}
                } else {
                	if (v2 == null) {
                		return 1;
                	} else {
		                return (v1 > v2 ? 1 : (v1 < v2 ? -1 : 0));
		            }
                }
            }
        });
        return datasFixColWidth;
    }

    handlerSort = (colFixColWidth, index) => {
        if (colFixColWidth.sort == false || this.state.isEditMode) {
            return;
        }
        var sort = this.state.sort;
        if (!sort) {
            sort = {};
        }
        var by = colFixColWidth._sort_by;
        sort.index = index;
        sort.orderBy = !(by == false ? false : true);
        colFixColWidth._sort_by = sort.orderBy;
        this.setState({ sort: sort });
    }

    genHeaderSort = (colFixColWidth, index) => {
        //20191029-begin
        if (colFixColWidth.sort == false) {
            return (<span></span>);
        }
        //20191029-end
        var sort = this.state.sort;

       

        if (!sort) {
            return (<div> <Icon type="arrow-up" className="arrowIcon"/>  </div>);
        }
        var colIndex = sort.index;
        var by = sort.orderBy;
        //if(col.sort==false || index != colIndex){
        if (index != colIndex) {
            return (<span> <Icon type="arrow-up" className="arrowIcon"/> </span>);
        }
        colFixColWidth._sort_by = by;
        //<span> {by!=false && <Icon type="caret-up" />}{by==false && <Icon type="caret-down" />}</span>

        return (
            <span > {by ==true && <Icon type="arrow-up" className="arrowIcon"/>}{by == false && <Icon type="arrow-down" className="arrowIcon"/>} </span>
        );
    }

   
    

    /* ****************************
    header -> {
        columns:[
            {
                field: 'id', width: 2, title: 'ID', 
                hide: true/false, ---mobile 下是否显示此列
                format:(val)=>{...}, 
                headerHandler: (headerCol)=> {...},
                rowHandler: (headerCol, index, rowData)=> {...},
                rowMobileHandler: (headerCol, index, rowData)=> {...},
            },
            {field: 'name', width: 3, title: 'Name'},
            {field: 'age', width: 2, title: 'Age'},
            {field: 'status', width: 2, title: 'Status'},
        ],
    }
    rowData -> {id:1, name:'Van', age: 23, status:'A'}
    ******************************************** */
    rowClickMethod = (header, rowData, rowIndex) => {
        if (this.props.handlerClick) {
            this.props.handlerClick(header, rowData, rowIndex);
        }
    }
    genRows = (header, datas) => {
        if (!datas) {
            datas = [];
        }
        var selectMode = this.props.selectMode;
        if (selectMode != 1 && selectMode != 2 && selectMode != 3 && selectMode != 4) {
            selectMode = 0;
        }
        return datas.map((item, index) => {
            return this.genOneRow(header, item, index, selectMode);
        });
    };
    getDefaultEditModel = (value, col, rowData) => {
        var dataFormat = col.format;
		var maxLength = this.state.maxLength;
		var minLength = this.state.minLength;
        //var value = lodash.get(rowData, col.field);
        if (dataFormat) {
            value = dataFormat(value);
        }
		if(col.field=="eventType"){
			return (
			    <Input onChange={(event) => this.handlerInputChange(event, rowData, col.field)} name={col.field} value={value} maxLength={minLength}/>
			);
		}else if(col.field=="similarWords" ||col.field=="eventNameEn" || col.field=="eventNameSc"||col.field=="eventNameTc"){
			return (
			    <Input onChange={(event) => this.handlerInputChange(event, rowData, col.field)} name={col.field} value={value} maxLength={maxLength}/>
			);
		}else{
			return (
			    <Input onChange={(event) => this.handlerInputChange(event, rowData, col.field)} name={col.field} value={value} />
			);
		}
       
    }

    handlerInputChange = (eventFixColWidth, rowData, field) => {
        var target = eventFixColWidth.currentTarget;
        var value = target.value;
       
        lodash.set(rowData, field, value);
        this.setState({ isEditMode: true });
    }



    genOneRow = (header, rowData, rowIndex, mode) => {

        var isResponse = this.props.response;
        if(isResponse == true){
            isResponse = true;
        }else{
            isResponse = false;
        }
       

        var columns = header.columns;
        if (!columns) {
            columns = [];
        }
        var thisObj = this;

        var dataStyle = this.props.dataStyle;
        if (!dataStyle) {
            dataStyle = {};
        }

        var cols = columns.map((col, colIndex) => {

            

            var dataFormat = col.format;
            var baseVal = lodash.get(rowData, col.field);
            var value = baseVal;
            if (dataFormat) {
                value = dataFormat(baseVal);
            }

            var rowHandler = col.rowHandler;
            //var rowMobileHandler = col.rowMobileHandler;
            var editModel = col.editModel;
            //var isEditMode = this.state.isEditMode;
            
            

            var isEditMode = rowData._rt_in_edit == true ? true : false;
            
            return (
                <span key={'_rds' + colIndex} style={{borderright: '1px white double'}}>
                    <Col className="_NMT_colRow" style={{width:col.width,float:'left' }} > 
                        {isEditMode == false && rowHandler && rowHandler(col, rowData, thisObj)}
                        {isEditMode == false && !rowHandler && 
                            (col.html==true? (<span dangerouslySetInnerHTML={{__html:value}}></span>)
                                :(col.showTips ? (<span style={dataStyle} title={value}>{value}</span>) : (<span style={dataStyle}>{value}</span>))
                            )
                            //(<span title={value} style={dataStyle}>{value}</span>)
                        }
                        {isEditMode == true && editModel &&  editModel(baseVal, col, rowData, thisObj)}
                        {isEditMode == true && (!editModel || ( ( col.field=='eventNameEn' || col.field=='eventNameTc' || col.field=='eventNameSc' )
                        		&& rowData.id==undefined)) && this.getDefaultEditModel(baseVal, col, rowData)}
                    </Col>
                    {
                        isResponse && !col.hide && (
                            <Col className="_NMT_colRow" xs={24} sm={24} md={0}>
                                {isEditMode == false && rowHandler && 
                                (
                                    <Row>
                                        <Col className="min-colRow-title" xs={8} sm={8} md={0}>
                                            { col.title } : 
                                        </Col>
                                        <Col className="min-colRow-value" xs={16} sm={16} md={0}>
                                            { rowHandler(col, rowData, thisObj) }
                                        </Col>
                                    </Row>
                                )}
                                {isEditMode == false && !rowHandler && (
                                    <Row>
                                        <Col className="min-colRow-title" xs={8} sm={8} md={0}>
                                            {col.title} :
                                        </Col>
                                        <Col className="min-colRow-value" xs={16} sm={16} md={0}>
                                            <span style={dataStyle}>{value}</span>
                                        </Col>
                                    </Row>
                                )}
                                {isEditMode == true && editModel && (
                                    <Row>
                                        <Col className="min-colRow-title" xs={8} sm={8} md={0}>
                                            {col.title} :
                                        </Col>
                                        <Col className="min-colRow-value" xs={16} sm={16} md={0}>
                                            {editModel(baseVal, col, rowData, thisObj)}
                                        </Col>
                                    </Row>
                                )}
                                {isEditMode == true && !editModel && (
                                    <Row>
                                        <Col className="min-colRow-title" xs={8} sm={8} md={0}>
                                            {col.title} :
                                        </Col>
                                        <Col className="min-colRow-value" xs={16} sm={16} md={0}>
                                            {this.getDefaultEditModel(baseVal, col, rowData)}
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        )
                    }
                </span>
            );
        });

        //gen checkbox begin
        if (mode != 0) {
            //!this.state.isEditMode &&
            var checkboxCol = (
                <span key={'_rdsc'}>
                    <Col  className="_NMT_col_checkboxAll" >
                        <Checkbox checked={rowData._rt_checked} onClick={(e) => this.handlerRowCheckbox(e, rowData)} />
                    </Col>
                </span>
            );
            cols.unshift(checkboxCol);
        }
        //gen checkbox end

        var rowClass = "_NMT_tableRow1";
        if (rowIndex % 2 == 0) {
            rowClass = "_NMT_tableRow2";
        }
        return (
            <Row onClick={() => this.rowClickMethod(header, rowData, rowIndex)} key={'_r' + rowIndex} className={rowClass}>
                {cols}
            </Row>
        );
    }

    

    genNoData = () => {
        return (
            <Row>
                <Col className="_NMT_nodata" xs={24}>
                    <Icon style={{ fontSize: '20px' }} type="inbox" />
                    <span>No Data</span>
                </Col>
            </Row>
        );
    }

    genButtons = () => {
    	
   
        var buttons = this.state.buttons;
        if (!buttons) {
            buttons = [];
        }
        var thisObj = this;
        var dataObj = {};
        var selectedDatas = [];
        var selectedIndexs = [];
        var datas = this.state.data;
        if (this.state.isEditMode) {
            datas = this.currentData;
        }
        if (!datas) {
            datas = [];
        }
        dataObj.datas = datas;
        dataObj.header = this.props.header;
        for (var i = 0; i < datas.length; i++) {
            if (datas[i]._rt_checked == true) {
                selectedDatas.push(datas[i]);
                selectedIndexs.push(i);
            }
        }
        dataObj.selectedDatas = selectedDatas;
        dataObj.selectedIndexs = selectedIndexs;
        var list = buttons.map((item, index) => {
            if (item.hide == true) {
                return (<span key={"_bs_" + index}></span>);
            }
            var icon = "file";
            if (item.icon) {
                icon = item.icon;
            }
            let style = {};
            if (item.style) {
                style = item.style;
            }
			if(item.customize==undefined){
				return (
					<div key={"_b_" + index} onClick={() => this.handlerButtonClick(thisObj, dataObj, item, index)} className="_NMT_tableButItem">
						<span style={style}><Icon type={item.icon?item.icon:icon} /> {item.title}</span>
					</div>
				);
			}else{
				return item.customize;
			}
			
        });

        if (list.length < 1) {
            return (
                <span></span>
            );
        }
        return (
            <div className="_NMT_tableButtons">
                {list}
            </div>
        );
    }

    handlerButtonClick = (tableObj, dataObj, button, index) => {
    	
    	this.state.showPageCreate=true;
        if (!button) {
            return;
        }
        if (button.handler) {
            button.handler(tableObj, dataObj, button);
        }
    }

    handlerOptionChange = (name, value) => {
        //console.log("=========handlerOptionChange========:", name, value);
        var headerChecked = this.state.checked;
        var selectMode = this.props.selectMode;
        var allDatas = this.state.data;
        if (selectMode == 2 || selectMode == 4) {//have checkbox, only select one record for current page; 
            headerChecked = false;
            for (var i = 0; i < allDatas.length; i++) {
                allDatas[i]._rt_checked = false;
            }
        }

        var selectedCount = 0;
        for (var i1 = 0; i1 < allDatas.length; i1++) {
            if (allDatas[i1]._rt_checked == true) {
                selectedCount++;
            }
        }

        //pageMode
        var pageMode = this.props.pageMode;
        if (pageMode == 2) {
            //reset selectPageIndex - begin
            var selectPageIndex = this.props.selectPageIndex;
            var onePageCount = value;
            var totalCount = this.props.totalCount;
            var totalPageCount = totalCount % onePageCount > 0 ? Math.ceil(totalCount / onePageCount) : Math.floor(totalCount / onePageCount);
            if (selectPageIndex > totalPageCount) {
                selectPageIndex = totalPageCount;
            }
            //reset selectPageIndex - end
            if (this.props.changePage) {
                this.props.changePage(selectPageIndex, value, this.state.sort);
            } else {
                console.error("When pageMode is 2, pls set changePage props.");
            }
        }

        this.setState({
            onePageCount: value,
            checked: headerChecked,
            selectedCount: selectedCount
        });
    }

    handlerPageChange = (pageIndex, pageSize) => {
        //console.log("===========handlerPageChange======:", pageIndex, pageSize);
        var headerChecked = this.state.checked;
        var selectMode = this.props.selectMode;
        var allDatas = this.state.data;
        if (selectMode == 2 || selectMode == 4) {//have checkbox, only select one record for current page; 
            headerChecked = false;
            for (var i = 0; i < allDatas.length; i++) {
                allDatas[i]._rt_checked = false;
            }
        }
        var selectedCount = 0;
        for (var i1 = 0; i1 < allDatas.length; i1++) {
            if (allDatas[i1]._rt_checked == true) {
                selectedCount++;
            }
        }

        //pageMode
        var pageMode = this.props.pageMode;
        if (pageMode == 2) {
            //var onePageCount = this.state.onePageCount;
            if (this.props.changePage) {
                this.props.changePage(pageIndex, pageSize, this.state.sort);
            } else {
                console.error("When pageMode is 2, pls set changePage props.");
            }
        }
        this.setState({ selectPageIndex: pageIndex, checked: headerChecked, selectedCount: selectedCount });
    }

    /* public - Refresh table */
    refreshTable = () => {
        this.setState({ refresh: this.state.refresh });
    }

    /* 
    public - get current sort info
    var table1 = this.tables ? this.tables['tabel name'] : null;
    var sort = table1.getCurrentSortInfo();
     */
    getCurrentSortInfo = () => {
        var sortObj = this.state.sort;
        var headerObj = this.props.header;
        sortObj.field = headerObj.columns[sortObj.index].field;
        return sortObj;
    }

    getHeaderCheckboxStatus = () => {
        return this.state.checked;
    }

    /* public - get selected row datas */
    getSelectedRowDatas = () => {
        var datas = this.state.data;
        if (this.state.isEditMode) {
            datas = this.currentData;
        }
        if (!datas) {
            datas = [];
        }
        var selectedDatas = [];
        for (var i = 0; i < datas.length; i++) {
            if (datas[i]._rt_checked == true) {
                selectedDatas.push(datas[i]);
            }
        }

        selectedDatas.map((item) => {
            delete item._rt_checked;
            return selectedDatas.map;
        });

        return selectedDatas;
    }


    /* public - get selected row indexs */
    getSelectedRowIndexs = () => {
        var datas = this.state.data;
        if (this.state.isEditMode) {
            datas = this.currentData;
        }
        if (!datas) {
            datas = [];
        }
        var selectedIndexs = [];
        for (var i = 0; i < datas.length; i++) {
            if (datas[i]._rt_checked == true) {
                selectedIndexs.push(i);
            }
        }
        return selectedIndexs;
    }


    /* public - delete row data */
    deleteSelectedRowData = () => {
        var rowIndexs = this.getSelectedRowIndexs();
        if (!rowIndexs || rowIndexs.length < 1) {
            return 0;
        }
        var list = this.state.data;
        if (this.state.isEditMode) {
            list = this.currentData;
        }
        for (var i = rowIndexs.length - 1; i >= 0; i--) {
            list.splice(rowIndexs[i], 1);
        }
        this.setState({ isEditMode: this.state.isEditMode });
        return list.length;
    }

    /* public method */
    isNewRecord = (rowData) => {
        return rowData._rt_new_data == true ? true : false;
    }

    /* public - set one row in edit mode */
    startEditRow = (rowData) => {
        if (!rowData) {
            return;
        }
        //this.clearRowData(rowData);
        var copy = this.copyData(rowData);
        copy._rt_checked = rowData._rt_checked;
        delete copy._rt_base_data;
        delete copy._rt_in_edit;

        rowData._rt_base_data = copy;
        rowData._rt_in_edit = true;
        this.setState({ isEditMode: true });
    }

    deleteOneRow = (rowData) => {
        if (!rowData) {
            return;
        }

        var selectedDatas = this.currentData;
        for (var i = 0; i < selectedDatas.length; i++) {
            if (selectedDatas[i] == rowData) {
                selectedDatas.splice(i, 1);
                break;
            }
        }

        var allStateDatas = this.state.data;
        for (var i2 = 0; i2 < allStateDatas.length; i2++) {
            if (allStateDatas[i2] == rowData) {
                allStateDatas.splice(i2, 1);
                break;
            }
        }

        var isEditMode = false;
        for (var i1 = 0; i1 < selectedDatas.length; i1++) {
            if (selectedDatas[i1]._rt_in_edit == true) {
                isEditMode = true;
            }
        }
        this.setState({ isEditMode: isEditMode });
    }


    updateEditRow = (oldData, newData) => {
        var selectedDatas = this.currentData;
        for (var i1 = 0; i1 < selectedDatas.length; i1++) {
            if (selectedDatas[i1] == oldData) {
                //console.log("=======updateEditRow====selectedDatas[i]=========", selectedDatas[i]);
                selectedDatas[i1] = newData;
                break;
            }
        }

        var allStateDatas = this.state.data;
        for (var i2 = 0; i2 < allStateDatas.length; i2++) {
            if (allStateDatas[i2] == oldData) {
                //console.log("=======updateEditRow====allStateDatas[i]=========", allStateDatas[i]);
                allStateDatas[i2] = newData;
                break;
            }
        }

        this.refreshTable();
    }

    /* public - set one row end edit mode */
    stopCancelEditRow = (rowData) => {
    	this.state.showPageCreate=false;
    	if (!rowData) {
            return;
        }

        rowData._rt_in_edit = false;

        var selectedDatas = this.currentData;
        for (var i = 0; i < selectedDatas.length; i++) {
            if (selectedDatas[i] == rowData) {
                //console.log("===========selectedDatas[i]=========", selectedDatas[i]);
                selectedDatas[i] = rowData._rt_base_data;
                if(rowData._rt_new_data == true){
                    selectedDatas.splice(i, 1);
                    //i--;
                }
                break;
            }
        }

        var allStateDatas = this.state.data;
        for (var i1 = 0; i1 < allStateDatas.length; i1++) {
            if (allStateDatas[i1] == rowData) {
                //console.log("===========allStateDatas[i]=========", allStateDatas[i]);
                allStateDatas[i1] = rowData._rt_base_data;
                if(rowData._rt_new_data == true){
                    allStateDatas.splice(i1, 1);
                    //i1--;
                }
                break;
            }
        }

        //console.log("==========selectedDatas==========", selectedDatas);
        //this.clearRowData(rowData);

        var isEditMode = false;
        for (var i2 = 0; i2 < selectedDatas.length; i2++) {
            if (selectedDatas[i2]._rt_in_edit == true) {
                isEditMode = true;
            }
        }
        this.setState({ isEditMode: isEditMode });
    }

    /* public - set one row end edit mode */
    stopSaveEditRow = (rowData) => {
    	this.state.showPageCreate=false;
    	if (!rowData) {
            return;
        }
        rowData._rt_in_edit = false;
        this.clearRowData(rowData);

        var selectedDatas = this.currentData;
        if (!selectedDatas) {
            selectedDatas = [];
        }
        var isEditMode = false;
        for (var i = 0; i < selectedDatas.length; i++) {
            if (selectedDatas[i]._rt_in_edit == true) {
                isEditMode = true;
            }
        }
        this.setState({ isEditMode: isEditMode });
    }



    /* public - set field value */
    setFieldValue = (rowData, col, value) => {
        lodash.set(rowData, col.field, value);
        this.setState({ isEditMode: this.state.isEditMode });
    }

    /* public - get edit datas */
    getEditDataList = () => {
        var selectedDatas = this.currentData;
        if (!selectedDatas || !this.state.isEditMode) {
            selectedDatas = [];
        }
        //_rt_in_edit
        var editingList = [];
        for(var i = 0; i < selectedDatas.length; i++){
            var data = selectedDatas[i];
            if(data._rt_in_edit){
                // editingList.push(this.copyData(data));
                editingList.push(data);
            }
        }
        /*
        editingList.map((item) => {
            delete item._rt_checked;
            delete item._rt_in_edit;
            //delete item._rt_base_data;
            delete item._rt_new_data;
        });
        */
        return editingList;
    }

    /* public - button hide */
    hideButton = (ids) => {
        var buttons = this.state.buttons;
        if (!buttons) {
            buttons = [];
        }
        if (!ids.length) {
            ids = [ids];
        }
        for (var j = 0; j < ids.length; j++) {
            for (var i = 0; i < buttons.length; i++) {
                if (buttons[i].id == ids[j]) {
                    buttons[i].hide = true;
                    break;
                }
            }
        }
        this.setState({ buttons: buttons });
    }

    /* public - button show */
    showButton = (ids) => {
        var buttons = this.state.buttons;
        if (!buttons) {
            buttons = [];
        }
        if (!ids.length) {
            ids = [ids];
        }
        for (var j = 0; j < ids.length; j++) {
            for (var i = 0; i < buttons.length; i++) {
                if (buttons[i].id == ids[j]) {
                    buttons[i].hide = false;
                    break;
                }
            }
        }
        this.setState({ buttons: buttons });
    }

    /* public - button show only, other buttons will hide */
    onlyShowButton = (ids) => {
        var buttons = this.state.buttons;
        if (!buttons) {
            buttons = [];
        }
        if (!ids.length) {
            ids = [ids];
        }

        for (var i = 0; i < buttons.length; i++) {
            buttons[i].hide = true;
            for (var j = 0; j < ids.length; j++) {
                if (buttons[i].id == ids[j]) {
                    buttons[i].hide = false;
                }
            }
        }
        this.setState({ buttons: buttons });
    }

    /* public - create new record */
    createNewRecord = (record) => {
        if (!record) {
            record = {};
        }
        this.newAddedRecord = record;
        this.newAddedRecord._rt_new_data = true;
        this.newAddedRecord._rt_base_data = this.copyData(record);
        this.newAddedRecord._rt_in_edit = true;
        this.openEditModel();
    }

    /* public - close edit model */
    closeEditModel = () => {
        this.setState({ isEditMode: false, data: this.beforeEditData });
    }

    /* public - open edit model */
    openEditModel = () => {
        //this.beforeEditData = this.copyData(this.state.data);
        this.setState({ isEditMode: true });
    }

    clearRowData = (rowData) => {
        if (!rowData) {
            return;
        }
        delete rowData._rt_checked;
        delete rowData._rt_in_edit;
        delete rowData._rt_base_data;
        delete rowData._rt_new_data;
    }

    copyData = (baseData) => {
        if (!baseData) {
            return baseData;
        }
        var datasStr = JSON.stringify(baseData);
        if (datasStr) {
            return JSON.parse(datasStr);
        }
        return undefined;
    }

    getRowCount = (dataList) => {
        if(!dataList){
            return 0;
        }
        var count = 0;
        for(var a1 = 0; a1 < dataList.length; a1++){
            var rowData = dataList[a1];
            if(!rowData._rt_new_data){
                count++;
            }
        }
        return count;
    }

    render() {

        //console.log(" # ResponseTable | render ");

        var pageMode = this.props.pageMode;
        if (!pageMode && pageMode != 0) {
            pageMode = 1;
        }
        var onePageCount = this.state.onePageCount;
        if (!onePageCount || onePageCount < 1) {
            onePageCount = 25;
        }
        var selectPageIndex = this.state.selectPageIndex;
        if (!selectPageIndex || selectPageIndex < 1) {
            selectPageIndex = 1;
        }

        // var datas = [];
        // var datasStr = JSON.stringify(this.state.data);
        // if(datasStr){
        //     datas = JSON.parse(datasStr);
        // }

        var datas = this.state.data;

        if (!datas) {
            datas = [];
        }
        var header = this.props.header;

        var totalCount = datas.length;
        var totalPageCount = totalCount % onePageCount > 0 ? Math.ceil(totalCount / onePageCount) : Math.floor(totalCount / onePageCount);
        if (selectPageIndex > totalPageCount) {
            selectPageIndex = totalPageCount;
        }
        var startIndex = (selectPageIndex - 1) * onePageCount;
        if (startIndex + 1 > totalCount) {
            startIndex = totalPageCount == 0 ? 0 : (totalPageCount - 1) * onePageCount; //最后一页
        }

        //0:不分页, 1: 客户端分页 (default), 2: 服务端分页
        if (pageMode == 2) { //2: 服务端分页, 以 props 为准
            onePageCount = this.props.onePageCount;
            selectPageIndex = this.props.selectPageIndex;
            totalCount = this.props.totalCount;
            if (!selectPageIndex || selectPageIndex < 1) {
                selectPageIndex = 1;
            }
            if (!totalCount || totalCount < 1) {
                totalCount = 0;
            }
        }

        // /* sort - begin */
        if(!this.state.isEditMode){
            this.sortDatas(datas, header);
        }
        // /* sort - end */

        var showDatas = [];
        if (this.state.isEditMode) {//只有save之后才能结束 edit mode
            showDatas = this.currentData;
            if (this.newAddedRecord) {
                showDatas.unshift(this.newAddedRecord);
                datas.push(this.newAddedRecord);//???
                this.newAddedRecord = undefined;
            }
        } else {
            if (pageMode == 1) {
                showDatas = datas.slice(startIndex, startIndex + onePageCount);
            } else {
                showDatas = datas.slice(0, datas.length);
            }

            /* sort - begin */
            // this.sortDatas(showDatas, header);
            /* sort - end */

            this.currentData = showDatas;
        }

        var selectMode = this.props.selectMode;
        if (!selectMode) {
            selectMode = 0;
        }

        //--------
        var selectedCount = 0;
        if (selectMode == 1 || selectMode == 3) {//have checkbox, only select one record for all page; 
            datas.map((item) => {
                if (item._rt_checked == true) {
                    selectedCount++;
                }
                return datas.map;
            });
        } else if (selectMode == 2 || selectMode == 4) {//have checkbox, only select one record for current page; 
            this.currentData.map((item) => {
                if (item._rt_checked == true) {
                    selectedCount++;
                }
               
            });
        }
        //--------


        var style = {};
        if (this.props.width && this.state.fixWidth) {
            style.width = this.props.width;
        }

        var tableWidth = this.props.tableWidth;
        if (!tableWidth) {
            tableWidth = { width: '100%' };
        }

        var showPageSelect = this.props.showPageSelect;
        if (!showPageSelect) {
            showPageSelect = false;
        }

        var totalCount2 = this.getRowCount(datas);
        
        
        
        
        this.state.buttons = this.props.buttons;
       
        return (
            <div style={tableWidth}>
                {/* <span>pageMode: {pageMode}</span>
                <span> | selectMode: {selectMode}</span> */}
                {this.genButtons()}
                <div className="_NMT_tableTop">
                    <Row>
                        <Col style={{ textAlign: 'left' }} xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                            
                            {selectMode > 0 && (this.state.showPageCreate||totalCount2>0) && <span className="_NMT_tableTotalRecord"><span style={{color:selectedCount>0?"#FB4F03":"#000000"}}>{selectedCount}</span> record(s) selected</span>}
                            
                            {selectMode > 0 && (this.state.showPageCreate||totalCount2>0) && <span className="_NMT_tableTotalRecord">|</span>}
                            
                            {  <span className="_NMT_tableTotalRecord">{totalCount2} record(s) matched</span>}

                            {
                                showPageSelect ? (
                                    pageMode != 0 && (
                                        <MySelect
                                            name="page_list_name"
                                            style={{ width: '160px', marginRight: '15px'  }}
                                            options={pageCounts}
                                            value={onePageCount}
                                            onChange={this.handlerOptionChange}
                                            disabled={this.state.isEditMode}
                                        />
                                    )
                                ) : (
                                        <div></div>
                                    )
                            }
                               



                        </Col>
                        {(this.state.showPageCreate||totalCount2>0) && pageMode != 0 && (<Col style={{ textAlign: 'right' }} xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
                            <MyPagination
                                size="small"
                                className="_NMT_Pagination"
                                disabled={this.state.isEditMode}
                                current={selectPageIndex}
                                onChange={this.handlerPageChange}
                                pageSize={onePageCount}
                                total={totalCount}
                            />
                        </Col>
                        )}
                    </Row>
                </div>
                <div className="_NMT_table">
                    <div className="_NMT_tableMain" style={{ ...style }}>
                        {(this.state.showPageCreate||totalCount2>0) &&  this.genHeader(header)}
                        {(this.state.showPageCreate||totalCount2>0) &&  this.genRows(header, showDatas)}
                        {(this.state.showPageCreate||totalCount2>0) &&  (!showDatas || showDatas.length < 1) && this.genNoData()}
                    </div>
                </div>
            </div>
        );
    }
}

export default ResponseTableFixColWidth;
