import React from 'react';

//export const TDC_SSS_BASE_URL = "http://192.168.0.34:9092";
export const TDC_SSS_BASE_URL = "https://successstories-tw.twds.tophenix.com";
//client timeout->Minute
export const CLIENT_TIMEOUT_SET = 15; //Minute

export const successStory_UI_CONFIG = {	
    config: 'user_page',
    url: TDC_SSS_BASE_URL+'/ssls'
};

export const DATA_UI_CONFIG = {	
		config: 'user_page',
        url: TDC_SSS_BASE_URL+'/ssls',
		loginAppUrl: TDC_SSS_BASE_URL+ '/ssls/userServer/getLoginUserEntity',
		loginPageUrl: TDC_SSS_BASE_URL+'/ssls/login.html',
		logoutPageUrl: TDC_SSS_BASE_URL+'/tdclogout/logout.html'
    }
;

export const LEVEL_UI_CONFIG = {	
		config: 'user_page',
        url: TDC_SSS_BASE_URL+'/ssls'
    }
;
export const REPORT_UI_CONFIG = {	
    config: 'user_page',
    url: TDC_SSS_BASE_URL+'/ssls'
};

export const SECURITY_UI_CONFIG = {	
    config: 'user_page',
    url: TDC_SSS_BASE_URL+'/ssls'
};

export const BLOB_UI_CONFIG = {	
    config: 'blob_config',
    url: 'https://tdcsssblob.blob.core.windows.net/tdcsssblob/'
};

export const permissions = {	

   permission1:'createLeads',
   permission2:'submitLeads',
   permission3:'editLeads',
   permission4:'viewLeads',
   permission5:'searchLeads',
   permission6:'reviewLeads',
   permission7:'approveArchiveLeads',
   permission8:'releaseLeads',
   permission9:'publishLeads',
   permission10:'rejectLeads',
   permission11:'reopenLeads',
   permission12:'exportLeads',
   permission13:'reportGeneration',
   permission14:'userRoleMaintenance',
   permission15:'userMaintenance',
   permission16:'eventTopicThemeTagMan'
};


export const getUIConfig = (config) => {
    var filter = DATA_UI_CONFIG.filter(c => (c.config === config));
    if (filter.length > 0) {
        return filter[0].url;
    } else {
        return {
            config: config
        };
    }
}
